import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from 'jose';
import * as React from "react";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BiFilterAlt, BiSortAlt2 } from "react-icons/bi";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fromString } from 'uint8arrays/from-string';
import { API_URL, REACT_APP_HKEY, REACT_APP_JKEY, REACT_APP_GA_MEASUREMENT_ID } from "../../../../constants";
import {
  addToCart,
  removeFromWishlistAction,
  setRoute
} from "../../../../store/reducers/userActions";
import { useStore } from "../../../../store/store";
import { getDiscountPercent } from "../../../../utils/getDiscountPercent";
import useAxios from "../../../../utils/useAxios";
import ChatBot from "../../../global_component/ChatBot/ChatBot";
import Footer from "../../../global_component/Footer/Footer";
import LoginRegisterPopup from "../../../global_component/LoginRegisterPopup/LoginRegisterPopup";
import LowerNavigation from "../../../global_component/LowerNavigation/LowerNavigation";
import Pagination from "../../../global_component/Pagination/Pagination";
import NavRoute from "../../Nav_Route/NavRoute";
import { ReactComponent as Heart } from "../Products_Detail/img/heart.svg";
import StarRating from "../Products_Detail/StarRating/StarRating";
import Brand from "./Filter_Components/Brand";
import Color from "./Filter_Components/Color";
import Discount from "./Filter_Components/Discount";
import Price from "./Filter_Components/Price";
import Thickness from "./Filter_Components/Thickness";
import "./NewProductList.css";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function NewProductList() {
  const [state, dispatch] = useStore();
  const [isChecked, setIsChecked] = useState(false);
  const [products, setProducts] = useState([]);
  const [saveProducts, setSaveProducts] = useState([]);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [filterValue, setFilterValue] = useState("Relevance");
  const api = useAxios();
  const [color, setColor] = useState([]);
  const [colorIndex, setColorIndex] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [maxPriceLimit, setMaxPriceLimit] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [brandToShow, setBrandToShow] = useState("");
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [discountFilter, setDiscountFilter] = useState(0);
  const [discountVal, setDiscountVal] = useState([]);
  const [thicknessVal, setThicknessVal] = useState([]);
  const [maxThickness, setMaxThickness] = useState(0);
  const [minThickness, setMinThickness] = useState(0);
  const [stateActive, setStateActive] = useState(0);
  const [stateDrawer, setStateDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterDrawer, setFilterDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [filterShow, setFilterShow] = useState("Brand");
  const [string, setString] = useState("uh-oh, something went wrong");
  const [xH, setXH] = useState("");
  const drawer = (
    <div
      style={{ marginTop: "60px", backgroundColor: "#E5E5E5", height: "90vh" }}
    >
      <List style={{ width: "160px" }}>
        {[
          "Brand",
          "Price",
          "Discount",
        ].map((text, index) => (
          <ListItem key={text} disablePadding className={filterShow === text?"filter_item_active":"filter_item"}>
            <ListItemButton
              onClick={() => {
                setFilterShow(text);
              }}
            >
              <ListItemText primary={text} className="filterSlider" />
            </ListItemButton>
          </ListItem>
        ))}
        {filterList?.map((text, index) => (
          <ListItem key={text} disablePadding className={filterShow === text?"filter_item_active":"filter_item"}>
            <ListItemButton
              onClick={() => {
                setFilterShow(text);
              }}
            >
              <ListItemText primary={text} className="filterSlider" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open, oper, num) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    oper && setFilterValue(oper);
    num && setStateActive(num);

    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  const filterToggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setFilterDrawer({ ...filterDrawer, [anchor]: open });
  };
  // console.log("discountVal",discountVal);
  const list1 = (anchor) => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false, "", 0)}
    >
      <List>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span className="DrawerSortBy">SORT BY</span>
          <span
            className="DrawerSortBy"
            style={{ color: "#FF7253" }}
            onClick={() => {
              setFilterValue("");
              setStateActive(0);
              setStateDrawer({ ...stateDrawer, [anchor]: false });
            }}
          >
            Clear
          </span>
        </div>
        <Divider />
        <List>
          <div
            className="DrawerSortBy_Detail"
            onClick={toggleDrawer(anchor, false, "", 1)}
          >
            <span>Relevance</span>
            <IoRadioButtonOffSharp
              className={stateActive === 1 ? "activeStateClass" : ""}
            />
          </div>
          <div
            className="DrawerSortBy_Detail"
            onClick={toggleDrawer(anchor, false, "Newest First", 2)}
          >
            <span>Latest</span>
            <IoRadioButtonOffSharp
              className={stateActive === 2 ? "activeStateClass" : ""}
            />
          </div>
          <div
            className="DrawerSortBy_Detail"
            onClick={toggleDrawer(anchor, false, "Low to High", 3)}
          >
            <span>Price Low to High</span>
            <IoRadioButtonOffSharp
              className={stateActive === 3 ? "activeStateClass" : ""}
            />
          </div>
          <div
            className="DrawerSortBy_Detail"
            onClick={toggleDrawer(anchor, false, "High to Low", 4)}
          >
            <span>Price High to Low</span>
            <IoRadioButtonOffSharp
              className={stateActive === 4 ? "activeStateClass" : ""}
            />
          </div>
          <div
            className="DrawerSortBy_Detail"
            onClick={toggleDrawer(anchor, false, "Discount", 5)}
          >
            <span>Discount</span>
            <IoRadioButtonOffSharp
              className={stateActive === 5 ? "activeStateClass" : ""}
            />
          </div>
        </List>
      </List>
    </Box>
  );

  const list2 = (anchor) => (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          zIndex: "11111",
          marginTop: "10px",
        }}
      >
        <span
          className="DrawerSortBy"
          style={{ fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }}
        >
          Filters
        </span>
        <span
          className="DrawerSortBy"
          style={{ color: "#FF7253" }}
          onClick={() => {
            setFilterShow("Brand");
            setFilterDrawer({ ...filterDrawer, [anchor]: false });
          }}
        >
          Clear
        </span>
      </div>
      <Box
        sx={{ width: "100%", height: "100vh" }}
        role="presentation"
        className="listTree"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Drawer variant="permanent" className="DrawerVariant" open>
          {drawer}
        </Drawer>
      </Box>
      <div
        style={{
          margin: "0px",
          position: "absolute",
          display: "flex",
          top: "55px",
          left: "175px",
          width: "100%",
        }}
      >
        {filterShow === "Brand" ? (
          <Brand
            Category={query}
            setBrandToShow={setBrandToShow}
            max={maxPrice}
            min={minPrice}
          />
        ) : filterShow === "Price" && maxPriceLimit!==undefined ? (
          <Price
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            minPrice={minPrice}
            setMinPrice={setMinPrice}
            maxPriceLimit={maxPriceLimit}
            setMinMaxPriceZero={setMinMaxPriceZero}
            newSliderOnChange={newSliderOnChange}
            widthClass
          />
        ) : filterShow === "color" ? (
          <Color
            setColor={setColor}
            color={color}
            colorIndex={colorIndex}
            setColorIndex={setColorIndex}
            setCurrentPage={setCurrentPage}
          />
        ) : filterShow === "thickness" ? (
          <Thickness
            thicknessVal={thicknessVal}
            setThicknessVal={setThicknessVal}
            newThicknessSliderOnChange={newThicknessSliderOnChange}
            minThickness={minThickness}
            maxThickness={maxThickness}
            setMinMaxThicknessZero={setMinMaxThicknessZero}
          />
        ) : filterShow === "Discount" ? (
          <Discount
            discountOnChange={discountOnChange}
            discountVal={discountVal}
            setDiscountVal={setDiscountVal}
          />
        ) : (
          "not ready"
        )}
      </div>
      <div style={{display: "flex",width: "100%",position: "absolute",bottom: "0px",left: "0px",zIndex: "11111"}} className="FilterShadowss">
        <button style={{width:"50%",padding:"19px",border:"none",backgroundColor:"#ffffff",fontFamily:"Poppins",color:"#C4C4C4",textTransform:"uppercase",fontSize:"20px",fontWeight:"400"}} onClick={() => {
              setFilterShow("Brand");
              setFilterDrawer({ ...filterDrawer, [anchor]: false });
            }}>Close</button>
        <button style={{width:"50%",padding:"19px",border:"none",backgroundColor:"#ffffff",fontFamily:"Poppins",borderLeft:"0.5px solid #C4C4C4",color:"#FF7253",textTransform:"uppercase",fontSize:"20px",fontWeight:"400"}} onClick={() => {
              setFilterShow("Brand");
              setFilterDrawer({ ...filterDrawer, [anchor]: false });
            }}>Apply</button>
      </div>
    </>
  );

  const alert = useAlert();

  const setMinMaxPriceZero = () => {
    setMaxPrice(0);
    setMinPrice(0);
  };

  const setMinMaxThicknessZero = () => {
    setMaxThickness(0);
    setMinThickness(0);
  };

  const newSliderOnChange = (e, newValue) => {
    e.preventDefault();
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
  };

  const newThicknessSliderOnChange = (e, newValue) => {
    e.preventDefault();
    setMinThickness(newValue[0]);
    setMaxThickness(newValue[1]);
  };

  const discountOnChange = (e, newValue) => {
    const sortedArray = newValue.sort((a, b) =>
      a.checked && b.checked && a.discountPercent > b.discountPercent ? 1 : -1
    );
    setDiscountFilter(sortedArray[0].discountPercent);
    // console.log("highestdiscountpercent " + sortedArray[0].discountPercent)
  };

  const navigate = useNavigate();
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const useQuery = () => new URLSearchParams(useLocation().search);

  let query = useQuery();
  useEffect(() => {
    setMinMaxPriceZero();
    setMinMaxThicknessZero();
  }, [
    query.get("group"),
    query.get("subCategory"),
    query.get("subcategory"),
    query.get("category"),
    query.get("brand"),
  ]);


  ReactGA.send({
    hitType: 'pageview',
    page: `/productlist`,
    title : 'New Product List'
  })

  useEffect(() => {
    setCurrentPage(1);
    setColor([]);
    setBrandToShow("");
    setColorIndex([]);
    setIsLoadingProducts(false);
    setFilterValue("Relevance");
    setMinMaxPriceZero();
    setMinMaxThicknessZero();
  }, [filterList]);

  useEffect(() => {
    setIsLoadingProducts(true);
    setCurrentPage(1);
    setColor([]);
    setBrandToShow("");
    setColorIndex([]);
    setIsLoadingProducts(false);
    setFilterValue("Relevance");
    // setFilterList([]);
    // setMaxThickness(0);
    // setMinThickness(0);
    window.scroll(0, 0);
  }, [
    query.get("group"),
    query.get("subCategory"),
    query.get("subgroup"),
    query.get("subGroup"),
    query.get("subcategory"),
    query.get("category"),
    query.get("brand"),
  ]);
  const location = useLocation();
  useEffect(() => {
    // fetch product useEffect
    async function fetchData(){
      var dec = genSaltSync(10);
      var hash = hashSync(REACT_APP_HKEY, dec);
      const secret = fromString(REACT_APP_JKEY);
      const twj = await new jose.SignJWT({ expiry: hash }).setProtectedHeader({ alg: "HS256" }).setExpirationTime("5s").sign(secret);
      setIsLoadingProducts((isLoadingProducts) => {
        setIsLoadingProducts(true);
      });
      // console.log("setIsLoadingProducts(true);" + isLoadingProducts)
      setProducts([]);
      document.title = "Loading products - PLYPICKER";
      setIsError(false);
      if (query.get("group")) {
        // console.log(1);
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&group=${query.get(
              "group"
            )}${
              color?.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
  
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setCount(res.data.count);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          dispatch(
            setRoute({
              categoryName: res.data.data[0]?.category?.name,
              categoryId: res.data.data[0]?.category?._id,
              subCategoryName: res.data.data[0]?.subcategory?.Sub_Category_name,
              subCategoryId: res.data.data[0]?.subcategory?._id,
              groupName: res.data.data[0]?.group?.Group_name,
              groupId: res.data.data[0]?.group?._id,
              productName: null,
              productId: null,
            })
          );
          document.title = `${res.data.data[0]?.group?.Group_name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else if (query.get("brand")) {
        // console.log(2);
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&brand=${query.get(
              "brand"
            )}${
              color.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setCount(res.data.count);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          document.title = `${res.data.data[0]?.brand?.Brand_name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else if (query.get("subgroup") || query.get("subGroup")) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&subgroup=${query.get(
              "subgroup"
            )}${
              color?.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setCount(res.data.count);
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          dispatch(
            setRoute({
              categoryName: res.data.data[0]?.category?.name,
              categoryId: res.data.data[0]?.category?._id,
              subCategoryName: res.data.data[0]?.subcategory?.Sub_Category_name,
              subCategoryId: res.data.data[0]?.subcategory?._id,
              groupName: null,
              groupId: null,
              productName: null,
              productId: null,
            })
          );
          document.title = `${res.data.data[0]?.subgroup?.Subgroup_name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else if (
        query.get("category") ||
        (query.get("subcategory")?.length === 0 &&
          query.get("group")?.length === 0)
      ) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&category=${query.get(
              "category"
            )}${
              color?.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setCount(res.data.count);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          dispatch(
            setRoute({
              categoryName: res.data.data[0]?.category?.name,
              categoryId: res.data.data[0]?.category?._id,
              subCategoryName: null,
              subCategoryId: null,
              groupName: null,
              groupId: null,
              productName: null,
              productId: null,
            })
          );
          document.title = `${res.data.data[0]?.category?.name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else if (query.get("subCategory") || query.get("subcategory")) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&subcategory=${query.get(
              "subcategory"
            )}${
              color?.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setCount(res.data.count);
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          dispatch(
            setRoute({
              categoryName: res.data.data[0]?.category?.name,
              categoryId: res.data.data[0]?.category?._id,
              subCategoryName: res.data.data[0]?.subcategory?.Sub_Category_name,
              subCategoryId: res.data.data[0]?.subcategory?._id,
              groupName: null,
              groupId: null,
              productName: null,
              productId: null,
            })
          );
          document.title = `${res.data.data[0]?.subcategory?.Sub_Category_name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else if (query.get("category")) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12&category=${query.get(
              "category"
            )}${color?.length !== 0 ? `&color=` + color : []}${
              maxPrice > 0 ? `&max=` + maxPrice : 0
            }${minPrice > 0 ? `&min=` + minPrice : 0}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setCount(res.data.count);
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          dispatch(
            setRoute({
              categoryName: res.data.data[0]?.category?.name,
              categoryId: res.data.data[0]?.category?._id,
              subCategoryName: null,
              subCategoryId: null,
              groupName: null,
              groupId: null,
              productName: null,
              productId: null,
            })
          );
          document.title = `${res.data.data[0]?.category?.name} - PLYPICKER`;
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      } else {
        (async () => {
          const res = await axios.get(
            `${API_URL}/products?page=${currentPage}&perPage=12${
              color?.length !== 0 ? `&color=` + color : []
            }&max=${maxPrice}&min=${minPrice}${
              discountVal?.length !== 0 ? `&discount=` + discountVal : []
            }&sortBy=${filterValue}&brand=${brandToShow}&maxThickness=${maxThickness}&minThickness=${minThickness}`,
            {
              headers: {
                "Content-Policy": twj
              },
            }
          );
          if (res.data.data?.length === 0) {
            setIsError(true);
            setIsLoadingProducts(false);
          } else {
            setIsError(false);
          }
          setCount(res.data.count);
          setProducts(res.data.data);
          setSaveProducts(res.data.data);
          setMaxPriceLimit(res.data.maxPrice.discounted_price);
          setIsLoadingProducts((isLoadingProducts) => {
            setIsLoadingProducts(false);
          });
          // console.log("setIsLoadingProducts(false);" + isLoadingProducts)
        })();
      }
      setIsLoadingProducts(false);
    }
    fetchData();
  }, [
    query.get("group"),
    query.get("subCategory"),
    query.get("subcategory"),
    query.get("subgroup"),
    query.get("category"),
    query.get("brand"),
    currentPage,
    color,
    maxPrice,
    discountVal,
    minPrice,
    filterValue,
    brandToShow,
    minThickness,
    maxThickness,
  ]);

  useEffect(() => {
    async function fetchData(){
      // filtersToShow useEffect
      if (query.get("group")) {
        // console.log(1);
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?group=${query.get("group")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else if (query.get("subgroup")) {
        // console.log(1);
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?subgroup=${query.get("subgroup")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else if (query.get("brand")) {
        // console.log(2);
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?brand=${query.get("brand")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else if (
        query.get("category") ||
        (query.get("subcategory")?.length === 0 &&
          query.get("group")?.length === 0)
      ) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?category=${query.get("category")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else if (query.get("subCategory") || query.get("subcategory")) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?subcategory=${query.get("subcategory")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else if (query.get("category")) {
        (async () => {
          const res = await axios.get(
            `${API_URL}/filtersToShow?category=${query.get("category")}`,
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          setFilterList(res?.data?.filtersToShow);
        })();
      } else {
        (async () => {
          const res = await axios.get(`${API_URL}/filtersToShow`, {
            headers: {
              // name:'xyz'
            },
          });
          setFilterList(res?.data?.filtersToShow);
        })();
      }
    }
    fetchData();
  }, [
    query.get("group"),
    query.get("subCategory"),
    query.get("subcategory"),
    query.get("subgroup"),
    query.get("category"),
    query.get("brand"),
  ]);

  useEffect(() => {
    const sortedByDiscountFilter = products.sort((a, b) =>
      getDiscountPercent(a.price, a.discounted_price) >
      getDiscountPercent(b.price, b.discounted_price)
        ? 1
        : -1
    );
  }, [discountFilter]);
  // console.log("discounts",discountFilter);
  const addToWishListHandler = async (p_id) => {
    if (state?.accessToken === null || !state?.authenticated) {
      setIsLoginPopupVisible(true);
      alert.error("PLEASE SIGN IN FIRST!");
    } else {
      const res = await api.post(`/wishlist`, {
        p_id: p_id,
      });

      // console.log(res);

      if (!res.data.error) {
        alert.success(res.data.msg);
      } else {
        alert.error(res.data.error);
      }
      dispatch(addToCart(p_id));
    }
  };

  const removeFromWishlist = async (id) => {
    try {
      dispatch(removeFromWishlistAction(id));
      const res = await api.delete(`/wishlist/${id}`, {
        headers: {
          // name:'xyz'
        },
      });
      if (res.data.error) {
        alert.error(res.data.error);
      } else {
        alert.success("Removed from wishlist");
      }
    } catch (err) {
      // console.log(err);
    }
  };

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  function removeFromWishlistHandler(pid) {
    if (state?.authenticated || !state?.accessToken === null) {
      removeFromWishlist(pid);
    } else {
      setIsLoginPopupVisible(true);
    }
  }
  function numberWithCommas(x) {
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x?.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x?.toString().split(".")[0].length - 3)
      : x?.toString();
  }
  const RenderComponent2 = ({ product }) => {
    return (
      <>
        <div className="newProductContainer">
          {!isMobile ? (
            <Link
              to={`/product/${product._id}`}
              target="_blank"
              className="renderComp2Link"
            >
              <div className="newProductImage">
                <img
                  src={product.attrs?.imgs[0] ? product.attrs?.imgs[0] : ""}
                  alt=""
                  className="renderComponent2Image"
                />
                <div
                  className="tablet_mobile_heart"
                  style={{ cursor: "pointer" }}
                >
                  {state?.user?.wishlist.includes(product._id) ? (
                    <Heart
                      className="selectedHeart"
                      onClick={() => removeFromWishlistHandler(product._id)}
                    />
                  ) : (
                    <Heart
                      className="greyColorHeart"
                      onClick={() => addToWishListHandler(product._id)}
                    />
                  )}
                </div>
              </div>
            </Link>
          ) : (
            <div className="newProductImage">
              <img
                src={product.attrs?.imgs[0] ? product.attrs?.imgs[0] : ""}
                alt=""
                className="renderComponent2Image"
                onClick={() => navigate(`/product/${product._id}`)}
              />
              <div
                className="tablet_mobile_heart"
                style={{ cursor: "pointer" }}
              >
                {state?.user?.wishlist.includes(product._id) ? (
                  <Heart
                    className="selectedHeart"
                    onClick={() => removeFromWishlistHandler(product._id)}
                  />
                ) : (
                  <Heart
                    className="greyColorHeart"
                    onClick={() => addToWishListHandler(product._id)}
                  />
                )}
              </div>
            </div>
          )}
          <div className="d-flex flex-column align-items-start justify-content-center">
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <Link
                to={`/product/${product._id}`}
                target="_blank"
                className="renderComp2Link renderComp2NameLink"
              >
                <div className="newProductName">{product.product_name}</div>
              </Link>
              <div className="newProductFavourite">
                {state?.user?.wishlist.includes(product._id) ? (
                  <Heart
                    className="selectedHeart"
                    onClick={() => removeFromWishlistHandler(product._id)}
                  />
                ) : (
                  <Heart
                    className="greyColorHeart"
                    onClick={() => addToWishListHandler(product._id)}
                  />
                )}
              </div>
            </div>
            <Link
              to={`/product/${product._id}`}
              target="_blank"
              className="renderComp2Link"
            >
              <div className="newProductBrandName">
                {product.brand?.Brand_name}
              </div>
              {product.discounted_price ? (
                <div className="newProductPrice">
                  <span className="price">
                    Rs. {numberWithCommas(product.discounted_price)}
                  </span>
                  <span className="reducedprice">
                    Rs. {numberWithCommas(product.price)}
                  </span>
                  <span className="discount">
                    -{Math.round(product.discounted_percent)}%
                  </span>
                </div>
              ) : (
                <div className="newProductPrice">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/product/${product?._id}`);
                    }}
                    className="addUpdateButton my-0"
                  >
                    Get price
                  </button>
                </div>
              )}
              <div className="newProductRating">
                <StarRating
                  stars={product.averageRating}
                  start
                  // stars={Math.round(rating / product.rating_and_review?.length)}
                  avgRate
                />
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
 
  return (
    <>
     <Helmet>
        <meta
          name="title"
          content={`Buy ${products[0]?.category.name} with plypicker at best price in maharastra | upto 75% discount | door step delivery within 48hr`}
        />
        <meta
          name="description"
          content={`Buy ${products[0]?.category.name} with plypicker at best price in maharastra and reduce your hassle of buying interior designing raw material from market and get your ${products[0]?.category.name}
          delivered at your door step in less than 48 hr so that your work never stops`}
        />
        <meta
          name="keywords"
          content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
        />
        <meta property="og:image" content="LOGO" />
        <link rel="canonical" href={`https://plypicker.com/productlist?category=${products?._id}`} />
        </Helmet>

      <div>
        {!isLoadingProducts && <ChatBot pageType="NPL" />}
        <LoginRegisterPopup
          isVisible={isLoginPopupVisible}
          setIsVisible={setIsLoginPopupVisible}
        />
        <NavRoute
          navRouter
          title="profile"
          brand={products[0]}
          brandToShow={query?.get("brand")}
          categoryId={query.get("category")}
          subCategoryId={query.get("subcategory")}
          groupId={query.get("group")}
          subGroupId={query.get("subgroup")}
          brandId={query.get("brand")}
        />
        <div className="newProductList">
          <div className="sortFilterDiv newUpperDiv">
            <div className="insideDiv1">Filters:</div>
            <div className="insideDiv2">
              <span className="d-inline sortBySpan"> SORT BY:</span>
              <div className="relevance d-inline">
                <select
                  className="nplSortSelector"
                  onChange={(e) => {
                    // console.log("e.target.value", e);
                    setFilterValue(e.target.value);
                    // this.onChangeColor.bind(this);
                  }}
                >
                  {/* <option>{filterValue}</option> */}
                  <option value="Relevance">Relevance</option>
                  <option value="Popularity">Popularity</option>
                  <option value="Low to High">Low to High</option>
                  <option value="High to Low">High to Low</option>
                  <option value="Newest First">Newest First</option>
                  <option value="Discount">Discount</option>
                </select>
              </div>
            </div>
          </div>
          <div className="sortFilterDiv mainProductDiv">
            <div className="insideDiv1">
              <div className="filterDiv">
                {/* <Brand Category={query.get("category")} /> */}
                <Brand
                  Category={query}
                  setBrandToShow={setBrandToShow}
                  max={maxPrice}
                  min={minPrice}
                />
              </div>
              {maxPriceLimit!==undefined &&(
              <>
              <Divider />
              <div className="filterDiv">
                <Price
                  maxPrice={maxPrice}
                  setMaxPrice={setMaxPrice}
                  minPrice={minPrice}
                  setMinPrice={setMinPrice}
                  maxPriceLimit={maxPriceLimit}
                  setMinMaxPriceZero={setMinMaxPriceZero}
                  newSliderOnChange={newSliderOnChange}
                />
              </div>
                </>)}
              <Divider />
              {filterList?.includes("color") ? (
                <>
                  <div className="filterDiv">
                    <Color
                      setColor={setColor}
                      color={color}
                      colorIndex={colorIndex}
                      setColorIndex={setColorIndex}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
              <Divider />
              {filterList.includes("thickness") ? (
                <>
                  <div className="filterDiv">
                    <Thickness
                      thicknessVal={thicknessVal}
                      setThicknessVal={setThicknessVal}
                      newThicknessSliderOnChange={newThicknessSliderOnChange}
                      minThickness={minThickness}
                      maxThickness={maxThickness}
                      setMinMaxThicknessZero={setMinMaxThicknessZero}
                    />
                  </div>
                  <Divider />
                </>
              ) : (
                <></>
              )}
              {/* {filterList?.includes('size') ? (<>
                            <div className="filterDiv">
                                <Size />
                            </div>
                            <Divider />
                        </>) : (<></>)} */}
              <div className="filterDiv">
                <Discount
                  discountOnChange={discountOnChange}
                  discountVal={discountVal}
                  setDiscountVal={setDiscountVal}
                />
              </div>
            </div>
            <div className="insideDiv2 d-flex flex-wrap flex-row">
              <div className="newMobileViewOptions">
                {["bottom"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                      <BiSortAlt2 />
                      Sort
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={stateDrawer[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      transitionDuration={600}
                    >
                      {list1(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
                {["bottom"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={filterToggleDrawer(anchor, true)}>
                      <BiFilterAlt />
                      Filter
                    </Button>
                    <Drawer
                      anchor={anchor}
                      open={filterDrawer[anchor]}
                      onClose={filterToggleDrawer(anchor, false)}
                      transitionDuration={600}
                      className="DrawerName"
                    >
                      {list2(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))}
              </div>
              {isLoadingProducts && (
                <div className="errorLoadingDiv">
                  {isLoadingProducts && (
                    <h4 className="text-center mb-0 errorLoadingPrompt">
                      Loading...
                    </h4>
                  )}
                  {isLoadingProducts && (
                    <h4 className="text-center mb-0 errorLoadingPrompt">
                      <CircularProgress style={{ color: "#ff7703" }} />
                    </h4>
                  )}
                </div>
              )}
              {isError && (
                <h4 className="text-center mt-3 noProductsFound">
                  No products found!
                </h4>
              )}
              {products?.length > 0 ? (
                <Pagination
                  data={products}
                  RenderComponent={RenderComponent2}
                  pageLimit={5}
                  dataLimit={12}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  count={count}
                  location={location}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <LowerNavigation />
        <Footer />
      </div>
    </>
  );
}

export default NewProductList;
