import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BsArrowLeft, BsAsterisk } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  RZR_PAY_KEY_ID,
  REACT_APP_GA_MEASUREMENT_ID,
} from "../../../../../constants";
import {
  getAddresses,
  setCartData,
  setCartDataNull,
  setSelectedAdd,
  setWishListNull,
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import CartNavbar from "../../CartNavbar/CartNavbar";
import "../sidebar.css";
import "./Payment.css";
import thankYou from "./thankYou.png";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const RZR_PAY_ID = RZR_PAY_KEY_ID;
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};
function Payment() {
  const alert = useAlert();
  const [state, dispatch] = useStore();
  const [paymentMethod, setPaymentMethod] = useState("upi");
  const [cart, setCart] = useState([]);
  const [gst, setGst] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(
    state?.appliedCoupon || null
  );
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [gstRate, setGstRate] = useState(0.18);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);
  const [address, setAddress] = useState({});
  const [maxTotal, setMaxTotal] = useState(null);
  const [calgst, setCalGst] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [backendMrp, setBackendMrp] = useState(0);
  const [backendDiscountedPrice, setBackendDiscountedPrice] = useState(0);
  const [isLaborRequired, setIsLaborRequired] = useState(
    state.isLaborRequiredState || true
  );
  const [laborPrices, setLaborPrices] = useState([0, 0, 0, 0]);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [loadingUnloadingPrice, setLoadingUnloadingPrice] = useState(0);
  const [backendDiscount, setBackendDiscount] = useState(
    backendMrp - backendDiscountedPrice
  );
  const [backendGst, setBackendGst] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(
    state.selectedVehicle || 4
  );
  const api = useAxios();
  const [loading, setLoading] = useState(true);
  const [addState, setAddState] = useState(false);
  const [deliveryPrices, setDeliveryPrices] = useState([0, 0, 0, 0]);
  const [deliveryPricesWithGst, setDeliveryPricesWithGst] = useState([
    0, 0, 0, 0,
  ]);
  const [applicability, setApplicability] = useState(1);
  const [cartAddress, setCartAddress] = useState(
    state?.selectedAddress || { ...state?.user?.addresses[0] }
  );

  const [couponId, setCouponId] = useState(state?.appliedCoupon);

  const [phone, setPhone] = useState({});
  const [payment, setPayment] = useState("COD");
  const [tax, setTax] = useState(0);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [selectBuyCall, setSelectBuyCall] = useState("");
  const [selectedAddress, setSelectedAddress] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(0);
  const navigate = useNavigate();
  const [userLocation, setUserLocation] = useState({
    zoom: 14,
    latitude: null,
    longitude: null,
    accuracy: null,
  });
  const [onlyOnePaymentMethod, setOnlyOnePaymentMethod] = useState("");

  ReactGA.send({
    hitType: "pageview",
    page: "/payment",
    title: "Payment",
  });

  useEffect(() => {
    document.title = `Complete your Payment - PLYPICKER`;
    const getUserAddress = async () => {
      try {
        const res = await api.get("/me/addresses");
        if (res.status === 200) {
          dispatch(getAddresses(res.data.addresses));
        } else {
          alert.error(res.data.error);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserAddress();
    if (!state.selectedAddress) {
      dispatch(setSelectedAdd(state.user.addresses[0]));
    }
  }, [state.selectedAddress]);

  const createOrder = async () => {
    setIsCreatingOrder(true);
    setSelectedAddress(state.user.addresses[0]);
    setPayment("COD");
    const res = await api.post(`/orders`, {
      address: state.selectedAddress,
      phone: state.selectedAddress.mobile,
      payment_mode: "COD",
      quantity: totalQuantity,
      deliveryVehicle: selectedVehicle,
      isLaborRequired: isLaborRequired,
      couponCodeId: appliedCoupon?._id || null,
    });
    if (!res.data.error && res.data.order_id) {
      alert.success("Order Placed Successfully");
      setCart([]);
      setIsCreatingOrder(false);
      dispatch(setWishListNull());
      setShowThankYou(true);
      setTimeout(() => {
        navigate(`/orders`);
      }, 2500);
    } else {
      setShowThankYou(false);
      setIsCreatingOrder(false);
      if (res.data.error) {
        alert.error(res.data.error);
      } else {
        alert.error("An error occoured.");
      }
    }
    setSelectBuyCall("");
    dispatch(setSelectedAdd());
    dispatch(setCartDataNull());
  };

  const payAndCheckout = async () => {
    setIsCreatingOrder(true);
    setSelectedAddress(state.user.addresses[0]);
    setPayment("online");
    const result = await api.post("/orders", {
      address: state.selectedAddress,
      phone: state.selectedAddress.mobile,
      payment_mode: "PHONEPE",
      deliveryVehicle: selectedVehicle,
      isLaborRequired: isLaborRequired,
      couponCodeId: appliedCoupon?._id || null,
    });
    if (result.data.error) {
      alert.error("Server error. Are you online?");
      setIsCreatingOrder(false);
      setShowThankYou(false);
      return;
    }
    window.location.href = result.data.payment_url;
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const res = await api.get("/carts", {
        headers: { addressId: cartAddress?._id },
      });
      dispatch(setCartData(res.data.cartItems));
      res.data.cartItems ? setCart(res.data.cartItems) : setCart([]);
      setBackendMrp(res.data.mrpTotal);
      setLoadingUnloadingPrice(res.data.loadingUnloadingPrice);
      setBackendDiscountedPrice(res.data.total);
      setBackendDiscount(backendMrp - backendDiscountedPrice);
      setBackendGst(res.data.totalGst);
      setDeliveryPrices(res.data.deliveryPrices);
      setDeliveryPricesWithGst(res.data.deliveryPricesWithGst);
      setLaborPrices(res.data.priceForLabor);
      setTotalLaborCost(res.data.totalPriceForLabor);
      setApplicability(res.data.vehicle);
      if (res.data.error) {
        return;
      }
      setUserLocation({
        latitude: cartAddress?.lat,
        longitude: cartAddress?.lng,
        zoom: 17,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
      });
    }
    getData();
    setLoading(false);
  }, []);

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  useEffect(() => {
    async function getData() {
      const res = await api.get("/carts", {
        headers: { addressId: cartAddress?._id },
      });
      if (res.data.error) {
        return;
      }

      const commonPaymentModes = res?.data?.commonPaymentModes;

      if (commonPaymentModes?.length == 1) {
        setPaymentMethod(commonPaymentModes[0]);
        setOnlyOnePaymentMethod(commonPaymentModes[0]);
      }

      dispatch(setCartData(res.data.cartItems));
      setBackendMrp(res.data.mrpTotal);
      setBackendDiscountedPrice(res.data.total);
      setLoadingUnloadingPrice(res.data.loadingUnloadingPrice);
      setBackendDiscount(backendMrp - backendDiscountedPrice);
      setBackendGst(res.data.totalGst);
      setApplicability(res.data.vehicle);
      setDeliveryPrices(res.data.deliveryPrices);
      setDeliveryPricesWithGst(res.data.deliveryPricesWithGst);
      setLoading(false);
    }
    getData();
    document.title = `Complete your payment - PLYPICKER`;
  }, [cart]);

  async function onClickMap(event) {
    event.preventDefault();
  }

  function numberWithCommas(x, y) {
    if (y) {
      x = Math.round(x * 1e2) / 1e2;
      x = x.toFixed(2);
    }
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  useEffect(() => {
    let tot = 0;
    let calcTotal = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (isLaborRequired) {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        totalLaborCost +
        loadingUnloadingPrice;
    } else {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice;
    }
    if (appliedCoupon) {
      let appliedDiscount = (appliedCoupon?.discountPercentage / 100) * tot;
      if (
        (appliedDiscount > appliedCoupon?.discountMaxValue &&
          appliedCoupon?.discountMaxValue !== 0) ||
        appliedCoupon?.discountPercentage === 0
      ) {
        appliedDiscount = appliedCoupon?.discountMaxValue;
      }
      calcTotal = tot - appliedDiscount;
      gstVal = calcTotal * 0.18;
      totalPrice = parseFloat(calcTotal) + parseFloat(gstVal);
      setAppliedCouponDiscount(appliedDiscount);
    } else {
      setAppliedCouponDiscount(0);
      gstVal = tot * 0.18;
      totalPrice = parseFloat(tot) + parseFloat(gstVal);
    }
    setGst(gstVal.toFixed(2));
    setTotal(totalPrice.toFixed(2));
  }, [
    appliedCoupon,
    backendDiscountedPrice,
    deliveryPrices,
    selectedVehicle,
    loadingUnloadingPrice,
  ]);

  return (
    <>
      {showThankYou && (
        <>
          <div className="thankYouLargeContainer">
            <div className="thankYouContainer shadow d-flex flex-column align-items-center justify-content-center p-0">
              <div className="col-12 d-flex align-items-center justify-content-center mb-4 px-3">
                <img src={thankYou} className="thankYouImage" />
              </div>
              <p>If you want to claim GST, please check your email</p>
              <div className="col-12 d-flex align-items-center justify-content-center mt-4 px-3">
                <button
                  className="thankYouButton py-2"
                  onClick={() => {
                    navigate("/");
                    setShowThankYou(false);
                  }}
                >
                  Home Page
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {isMobile ? (
        <div className="MobileView">
          <span className="headingView">
            <BsArrowLeft
              onClick={() => navigate(-1)}
              style={{ fontSize: "30px" }}
            />{" "}
            Payment
          </span>
        </div>
      ) : (
        ""
      )}
      <CartNavbar currentStep="3" />
      {/* <NavRoute title="cart" /> */}
      <div className="container threeimage_container pt-2 mb-5 pb-5 px-5 px-lg-0">
        <div className="d-flex flex-lg-row flex-column">
          <div className="col-lg-8 col-12 overflow-y-auto">
            <h2 className="choosePaymentOptionHeading">
              CHOOSE PAYMENT OPTION
            </h2>
            <button
              style={{ textAlign: "start" }}
              className="paymentOption px-md-4 py-md-3 px-2 py-2"
              onClick={(e) => setPaymentMethod("online")}
              disabled={onlyOnePaymentMethod === "COD"}
            >
              <input
                type="radio"
                id="online"
                checked={paymentMethod === "online"}
                disabled={onlyOnePaymentMethod === "COD"}
                value="online"
                className="paymentRadioInputButton with-gap me-1"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              <label className="ms-sm-0 ms-3 paymentOptionOption" for="online">
                Online Payment
              </label>
            </button>
            <div
              className="paymentOption px-md-4 py-md-3 px-2 py-2"
              onClick={(e) => setPaymentMethod("COD")}
            >
              <input
                type="radio"
                id="COD"
                checked={paymentMethod === "COD"}
                value="COD"
                disabled={onlyOnePaymentMethod === "online"}
                className="paymentRadioInputButton me-1"
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
              <label className="ms-sm-0 ms-3 paymentOptionOption" for="COD">
                Cash on Delivery (UPI/Cash/Cards accepted)
              </label>
            </div>
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-3">
            <div className="w-100">
              <div className="renderside_cartside_total ms-lg-3 ms-0">
                <div className="cartside_total_heading">ORDER SUMMARY</div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">
                    Total MRP ({cart.length} items)
                  </div>
                  <div
                    className="cartside_total_amount"
                    style={{ textDecoration: "line-through" }}
                  >
                    Rs. {numberWithCommas(backendMrp, 2)}
                  </div>
                </div>
                <div className="cartside_total_discount">
                  <div className="cartside_total_text">Total Selling Price</div>
                  <div className="cartside_total_amount">
                    Rs. {numberWithCommas(backendDiscountedPrice, 2)}
                  </div>
                </div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Delivery</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1 ? (
                      "Select a delivery vehicle"
                    ) : (
                      <>
                        Rs.{" "}
                        {numberWithCommas(
                          deliveryPrices[selectedVehicle - 1],
                          2
                        )}
                      </>
                    )}
                  </div>
                </div>
                {loadingUnloadingPrice > 0 && (
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">Loading/Unloading</div>
                    <div className="cartside_total_amount">
                      Rs. {numberWithCommas(loadingUnloadingPrice, 2)}
                    </div>
                  </div>
                )}
                <div className="cartside_total_total">
                  <div className="cartside_total_text">
                    Labor{" "}
                    {isLaborRequired ? (
                      <>({cartAddress.floor} floors)</>
                    ) : (
                      <>(Not opted)</>
                    )}
                  </div>
                  <div className="cartside_total_amount">
                    {isLaborRequired ? (
                      <>Rs. {numberWithCommas(totalLaborCost, 2)}</>
                    ) : (
                      "Rs. 0"
                    )}
                  </div>
                </div>
                <div className="cartside_total_total">
                  {appliedCoupon && (
                    <div className="cartside_total_text">
                      Coupon ({appliedCoupon.codeName})
                    </div>
                  )}
                  <div className="cartside_total_total">
                    {appliedCoupon && (
                      <div className="cartside_total_amount">
                        {selectedVehicle < 1 ? (
                          "Select a delivery vehicle"
                        ) : (
                          <>- Rs. {appliedCouponDiscount.toFixed(2)}</>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cartside_total_total mb-4">
                  <div className="cartside_total_text">GST (18%)</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1
                      ? "Select a delivery vehicle"
                      : `Rs. ${gst}`}
                  </div>
                </div>
                <div className="cartside_total_subtotal">
                  <div className="cartside_total_text">Total amount</div>
                  <div className="cartside_total_amount">
                    <div className="cartside_total_amount">
                      {selectedVehicle < 1 ? (
                        "Select a delivery vehicle"
                      ) : (
                        <>Rs. {total}</>
                      )}
                    </div>
                  </div>
                </div>
                <button
                  className="cartside_total_checkout"
                  onClick={(e) => {
                    e.preventDefault();
                    if (cart.length === 0) {
                      alert.error("Your cart is empty");
                    } else {
                      if (paymentMethod === "COD") {
                        createOrder();
                      } else {
                        payAndCheckout();
                      }
                    }
                  }}
                  disabled={isCreatingOrder}
                >
                  {isCreatingOrder || loading ? (
                    <>
                      <CircularProgress
                        style={{ color: "white" }}
                        size="30px"
                      />
                    </>
                  ) : (
                    <>PLACE ORDER</>
                  )}
                </button>
                <span className="d-flex align-items-center">
                  <BsAsterisk
                    style={{
                      marginRight: "5px",
                      fontSize: "11px",
                      fill: "#FF7253",
                    }}
                  />
                  <span
                    type="button"
                    className="loginPopupForgotPasswordButton"
                    onClick={() => {
                      navigate("/cancellation-refund-policy");
                    }}
                  >
                    Cancellation/Refund Policy
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="checkout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content password_change_popup">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteAddress">
                {!addState
                  ? "Choose your Address ?"
                  : // "Address Details"}
                    ""}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddState(false);
                  setSelectedAddress({});
                  setSelectBuyCall("");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="checkoutModalBody">
                {addState ? (
                  <>
                    <h3 style={{ textAlign: "center", color: "black" }}>
                      Confirm your Address
                    </h3>
                    <br />
                    <div className="orderButtons">
                      <button
                        type="button"
                        className="addUpdateButton"
                        onClick={() => {
                          setAddState(false);
                          setSelectedAddress({});
                          setSelectBuyCall("");
                        }}
                      >
                        Go Back
                      </button>
                      <button
                        type="button"
                        className="addUpdateButton"
                        data-dismiss="modal"
                      >
                        Yes
                      </button>
                    </div>
                  </>
                ) : (
                  state.user.addresses?.map((address, i) => (
                    <div
                      className="profile_form_content"
                      onClick={() => {
                        setSelectedAddress(address);
                        setAddState(true);
                      }}
                    >
                      <div className="profile_form_content_inside">
                        Address ({address.addType}):
                        <span>{address.name}</span>
                        <span>{address?.address}</span>
                        <span>{address?.landmark}</span>
                        <span>
                          {address?.city}, {address?.state}-{address?.pincode}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
