import { CircularProgress } from "@mui/material";
import {
  GoogleMap,
  LoadScript,
  Marker
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import { BsArrowLeft, BsAsterisk } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { REACT_APP_GOOGLE_MAPS_KEY, RZR_PAY_KEY_ID,REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import {
  removeFromCartAction,
  setCartData
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import CartNavbar from "../../CartNavbar/CartNavbar";
import "../sidebar.css";
import "./Cart.css";
import Auto from "./img/auto.PNG";
import Bike from "./img/bike.PNG";
import Pickup from "./img/pickup.PNG";
import Truck from "./img/truck.PNG";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const RZR_PAY_ID = RZR_PAY_KEY_ID;

function OrderSummary() {
  const alert = useAlert();
  const [state, dispatch] = useStore();
  const [cart, setCart] = useState([]);
  const [gst, setGst] = useState(0);
  const [total, setTotal] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(state?.appliedCoupon || null);
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [backendMrp, setBackendMrp] = useState(0);
  const [backendDiscountedPrice, setBackendDiscountedPrice] = useState(0);
  const [backendDiscount, setBackendDiscount] = useState(backendMrp - backendDiscountedPrice);
  const [backendGst, setBackendGst] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(state.selectedVehicle || 4);
  const api = useAxios();
  const [isLaborRequired, setIsLaborRequired] = useState(state.isLaborRequiredState || true);
  const [laborPrices, setLaborPrices] = useState([0, 0, 0, 0]);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [loading, setLoading] = useState(true);
  const [addState, setAddState] = useState(false);
  const [deliveryPrices, setDeliveryPrices] = useState([0, 0, 0, 0]);
  const [deliveryPricesWithGst, setDeliveryPricesWithGst] = useState([0, 0, 0, 0]);
  const [applicability, setApplicability] = useState(1);
  const [cartAddress, setCartAddress] = useState(state?.selectedAddress || { ...state?.user?.addresses[0] })
  const [address, setAddress] = useState({});
  const [phone, setPhone] = useState({});
  const [payment, setPayment] = useState("COD");
  const [loadingUnloadingPrice, setLoadingUnloadingPrice] = useState(0);
  const [gstRate, setGstRate] = useState(0.18);
  const [tax, setTax] = useState(0);
  const [apiReload, setApiReload] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [userLocation, setUserLocation] = useState({
    zoom: 14,
    latitude: null,
    longitude: null,
    accuracy: null
  });
  const navigate = useNavigate();
  const tempRe = false;
  const today = new Date();
  let deliveryDate = new Date(today);
  deliveryDate.setDate(deliveryDate.getDate() + 2);

  ReactGA.send({
    hitType: 'pageview',
    page: '/ordersummary',
    title : 'Order Summary'
  })
  
  const removeFromCart = async (id) => {
    setLoading(true);
    const res = await api.delete(`/carts/${id}`);
    if (res.data.error) {
      alert.error(res.data.error);
      setLoading(false);
    } else {
      let updatedArr = cart.filter((item) => item._id !== id);
      dispatch(removeFromCartAction(id));
      setCart(updatedArr);
      setLoading(false);
    }
  };

  useEffect(() => {
    let tot = 0;
    let calcTotal = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (isLaborRequired) {
      tot = backendDiscountedPrice + deliveryPrices[selectedVehicle - 1] + totalLaborCost + loadingUnloadingPrice;
    }
    else {
      tot = backendDiscountedPrice + deliveryPrices[selectedVehicle - 1] + loadingUnloadingPrice;
    }
    if (appliedCoupon) {
      let appliedDiscount = (appliedCoupon?.discountPercentage / 100) * tot;
      if ((appliedDiscount > appliedCoupon?.discountMaxValue && appliedCoupon?.discountMaxValue !== 0) || appliedCoupon?.discountPercentage === 0) {
        appliedDiscount = appliedCoupon?.discountMaxValue;
      }
      calcTotal = (tot - appliedDiscount);
      gstVal = calcTotal * 0.18;
      totalPrice = parseFloat(calcTotal) + parseFloat(gstVal);
      setAppliedCouponDiscount(appliedDiscount);
    } else {
      setAppliedCouponDiscount(0);
      gstVal = tot * 0.18;
      totalPrice = parseFloat(tot) + parseFloat(gstVal);
    }
    setGst(gstVal.toFixed(2));
    setTotal(totalPrice.toFixed(2));
  }, [appliedCoupon, backendDiscountedPrice, deliveryPrices, selectedVehicle, loadingUnloadingPrice]);


  function cantFetchCartItems(res) {
    alert.error(res.data.error);
    dispatch(setCartData([]));
    setBackendMrp(0);
    setBackendDiscountedPrice(0);
    setBackendDiscount(0);
    setBackendGst(0);
    setApplicability(5);
    setLoadingUnloadingPrice(0);
    setDeliveryPrices([0, 0, 0, 0]);
    setDeliveryPricesWithGst([0, 0, 0, 0]);
    setLaborPrices([]);
    setTotalLaborCost(0);
  }

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const res = await api.get("/carts", { headers: { addressId: cartAddress._id } });
      if (res.data.error) {
        cantFetchCartItems(res);
      } else {
        dispatch(setCartData(res.data.cartItems));
        res.data.cartItems ? setCart(res.data.cartItems) : setCart([])
        setBackendMrp(res.data.mrpTotal);
        setBackendDiscountedPrice(res.data.total);
        setBackendDiscount(backendMrp - backendDiscountedPrice);
        setBackendGst(res.data.totalGst);
        setDeliveryPrices(res.data.deliveryPrices);
        setDeliveryPricesWithGst(res.data.deliveryPricesWithGst);
        setLoadingUnloadingPrice(res.data.loadingUnloadingPrice);
        setLaborPrices(res.data.priceForLabor);
        setTotalLaborCost(res.data.totalPriceForLabor);
        setApplicability(res.data.vehicle);
      }
      setUserLocation({
        latitude: cartAddress?.lat,
        longitude: cartAddress?.lng,
        zoom: 17,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`
      })
    }
    getData();
    setLoading(false);
  }, []);

  useEffect(() => {
    async function getData() {
      const res = await api.get("/carts", { headers: { addressId: cartAddress?._id } });
      if (res.data.error) {
        cantFetchCartItems(res);
      } else {
        dispatch(setCartData(res.data.cartItems));
        setBackendMrp(res.data.mrpTotal);
        setBackendDiscountedPrice(res.data.total);
        setBackendDiscount(backendMrp - backendDiscountedPrice);
        setBackendGst(res.data.totalGst);
        setLoadingUnloadingPrice(res.data.loadingUnloadingPrice);
        setApplicability(res.data.vehicle);
        setDeliveryPrices(res.data.deliveryPrices);
        setDeliveryPricesWithGst(res.data.deliveryPricesWithGst);
        setLoading(false);
      }
    }
    getData();
    document.title = `Order Summary - PLYPICKER`;
  }, [cart]);

  async function onClickMap(event) {
    event.preventDefault();
  }

  function numberWithCommas(x, y) {
    if (y) {
      x = Math.round(x * 1e2) / 1e2;
      x = x.toFixed(2);
    }
    return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
  }

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });
  
  return (
    <>
      {isMobile ?
        <div className='MobileView'>
          <span className="headingView">
            <BsArrowLeft onClick={() => navigate(-1)} style={{ fontSize: "30px" }} /> Order Summary
          </span>
        </div>
        : ""}
      <CartNavbar currentStep="2" />
      {/* <NavRoute title="cart" /> */}
      <div className="container threeimage_container pt-2 mb-5 pb-5 px-2 px-lg-0">
        <div className="d-flex flex-lg-row flex-column">
          {/* <SideBar title="cart" /> */}
          <div className="col-lg-8 col-12 overflow-y-auto">
            <div className="cartBox borderedContainer p-4">
              <div className="addressTypeData d-flex justify-content-between">
                <div className="inside_addressTypeData">
                  <h2>Delivering to </h2>
                  <span>{<>{cartAddress?.addType}</>}
                  </span>
                </div>
                {/* <div data-toggle="modal"
                  data-target="#checkout" onClick={setAddress} className="changeAddressButton">CHANGE</div> */}
              </div>
              <p>
                <b className="checkoutAddressName">{cartAddress.name}</b>,
                {cartAddress?.address}, {cartAddress?.landmark},
                {cartAddress?.city},
                {cartAddress?.state} - {cartAddress?.pincode}
              </p>
              <b className="checkoutAddressName">{cartAddress?.mobile}</b>
            </div>
            {loading && <>
              <div>
                <h4 className="text-center">Loading your cart... <CircularProgress style={{ "color": "#ff7703" }} /></h4>
              </div>
            </>}
            {!loading && cart.length === 0 && <>
              <div>
                <h4 className="text-center">No products in cart!</h4>
              </div>
            </>}
            {cart.map((item, cartItemIndex) => {
              const p = item.product_id;
              return (
                <div className="">
                  <div className="borderedContainer px-2 py-2 d-flex flex-row flex-wrap">
                    <div className="w-100 col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row flex-wrap align-items-center justify-content-start col-sm-8 col-12">
                        <Link to={`/product/${item?.product_id?._id}`}>
                          <img
                            src={item?.variation ? (item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].attrs.imgs ? item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].attrs.imgs : item.product_id?.attrs?.imgs[0]) : item.product_id?.attrs?.imgs[0]}
                            alt=""
                            className="cartImage"
                          />
                        </Link>
                        <div className="d-flex flex-column ps-2 flex1Inner">
                          <p className="m-0 p-0"><Link
                            className="checkoutProductLink"
                            to={`/product/${item?.product_id?._id}`}
                          >
                            {p?.product_name}
                          </Link></p>
                          <p className="cartBrand m-0 subtext">{item?.product_id?.brand?.Brand_name}</p>
                          {item?.variation &&
                            Object.entries(item?.variation).map(
                              ([variationKey, variationValue]) => (
                                <>
                                  <div className="subtext">
                                    {variationKey}: {variationValue}
                                  </div>
                                </>
                              )
                            )
                          }
                          <p className="m-0 p-0">
                            <span className="m-0 p-0 productDiscountedPrice text">Rs. {item?.variation ? (item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].discounted_price ? numberWithCommas(item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].discounted_price) : "N/A") : numberWithCommas(p?.discounted_price)} </span>
                            <span className="m-0 p-0 productPrice subtext">Rs. {item?.variation ? (item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].price ? numberWithCommas(item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].price) : "N/A") : numberWithCommas(p?.price)} </span>
                            <span className="m-0 p-0 productDiscountedPercent subtext">-{item?.variation ? numberWithCommas(item.product_id.vars[Object.keys(item.product_id.vars)[0]].filter(x => x.attrs[Object.keys(item.product_id.vars)[0]] === item?.variation[Object.keys(item.product_id.vars)[0]])[0].discounted_percent) : numberWithCommas(p?.discounted_percent)}%</span>
                          </p>
                        </div>
                      </div>
                      <div className="align-self-start col-sm-4 col-12">
                        {item?.product_id?.loadingUnloadingPrice ? <>{item?.product_id?.loadingUnloadingPrice > 0 ? <p className="subtext m-0 p-0 text-sm-end text-start">LOADING UNLOADING APPLICABLE</p> : <></>}</> : <></>}
                        {(isLaborRequired && laborPrices[cartItemIndex] > 0) ? <><p className="subtext m-0 p-0 text-sm-end text-start">LABOR APPLICABLE</p></> : <></>}
                      </div>
                    </div>
                    <div className="w-100 col-12 subtext d-flex flex-row align-items-center justify-content-between">
                      <div>
                        <div>
                          <p className="m-0 p-0">Delivery by {deliveryDate.getDate()} {deliveryDate.toLocaleString('default', { month: 'long' })}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-3">
            <div className="w-100">
              <div className="renderside_cartside_total ms-lg-3 ms-0 pb-2">
                {/* <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_KEY}>
                  <GoogleMap onClick={(e) => onClickMap(e)} clickableIcons={false} zoom={userLocation.zoom} center={{ lat: userLocation.latitude || 18.5204, lng: userLocation?.longitude || 73.8567 }} mapContainerClassName="map-container">
                    {userLocation?.latitude && <Marker position={{ lat: userLocation?.latitude || 18.5204, lng: userLocation?.longitude || 73.8567 }}></Marker>}
                  </GoogleMap>
                </LoadScript> */}
                <h6 className="mt-3 mb-0">Chosen delivery vehicle</h6>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-start gx-1">
                  <div className="col-3">
                    <div className="px-1">
                      <button className="deliveryVehicleButton deliveryVehicleButtonActive p-1" onClick={(e) => { e.preventDefault(); }}>
                        {selectedVehicle === 1 && <img className="deliveryVehicleImg" src={Bike} />}
                        {selectedVehicle === 2 && <img className="deliveryVehicleImg" src={Auto} />}
                        {selectedVehicle === 3 && <img className="deliveryVehicleImg" src={Pickup} />}
                        {selectedVehicle === 4 && <img className="deliveryVehicleImg" src={Truck} />}
                      </button>
                    </div>
                  </div>
                </div>
                {isLaborRequired && <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-3">
                  <p className="col-12">Selected floor: {cartAddress.floor}</p>
                  <div className="col-12 text-end cartLaborInfo">
                    Floor * Labor Price (per floor) * Quantity = Labor cost
                  </div>
                  {cart.map((cartItem, cartIndex) => <>
                    <div className="col-6 py-1">
                      <div className="">
                        <p className="p-0 m-0 cartLaborProduct">
                          <Link
                            className="checkoutProductLink"
                            to={`/product/${cartItem?.product_id?._id}`}
                          >
                            {cartItem.product_id?.product_name}
                          </Link>
                        </p>
                        {cartItem?.variation &&
                          Object.entries(cartItem?.variation).map(
                            ([variationKey, variationValue]) => (
                              <>
                                <p className="m-0 p-0 subtext cartLaborVariation">
                                  {variationKey}: {variationValue}
                                </p>
                              </>
                            )
                          )}
                      </div>
                    </div>
                    <div className="col-6 text-end align-self-start subtext py-1">
                      {cartAddress.floor} * Rs. {cartItem?.variation ? cartItem.product_id.vars[Object.keys(cartItem.product_id.vars)[0]].filter(x => x.attrs[Object.keys(cartItem.product_id.vars)[0]] === cartItem.variation[Object.keys(cartItem.product_id.vars)[0]])[0].laborPerFloor : cartItem.product_id.laborPerFloor} * {cartItem.quantity} = Rs. {laborPrices[cartIndex]}
                    </div>
                  </>)}
                  <hr className="w-100 mt-2 mb-0" />
                  <div className="col-6 mt-2 align-self-end">
                    Total labor cost
                  </div>
                  <div className="col-6 text-end align-self-start mt-3">
                    Rs. {numberWithCommas(totalLaborCost, 2)}
                  </div>
                </div>}
              </div>
              <div className="renderside_cartside_total ms-lg-3 ms-0">
                <div className="cartside_total_heading">ORDER SUMMARY</div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Total MRP ({cart.length} items)</div>
                  <div className="cartside_total_amount" style={{ textDecoration: "line-through" }}>Rs. {numberWithCommas(backendMrp, 2)}</div>
                </div>
                <div className="cartside_total_discount">
                  <div className="cartside_total_text">Total Selling Price</div>
                  <div className="cartside_total_amount">- Rs. {numberWithCommas(backendDiscountedPrice, 2)}</div>
                </div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Delivery</div>
                  <div className="cartside_total_amount">{selectedVehicle < 1 ? "Select a delivery vehicle" : <>Rs. {numberWithCommas(deliveryPrices[selectedVehicle - 1], 2)}</>}</div>
                </div>
                {loadingUnloadingPrice > 0 && <div className="cartside_total_total">
                  <div className="cartside_total_text">Loading/Unloading</div>
                  <div className="cartside_total_amount">Rs. {numberWithCommas(loadingUnloadingPrice, 2)}</div>
                </div>}
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Labor {isLaborRequired ? <>({cartAddress.floor} floors)</> : <>(Not opted)</>}</div>
                  <div className="cartside_total_amount">{isLaborRequired ? <>Rs. {numberWithCommas(totalLaborCost, 2)}</> : "Rs. 0"}</div>
                </div>
                <div className="cartside_total_total">
                  {appliedCoupon && (
                    <div className="cartside_total_text">
                      Coupon ({appliedCoupon.codeName})
                    </div>
                  )}
                  <div className="cartside_total_total">
                    {appliedCoupon && (
                      <div className="cartside_total_amount">
                        {selectedVehicle < 1 ? (
                          "Select a delivery vehicle"
                        ) : (
                          <>
                            - Rs. {appliedCouponDiscount.toFixed(2)}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cartside_total_total mb-4">
                  <div className="cartside_total_text">GST (18%)</div>
                  <div className="cartside_total_amount">{selectedVehicle < 1
                      ? "Select a delivery vehicle"
                      : `Rs. ${gst}`}</div>
                </div>
                <div className="cartside_total_subtotal">
                  <div className="cartside_total_text">Total amount</div>
                  <div className="cartside_total_amount">{selectedVehicle < 1 ? (
                    "Select a delivery vehicle"
                  ) : (
                    <>
                      Rs.{" "}{total}
                    </>
                  )}
                  </div>
                </div>
                <button
                  className="cartside_total_checkout"
                  onClick={() => {
                    if (cart.length === 0) {
                      alert.error("Your cart is empty!");
                    } else {
                      navigate("/payment");
                    }
                  }}
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "CONTINUE"}
                </button>
                <span className="d-flex align-items-center">
                  <BsAsterisk style={{ marginRight: "5px", fontSize: "11px", fill: "#FF7253" }} />
                  <span
                    type="button"
                    className="loginPopupForgotPasswordButton"
                    onClick={() => {
                      navigate("/terms-and-condition");
                    }}
                  >
                    T&C Apply
                  </span>
                </span>
              </div>
              {/* <div className="renderside_cartside_img_bottom">
                  <img src={profileimg_3} alt="" />
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummary