import { useEffect, useState } from "react";
import UpperNavbar from "./UpperNavbar/UpperNavbar";
import { API_URL } from "../../../../../constants";
import NewLowerNavbar from "./LowerNavbar/LowerNavbar";
import "./Component1.css"
import axios from "axios";
import { useMediaQuery } from "react-responsive";

export default function Component1({ data, navbar }) {
    let [categories, setCategories] = useState([]);
    let [isNavbarScrolled, setIsNavbarScrolled] = useState(false);
    let isMobileOrTablet = useMediaQuery({
        query: "(max-width:992px)",
    });
    useEffect(() => {
        async function apiCall() {
            const cat = await axios.get(`${API_URL}/navbar`);
            setCategories([...cat.data]);
        }
        function handleScroll() {
            let height = 0.5 * window.innerHeight;
            if (isMobileOrTablet) height = 0.18 * window.innerHeight
            if (window.scrollY > height) {
                setIsNavbarScrolled(true);
            } else {
                setIsNavbarScrolled(false);
            }
        }
        apiCall();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    return <>
        <style>
            {`
            @media (max-width: 992px) {
                .hpComponent1ParentShadow:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    background: url(${data?.buildingLower}) center top / contain no-repeat;
                    z-index: -1;
                    opacity: 0.4;
                }
            }
            @media (max-width: 576px) {
                .hpComponent1ParentShadow:after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 50%;
                    background: url(${data?.buildingLower}) center top / contain no-repeat;
                    z-index: -1;
                    opacity: 0.4;
                }
            }
            `}
        </style>
        <div>
            <div className="w-100 p-relative d-block hpComponent1Parent hpComponent1ParentShadow" style={isMobileOrTablet ? { background: "linear-gradient(0deg, rgba(254,119,7,1) 0%, rgba(254,159,79,1) 39%, rgba(254,182,121,1) 68%, rgba(255,255,255,0) 100%)" } : { background: "linear-gradient(0deg, rgb(254, 119, 7) 0%, rgb(255 117 0) 39%, rgb(255 154 69 / 75%) 68%, rgba(255, 255, 255, 0) 100%)" }}>
                <div className="w-100 p-relative d-sm-block d-none hpComponent1Parent" style={{ background: `url("${data?.buildingUpper}") no-repeat center bottom/contain` }}>
                    <div className="w-100" style={{position: "fixed", zIndex: "999999", ...{ background: isNavbarScrolled ? "white" : "none" }, transition: "all 0.75s"}}>
                        <UpperNavbar categories={categories} data={navbar} isNavbarScrolled={isNavbarScrolled} />
                        <NewLowerNavbar categories={categories} isNavbarScrolled={isNavbarScrolled} />
                    </div>
                </div>
                <div className="w-100 p-relative d-sm-none d-block hpComponent1Parent" style={{ background: `url("${data?.buildingUpper}") no-repeat center bottom/contain` }}>
                    <UpperNavbar categories={categories} data={navbar} isNavbarScrolled={isNavbarScrolled} />
                    <NewLowerNavbar categories={categories} isNavbarScrolled={isNavbarScrolled} />
                </div>
            </div>
        </div>
    </>
}