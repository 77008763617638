import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import validator from "validator";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import {
  setAuth,
  setCartData,
  setUser,
} from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import useAxios from "../../../utils/useAxios";
import "../Register/SignUp.css";
import ClosedEye from "./images/ClosedEye.png";
import OpenEye from "./images/OpenEye.png";
import plypickerLogo from "./images/plypicker_logo.png";
import sign_in from "./images/sign_in.png";
import "./SignIn.css";
import { BsAsterisk } from "react-icons/bs";
import ReactGA from "react-ga4";
import { getAddresses } from "../../../store/reducers/userActions";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7703",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "uppercase",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      color: "#FF7703",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#FF7703",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function SignIn() {
  const alert = useAlert();
  const navigate = useNavigate();
  const [state, dispatch] = useStore();
  const api = useAxios();
  const [resPassType, setResPassType] = useState("Email ID");
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [resEmail, setResEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [resetPass, setResetPass] = useState(true);
  const [value, setValue] = useState("one");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpPopup, setOTPopup] = useState(false);
  const [otpRP, setOtpRP] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const OTPRPhandleChange = (e) => setOtpRP(e);
  const OTPhandleChange = (e) => setOtp(e);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [timer, setTimer] = useState(30);
  const [linkEnabled, setLinkEnabled] = useState(true);
  const query = useQuery();

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setLinkEnabled(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  ReactGA.send({
    hitType: "pageview",
    page: "/signin",
    title: "Sign In",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    if (!emailOrMobile || !password) {
      alert.error("Please enter Email/Mobile Number and Password");
      return;
    }
    const data = {
      emailOrMobile,
      password,
    };

    axios
      .post(API_URL + "/auth/login", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then(async (response) => {
        if (
          !response.data.error &&
          response.data.accessToken &&
          response.data.user
        ) {
          const res = await api.get("/carts", {
            headers: {
              // name:'xyz'
            },
          });
          alert.success("Logged in Successfully");
          setEmailOrMobile("");
          dispatch(setUser(response.data.user));
          dispatch(setAuth(response.data.accessToken, true));
          const addresses = await api.get("/me/addresses");
          if (!addresses.data.error) {
            dispatch(getAddresses(addresses.data?.addresses));
          } else {
            console.log(addresses.data.error);
          }
          dispatch(setCartData(res.data?.cartItems || []));
          if (query.get("redirectURL")) {
            navigate(query.get("redirectURL"));
          } else {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        alert.error(error.response.data.error);
      });
    // const response = await axios.post(API_URL + "/auth/login", data, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   withCredentials: true,
    // });
    // console.log(response, "response");
    // if (!response.data.error && response.data.accessToken && response.data.user) {
    //   console.log(response.data.accessToken, true);
    //   alert.success("Logged in Successfully")
    //   dispatch(setUser(response.data.user));
    //   dispatch(setAuth(response.data.accessToken, true));
    //   navigate("/");
    // } else {
    //   alert.error(response.data.error);
    // }
  };

  function loginOtpHandler(event) {
    event.preventDefault();
    if (!emailOrMobile || !otp) {
      alert.error("Please enter Mobile Number and OTP");
      return;
    }
    const data = {
      emailOrMobile,
      otp: otp,
    };

    axios
      .post(API_URL + "/auth/loginOTP", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then(async (response) => {
        if (
          !response.data.error &&
          response.data.accessToken &&
          response.data.user
        ) {
          const res = await api.get("/carts");
          alert.success("Logged in Successfully");
          dispatch(setUser(response.data.user));
          dispatch(setAuth(response.data.accessToken, true));
          const addresses = await api.get("/me/addresses");
          if (!addresses.data.error) {
            dispatch(getAddresses(addresses.data?.addresses));
          } else {
            console.log(addresses.data.error);
          }
          dispatch(setCartData(res.data?.cartItems || []));
          if (query.get("redirectURL")) {
            navigate(query.get("redirectURL"));
          } else {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        alert.error(error.response.data.error);
      });
  }

  const verifyMobileRP = async () => {
    if (!validator.isMobilePhone(otpNumber)) {
      alert.error("Invalid phone number.");
      return;
    } else {
      setOtpSent(true);
    }
    try{
        const response = await axios.post(
          API_URL + "/auth/reset-password-otp",
          { mobileNumber: otpNumber, otp: otpRP },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.data.error) {
          alert.success(response.data.msg);
          window.open(response.data.resetLink, "_blank");
          setOTPopup(false);
        } else {
          alert.error(response.data.error);
        }
    } catch (e) {
        alert.error("Wrong OTP");
    }
  };
  useEffect(() => {
    document.title = "Sign In - PLYPICKER";
  }, []);

  const forgotPwdHandler = async () => {
    if (!resEmail) {
      alert.error("Please enter Email");
      return;
    }
    const data = {
      email: resEmail,
      type: resPassType,
    };

    axios
      .post(API_URL + "/auth/reset-password", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then((response) => {
        alert.success(response.data.msg);
        if (resPassType === "Mobile Number") {
          setOTPopup(true);
          setOtpNumber(resEmail);
        }
      })
      .catch((error) => {
        alert.error(error.response.data.error);
      });
  };
  const handleResPassTypeChange = (event) => {
    setResPassType(event.target.value);
    setResEmail("");
  };

  const verifyMobile = async () => {
    if (!validator.isMobilePhone(emailOrMobile)) {
      alert.error("Invalid phone number.");
      return;
    } else {
      setOtpSent(true);
    }
    const response = await axios.post(
      API_URL + "/auth/verify-mobile-otp",
      { mobileNumber: emailOrMobile },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.data.error) {
      alert.success(response.data.msg);
    } else {
      alert.error(response.data.error);
    }
    setLinkEnabled(false);
    setTimer(30);
  };

  return (
    <div className="signup_container signin_container">
      <Link to="/" className="position-absolute start-0 top-0 p-3">
        <img src={plypickerLogo} width="200px" />
      </Link>
      <div className="signup_image_side">
        <img src={sign_in} alt="" />
      </div>
      <div className="signup_form_side_outer">
        <div className="signup_form_side">
          <div className="signup_form_side_heading">Login</div>
          <Box
            sx={{ width: "100%" }}
            className="LoginTabBox"
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <AntTabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <AntTab value="one" label="Login with Password" />
              <AntTab value="two" label="Login with OTP" />
            </AntTabs>
          </Box>
          <div className="signup_form_side_form">
            <form
              action="#"
              onSubmit={(event) => {
                value === "one" ? loginHandler(event) : loginOtpHandler(event);
              }}
            >
              {value === "one" ? (
                <>
                  <input
                    type="text"
                    value={emailOrMobile}
                    onChange={(e) => setEmailOrMobile(e.target.value.trim())}
                    placeholder="Email ID/Mobile Number"
                  />
                  <div className="position-relative">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                    {password.length !== 0 && (
                      <button
                        className="signupPagePasswordVisibleButton"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsPasswordVisible(!isPasswordVisible);
                        }}
                      >
                        {isPasswordVisible ? (
                          <img src={ClosedEye} style={{ width: "100%" }} />
                        ) : (
                          <img src={OpenEye} style={{ width: "100%" }} />
                        )}
                      </button>
                    )}
                  </div>

                  <div className="profile_form_change signin_modal_password">
                    {/* <!-- Button trigger modal --> */}
                    <button
                      type="button"
                      className="btn btn-primary profile_form_change_btn sign_in_forget_password"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      Forgot Password
                    </button>
                  </div>

                  <button type="submit" className="form_register_button">
                    Login
                  </button>
                  <div
                    className="form_login_button"
                    onClick={() => navigate("/signup")}
                  >
                    Did not Sign Up? Sign Up
                  </div>
                  <span className="d-flex align-items-center">
                    <BsAsterisk
                      style={{
                        marginRight: "5px",
                        fontSize: "11px",
                        fill: "#FF7253",
                      }}
                    />
                    <span
                      type="button"
                      className="loginPopupForgotPasswordButton"
                      onClick={() => {
                        navigate("/terms-and-condition");
                      }}
                    >
                      T&C Apply
                    </span>
                  </span>
                </>
              ) : (
                <>
                  {otpSent ? (
                    <>
                      <p className="loginPopupInputText">OTP</p>
                      <OtpInput
                        className="OtpInput"
                        value={otp}
                        onChange={OTPhandleChange}
                        numInputs={6}
                        isDisabled={emailOrMobile.length === 10 ? false : true}
                        separator={<span style={{ padding: "5px" }}>-</span>}
                      />
                      <br />
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "1.1rem",
                            alignItems: "center",
                          }}
                        >
                          <button
                            type="button"
                            disabled={!linkEnabled}
                            style={{
                              cursor: linkEnabled ? "pointer" : "not-allowed",
                            }}
                            className={"loginPopupForgotPasswordButton"}
                            onClick={() => {
                              verifyMobile();
                            }}
                          >
                            Resend OTP
                          </button>
                          <p style={{ marginTop: "1rem" }}>
                            in {timer} seconds
                          </p>
                        </div>
                        <button
                          type="button"
                          className="loginPopupForgotPasswordButton"
                          onClick={() => {
                            setOtpSent(false);
                          }}
                        >
                          Back
                        </button>
                      </span>
                    </>
                  ) : (
                    <>
                      <p className="loginPopupInputText">Mobile number</p>
                      <input
                        autoFocus
                        className="loginPopupInputBox"
                        type="text"
                        minlength="10"
                        maxlength="10"
                        size="10"
                        value={emailOrMobile}
                        onChange={(e) =>
                          setEmailOrMobile(e.target.value.trim())
                        }
                      />
                    </>
                  )}
                  <br />
                  {!otpSent && (
                    <button
                      className="loginPopupButton loginPopupLoginButton"
                      onClick={() => {
                        verifyMobile();
                      }}
                    >
                      Send OTP
                    </button>
                  )}
                  {otpSent && (
                    <button type="submit" className="form_register_button">
                      Login
                    </button>
                  )}
                  <br />
                  <div
                    className="form_login_button"
                    onClick={() => navigate("/signup")}
                  >
                    Did not Sign Up? Sign Up
                  </div>
                  <span className="d-flex align-items-center">
                    <BsAsterisk
                      style={{
                        marginRight: "5px",
                        fontSize: "11px",
                        fill: "#FF7253",
                      }}
                    />
                    <span
                      type="button"
                      className="loginPopupForgotPasswordButton"
                      onClick={() => {
                        navigate("/terms-and-condition");
                      }}
                    >
                      T&C Apply
                    </span>
                  </span>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content password_change_popup">
            <div
              className="modal-header signin_modal_title"
              style={{ borderBottom: "none" }}
            >
              <h5 className="modal-title" id="exampleModalLabel">
                Forgot Password
                <br />
              </h5>
              <div className="modal_password_reset">
                {resetPass ? "" : "We will send you a password reset link."}
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {!otpPopup ? (
                <>
                  <FormControl>
                    <RadioGroup
                      row={false}
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={resPassType}
                      onChange={handleResPassTypeChange}
                    >
                      <FormControlLabel
                        value="Email ID"
                        control={<Radio />}
                        label="Email ID"
                      />
                      <FormControlLabel
                        value="Mobile Number"
                        control={<Radio />}
                        label="Mobile Number"
                      />
                    </RadioGroup>
                  </FormControl>
                  <br />
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {resPassType === "Mobile Number" && (
                      <span
                        style={{ marginBottom: "4px" }}
                        className="addressMobileNumberInputPrefix"
                      >
                        +91
                      </span>
                    )}
                    {resPassType === "Mobile Number" ? (
                      <input
                        type="text"
                        minlength="10"
                        maxlength="10"
                        size="10"
                        value={resEmail}
                        onChange={(e) => setResEmail(e.target.value.trim())}
                        placeholder={resPassType}
                      />
                    ) : (
                      <input
                        type="text"
                        value={resEmail}
                        onChange={(e) => setResEmail(e.target.value.trim())}
                        placeholder={resPassType}
                      />
                    )}
                  </span>
                </>
              ) : (
                <>
                  <p className="loginPopupInputText">OTP</p>
                  <OtpInput
                    className="OtpInput"
                    value={otpRP}
                    onChange={OTPRPhandleChange}
                    numInputs={6}
                    separator={<span style={{ padding: "5px" }}>-</span>}
                  />
                  <br />
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <button
                      type="button"
                      className="loginPopupForgotPasswordButton"
                      onClick={() => {
                        forgotPwdHandler();
                      }}
                    >
                      Resend OTP
                    </button>
                    <button
                      type="button"
                      className="loginPopupForgotPasswordButton"
                      onClick={() => {
                        setOTPopup(false);
                      }}
                    >
                      Back
                    </button>
                  </span>
                </>
              )}
            </div>
            <div className="modal-footer" style={{ borderTop: "none" }}>
              <button
                type="button"
                className="form_register_button"
                onClick={otpPopup ? verifyMobileRP : forgotPwdHandler}
              >
                Send Reset Link
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
