import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL,REACT_APP_GA_MEASUREMENT_ID } from "../../../../constants";
import Footer from "../../../global_component/Footer/Footer";
import LowerNavigation from "../../../global_component/LowerNavigation/LowerNavigation";
import AddVisit from "./AddVisit";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function TopHero({heroImage}) {
  return (
    <>
      <div className="m-auto col-xxl-10 px-5 py-5">
        <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
          <div className="col-sm-10 col-lg-6 imgPostiion" style={{marginLeft:'-30px'}}>
            <img
              src={heroImage}
              className="d-block mx-lg-auto img-fluid"
              width="600"
              // height="500"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6">
            <h1 className="fw-bold mb-5 lh-base text-trilong">
              CONSIDERING BUILDING YOUR DREAM HOME?
            </h1>
            <p className="lead">
              We offer the best floor plans and interior plans to exist in the
              market. Our Experienced Architecturers are here to build your
              dream home.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function FeaturesCard({image,para}) {
  return (
    <>
      <div className="feature col border border-1 m-2 mx-5" style={{width:'270px'}}> 
        <div className="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3" style={{width:'107%',margin:'0px',padding:'0px',marginLeft:'-10px',marginRight:'-30px'}}>
          <img
            src={image}
            width="100%"
            style={{width:'100%',margin:'0px', padding:'0px'}}
          />
        </div>
        <p className="text-center pb-5 pt-3">
          {para}
        </p>
      </div>
    </>
  );
}

function Features({cardData}) {
  return (
    <>
      <div className="container row p-5 row-cols-2 row-cols-lg-4 d-flex justify-content-center m-auto">
        {cardData?cardData.map((e)=>(
          <FeaturesCard image={e.image} para={e.description} key={e._id} />
        )):(<></>)}
      </div>
    </>
  );
}

function Building() {
  const [VisitModal, setVisitModal] = useState(false);
  const [heroImage , setHeroImage] = useState("")
  const [cardData , setCardData] = useState()

  ReactGA.send({
    hitType: 'pageview',
    page: `/building`,
    title : 'Building'
  })

  useEffect(() => {
    const apiCall = async() =>{
        const res = await axios.get(`${API_URL}/home`)
        let result =await res.data;
        setHeroImage(result.filter(e=>e.type === 'creative_idea_building' && e.title === 'hero')[0].image)
        setCardData(result.filter(e=>e.type === 'creative_idea_building' && e.title === 'cards'))
        
    }
    apiCall();
    window.scroll(0,0);
  }, []);
  return (
    <div className=" text-trilong" style={{ overflowX: "hidden" }}>
      <div className="interiorTopMargin" style={{marginTop:'100px'}}></div>
      <TopHero heroImage={heroImage} />
      <Features cardData={cardData} />
      <div class="d-grid gap-2 d-md-flex justify-content-center mb-5 mt-5" >
          <button type="button" class="btn-lg px-4 me-md-2 text-light mt-3" style={{backgroundColor:'#FF7703',borderRadius:'1px', border: "none"}} onClick={()=>{setVisitModal(true)}}>Build Your Home</button>
        </div>
        <div style={{marginTop:'100px'}}></div>
        <LowerNavigation />
        <Footer />
        <AddVisit isOpen={VisitModal} setIsOpen={setVisitModal} />
    </div>
  );
}

export default Building;
