import Rating from '@mui/material/Rating';
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BsArrowDownCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { addReview, setAvgRating } from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";

function OrderMoreThan2({ order }) {
  const navigate = useNavigate()
  const api = useAxios();
  const [state, dispatch] = useStore();
  const [orderMoreAccordion, setOrderMoreAccordion] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [newRating, setNewRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [product, setProduct] = useState({})
  const hideReviewModal = () => {
    setReviewModal(false);
    setNewRating(0);
    setReviewText("");
  };

  const checkReview = (allRating) => {
    // console.log("testdata",allRating)
    //   allRating?.rating_and_review?.some(function (x) {
    //     if (x.user_id === state.user?._id) {
    //         return true;
    //     } else {
    //       return false;
    //     }
    // })
    // if(allRating?.rating_and_review.length>0){
    // for(let item of allRating?.rating_and_review){
    //     if (item.user_id === state.user?._id) {
    //       return true;
    //     }
    //   }
    //   }
    //   return false;
  }

  const showReviewModal = (e) => {
    setProduct(e)
    setReviewModal(true);
  }

  const addReviewHander = async (array, p_id) => {
    try {
      if (newRating !== 0 && reviewText.length > 0) {
        const res = await api.post("/review/", {
          rating: newRating,
          review: reviewText,
          productId: p_id
        })
        dispatch(addReview(array, res.data?.newData))
        setReviewModal(false);
        dispatch(setAvgRating(res.data?.averageRating))
        alert.success("Review Added Successfully")
      } else {
        alert.error("Please Add Rating and Review")
      }
    } catch (err) {
      console.log(err);
    }
  }
  function numberWithCommas(x) {
    return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
  }
  return (
    <>
      {reviewModal && (
        <Modal
          show={reviewModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={hideReviewModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addRatingModalBody">
              <Rating
                name="simple-controlled"
                value={newRating}
                size="large"
                onChange={(event, newValue) => {
                  setNewRating(newValue);
                }}
              />
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Review</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
              </Form>
              <Button
                className="submitReview"
                onClick={() => addReviewHander(product.product?.rating_and_review, product.product._id)}
              >
                Add Review
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {orderMoreAccordion ? (
        <>
          {order?.order_items.map((e, i) => (
            <>
              <div className="d-flex flex-wrap justify-content-between align-items-center py-3 orderContainer">
                <div className="d-flex align-items-center col-8">
                  <div className="imageColumn">
                    <img src={e?.image} className="w-100 ms-3 currOrdersImg" />
                  </div>
                  <div className="col-12 ps-4 ms-3">
                    <div className="current_orders_deliverydate current_orders_green text-dark mb-5">
                      <div className='me-4 hoverOrderClass d-flex flex-column'><span onClick={() => navigate(`/product/${e?.product?._id}`)}>{e?.name}</span> <span onClick={() => navigate(`/productlist?brand=${e?.product?.brand?._id}`)} className='wishlist_product_company'>{e?.product?.brand?.Brand_name}</span></div>
                    </div>
                    <div className="current_orders_orderID mt-5 text-dark">
                      Quantity: {e?.quantity}
                    </div>
                  </div>
                </div>
                <div className="col-4 ps-3 d-lg-block">
                  <div className="current_orders_title_rightside ms-auto me-3" style={{ color: "#8A5B40", fontWeight: "600" }}>
                    Rs. {numberWithCommas(e?.price)}
                  </div>
                </div>
              </div>
              <span style={{ display: "flex", justifyContent: "end" }}>
                {/* {order.delivery.status==="delivered"?!checkReview(e?.product)?(<button className="addReview" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Add Review</button>):(<button className="addReview" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Edit Review</button>):<></>} */}
              </span>
              {<hr className='mx-3' style={{ margin: "0px", marginLeft: "auto", marginRight: "auto" }} />}
            </>
          ))}
          <div
            className="d-flex justify-content-center align-items-center p-4 m-auto show-prop"
            style={{ color: "#878787" }}
            onClick={() => {
              setOrderMoreAccordion(false);
            }}
          >
            Show Less
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-between align-items-center py-3 orderContainer">
            <div className="d-flex align-items-center col-8">
              <div className="imageColumn">
                <img
                  src={order?.order_items[0]?.image}
                  className="w-100 ms-3 currOrdersImg"
                />
              </div>
              <div className="col-12 ps-4 ms-3">
                <div className="current_orders_deliverydate current_orders_green text-dark mb-5">
                  <div className="me-4 hoverOrderClass d-flex flex-column"><span onClick={() => navigate(`/product/${order.order_items[0]?.product?._id}`)}>{order.order_items[0]?.name}</span> <span onClick={() => navigate(`/productlist?brand=${order.order_items[0]?.product?.brand?._id}`)} className='wishlist_product_company'>{order.order_items[0]?.product?.brand?.Brand_name}</span></div>
                </div>
                <div className="current_orders_orderID mt-5 text-dark">
                  Quantity: {order.order_items[0]?.quantity}
                </div>
              </div>
            </div>
            <div className="col-4 ps-3 d-lg-block ">
              <div className="current_orders_title_rightside ms-auto me-3" style={{ color: "#8A5B40", fontWeight: "600" }}>
                Rs. {numberWithCommas(order.order_items[0]?.price)}
              </div>
            </div>
          </div>
          <span style={{ display: "flex", justifyContent: "end" }}>
            {/* {order.delivery.status==="delivered"?!checkReview(order.order_items[0]?.product)? (<button className="addReview" onClick={() => {  window.location.href = `/rating-review/${order.order_items[0].product._id}` }}>Add Review</button>):(<button className="addReview" onClick={() => {  window.location.href = `/rating-review/${order.order_items[0].product._id}` }}>Edit Review</button>):""} */}
          </span>
          <hr className='mx-3' style={{ margin: "0px",  marginLeft: "auto", marginRight: "auto" }} />
          <div className="d-flex flex-wrap justify-content-between align-items-center py-3">
            <div className="d-flex align-items-center col-8">
              <div className="imageColumn">
                <img
                  src={order.order_items[1]?.image}
                  className="w-100 ms-3 currOrdersImg"
                />
              </div>
              <div className="col-12 ps-4 ms-3">
                <div className="current_orders_deliverydate current_orders_green text-dark mb-5">
                  <div className="me-4 hoverOrderClass d-flex flex-column"><span onClick={() => navigate(`/product/${order.order_items[1]?.product?._id}`)}>{order.order_items[1]?.name}</span> <span onClick={() => navigate(`/productlist?brand=${order.order_items[1]?.product?.brand?._id}`)} className='wishlist_product_company'>{order.order_items[1]?.product?.brand?.Brand_name}</span></div>
                </div>
                <div className="current_orders_orderID mt-5 text-dark">
                  Quantity: {order.order_items[1]?.quantity}
                </div>
              </div>
            </div>
            <div className="col-4 ps-3 d-lg-block">
              <div className="current_orders_title_rightside ms-auto me-3" style={{ color: "#8A5B40", fontWeight: "600" }}>
                Rs. {numberWithCommas(order.order_items[1]?.price)}
              </div>
            </div>
          </div>
          <span style={{ display: "flex", justifyContent: "end" }}>
            {/* {order.delivery.status==="delivered"?!checkReview(order.order_items[1]?.product)? (<button className="addReview" onClick={() => { navigate( `/rating-review/${order.order_items[1].product._id}`) }}>Add Review</button>):(<button className="addReview" onClick={() => { navigate(`/rating-review/${order.order_items[1].product._id}`) }}>Edit Review</button>):""} */}
          </span>
          <hr className='mx-3' style={{ margin: "0px",marginLeft:"auto",marginRight:"auto" }} />

          <div
            className="d-flex justify-content-center align-items-center p-4 m-auto show-prop"
            style={{ color: "#878787" }}
            onClick={() => {
              setOrderMoreAccordion(true);
            }}
          >
            <BsArrowDownCircle className='downArrowBtn' />  +{order.order_items.length - 2} more items
          </div>
        </>
      )}
    </>
  );
}

function OrderComponent({ order }) {
  const navigate = useNavigate()
  const api = useAxios();
  const [state, dispatch] = useStore();
  const [orderMoreAccordion, setOrderMoreAccordion] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [newRating, setNewRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [product, setProduct] = useState({})
  const hideReviewModal = () => {
    setReviewModal(false);
    setNewRating(0);
    setReviewText("");
  };
  const checkReview = (allRating) => {
    // console.log("testdata",allRating)
    //   allRating?.rating_and_review?.some(function (x) {
    //     if (x.user_id === state.user?._id) {
    //         return true;
    //     } else {
    //       return false;
    //     }
    // })
    // if(allRating?.rating_and_review.length>0){
    // for(let item of allRating?.rating_and_review){
    //     if (item.user_id === state.user?._id) {
    //       return true;
    //     }
    //   }
    // }
    //   return false;
  }

  const showReviewModal = (e) => {
    setProduct(e)
    setReviewModal(true);
  }
  // console.log("testing",checkReview(order.order_items[0].product))
  const addReviewHander = async (array, p_id) => {
    try {
      if (newRating !== 0 && reviewText.length > 0) {
        const res = await api.post("/review/", {
          rating: newRating,
          review: reviewText,
          productId: p_id
        })
        dispatch(addReview(array, res.data.newData))
        setReviewModal(false);
        dispatch(setAvgRating(res.data?.averageRating))
        alert.success("Review Added Successfully")
      } else {
        alert.error("Please Add Rating and Review")
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      {order?.order_items.length > 2 ? (
        <OrderMoreThan2 order={order} />
      ) : (
        order?.order_items.map((e) => {
          return (
            <>
              <div className="d-flex flex-wrap justify-content-between align-items-center py-3 orderContainer">
                <div className="d-flex align-items-center col-8">
                  <div className="imageColumn">
                    <img src={e.image} className="w-100 ms-3 currOrdersImg" />
                  </div>
                  <div className="col-12 ps-4 ms-3">
                    <div className="current_orders_deliverydate current_orders_green text-dark mb-5">
                      <div className='me-4 hoverOrderClass d-flex flex-column'>
                        <span onClick={() => navigate(`/product/${e?.product?._id}`)}>{e?.name}</span> <span onClick={() => navigate(`/productlist?brand=${e?.product?.brand?._id}`)} className='wishlist_product_company'>{e?.product?.brand?.Brand_name}</span></div>
                    </div>
                    <div className="current_orders_orderID mt-5 text-dark">
                      Quantity: {e?.quantity}
                    </div>
                  </div>
                </div>
                <div className="col-4 ps-3 d-lg-block">
                  <div className="current_orders_title_rightside ms-auto me-3" style={{ color: "#8A5B40", fontWeight: "600" }}>
                    Rs. {e?.price}
                  </div>
                </div>
              </div>
              <span style={{ display: "flex", justifyContent: "end" }}>
                {/* {order.delivery.status==="delivered"?!checkReview(e?.product)? (<button className="addReview" onClick={() => { navigate(`/rating-review/${e.product._id}`)}}>Add Review</button>):(<button className="addReview" onClick={() => { navigate(`/rating-review/${e.product._id}`)}}>Edit Review</button>):""} */}
              </span>
            </>
          )
        })
      )}
    </>
  );
}

export default OrderComponent;