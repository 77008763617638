import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { BsArrowLeft } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_GA_MEASUREMENT_ID,
} from "../../../../../constants";
import {
  addAddress,
  deleteAddress,
  getAddresses,
  updateAddress,
  updateSelectedCity,
  setSelectedAdd,
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import "./addressess.css";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const Addresses = () => {
  const navigate = useNavigate();
  const api = useAxios();
  const alert = useAlert();
  const [state, dispatch] = useStore();
  const [addrSelec, setAddrSelec] = useState("Add");
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(-1);

  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressData, setAddressData] = useState({
    name: "",
    address: "",
    mobile: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    addType: "",
  });

  ReactGA.send({
    hitType: "pageview",
    page: "/addresses",
    title: "Address",
  });

  useEffect(() => {
    document.title = "Manage Address - PLYPICKER";
    const getUserAddress = async () => {
      try {
        const res = await api.get("/me/addresses");

        if (res.status === 200) {
          dispatch(getAddresses(res.data.addresses));
        } else {
          alert.error(res.data.error);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUserAddress();
  }, []);

  const addAddressHandler = async () => {
    if (
      addressData.name !== "" &&
      addressData.mobile !== "" &&
      addressData.address !== "" &&
      addressData.landmark !== "" &&
      addressData.city !== "" &&
      addressData.state !== "" &&
      addressData.pincode !== "" &&
      addressData.addType !== ""
    ) {
      try {
        let localLatitude, localLongitude;
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          {
            params: {
              address:
                addressData.address +
                ", " +
                addressData.landmark +
                ", " +
                addressData.city +
                ", " +
                addressData.state +
                ", " +
                addressData.pincode,
              key: REACT_APP_GOOGLE_MAPS_KEY,
            },
          }
        );
        if (response.data?.results.length > 0) {
          localLatitude = response.data.results[0].geometry.location.lat;
          localLongitude = response.data.results[0].geometry.location.lng;
        }
        const res = await api.post("/me/addresses", {
          lat: localLatitude,
          lng: localLongitude,
          address: {
            address: addressData.address.trim(),
            ...addressData,
            address: addressData.address.trim(),
          },
        });
        if (res.status === 200) {
          dispatch(
            addAddress(state.user.addresses, {
              lat: localLatitude,
              lng: localLongitude,
              address: addressData.address.trim(),
              ...addressData,
              address: addressData.address.trim(),
              floor: 1,
              _id: res.data.addressId,
            })
          );
          alert.success(res.data.msg);
          setAddressData({
            name: "",
            address: "",
            mobile: "",
            landmark: "",
            city: "",
            state: "",
            pincode: "",
            country: "",
            addType: "Home",
            floor: 1,
          });
          dispatch(updateSelectedCity(res.data.cityId));
        } else {
          alert.error(res.data.error);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert.error("Fields are empty");
    }
  };

  const updateAddressHandler = async () => {
    const getUpdateData = (state, data) => {
      if (!state) {
        return data;
      } else {
        return state;
      }
    };
    try {
      const res = await api.patch("/me/addresses/", {
        address: {
          _id: getUpdateData(addressData._id, selectedAddress._id),
          name: getUpdateData(addressData.name, selectedAddress.name),
          address: getUpdateData(
            addressData.address.trim(),
            selectedAddress.address.trim()
          ),
          mobile: getUpdateData(addressData.mobile, selectedAddress.mobile),
          landmark: getUpdateData(
            addressData.landmark,
            selectedAddress.landmark
          ),
          city: getUpdateData(addressData.city, selectedAddress.city),
          state: getUpdateData(addressData.state, selectedAddress.state),
          pincode: getUpdateData(addressData.pincode, selectedAddress.pincode),
          lat: selectedAddress.lat,
          lng: selectedAddress.lng,
          addType: selectedAddress.addType,
        },
      });
      if (res.status === 200) {
        alert.success("Updated successfully");
        dispatch(
          updateAddress(selectedAddressIndex, {
            _id: getUpdateData(addressData._id, selectedAddress._id),
            name: getUpdateData(addressData.name, selectedAddress.name),
            address: getUpdateData(
              addressData.address.trim(),
              selectedAddress.address.trim()
            ),
            mobile: getUpdateData(addressData.mobile, selectedAddress.mobile),
            landmark: getUpdateData(
              addressData.landmark,
              selectedAddress.landmark
            ),
            city: getUpdateData(addressData.city, selectedAddress.city),
            state: getUpdateData(addressData.state, selectedAddress.state),
            pincode: getUpdateData(
              addressData.pincode,
              selectedAddress.pincode
            ),
            lat: getUpdateData(addressData.lat, selectedAddress.lat),
            lng: getUpdateData(addressData.lng, selectedAddress.lng),
            addType: selectedAddress.addType,
          })
        );
      } else {
        alert.error("An error occoured with your address");
      }
    } catch (err) {
      console.log(err);
    }
    setSelectedAddress({});
    setSelectedAddressIndex(-1);
  };

  const deleteAddressHandle = async (id) => {
    try {
      const res = await api.delete(`/me/addresses/delete/${id}`);
      dispatch(deleteAddress(selectedAddress));
      if (res.status === 200) {
        alert.success(res.data.msg);
        dispatch(setSelectedAdd(null));
      } else {
        alert.error(res.data.msg);
      }
    } catch (err) {
      console.log(err);
    }
    setSelectedAddress({});
    setSelectedAddressIndex(-1);
  };

  const handleChange = (e) => {
    const pincode = e.target.value;
    if (pincode.length <= 6 && !isNaN(pincode)) {
      let localAddress = {
        ...addressData,
        pincode: pincode,
      };
      setAddressData((prev) => ({
        ...prev,
        pincode: pincode,
      }));
      if (localAddress.pincode.length === 6) {
        fetch(`https://api.postalpincode.in/pincode/${localAddress.pincode}`)
          .then((res) => res.json())
          .then((data) => {
            if (data && data[0] && data[0].Status === "Success") {
              const postOfficeData = data[0].PostOffice[0];
              setAddressData((prev) => ({
                ...prev,
                city: postOfficeData.District,
                state: postOfficeData.State,
              }));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // setAddressData((prev) => ({
        //   ...prev,
        //   city: '',
        //   state: '',
        // }));
      }
    }
  };

  const handleAddressTypeChange = (event) => {
    // setAddressData(event.target.value);
    setAddressData((prev) => ({
      ...prev,
      addType: event.target.value,
    }));
  };

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  return (
    <>
      {isMobile ? (
        <div className="MobileView">
          <span className="headingView">
            <BsArrowLeft
              onClick={() => navigate("/")}
              style={{ fontSize: "30px" }}
            />{" "}
            Address
          </span>
        </div>
      ) : (
        <NavRoute title="addresses" />
      )}
      <div
        className="sidebarProfilePageContainer w-100 px-0"
        style={!isMobile ? { borderTop: "1px solid black" } : {}}
      >
        <div className="row m-0 p-0 w-100" style={{ overflow: "visible" }}>
          <SideBar title="addresses" />
          <div className="col-sm-9 col-12 d-flex flex-column">
            <div style={{ marginTop: 15, marginLeft: 20, marginBottom: 15 }}>
              <button
                className="addAddress"
                data-toggle="modal"
                data-target="#addAddress"
                onClick={() => setAddrSelec("Add")}
              >
                + Add new address
              </button>
            </div>
            {state.user.addresses.map((addressData, i) => (
              <div className="renderside_profile address_data">
                <div className="inside_address">
                  <span className="address_first">{addressData.addType}</span>
                  <div className="address_middle">
                    <span style={{ fontWeight: 400 }}>{addressData.name}</span>
                    <span style={{ fontWeight: 300 }}>
                      {addressData.address},{addressData.landmark},
                    </span>
                    <span style={{ fontWeight: 300 }}>{addressData.city},</span>
                    <span style={{ fontWeight: 300 }}>
                      {addressData.state} - {addressData.pincode}
                    </span>
                  </div>
                  <span
                    className="address_mobile"
                    style={{
                      marginTop: 10,
                      fontFamily: "Poppins",
                      fontWeight: 300,
                    }}
                  >
                    Mobile: {addressData.mobile}
                  </span>
                </div>
                <div className="address_data_buttons">
                  <button
                    className="editAddress"
                    onClick={() => {
                      setAddrSelec("Edit");
                      setSelectedAddress(addressData);
                      setSelectedAddressIndex(i);
                      setAddressData({
                        name: addressData.name,
                        address: addressData.address,
                        mobile: addressData.mobile,
                        landmark: addressData.landmark,
                        city: addressData.city,
                        state: addressData.state,
                        pincode: addressData.pincode,
                        country: addressData.country,
                        addType: addressData.addType,
                      });
                    }}
                    data-toggle="modal"
                    data-target="#addAddress"
                  >
                    edit
                  </button>
                  <button
                    className="removeAddress"
                    data-toggle="modal"
                    data-target="#deleteAddress"
                    onClick={() => {
                      setSelectedAddressIndex(i);
                      setSelectedAddress(addressData);
                    }}
                  >
                    remove
                  </button>
                </div>
              </div>
            ))}
            <br />
          </div>
        </div>
        {/* {add/edit modal} */}
        <div
          className="modal fade"
          id="addAddress"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content address_change_popup">
              <div className="modal-header headingADeleteAddress">
                <h5
                  className="modal-title AddressNameModal"
                  id="exampleModalLabel"
                  style={{
                    fontWeight: "bold",
                    fontSize: "24px",
                    fontFamily: "Poppins",
                  }}
                >
                  {addrSelec} Address
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setAddressData({
                      name: "",
                      address: "",
                      mobile: "",
                      landmark: "",
                      city: "",
                      state: "",
                      pincode: "",
                      country: "",
                      addType: "",
                    });
                    setSelectedAddress({});
                    setSelectedAddressIndex(-1);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
                <label className="inputLabels" id="NameId">
                  Name
                </label>
                <input
                  id="NameId"
                  type="text"
                  // placeholder={addrSelec === "Update" && selectedAddress?.name?.length > 0 ? selectedAddress?.name : ""}
                  value={addressData.name}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }));
                  }}
                />
                <label className="inputLabels" id="MobileId">
                  Mobile
                </label>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  {addrSelec !== "Edit" && (
                    <span className="addressMobileNumberInputPrefix">+91</span>
                  )}
                  <span className="addressMobileNumberInputWrapper">
                    <input
                      id="MobileId"
                      type="text"
                      min={addrSelec !== "Edit" && 10}
                      max={addrSelec !== "Edit" && 10}
                      // placeholder={addrSelec === "Update" && selectedAddress?.mobile?.length > 0 ? selectedAddress?.mobile : ""}
                      value={addressData.mobile}
                      onChange={(e) => {
                        if (addrSelec !== "Edit") {
                          if (
                            e.target.value.length <= 10 &&
                            !isNaN(e.target.value)
                          ) {
                            setAddressData((prev) => ({
                              ...prev,
                              mobile: e.target.value,
                            }));
                          }
                        } else {
                          if (
                            e.target.value.length <= 13 &&
                            !isNaN(
                              e.target.value.substring(
                                1,
                                e.target.value.length - 1
                              )
                            )
                          ) {
                            setAddressData((prev) => ({
                              ...prev,
                              mobile: e.target.value,
                            }));
                          }
                        }
                      }}
                    />
                  </span>
                </div>
                <label className="inputLabels" id="AddressId">
                  Address( House No., Building, Street, Area )
                </label>
                <input
                  id="AddressId"
                  // type="textarea"
                  // placeholder={addrSelec === "Update" && selectedAddress?.address?.length > 0 ? selectedAddress?.address : ""}
                  value={addressData.address}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      address: e.target.value,
                    }));
                  }}
                />
                <label className="inputLabels" id="LandmarkId">
                  Landmark
                </label>
                <input
                  id="LandmarkId"
                  type="text"
                  // placeholder={addrSelec === "Update" && selectedAddress?.landmark?.length > 0 ? selectedAddress?.landmark : ""}
                  // placeholder="Landmark"
                  value={addressData.landmark}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      landmark: e.target.value,
                    }));
                  }}
                />
                <label className="inputLabels" id="CityId">
                  City
                </label>
                <input
                  id="CityId"
                  type="text"
                  // placeholder={addrSelec === "Update" && selectedAddress?.city?.length > 0 ? selectedAddress?.city : ""}
                  // placeholder="City"
                  value={addressData.city}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                />
                <div className="InsideModalSpace">
                  <span>
                    <label className="inputLabels" id="StateId">
                      State
                    </label>
                    <input
                      id="StateId"
                      type="text"
                      // placeholder={addrSelec === "Update" && selectedAddress?.state?.length > 0 ? selectedAddress?.state : ""}
                      // placeholder="State"
                      value={addressData.state}
                      onChange={(e) => {
                        setAddressData((prev) => ({
                          ...prev,
                          state: e.target.value,
                        }));
                      }}
                    />
                  </span>
                  <span>
                    <label className="inputLabels" id="PincodeId">
                      Pincode
                    </label>
                    <input
                      id="PincodeId"
                      type="text"
                      // placeholder={addrSelec === "Update" && selectedAddress?.pincode?.length > 0 ? selectedAddress?.pincode : ""}
                      // placeholder="Pincode"
                      value={addressData.pincode}
                      onChange={handleChange}
                    />
                  </span>
                </div>
                {/* addType */}
                {/* {addrSelec === "Update" ? "" : ( */}
                <div className="address_type">
                  <span>Type of Address</span>
                  <br />
                  <FormControl>
                    <RadioGroup
                      row={true}
                      className="AddressRadio ms-0"
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={addressData.addType}
                      onChange={handleAddressTypeChange}
                    >
                      <FormControlLabel
                        value="Home"
                        control={<Radio />}
                        label="Home"
                      />
                      <FormControlLabel
                        value="Site"
                        control={<Radio />}
                        label="Site"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* )} */}
              </div>
              <div className="AdressModalSubmit pb-2">
                <button
                  className="CloseaddUpdateButton"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setAddressData({
                      name: "",
                      address: "",
                      mobile: "",
                      landmark: "",
                      city: "",
                      state: "",
                      pincode: "",
                      country: "",
                      addType: "",
                    });
                    setSelectedAddress({});
                  }}
                >
                  Discard
                </button>
                <button
                  className="addUpdateButton"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() =>
                    addrSelec === "Add"
                      ? addAddressHandler()
                      : updateAddressHandler()
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal for Delete Address--> */}
        <div
          className="modal fade"
          id="deleteAddress"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content password_change_popup px-3 py-2">
              <div className="modal-header headingADeleteAddress">
                <h5 className="modal-title" id="deleteAddress">
                  Do you want to delete the address?
                </h5>
              </div>
              <div className="profile_form_content_inside_data">
                <div className="select_address w-100 ms-2">
                  <div className="select_address_mid">
                    <div className="inside_address_mid">
                      <span className="address_name_mid">
                        {selectedAddress?.name}
                      </span>
                      <span className="address_type_mid">
                        {selectedAddress?.addType}
                      </span>
                    </div>
                    <span className="address_add_mid">
                      {selectedAddress?.address}, {selectedAddress?.landmark},{" "}
                      {selectedAddress?.city}, {selectedAddress?.state}-
                      {selectedAddress?.pincode}
                    </span>
                  </div>
                </div>
              </div>
              <div className="modal-body pt-0 pb-2">
                <div className="d-flex w-100 flex-row align-items-center justify-content-start">
                  <button
                    className="CloseaddUpdateButton ms-0"
                    type="button"
                    data-dismiss="modal"
                    onClick={() => setSelectedAddressIndex(-1)}
                  >
                    No
                  </button>
                  <button
                    className="addUpdateButton"
                    type="button"
                    data-dismiss="modal"
                    onClick={() => deleteAddressHandle(selectedAddress._id)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LowerNavigation />
      <Footer />
    </>
  );
};

export default Addresses;
