import React from "react";
import "./Component9.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";

export default function Component9({ props }) {
    const useFulLinks = props?.title1;
    const useFulLinksHeading = props?.title1[0]?.title;
    const servicesLinks = props?.title2;
    const servicesLinksHeading = props?.title2[0]?.title;
    const needHelpLinks = props?.title3;
    const needHelpLinksHeading = props?.title3[0]?.title;
    const imgLinks = props?.image;
    const socialImgLinks = props?.image?.data2;
    const navigate = useNavigate();
    const AccordionFunction = ({ ques, ans, index }) => {
        return (
            <Accordion style={{ boxShadow: "none" }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                >
                    <Typography style={{ fontWeight: "bold", fontFamily: "poppins" }}>
                        {ques}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <a href="">{ans}</a>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <>
            {props && <div className="compBottomMargin mb-5 pb-3">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                    }}
                >
                    <div className="d-lg-block d-none">
                        <span className="newHeading">{useFulLinksHeading}</span>
                        {useFulLinks?.map((data, index) => {
                            return <div className="newText" onClick={() => navigate(data?.redirect)} style={{ cursor: "pointer" }} key={index}>{data?.subtitle}</div>;
                        })}
                    </div>
                    <div className="d-lg-block d-none">
                        <span className="newHeading">{servicesLinksHeading}</span>
                        {servicesLinks?.map((data, index) => {
                            return <div className="newText" onClick={() => navigate(data?.redirect)} style={{ cursor: "pointer" }} key={index}>{data?.subtitle}</div>;
                        })}
                    </div>
                    <div className="d-lg-block d-none">
                        <span className="newHeading">{needHelpLinksHeading}</span>
                        {needHelpLinks?.map((data, index) => {
                            return <div className="newText" onClick={() => navigate(data?.redirect)} style={{ cursor: "pointer" }} key={index}>{data?.subtitle}</div>;
                        })}
                    </div>
                    <div
                        style={{
                            width: "20%",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                        className="d-lg-block d-none"
                    >
                        {imgLinks?.data?.map((data, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
                                    onClick={() => window.location.href = (data?.redirect)}
                                >
                                    <img
                                        key={index}
                                        style={{ width: "100%", margin: "1%" }}
                                        src={data.url}
                                        alt={`Image ${index}`}
                                    />
                                </div>
                            );
                        })}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {socialImgLinks?.map((item, index) => {
                                return <img key={index} style={{ height: "10%", width: "10%", margin: "2%", cursor: "pointer" }} key={index} src={item?.url} onClick={() => window.location.href = (item?.redirect)} />;
                            })}
                        </div>
                    </div>
                </div>
                <div className="d-lg-none d-block">
                <Accordion>
                    <AccordionSummary>{props.title1[0].title}</AccordionSummary>
                    <AccordionDetails>
                        {props.title1.map(x => <>
                            <div>
                                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={x?.redirect}>{x?.subtitle}</Link>
                            </div>
                        </>)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>{props.title2[0].title}</AccordionSummary>
                    <AccordionDetails>
                        {props.title2.map(x => <>
                            <div>
                                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={x?.redirect}>{x?.subtitle}</Link>
                            </div>
                        </>)}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>{props.title3[0].title}</AccordionSummary>
                    <AccordionDetails>
                        {props.title3.map(x => <>
                            <div>
                                <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={x?.redirect}>{x?.subtitle}</Link>
                            </div>
                        </>)}
                    </AccordionDetails>
                </Accordion>
                <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                        className="w-100 mx-2"
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {socialImgLinks?.map((item, index) => {
                                return <img style={{ height: "10%", width: "5%", margin: "2%" }} key={index} src={item?.url} />;
                            })}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}
