import Slider from '@mui/material/Slider';
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import x from "../img/Filter/x.png";
import "./Price.css";

function Price({maxPrice,
  setMaxPrice,
  minPrice,
  setMinPrice,
  maxPriceLimit,
  setMinMaxPriceZero,
  newSliderOnChange,widthClass}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  let params = {};

  const [queryS, setQueryS] = useState("");

  var esc = encodeURIComponent;
  // let query = Object.keys(params)
  //   .map((k) => esc(k) + "=" + esc(params[k]))
  //   .join("&");


  const getMax = (productData) => {
    let maxi = 0;
    productData.map((item) => (maxi = Math.max(maxi, item.actual_price)));
    setMaxPrice(maxi);
    return maxi;
  };

  const getMin = (productData) => {
    let mini = Number.MAX_SAFE_INTEGER;
    productData.map((item) => (mini = Math.min(mini, item.actual_price)));
    setMinPrice(mini);
    return mini;
  };
  // Double Slider
  const [value, setValue] = useState([0, 1000]);
  const changeValue = (event, value) => {
    setValue(value);
  };

  const getText = (value) => `${value}`;

  // //////////////////////////////////


  // useEffect(() => {
  //   const getData = async () => {
  //     const productData = await (
  //       await axios.get(`${API_URL}/products?max=${maxPrice}&min=${minPrice}`)
  //     ).data;
  //     setItems(productData);
  //     setProducts(productData);
  //     let maxi = getMax(productData);
  //     let mini = getMin(productData);
  //     setValue([mini, maxi]);
  //   };
  //   getData();
  // }, [maxPrice,minPrice])
  return (
    <div style={widthClass?{width:"55%"}:{}}>
      <table class="table my-0">
        <thead>
          <tr>
            <th scope="col" className="ps-0">PRICE</th>
            <td className="clearFilterButton">
              {/* <Cancel/> */}
              <img src={x} alt="" style={{cursor: "pointer"}} onClick={(e) => {setMinMaxPriceZero()}} />
            </td>
          </tr>
        </thead>
      </table>
      <div className="">
        <div className="row w-100 m-0">
          <div className="col p-0 d-flex justify-content-start">
            <button type="button" className="priceFilterButton ms-0 me-1">
              {/* ₹{Math.min(...value)} */}
              Rs. {minPrice}
            </button>
          </div>
          <div className="col p-0 d-flex justify-content-end">
            <button type="button" className="priceFilterButton ms-1 me-0">
              {/* ₹{Math.max(...value)} */}
              Rs. {maxPrice}
            </button>
          </div>
        </div>
        <div className="row priceFilterSlider mx-auto">
          <Slider
              value={[minPrice, maxPrice]}
              max={maxPriceLimit}
              min={0}
              onChange={newSliderOnChange}
              valueLabelDisplay="off"
              step={maxPriceLimit/10 || 150}
              marks
          />
        </div>
      </div>
    </div>
  );
}

export default Price;