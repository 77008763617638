import { Avatar } from '@mui/material';
import { useEffect, useState } from "react";
import Modal from 'react-awesome-modal';
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../constants";
import { logout } from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import useAxios from "../../../utils/useAxios";
import FemaleVector from "./images/FemaleVector.png";
import MaleVector from "./images/MaleVector.png";

const SideBar = (props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const api = useAxios();
  // https://stackoverflow.com/a/57236925 🛐🛐🛐
  const logoutHandler = async () => {
    api.post(API_URL + "/auth/logout", {}, { withCredentials: true, })
      .then(response => {
        dispatch(logout())
        navigate("/")
      })
      .catch(error => {
        alert.error(error.response.data.error)
      });
  }
  const deleteAccountHandler = async (e) => {
    // e.preventDefault();
    api.get(API_URL + "/deleteAccount")
      .then(response => {
        alert.success(response.data?.msg)

      })
      .catch(error => {
        alert.error(error.response.data.error)
      });
    setIsOpen(false)
    navigate('/')
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return (
    <div className="col-sm-3 d-sm-block d-none sidebarRightBorder pe-0">
        <div className="profilePicDiv">
          <Avatar src={state?.user?.gender.toLowerCase() === "male" ? MaleVector : FemaleVector} sx={{ width: 150, height: 150 }} />
        </div>
        <div className="profile__sidebar d-flex flex-column justify-content-between pe-2">
          <div className="d-flex flex-column justify-content-center align-items-start">
            <p className="my-3 sidebarAccountSettings">Account settings</p>
            <Link to="/profile" className={props.title === "profile" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Profile</span>
            </Link>
            <Link to="/addresses" className={props.title === "addresses" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Your Address</span>
            </Link>

            <Link to="/wishlist" className={props.title === "wishlist" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Wishlist</span>
            </Link>

            <Link to="/cart" className={props.title === "cart" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Cart</span>
            </Link>

            <Link to="/notif" className={props.title === "notif" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Notification</span>
            </Link>

            <Link to="/orders" className={props.title === "orders" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Your Orders</span>
            </Link>

            <Link to="/faq" className={props.title === "faq" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">FAQ</span>
            </Link>

            <Link to="/privacy-policy" className={props.title === "privacyPolicy" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name">Privacy Policy</span>
            </Link>

            <div className={props.title === "deleteAccount" ? "nav__link active-nav" : "nav__link"}>
              <span className="nav__name" onClick={() => { setIsOpen(true) }}>Delete Account</span>
            </div>

            <div className={props.title === "logout" ? "nav__link change__password d-flex align-items-center justify-content-center active-nav mb-3" : "nav__link change__password d-flex align-items-center justify-content-center sideBarLogoutButton me-2 mb-3"}>
              <span className="" onClick={logoutHandler}>Logout</span>
            </div>

          </div>
        </div>

        {/* <div className="nav__link__outer LogoutButton">
        <Link to="/" className="nav__link logout">
        <span className="nav__name" onClick={logoutHandler}>Logout</span>
        </Link>
      </div> */}
        <Modal visible={isOpen}
          width="475"
          height="200"
          effect="fadeInUp"
          onClickAway={() => { setIsOpen(false); }}>
          <div class="modal-content px-3 py-3 password_change_popup">
            <div class="modal-header p-0 headingADeleteAddress">
              <h5 class="modal-title px-2 pt-3" id="deleteItemFromCart" style={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Poppins' }} >Are u sure to delete this account?</h5></div>
            <div class="login-box">
              <div class="d-flex flex-row align-items-center w-100" style={{justifyContent:"space-evenly"}}>
                <button class="CloseaddUpdateButton ms-2" type="button" onClick={() => { setIsOpen(false) }}>
                  No
                </button>
                <button class="addUpdateButton" type="button" onClick={deleteAccountHandler}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
    </div>
  )
}

export default SideBar