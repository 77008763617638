import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { API_URL } from "../../../constants";
import {
  setAuth,
  setCartData,
  setUser,
} from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import useAxios from "../../../utils/useAxios";
import Close from "./Close.png";
import ClosedEye from "./ClosedEye.png";
import LoginImage from "./LoginImage.png";
import "./LoginRegisterPopup.css";
import OpenEye from "./OpenEye.png";
import RegisterImage from "./RegisterImage.png";
import { BsAsterisk } from "react-icons/bs";
import { getAddresses } from "../../../store/reducers/userActions";

const AntTabs = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#FF7703",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "uppercase",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    "&:hover": {
      color: "#FF7703",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#FF7703",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  })
);

function LoginRegisterPopup({ isVisible, setIsVisible }) {
  const [state, dispatch] = useStore();
  const [modalType, setModalType] = useState("login");
  const alert = useAlert();
  const navigate = useNavigate();
  const api = useAxios();
  const [emailOrMobile, setEmailOrMobile] = useState("");
  const [resEmail, setResEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [resPassType, setResPassType] = useState("Email ID");
  const [otpPopup, setOTPopup] = useState(false);
  const [value, setValue] = useState("one");
  const [otp, setOtp] = useState("");
  const [otpRP, setOtpRP] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpNumber, setOtpNumber] = useState("");
  const OTPhandleChange = (e) => setOtp(e);
  const OTPRPhandleChange = (e) => setOtpRP(e);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [timer, setTimer] = useState(30);
  const [linkEnabled, setLinkEnabled] = useState(true);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setLinkEnabled(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  function loginHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    if (!emailOrMobile || !loginPassword) {
      alert.error("Please enter Email/Mobile Number and Password");
      setIsLoading(false);
      return;
    }
    const data = {
      emailOrMobile,
      password: loginPassword,
    };

    axios
      .post(API_URL + "/auth/login", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then(async (response) => {
        if (
          !response.data.error &&
          response.data.accessToken &&
          response.data.user
        ) {
          const res = await api.get("/carts");
          alert.success("Logged in Successfully");
          dispatch(setUser(response.data.user));
          dispatch(setAuth(response.data.accessToken, true));
          const addresses = await api.get("/me/addresses");
          if (!addresses.data.error) {
            dispatch(getAddresses(addresses.data?.addresses));
          } else {
            console.log(addresses.data.error);
          }
          dispatch(setCartData(res.data?.cartItems || []));
          setIsLoading(false);
          setIsVisible(false);
        }
      })
      .catch((error) => {
        alert.error(error.response.data.error);
        setIsLoading(false);
      });
  }

  function loginOtpHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    if (!emailOrMobile || !otp) {
      alert.error("Please enter Mobile Number and OTP");
      setIsLoading(false);
      return;
    }
    const data = {
      emailOrMobile,
      otp: otp,
    };

    axios
      .post(API_URL + "/auth/loginOTP", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then(async (response) => {
        if (
          !response.data.error &&
          response.data.accessToken &&
          response.data.user
        ) {
          const res = await api.get("/carts");
          alert.success("Logged in Successfully");
          dispatch(setUser(response.data.user));
          dispatch(setAuth(response.data.accessToken, true));
          const addresses = await api.get("/me/addresses");
          if (!addresses.data.error) {
            dispatch(getAddresses(addresses.data?.addresses));
          } else {
            console.log(addresses.data.error);
          }
          dispatch(setCartData(res.data?.cartItems || []));
          setIsLoading(false);
          setIsVisible(false);
        }
      })
      .catch((error) => {
        alert.error(error.response.data.error);
        setIsLoading(false);
      });
  }

  function registerHandler(event) {
    event.preventDefault();
    if (isNaN(emailOrMobile)) {
      navigate(`/signup?email=${emailOrMobile}`);
    } else {
      navigate(`/signup?mobile=${emailOrMobile}`);
    }
  }

  const handleResPassTypeChange = (event) => {
    setResPassType(event.target.value);
    setResEmail("");
  };

  const forgotPwdHandler = async () => {
    if (!resEmail) {
      alert.error("Please enter email/number");
      return;
    }
    const data = {
      email: resEmail,
      type: resPassType,
    };

    axios
      .post(API_URL + "/auth/reset-password", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then((response) => {
        alert.success(response.data.msg);
        if (resPassType === "Mobile Number") {
          setOTPopup(true);
          setOtpNumber(resEmail);
        }
        // setResEmail("");
      })
      .catch((error) => {
        alert.error(error.response.data.error);
      });
  };

  const verifyMobile = async () => {
    if (!validator.isMobilePhone(emailOrMobile)) {
      alert.error("Invalid phone number.");
      return;
    } else {
    }
    const response = await axios.post(
      API_URL + "/auth/verify-mobile-otp",
      { mobileNumber: emailOrMobile },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.data.error) {
      alert.success(response.data.msg);
      setOtpSent(true);
      setTimer(30);
    } else {
        alert.error(response.data.error);
        setOtpSent(false);
    }
    setLinkEnabled(false);
  };

  const verifyMobileRP = async () => {
    if (!validator.isMobilePhone(otpNumber)) {
      alert.error("Invalid phone number.");
      return;
    } else {
      setOtpSent(true);
    }
    try{
        const response = await axios.post(
          API_URL + "/auth/reset-password-otp",
          { mobileNumber: otpNumber, otp: otpRP },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.data.error) {
          alert.success(response.data.msg);
          window.open(response.data.resetLink, "_blank");
          setOTPopup(false);
        } else {
          alert.error(response.data.error);
        }
    } catch (e) {
        alert.error("Wrong OTP");
    }
  };

  return (
    <>
      {isVisible && (
        <>
          {!state.authenticated && (
            <>
              <div className="popupBackPanel">
                <div className="popupFrontPanel">
                  <img
                    src={Close}
                    className="loginPopupCloseButton"
                    onClick={() => {
                      setIsVisible(false);
                    }}
                  />
                  {modalType === "login" ? (
                    <>
                      <img src={LoginImage} className="popupImage" />
                      <div className="formDiv">
                        <h1 className="loginPopupHeading">Login</h1>
                        <Box
                          sx={{ width: "100%" }}
                          className="LoginTabBox"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <AntTabs
                            value={value}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                          >
                            <AntTab value="one" label="Login with Password" />
                            <AntTab value="two" label="Login with OTP" />
                          </AntTabs>
                        </Box>
                        <form
                          className="loginForm"
                          onSubmit={(event) => {
                            value === "one"
                              ? loginHandler(event)
                              : loginOtpHandler(event);
                          }}
                        >
                          {value == "one" ? (
                            <>
                              <p className="loginPopupInputText">
                                Email/Mobile number
                              </p>
                              <input
                                autoFocus
                                className="loginPopupInputBox"
                                type="text"
                                id="email"
                                name="email"
                                value={emailOrMobile}
                                onChange={(e) =>
                                  setEmailOrMobile(e.target.value.trim())
                                }
                              />
                              <p className="loginPopupInputText">Password</p>
                              <div className="position-relative">
                                <input
                                  className="loginPopupPasswordBox"
                                  type={isPasswordVisible ? "text" : "password"}
                                  id="password"
                                  name="password"
                                  value={loginPassword}
                                  onChange={(e) =>
                                    setLoginPassword(e.target.value)
                                  }
                                />
                                {loginPassword.length !== 0 && (
                                  <button
                                    className="popupShowPasswordButton"
                                    type="button"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setIsPasswordVisible(!isPasswordVisible);
                                    }}
                                  >
                                    {isPasswordVisible ? (
                                      <img
                                        src={ClosedEye}
                                        style={{ width: "100%" }}
                                      />
                                    ) : (
                                      <img
                                        src={OpenEye}
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  </button>
                                )}
                              </div>
                              <br />
                              <button
                                type="button"
                                className="loginPopupForgotPasswordButton"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                Forgot Password
                              </button>
                              <br />
                              <button
                                className="loginPopupButton loginPopupLoginButton"
                                type="submit"
                              >
                                {isLoading ? (
                                  <>
                                    <CircularProgress
                                      style={{ color: "white" }}
                                      size="24px"
                                    />
                                  </>
                                ) : (
                                  <div className="loginPopupLoginText">
                                    Login
                                  </div>
                                )}
                              </button>
                              <br />
                              <button
                                className="loginPopupButton loginPopupRegisterButton"
                                type="button"
                                onClick={() => {
                                  setModalType("register");
                                }}
                              >
                                Sign Up
                              </button>
                              <span className="d-flex align-items-center">
                                <BsAsterisk
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "11px",
                                    fill: "#FF7253",
                                  }}
                                />
                                <span
                                  type="button"
                                  className="loginPopupForgotPasswordButton"
                                  onClick={() => {
                                    navigate("/terms-and-condition");
                                    setIsVisible(false);
                                  }}
                                >
                                  T&C Apply
                                </span>
                              </span>
                            </>
                          ) : (
                            <>
                              {otpSent ? (
                                <>
                                  <p className="loginPopupInputText">OTP</p>
                                  <OtpInput
                                    className="OtpInput"
                                    value={otp}
                                    onChange={OTPhandleChange}
                                    numInputs={6}
                                    isDisabled={
                                      emailOrMobile.length === 10 ? false : true
                                    }
                                    separator={
                                      <span style={{ padding: "5px" }}>-</span>
                                    }
                                  />
                                  <br />
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "start",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        display: "flex",
                                        gap: "1rem",
                                        border: "none",
                                        cursor: linkEnabled
                                          ? "pointer"
                                          : "not-allowed",
                                        backgroundColor: "transparent",
                                      }}
                                      className={
                                        linkEnabled
                                          ? "loginPopupForgotPasswordButton"
                                          : null
                                      }
                                      disabled={!linkEnabled}
                                      onClick={() => {
                                        verifyMobile();
                                      }}
                                    >
                                      Resend OTP
                                      <p
                                        style={{
                                          color: "black",
                                          fontStyle: "normal",
                                          textDecorationLine: "none",
                                          ":hover": {
                                            textDecorationLine: "none",
                                          },
                                        }}
                                      >
                                        in {timer} seconds
                                      </p>
                                    </button>
                                    <button
                                      type="button"
                                      className="loginPopupForgotPasswordButton"
                                      onClick={() => {
                                        setOtpSent(false);
                                      }}
                                    >
                                      Back
                                    </button>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <p className="loginPopupInputText">
                                    Mobile number
                                  </p>
                                  <input
                                    autoFocus
                                    className="loginPopupInputBox"
                                    type="text"
                                    minlength="10"
                                    maxlength="10"
                                    size="10"
                                    value={emailOrMobile}
                                    onChange={(e) =>
                                      setEmailOrMobile(e.target.value.trim())
                                    }
                                  />
                                </>
                              )}
                              <br />
                              {!otpSent && (
                                <button
                                  className="loginPopupButton loginPopupLoginButton"
                                  onClick={() => {
                                    verifyMobile();
                                  }}
                                  type="button"
                                >
                                  Send OTP
                                </button>
                              )}
                              {otpSent && (
                                <button
                                  className="loginPopupButton loginPopupLoginButton"
                                  type="submit"
                                >
                                  {isLoading ? (
                                    <>
                                      <CircularProgress
                                        style={{ color: "white" }}
                                        size="24px"
                                      />
                                    </>
                                  ) : (
                                    <div className="loginPopupLoginText">
                                      Login
                                    </div>
                                  )}
                                </button>
                              )}
                              <br />
                              <button
                                className="loginPopupButton loginPopupRegisterButton"
                                type="button"
                                onClick={() => {
                                  setModalType("register");
                                }}
                              >
                                Sign Up
                              </button>
                              <span className="d-flex align-items-center">
                                <BsAsterisk
                                  style={{
                                    marginRight: "5px",
                                    fontSize: "11px",
                                    fill: "#FF7253",
                                  }}
                                />
                                <span
                                  type="button"
                                  className="loginPopupForgotPasswordButton"
                                  onClick={() => {
                                    navigate("/terms-and-condition");
                                    setIsVisible(false);
                                  }}
                                >
                                  T&C Apply
                                </span>
                              </span>
                            </>
                          )}
                        </form>
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={RegisterImage} className="popupImage" />
                      <div className="formDiv">
                        <h1 className="registerPopupHeading">Sign Up</h1>
                        <form
                          className="loginForm"
                          onSubmit={(event) => {
                            registerHandler(event);
                          }}
                        >
                          <p className="loginPopupInputText">
                            Email/Mobile number
                          </p>
                          <input
                            autoFocus
                            className="registerPopupInputBox"
                            type="text"
                            id="email"
                            name="email"
                            value={emailOrMobile}
                            onChange={(e) =>
                              setEmailOrMobile(e.target.value.trim())
                            }
                          />
                          <br />
                          <br />
                          <button
                            className="loginPopupButton loginPopupLoginButton"
                            type="submit"
                          >
                            Sign Up
                          </button>
                          <br />
                          <button
                            className="loginPopupButton registerPopupRegisterButton"
                            type="button"
                            onClick={() => {
                              setModalType("login");
                            }}
                          >
                            Login
                          </button>
                          <span className="d-flex align-items-center">
                            <BsAsterisk
                              style={{
                                marginRight: "5px",
                                fontSize: "11px",
                                fill: "#FF7253",
                              }}
                            />
                            <span
                              type="button"
                              className="loginPopupForgotPasswordButton"
                              onClick={() => {
                                navigate("/terms-and-condition");
                                setIsVisible(false);
                              }}
                            >
                              T&C Apply
                            </span>
                          </span>
                        </form>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* <!--Forgot passowrd Modal --> */}
              <div
                className="modal lrp-modal"
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content password_change_popup">
                    <div
                      className="modal-header signin_modal_title"
                      style={{ borderBottom: "none" }}
                    >
                      <h5 className="modal-title" id="exampleModalLabel">
                        Forgot Password? <br />
                      </h5>
                      <div className="modal_password_reset">
                        We will send you a password reset link.
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      {!otpPopup ? (
                        <>
                          <FormControl>
                            <RadioGroup
                              row={false}
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={resPassType}
                              onChange={handleResPassTypeChange}
                            >
                              <FormControlLabel
                                value="Email ID"
                                control={<Radio />}
                                label="Email ID"
                              />
                              <FormControlLabel
                                value="Mobile Number"
                                control={<Radio />}
                                label="Mobile Number"
                              />
                            </RadioGroup>
                          </FormControl>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            {resPassType === "Mobile Number" && (
                              <span
                                style={{ marginBottom: "4px" }}
                                className="addressMobileNumberInputPrefix"
                              >
                                +91
                              </span>
                            )}
                            {resPassType === "Mobile Number" ? (
                              <input
                                type="text"
                                minlength="10"
                                maxlength="10"
                                size="10"
                                value={resEmail}
                                onChange={(e) =>
                                  setResEmail(e.target.value.trim())
                                }
                                placeholder={resPassType}
                              />
                            ) : (
                              <input
                                type="text"
                                value={resEmail}
                                onChange={(e) =>
                                  setResEmail(e.target.value.trim())
                                }
                                placeholder={resPassType}
                              />
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <p className="loginPopupInputText">OTP</p>
                          <OtpInput
                            className="OtpInput"
                            value={otpRP}
                            onChange={OTPRPhandleChange}
                            numInputs={6}
                            separator={
                              <span style={{ padding: "5px" }}>-</span>
                            }
                          />
                          <br />
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "start",
                            }}
                          >
                            <button
                              type="button"
                              className="loginPopupForgotPasswordButton"
                              onClick={() => {
                                forgotPwdHandler();
                              }}
                            >
                              Resend OTP
                            </button>
                            <button
                              type="button"
                              className="loginPopupForgotPasswordButton"
                              onClick={() => {
                                setOTPopup(false);
                              }}
                            >
                              Back
                            </button>
                          </span>
                        </>
                      )}
                    </div>
                    <div className="modal-footer" style={{ borderTop: "none" }}>
                      <button
                        type="button"
                        className="form_register_button"
                        onClick={otpPopup ? verifyMobileRP : forgotPwdHandler}
                      >
                        Send Reset Link
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default LoginRegisterPopup;
