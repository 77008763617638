import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const AboutUs = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: `/about-us`,
    title : 'About Us'
  })
  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <>
      <NavRoute title="ABOUT US" policy navRouter link="about-us" />
      <div className="TC container">
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          About Us
        </span>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          What we do
        </span>
        <span>-Delivering your dreams at your doorstep</span>
        <span>
          -Designing your own interiors is no longer just a dream,
          come pick your ply
        </span>
        <span>
          -Furnish and design your home from scratch, make it about you
        </span>
        <span>-All your home furnishing needs delivered at your door</span>
        <span>-Welcome to the haven of home decor junkies</span>
        <span>
          -Ned hassle free delivery of home appliances and decor material? look
          no more, we are at your service
        </span>
        <span>
          -Conceptualise, design and furnish your home the way you like, with
          products delivered at your doorsteps
        </span>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          About Plypicker
        </span>
        <p>
          Envisioning your interiors, going to the store, finding all the
          desired materials and then spending the whole day clumsily decorating
          your house with your own hands. Sounds very filmy, we know. But, this
          is not just a dream anymore, rather an Indian reality albeit
          with greater ease at finding curated products and hassle free
          delivery. Your home is a true reflection of who you are, be forthright
          about it and make your choices boldly with us. Now you can
          conceptualise, envisage, and furnish your own house, all at one stop.
        </p>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          What is it that makes us your first choice?
        </span>
        <span>
          -Top brands available in all niche products, partners like Hindware,
          Godrej, Faber and Elica
        </span>
        <span>-Only place selling raw material for decor</span>
        <span>
          -Branded and finest quality products and lowest possible prices
        </span>
        <span>-Confused? Hire an expert designer working with us</span>
        <span>
          -Still don’t trust online shopping? You can also visit our store to be
          doubly sure
        </span>
        <span>
          -Building your home is not a joke, we offer you the expert advice of
          our seasoned architects and construction professionals
        </span>
        <span>
          -Get access to the most sound floor and interior plans available in
          the market
        </span>
        <span>-Live product demo from the comfort of your home</span>
        <span>
          -Instant customer support and consultations with expert technical
          advice
        </span>
        <span>-Assured delivery in 48 hours</span>
        <span>-Best experience with curated and unique products</span>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Where are we available?
        </span>
        <p>
          To provide you with the best possible experience and focus on our
          initial customer base, we are delivering all over India.</p>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Have a look at some of our notable products:
        </span>
        <span>-Tiles and Stones</span>
        <span>-Laminates, Veneers, Plywood</span>
        <span>-Chimneys and Water Heaters</span>
        <span>
          -Pad Locks, Shutter Locks, Furniture Locks, Rod Locks, Main Door
          Locks, Smart Locks, Handle Locks, Round Locks, Mortise, Internal Lock
          Bodies, Lock Handles
        </span>
        <span>
          -Sanitary Accessories, Fittings, Faucets, Showers and Basins
        </span>
        <span>-Switches, Sockets, Dimmers and other Board Accessories</span>
        <span>-Pipes, Fittings and Adhesives</span>
        <br />
        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Browse through our most popular brands:
        </span>
        <p>
          Cera, Hindware, Kutchina, Royal Crown, Johnson, Godrej, Airolam,
          Amulya Laminates, Merino Lam, Delta Laminates, Faber, Elica, Somany,
          Nitco, Kohler, Parryware, Orientbell, Veneers and Century.
        </p>
        <p>We are also available for use on IOS devices and web browser.</p>
        <p>Download now to create your dreams with your own hands!</p>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
