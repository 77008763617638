import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { StoreProvider } from "./store/store";
import { createRoot } from 'react-dom/client';
import { positions,transitions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { HelmetProvider } from 'react-helmet-async';

import { initialState, userReducer } from "./store/reducers/userReducer";
// import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
const options = {
  timeout: 3000,
  offset: '35px',
  position: positions.BOTTOM_CENTER,
  containerStyle: {
    zIndex: 10000000,
  },
  transition: transitions.SCALE
};

root.render(
  <StoreProvider initialState={initialState} reducer={userReducer}>
    <HelmetProvider>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
    </HelmetProvider>
  </StoreProvider>
);
