import axios from "axios";
import React, { useState } from "react";
import Modal from "react-awesome-modal";
import { API_URL } from "../../../../constants";
import { AiOutlineClose } from "react-icons/ai";
import "./visit.css";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { format } from "date-fns";

function AddVisit({ isOpen, setIsOpen }) {
  const [datePicker, setDatePicker] = useState(null)
  const [timePicker, setTimePicker] = useState(null)
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    date: null,
    time: null,
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.post(`${API_URL}/visit-mail`, formData);
    const result = res.data;
    if (result.success) {
      setformData({ name: "", email: "", phoneNumber: "", date: null,
      time: null});
      setDatePicker(null);
      setTimePicker(null);
      setIsOpen(false);
    } else {
      window.alert(result.msg);
    }
  };
  return (
    <Modal
      visible={isOpen}
      width="600"
      height="560"
      effect="fadeInUp"
      className="visitModal"
      style={{ zIndex: "100" }}
      onClickAway={() => {
        setIsOpen(false);
        setformData({
          name: "",
          email: "",
          phoneNumber: "",
          date: "",
          time: "",
        });
        setDatePicker(null);
        setTimePicker(null);
      }}
    >
      <button
        type="button"
        className="close closeChatBotButton"
        aria-label="Close"
        onClick={() => setIsOpen(false)}
      >
        <span aria-hidden="true">
          <AiOutlineClose />
        </span>
      </button>
      <div className="login-box">
        <h2>Details</h2>
        <form onSubmit={handleSubmit}>
          <div className="user-box">
            <input
              type="text"
              name=""
              value={formData.name}
              onChange={(e) => {
                setformData({ ...formData, name: e.currentTarget.value });
              }}
              required
            />
            <label>Name</label>
          </div>
          <div className="user-box">
            <input
              type="email"
              name=""
              value={formData.email}
              onChange={(e) => {
                setformData({ ...formData, email: e.currentTarget.value });
              }}
              required
            />
            <label>Email</label>
          </div>
          <div className="user-box">
            <input
              type="text"
              name=""
              value={formData.phoneNumber}
              onChange={(e) => {
                setformData({
                  ...formData,
                  phoneNumber: e.currentTarget.value,
                });
              }}
              required
            />
            <label>Phone Number</label>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="" style={{ width: "45%" }}>
              <label>Date</label>
              <br />
              {/* <input type="date"  name="" value={formData.date} onChange={(e)=>{setformData({...formData,date:e.currentTarget.value})}} required/> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  // PaperProps={{
                  //   sx: {
                  //     "& .MuiPickersDay-root": {
                  //       "&.Mui-selected": {
                  //         backgroundColor: "#00BB07",
                  //       },
                  //     },
                  //   },
                  // }}
                  value={datePicker}
                  onChange={(e) => {
                    setDatePicker(e.$d);
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params}/>
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="" style={{ width: "45%" }}>
              <label>Time</label>
              <br />
              {/* <input type="time"  name="" value={formData.time} onChange={(e)=>{setformData({...formData,time:e.currentTarget.value})}}placeholder='none' required/> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  value={timePicker}
                  onChange={(e) => {
                    setTimePicker(e.$d);
                  }}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div className="d-flex mb-3">
            <input type="checkbox" id="terms" required />
            <label className="container">
              I have read and agree to the terms & conditions
            </label>
          </div>

          <button type="submit" className="addUpdateButton" onClick={()=>setformData({ ...formData, date: format(datePicker,"P"),time :format(timePicker,"p")})}>
            Submit
          </button>
        </form>
      </div>
    </Modal>
  );
}

export default AddVisit;
