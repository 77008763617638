import { Avatar } from "@mui/material";
import { useState } from "react";
import Modal from 'react-awesome-modal';
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { logout } from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import FemaleVector from "../../../../global_component/SideBar/images/FemaleVector.png";
import MaleVector from "../../../../global_component/SideBar/images/MaleVector.png";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function Account() {
    const api = useAxios();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [state, dispatch] = useStore();


    ReactGA.send({
      hitType: 'pageview',
      page: '/account',
      title : 'Account'
    })

    const ActionsFunction = ({type,desc,dest}) => {
        return(
          <div className="Actions_profile" onClick={()=>navigate(`/${dest}`)}>
                <div className="Actions_type">
                    <span className="Actions_type_one">{type}</span>
                    <span className="Actions_type_two">{desc}</span>
                </div>
                <IoIosArrowForward/>
            </div>
        )
      }

      const logoutHandler = async () => {
        api.post(API_URL + "/auth/logout", {}, { withCredentials: true, })
          .then(response => {
            dispatch(logout())
            navigate("/")
          })
          .catch(error => {
            alert.error(error.response.data.error)
          });
      }

      const deleteAccountHandler = async (e) => {
        // e.preventDefault();
        api.get(API_URL + "/deleteAccount")
          .then(response => {
            alert.success(response.data?.msg)
    
          })
          .catch(error => {
            alert.error(error.response.data.error)
          });
        setIsOpen(false)
        navigate('/')
      }

  return (
    <div className="MobileView">
        <span className="headingView">
          <BsArrowLeft onClick={()=>navigate(-1)} style={{fontSize: "30px"}}/> Account
        </span>
        <span style={{ padding: "4px", backgroundColor: "#E5E5E5" }}></span>
        <div
          className="profilePicDiv"
          style={{ backgroundColor: "#D7D9D8", borderBottom: "none" }}
        >
          <Avatar
            src={
              state?.user?.gender.toLowerCase() === "male"
                ? MaleVector
                : FemaleVector
            }
            sx={{ width: 150, height: 150 }}
          />
        </div>
        <div className="actionsMobileItems">
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Profile" desc="Change profile details and passwords" dest="profile"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Manage Address" desc="Check status" dest="addresses"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Wishlist" desc="For future purchases" dest="wishlist"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Cart" desc="All your cart items" dest="cart"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Notification" desc="Answer to all your queries" dest="notif"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <ActionsFunction type="Your Orders" desc="Manage notification and app settings"  dest="orders"/>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
          <div className="Actions_profile">
                <div className="Actions_type" onClick={logoutHandler}>
                    <span className="Actions_type_one">Logout</span>
                    <span className="Actions_type_two">Change and save address</span>
                </div>
                <IoIosArrowForward/>
          </div>
          <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
        </div>
        <span style={{ padding: "10px", backgroundColor: "#E5E5E5" }}></span>
        <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
        <div className="Actions_profile" style={{padding:"5px"}} onClick={() =>{ navigate("/faq") }}>
            <div className="Actions_type">
                <span className="Actions_type_one">FAQs</span>
            </div>
            <IoIosArrowForward/>
        </div>
        <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
        <div className="Actions_profile" style={{padding:"5px"}} onClick={() => { navigate("/privacy-policy") }}>
            <div className="Actions_type">
                <span className="Actions_type_one">Privacy Policy</span>
            </div>
            <IoIosArrowForward/>
        </div>
        <hr style={{margin:'10px',height:'1px',color:"#C4C4C4"}}/>
        <div className="Actions_profile" style={{padding:"5px"}} onClick={() => { setIsOpen(true) }}>
            <div className="Actions_type">
                <span className="Actions_type_one">Delete Account</span>
            </div>
            <IoIosArrowForward/>
        </div>
        <br/>
        <br/>
        <Modal visible={isOpen}
          width="475"
          height="200"
          effect="fadeInUp"
          onClickAway={() => { setIsOpen(false); }}>
          <div class="modal-content px-3 py-3 password_change_popup">
            <div class="modal-header p-0 headingADeleteAddress">
              <h5 class="modal-title px-2 pt-3" id="deleteItemFromCart" style={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Poppins' }} >Are u sure to delete this account?</h5></div>
            <div class="login-box">
              <div class="d-flex flex-row align-items-center w-100" style={{justifyContent:"space-evenly"}}>
                <button class="CloseaddUpdateButton ms-2" type="button" onClick={() => { setIsOpen(false) }}>
                  No
                </button>
                <button class="addUpdateButton" type="button" onClick={deleteAccountHandler}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
  )
}

export default Account