import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function PrivacyPolicy() {

  ReactGA.send({
    hitType: 'pageview',
    page: `/privacy-policy`,
    title : 'Privacy Policy'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <NavRoute title="PRIVACY POLICY" policy navRouter link="privacy-policy" />
      <div className="TC container">
        <span
          className="subheading"
          style={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Privacy Policy
        </span>
        <br />
        <p>
          This Privacy Policy govern mobile application/website which has been operated under the Application name/Brand name – “PlyPicker” (“Platform”) owned and operated by 	PLYPICKER PRIVATE LIMITED having CIN: U51909PN2022PTC213841 (Company).
          User shall mean any person/individual, who visits, uses, deals with and/or transacts through the website/app/referrals/API or avails other Services (defined below) provided by the Platform.
          By accessing, downloading, using, or clicking “I agree” to any of the services made available by Company through PlyPicker, you agree that you have read, understood and accepted all of the terms and conditions contained in this Terms of Use (the “Terms/Terms of Use”), our Privacy Policy available at https://plypicker.com
        </p>
        <b>Collectively it is called as parties to this Agreement (i.e., Company, platform & user) AND Party refers to either Company, Platform OR User, as the context may require.</b>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          DISCLAIMER:
        </span>
        <br />
        <p>
          This policy describes our policies and procedures for collection, use, storage, disclosure and protection of any personal information, including, but not limited to, business or personal information provided by you (“you/r”) while using the platform.
        </p>
        <p>
          This policy constitutes a legal agreement between you, as the user of the platform, and us, as the owner of the platform. By voluntarily providing us or allowing us to access the personal information, you are consenting to our use of it in accordance with this policy. By mere visiting/accessing the platform, you expressly consent to Company use and disclosure of your personal information in accordance with this privacy policy. This policy does not apply to third-party platforms that are connected via links to the platform. If you do not agree with the terms and conditions of this policy, please do not proceed further to use/access this platform. Also, in the absence of an acceptance of the policy by you, we will not be able to provide the services to you.
        </p>
        <br />
        <p>
          Your use of the platform will be governed by this policy in addition to the TERMS OF USE as applicable to you.
        </p>
        <p>
          This policy has been drafted in accordance with the Information Technology Act, 2000 and the data protection Rules and Regulations of India.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          1. Information Collected:
        </span>
        <br />
        <p>
          We will use the information provided by you only in accordance with the purposes described in the Policy.
        </p>
        <p>
          We collect your Personal Information or Non-Personal Information (as hereinafter defined) when use the Platform and at the time of your registration with the Platform, i.e., create an account on the Platform to avail our services. Further, we collect information from you during the performance of Services for you. we collect information with respect to your name, email, address, IP Address and pin code, your location, device ID, Device location. We collect all of the foregoing information in order to provide you the services.
        </p>
        <p>
          Except as provided herein or as clearly disclosed to you at the time of collection, we do not solicit any Sensitive Information about You. However, if you share such information with us voluntarily, we will not be liable for any actions, claims, costs, expenses or other liabilities that may arise as a consequence of any unauthorized use or misuse of such information.
        </p>
        <p>
          During your use of the Platform, we may collect and process such information from you, including but not limited to the below mentioned:
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          a. Personal Information:
        </span>
        <br />
        <p>
          “Personal Information” means and includes:
        </p>
        <p>
          i. Information that you provide to us by filling in forms on the Platform. This may include contact information such as name, email address, mailing address, phone number.
        </p>
        <p>
          ii. Information that You voluntarily provide when you write directly to us (including by e-mail and surveys)
        </p>
        <p>
          iii. Know Your Customer (“KYC”) Documents and data that you provide to us.
        </p>
        <p>
          iv. Information that You specifically provide access to while accepting the Terms and Conditions of use of the Platform.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          b. Non- Personal Information:
        </span>
        <br />
        <p>
          “Non-Personal Information” means and includes any information that does not reveal your specific identity, such as, browser information, information collected through Cookies (as defined below), pixel tags and other technologies, demographic information etc. Our Platform gathers some information automatically when you visit the Platform and stores it in log files. We may collect certain information about your computer or other electronic device through which you have accessed the Platform to facilitate, evaluate and verify your use of the Platform.
        </p>
        <p>
          “Cookies” are small files that reside on your computer's hard drive and generally contain an anonymous unique identifier and are accessible only by the Platform that placed them there and not any other sites. You may refuse to accept Cookies by activating the setting on Your browser which allows You to refuse the setting of Cookies. However, if You select this setting, you may be unable to access certain parts of the Platform. Unless You have adjusted your browser setting so that it will refuse Cookies, our system may issue Cookies when you log on to the Platform. The use of Cookies by our partners, affiliates, advertisers or service providers is not covered by the Policy.
        </p>
        <p>
          Herein Personal Information & Non-Personal Information shall be collectively called it as “Information”.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          2. Use of the Information
        </span>
        <span>We may use and share the information provided by or collected from you in the following ways, viz:</span>
        <span>
          a. provide and improve the services offered to you through the Platform,
        </span>
        <span>
          b. undertake KYC checks and verifications,
        </span>
        <span>
          c. establish and verify Your identity,
        </span>
        <span>
          d. conduct research and analysis to detect, prevent, mitigate and investigate fraudulent or illegal activities,
        </span>
        <span>
          e. analyse how the Platform is used, diagnose service or technical problems, maintain security,

        </span>
        <span>
          f. help you efficiently use and access the Platform,


        </span>
        <span>
          g. send you notifications, updated and newsletters,


        </span>
        <span>
          h. monitor aggregate metrics such as total number of viewers, visitors, traffic, and demographic patterns,

        </span>
        <span>
          i. to enable us to comply with any legal and regulatory obligations or requisition requests,


        </span>
        <span>
          j. to enforce our legal rights against you,


        </span>
        <span>
          k. complete know-your customer (KYC) requirements and on-boarding.


        </span>
        <p>
          We will use and retain your information for such periods as necessary to comply with any applicable laws, our legal obligations, resolve disputes, record keeping and enforce this agreement.

        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          3. Sharing and Disclosure of your Information

        </span>
        <br />
        <p>
          We may disclose Your information to third parties for the purposes mentioned under clause 2 herein above, i.e., in order to perform KYC checks, provide you with customer support facilities, to comply with our legal obligations including prevention of money laundering, to enforce our Terms of Use, to facilitate our marketing and advertising activities, to conduct analysis, to provide you services, to facilitate services for you, to send notifications, reminders or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to the Platform and to avail services from third parties for data analysis and market research.

        </p>
        <p>In the event the Company has merged with or acquired by another Company or in case of re-organization or re-structuring of business, we and our affiliates will share Your Personal Information, wholly or in part, with another business entity.
        </p>
        <p>We will share your information with third parties only in such manner as described below:
        </p>
        <p>a. We may share your information with third parties including third party service providers/agents/partners, etc. and in such an event, the third parties’ use of your information will be bound, among other things, with obligation to protect the confidentiality of your information. We may store information in locations outside the direct control of the Company (for instance, on servers or databases co-located with hosting providers) and your use of the Platform shall constitute consent for the same.
        </p>
        <p>b. We may disclose your information, to any member of our related or group companies including but not limited to, our subsidiaries, our Associate Company, as the case may be, and your use of the Platform shall constitute consent for the same.
        </p>
        <p>c. We may disclose your information if we are under a duty to do so in order to comply with any legal obligation or an order from the government and/or a statutory authority, or in order to enforce or apply our Terms of Use or assign such information in the course of corporate divestitures, mergers, or to protect the rights, property, or safety of us, our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          4. Storage, Security and Disclosure of Information


        </span>
        <br />
        <p>We use commercially reasonable safeguards to preserve the integrity and security of your information and data against loss, theft, unauthorised access, disclosure, reproduction, use or amendment. All information you provide to us is stored on our secure servers within India. We may also store/move Your data on our Servers outside India, if required in our discretion. You hereby consent to your data being stored outside India as well.
</p>
        <p>By submitting your information on our Platform, you agree to the handling of your information by the Company in a manner as stated under this Policy and the AML Policy.
</p>
        <p>We assume no liability for any disclosure of information due to errors in transmission, unauthorised third-party access or other acts of third parties, or acts or omissions beyond our reasonable control and you agree that you will not hold us responsible for any breach of security unless such breach has been caused as a direct result of our gross negligence or wilful default.
</p>
        <p>In using the Platform, you accept the inherent security implications of data transmission over the internet and the world wide web cannot always be guaranteed as completely secure. Therefore, your use of the Platform will be at your own risk.
</p>
<br />
<span className="subheading" style={{ fontWeight: "bold" }}>
          5. Use of Information

        </span>
        <br />
        <p>By the use of the Platform, you will be able to see the service offered/facilitated by us. You may have interest or intention of availing such services or a general query with respect to the services available on the Platform and through the Platform. Accordingly, you may contact us. By contacting us in anyway and by providing us your phone number and contact details and other details, you thereby give us the undeniable right to:
</p>
        <p>1. Contact you through various mediums, including but not limited to phone calls, emails, etc. or make a phone call to you to discuss your query or request,
</p>
        <p>2. Contact you through phone or message notwithstanding the fact that you may have registered under the Telecom Regulatory Authority of India regulations as a fully blocked or a partially blocked customer. It is further clarified that We shall only make solicited phone calls or messages,
</p>
        <p>3. Share the contact details provided by you with the service provider or agent or representative available on or through the Platform who may be in a position to sufficiently answer or respond to your query, including but not limited to third party service providers.
</p>
<p>We understand that Your contact details are important to you, and we shall bind the service providers/agents/affiliates that we share your contact details with, with the same level of protection as required under the applicable laws.
</p>
<span className="subheading" style={{ fontWeight: "bold" }}>
          6. Non- Disclosure of Information


        </span>
        <br />
        <p>You may choose not to provide identifiable information through the Platform, in which case you may not be allowed to access certain features/parts of the Platform. If you are a registered user, you may update, edit or correct your account information and email preferences at any time by logging in to your account. If you believe that any of your information held by us is inaccurate, you may write to our Grievance Officer (“GO”) as detailed under clause 10. We will respond to Your request within a period of thirty (30) days. It is your responsibility to ensure that any information you provide us remains accurate and updated.</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          7. Links to Third Party Sites


        </span>
        <br />
        <p>You may choose not to provide identifiable information through the Platform, in which case you may not be allowed to access certain features/parts of the Platform. If you are a registered user, you may update, edit or correct your account information and email preferences at any time by logging in to your account. If you believe that any of your information held by us is inaccurate, you may write to our Grievance Officer (“GO”) as detailed under clause 10. We will respond to Your request within a period of thirty (30) days. It is your responsibility to ensure that any information you provide us remains accurate and updated.
</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          8. Data Protection And Information Sharing 

        </span>
        <br />
        <p>For the purposes of this Agreement, “Personal Data”, "data controller", "data subject", "supervisory authority", computer database, 'information, 'electronic form, 'originator, 'addressee" personal data breach" and "process" shall have the meaning given by the Information Technology Act 2000.
</p>
        <p>You and we shall each comply with our respective obligations under the applicable Information Technology Act 2000 and other relevant law in India and neither of us will knowingly perform our obligations under this Agreement in such a way as to cause the other to breach any of its obligations under the applicable Law. 
</p>
        <p>You agree that you will: a) ensure appropriate technical and organizational security measures are in place to protect personal data under your control; b) notify Partner 1 without undue delay in the event that you receive a request, complaint or other communication from a client/customer/third party.
</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          9. Restrictions and Liabilities

        </span>
        <br />
        <p>a. We are liable only for any recommendations made by our authorised person through the Platform.
</p>
        <p>b. We are not liable for any recommendations posted by any third party.
</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          10. Severability and Exclusion


        </span>
        <br />
        <p>We have taken every effort to ensure that this Policy adheres with the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the information collected by the Company through the Platform. This Policy shall be inapplicable to any unsolicited information you provide us through this Platform or through any other means or through public channels. This includes, but is not limited to, information posted in any public areas of the Platform. All unsolicited information shall be deemed to be non-confidential, and the LLP shall be free to use and/or disclose such unsolicited information without any limitations.
</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          11. Changes to the Policy


        </span>
        <br />
        <p>We may update this Policy from time to time with or without any notice to you. We encourage you to periodically review the Policy for the latest information on our privacy practices.</p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          12. Contact
        </span>
        <br />
        <p>Please address Your grievances, feedback or questions, without limitation, with respect to the collection, processing, usage, disclosure, security of Your information in writing to our grievance cell at info@plypicker.com</p>
        <br />   
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
