import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import "./TC.css";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ShippingDelivery() {
    ReactGA.send({
        hitType: 'pageview',
        page: `/shipping-and-delivery-policy`,
        title : 'Shipping And Delivery Policy'
      })
    useEffect(() => {
        window.scroll(0, 0);
    }, []);
    return (
        <>
            <NavRoute
                title="SHIPPING AND DELIVERY POLICY"
                policy
                navRouter
                link="shipping-and-delivery-policy"
            />
            <div className="TC container">
                <p style={{ fontWeight: "bold" }}>
                    Shipping and Delivery Policy
                </p>
                <p>
                    This page and all of its contents can be collectively referred to as "Shipping and Delivery Policy" for PlyPicker. All the key terms and definitions can be understood as per the Terms and Conditions page. Any undefined words shall be understood as per ordinary prudence and as per what provided in the PlyPicker.
                </p>
                <p>
                1. PlyPicker provides interior designing Products and Services to its customers (You). 
                </p>
                <p>
                2. The delivery date and time for the Products and the Services will vary depending on the destination, shipping method, type of delivery vehicle opted by You, and the availability of the Products and the Services.
                </p>
                <p>
                3. We will try to deliver Your order as soon as possible or normally within 48 hours (including processing time which normally ranges between 1 hour and 40 hours), but We cannot guarantee a specific delivery date or time. Your order may take more than 48 hours of time to be delivered due to any reason.
                </p>
                <p>
                4. If a Product or Service is temporarily unavailable, We will notify You of the expected shipping or delivery time and give You the option to wait or to cancel Your order.
                </p>
                <p>
                5. Shipping costs will vary depending on the type of Product or the Service, destination, shipping method, type of delivery vehicle opted by You and the size and weight of the Product. These costs will be displayed during the checkout process. All the costs and prices will be at Our sole discretion and We are free to make any changes, at any time, to the price of any Product or any Service or in the delivery or the shipping charges.
                </p>
                <p>
                6. The cost of any Product or Service displayed on the PlyPicker is just an invitation to offer made to You, thus making it non-binding on Us to provide You with such Products or Services at the displayed price, even if available. So, We may revise the prices anytime and as per what We decide; and We may also refuse to provide You such Product or Service due to any reason.
                </p>
                <p>
                7. Though We ourselves be providing You the delivery of most of the Products and the Services, We may use third-party shipping carriers. We may also provide You with tracking information of Your order if You wish to opt for same.
                </p>
                <p>
                8. If You receive a Product that is damaged or defective, then We may provide a replacement or a full or partial refund. All types of returns, replacements, re-orders and cancellations will be available only at Our discretion and on the grounds We think as just and proper and in Our interest. For example, You will not be able to return a Product and claim refund (in full or in part) if You have opened a sealed package containing a single-use or one-time use Product or any other such Product, thus making it non-saleable further.
                </p>
                <p>
                9. In the event of a lost or damaged shipment or any other unwanted situation, We will try to resolve the issue and to get Your delivery to You as soon as possible. However, if the problem persists or becomes impracticable due to any situation, and thus it becomes unfeasible for Us to deliver You such Product(s) or the Service(s), You will be returned Your pre-paid money (if any) and the order will be cancelled. Alternatively, if feasible for Us and if available, We will try making a re-delivery of another similar Product or the Service.
                </p>
                <p>
                10. If You have any questions about Our shipping and delivery policy, please contact Us at info@plypicker.com. We are here to help and will be happy to answer any questions You may have.
                </p>
            </div>
            <Footer/>
        </>
    )
}

export default ShippingDelivery;