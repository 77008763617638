import React, { useEffect, useState } from "react";
import { useStore } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../../utils/useAxios";

const ServicesCartPopup = (prop) => {
  const [cartLength, setCartLength] = useState(0);
  const [state, dispatch] = useStore();
  const [isCartNotifPopupVisible, setIsCartNotifPopupVisible] = useState(false);
  const navigate = useNavigate();
  const api = useAxios();
  useEffect(() => {
    async function fetchData() {
      if (state.authenticated) {
        try {
          const cartRes = await api.get("/servicescart", {
            headers: {
              cityId: state?.selectedCity,
            },
          });
          if (!cartRes.data.error) {
            setCartLength(cartRes?.data?.cartItems.length);
            setIsCartNotifPopupVisible(cartRes?.data?.cartItems.length > 0);
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setCartLength(0);
        setIsCartNotifPopupVisible(false);
      }
    }
    state.selectedCity && fetchData();
  }, [prop.props,state.selectedCity]);

  return (
    <div
      className={`serviceCartNotifPopup ${
        isCartNotifPopupVisible ? "serviceCartNotifPopupVisible shadow" : ""
      } d-flex align-items-center justify-content-between px-4 py-1`}
    >
      <div className="w-100 d-flex align-items-center justify-content-between">
        <p className="m-0 p-0">
          {cartLength} {cartLength === 1 ? "service" : "services"} in your cart
        </p>
        <button
          className="cartside_total_checkout_services m-0"
          onClick={() => navigate("/services/cart")}
          style={{ whiteSpace: "nowrap" }}
        >
          Checkout
        </button>
      </div>
    </div>
  
    );
};

export default ServicesCartPopup;
