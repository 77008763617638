import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ReturnPolicy() {

  ReactGA.send({
    hitType: 'pageview',
    page: `cancellation-refund-policy`,
    title : 'Services'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <>
      <NavRoute title="CANCELLATION/REFUND POLICY" policy navRouter link="cancellation-refund-policy" />
      <div className="TC container">
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Cancellation/Refund Policy</span>
        <br />
        <p>
          Last updated: January 05, 2023
        </p>
        <p>
          Thank you for shopping at the Plypicker website and Plypicker app.
        </p>
        <p>
          If for any reason, You are not completely satisfied with a purchase We invite You to review our
          policy on refunds and returns. The following terms are applicable for any products that You
          purchase with Us.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Interpretation</span>
        <p>
          The words of which the initial letter is capitalized have meanings defined under the following
          conditions. The following definitions shall have the same meaning regardless of whether they
          appear in the singular or in the plural.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Definitions</span>
        <p>
          For the purposes of this Return and Refund Policy:
        </p>
        <p>
          Application means the software program provided by the Company downloaded by You on any
          electronic device, named Plypicker
        </p>
        <p>
          Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
          Plypicker Pvt Ltd, 251/4 Vrindavan Nagar Sus Pune, Maharashtra 411021
        </p>
        <p>
          Goods refer to the items offered for sale on the Service.
        </p>
        <p>
          Orders mean a request by You to purchase Goods from Us.
        </p>
        <p>
          Service refers to the Application or the Website or both.
        </p>
        <p>
          Website refers to Plypicker, accessible from http://plypicker.com
        </p>
        <p>
          You mean the individual accessing or using the Service, or the company, or other legal entity on
          behalf of which such individual is accessing or using the Service, as applicable.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Your Order Cancellation Rights</span>
        <p>
          All returns must be postmarked within 24 hours of the purchase date. All returned items must
          be in new and unused condition, with all original tags and labels attached if any
        </p>
        <p>
          The deadline for canceling an Order is before it has been shipped. In order to exercise
        </p>
        <p>
          Your right of cancellation, You must inform Us of your decision by means of a clear statement.
          You can inform us of your decision by:
        </p>
        <p>
          By email: info@plypicker.com
        </p>
        <p>
          By Phone - 18002088886
        </p>
        <p>
          We will reimburse You no later than 3-5 Business days from the day on which We receive the
          returned Goods. We will use the same means of payment as You used for the Order, and You will
          not incur any fees for such reimbursement.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Conditions for Returns</span>
        <p>
          EXCEPTIONS
        </p>
        <p>
          Please contact us at the contact details below for defective or damaged products to arrange a
          refund or exchange.
        </p>
        <p>
          ● Once you provide the one-time password to the delivery agent you will not be able to return
          the item for damage, item not matching the website description, or color issues. After this only
          manufacturing defects would be entertained
        </p>
        <p>
          ● Before giving the one-time password received on your email/phone number, all your ordered
          items would be unboxed and checked for damage, website description match, and customer
          feedback on whether they still love the product.
        </p>
        <p>
          The supply of Goods that are, after delivery, according to their nature, inseparably mixed with
          other items will not be returned
        </p>
        <p>
          We reserve the right to refuse returns of any merchandise that does not meet the above return
          conditions at our sole discretion.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>RETURN PROCESS</span>
        <p>
          To return an item, please email customer service at info@plypicker.com to
          obtain a Return Merchandise Authorisation (RMA) number. After receiving an RMA number,
          place the item securely in its original packaging our agent will come for the pickup
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>REFUNDS</span>
        <p>
          After receiving your return and inspecting the condition of your item, we will process your return
          or exchange. Please allow at least one (1) day from the receipt of your item to process your
          return or exchange. We will notify you by email when your return has been processed.
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}>Contact Us</span>
        <p>
          If you have any questions concerning our return policy, please contact us at:
        </p>
        <p>
            18002088886
        </p>
        <p>
          info@plypicker.com
        </p>
        {/* <br /> */}
        <span className="subheading" style={{ fontWeight: "bold", textTransform: "uppercase" }}></span>
        <p>

        </p>
      </div>
      <Footer />
    </>
  );
}

export default ReturnPolicy;
