import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL,REACT_APP_GA_MEASUREMENT_ID } from '../../../../constants';
import Footer from '../../../global_component/Footer/Footer';
import ImageCarousel from '../../../global_component/ImageCarousel/ImageCarousel';
import LowerNavigation from '../../../global_component/LowerNavigation/LowerNavigation';
import BookVisit from './BookVisit';
import BGIMG from './bgimg.png';
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function TopHero({heroImage , setIsOpen}) {
    return (
      <>
        <div className="m-auto col-xxl-10 px-4 py-2" style={{background:`url(${BGIMG})`,backgroundPosition:'bottom',backgroundSize:'100% 52%',backgroundRepeat:'no-repeat',marginBottom:'50px'}}>
          <div className="row flex-lg-row-reverse align-items-center g-5 pt-5">
            <div className="col-10 col-sm-8 col-lg-6">
              <img
                src={heroImage}
                className="d-block mx-lg-auto img-fluid"
                // width="700"
                // height="500"
                loading="lazy"
              />
            </div>
            <div className="col-lg-6 ps-4">
              <h1 className="lh-2 mb-4 visitHeading text-trilong">
              CONFUSED ABOUT WHETHER TO DIRECTLY PURCHASE OR<br/> <span className='text-trilong' style={{color:'#fff'}}>VISIT SHOWROOM?</span>
              </h1>
              <h4 style={{fontWeight: 400}} className="text-light">Don’t worry we have a solution.</h4>
              <p className="lead"style={{color:'#C4C4C4'}}>
              
We'll discover local showrooms and organise an appointment at your convenience to see and view the items before purchasing them on our platform.
              </p>
              <div class="d-grid gap-2 d-md-flex justify-content-md-start visitButton">
          <button type="button" class="addUpdateButton" onClick={()=>{setIsOpen(true)}}>Schedule Your Visit</button>
        </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  function FeaturesCard({heading,para}) {
    return (
      <>
        <div className="feature col p-5">
            <h5 className="text-center "style={{marginBottom:'2.5rem'}}>{heading}</h5>
          <p className="text-center " style={{color:'#878787'}}>
            {para}
          </p>
        </div>
      </>
    );
  }
  
  function Features({cardData}) {
    return (
      <>
      <div className='d-flex flex-column justify-content-center align-items-center'><div className='shopbyprice_image_row_slider container' style={{marginBottom:'-50px',marginTop:'100px'}}>
        <ImageCarousel images={cardData?cardData:[]} />
      </div>
      <div className="m-5 mt-2 container visitContainer">
          <FeaturesCard heading="SCHEDULE A VISIT" para="We'll choose a showroom nearby based on the day and time you choose for the visit." />
          <div style={{borderLeft:'2px solid #707070',height:'200px' ,width:'3px' }} className="verti mx-3"></div>
          <FeaturesCard heading="VISIT AND SEE" para="After you reach, someone will assist you with the purchase and will explore for alternatives as per your reqirements." />
          <div style={{borderLeft:'2px solid #707070',height:'200px',width:'3px'}} className="verti mx-3"></div>
          <FeaturesCard heading="LIKE IT. BUY IT." para="After making a purchase decision, you may place an order on our website." />
        </div></div>
      
            {/* <h1 className='display-1 text-center mt-4'>HOW IT WORKS?</h1> */}
        
      </>
    );
  }
  

function VisitShowroom() {
  const [VisitModal, setVisitModal] = useState(false);
  const [heroImage , setHeroImage] = useState("")
  const [cardData , setCardData] = useState()

  ReactGA.send({
    hitType: 'pageview',
    page: `/visit-showroom`,
    title : 'Visit Showroom'
  })
  useEffect(() => {
    const apiCall = async() =>{
        const res = await axios.get(`${API_URL}/home`)
        let result =await res.data;
        setHeroImage(result.filter(e=>e.type === 'creative_idea_visit_showroom' && e.title === 'hero')[0].image)
        setCardData(result.filter(e=>e.type === 'creative_idea_visit_showroom' && e.title === 'slider'))
        
    }
    apiCall();
    window.scroll(0,0);
  }, []);
  return (<>
    <div className='interiorTopMargin' style={{marginTop:'100px',marginBottom:'100px'}}>
        <TopHero heroImage={heroImage} setIsOpen={setVisitModal}/>
        <Features cardData={cardData} />
    </div>
        <LowerNavigation />
        <Footer/>
        <BookVisit isOpen={VisitModal} setIsOpen={setVisitModal} />
    </>
  )
}

export default VisitShowroom