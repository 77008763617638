import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../../constants";
import { useStore } from "../../../../store/store";
import useAxios from "../../../../utils/useAxios";
import StarRating from "../../IndividualProducts/Products_Detail/StarRating/StarRating";
import { ReactComponent as Heart } from "../../IndividualProducts/Products_Detail/img/heart.svg";
function RelatedServices({ p_id, s_sc }) {
    const [related, setRelated] = useState(false);
    const [relProd, setRelProd] = useState([]);
    const [loading, setLoading] = useState(true);
    const noOfProductsPerLoad = 10;
    const api = useAxios();
    const navigate = useNavigate();
    const alert = useAlert();
    const [state, dispatch] = useStore();
  
    const fetchMoreRelatedProducts = useCallback(async () => {
      const res = await axios.get(
        `${API_URL}/services/related-service/${p_id}?limit=${noOfProductsPerLoad + relProd.length
        }&prods=${relProd.length}&serviceSubcategory=${s_sc}`
      );
      if (!res.data.error) {
        setRelProd([...relProd, ...res.data]);
      } else {
      }
      return 0;
    });
    
    useEffect(() => {
      const fetch = async () => {
        const res = await axios.get(
          `${API_URL}/services/related-service/${p_id}?limit=${noOfProductsPerLoad}&serviceSubcategory=${s_sc}`
        );
        if (!res.data.error) {
          setRelProd(res.data);
        } else {
        }
        setLoading(false);
      };
      fetch();
    }, [p_id,s_sc]);

    return loading ? null : (
      <div>
        <div className="RelatedProductsContainer mt-5">
          <div className="row w-100 m-0 text-center">
            <div className="related_products_heading">Related Services</div>
          </div>
          <div
            className="d-flex flex-row w-100 flex-wrap justify-content-start mb-5"
            style={{ marginTop: "90px",rowGap:"25px" }}
          >
                            {relProd.map((service) => (
                                              
            <div className="col-lg-2 col-md-4 col-12 col-sm-6">
    <Link to={`/hire-artisans/service/${service._id}`} >
        <div
            className="cardServiceCat box d-flex flex-column mx-2"
            style={{
                fontFamily: "Poppins",
                fontSize: "22px",
                fontWeight: 400,
                textTransform: "uppercase",
                color: "black",
                textDecoration: "underline"
            }}
            onClick={() => {
                window.scroll(0, 0);
            }}
        >
            <img src={service?.attrs?.imgs[0]} alt="Service" />
            <a style={{textDecoration:"none"}}>
                {service?.serviceName}
            </a>
        </div>
    </Link>
</div>

            
            ))}
          </div>
          <div
                className="related_show_more_button"
                onClick={fetchMoreRelatedProducts}
              >
                Show more
              </div>
        </div>
      </div>
    );
}

export default RelatedServices