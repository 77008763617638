import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import FModal from "react-awesome-modal";
import Modal from "react-bootstrap/Modal";
import { BsArrowLeft, BsAsterisk } from "react-icons/bs";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import {
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_GA_MEASUREMENT_ID,
} from "../../../../../constants";
import {
  addAddress,
  removeFromCartAction,
  setCartData,
  setIsLaborRequiredState,
  setSelectedAdd,
  setSelectedCoupon,
  setSelectedVehicleAction,
  updateAddress,
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import { ReactComponent as NewSearchIcon } from "../../../../global_component/Navbar/NavbarUpper/NewSearchIcon.svg";
import ProductCounter from "../../../../global_component/ProductCounter/ProductCounter";
import CartNavbar from "../../CartNavbar/CartNavbar";
import "../sidebar.css";
import "./Cart.css";
import bin from "./bin.png";
import { ReactComponent as FourWheelerDelivered } from "./img/FourWheelerDelivered.svg";
import { ReactComponent as PickUpTruckDelivered } from "./img/PickUpTruckDelivered.svg";
import { ReactComponent as ThreeWheelerDelivered } from "./img/ThreeWheelerDelivered.svg";
import { ReactComponent as TwoWheelerDelivered } from "./img/TwoWheelerDelivered.svg";
import Auto from "./img/auto.PNG";
import Bike from "./img/bike.PNG";
import Pickup from "./img/pickup.PNG";
import Truck from "./img/truck.PNG";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
};

function CurrentOrder() {
  const alert = useAlert();
  const [state, dispatch] = useStore();
  const [cart, setCart] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [backendMrp, setBackendMrp] = useState(0);
  const [backendDiscountedPrice, setBackendDiscountedPrice] = useState(0);
  const [backendDiscount, setBackendDiscount] = useState(
    backendMrp - backendDiscountedPrice
  );
  const [backendGst, setBackendGst] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(0);
  const [alertModal, setAlertModal] = useState(false);
  const [address, setAddress] = useState({});
  const [coupons, setCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [recommendedCoupons, setRecommendedCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(
    state?.appliedCoupon || null
  );
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [gst, setGst] = useState(null);
  const [total, setTotal] = useState(null);
  const api = useAxios();
  const [loading, setLoading] = useState(true);
  const [addState, setAddState] = useState(false);
  const [deliveryPrices, setDeliveryPrices] = useState([0, 0, 0, 0]);
  const [deliveryPricesWithGst, setDeliveryPricesWithGst] = useState([
    0, 0, 0, 0,
  ]);
  const [numberOfDeliveryVehicles, setNumberOfDeliveryVehicles] = useState([
    1, 1, 1, 1,
  ]);
  const [applicability, setApplicability] = useState(1);

  const [laborPrices, setLaborPrices] = useState([0, 0, 0, 0]);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [isLaborRequired, setIsLaborRequired] = useState(
    state.isLaborRequiredState || true
  );
  const [isOpen, setIsOpen] = useState(false);
  const [loadingUnloadingPrice, setLoadingUnloadingPrice] = useState(0);
  const [cartAddress, setCartAddress] = useState(
    state?.selectedAddress || { ...state?.user?.addresses[0] }
  );
  const [isMapEditable, setIsMapEditable] = useState(false);
  const [addressData, setAddressData] = useState(
    state?.selectedAddress || { ...state?.user?.addresses[0] }
  );
  const [userLocation, setUserLocation] = useState({
    zoom: 14,
    latitude: 18.5204,
    longitude: 73.8567,
    accuracy: null,
  });

  ReactGA.send({
    hitType: "pageview",
    page: "/cart",
    title: "Cart",
  });

  const today = new Date();
  let deliveryDate = new Date(today);
  deliveryDate.setDate(deliveryDate.getDate() + 2);

  async function updateCartData(showShimmer = false, showError = true, addressId = undefined) {
    showShimmer && setLoading(true);
    const res = await api.get("/carts", {
      headers: {
        addressId: addressId || cartAddress?._id,
      },
    });
    if (res.data.error) {
      cantFetchCartItems(res, showError);
      showShimmer && setLoading(false);
    } else {
      setCart(res.data.cartItems || []);
      dispatch(setCartData(res.data.cartItems));
      setBackendMrp(res.data.mrpTotal);
      setBackendDiscountedPrice(res.data.total);
      setBackendDiscount(backendMrp - backendDiscountedPrice);
      setBackendGst(res.data.totalGst);
      setApplicability(res.data.vehicle);
      setLoadingUnloadingPrice(res.data.loadingUnloadingPrice);
      setDeliveryPrices(res.data.deliveryPrices);
      setDeliveryPricesWithGst(res.data.deliveryPricesWithGst);
      setNumberOfDeliveryVehicles(res.data.numberOfDeliveryVehicles);
      setLaborPrices(res.data.priceForLabor);
      setTotalLaborCost(res.data.totalPriceForLabor);
      if (res.data.totalPriceForLabor === 0 && cartAddress.floor !== 0) {
        setIsLaborRequired(false);
        dispatch(setIsLaborRequiredState(isLaborRequired));
      }
      showShimmer && setLoading(false);
    }
  }

  const handlePincodeChange = (e) => {
    ReactGA.event({
      category: "pincode",
      action: `${e}`,
      label: "pincode",
      value: e.target.value,
    });

    let pincode = e.target.value;
    setAddressData((prev) => ({ ...prev, pincode }));
    if (pincode.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data && data[0] && data[0].Status === "Success") {
            const postOfficeData = data[0].PostOffice[0];
            setAddressData((prev) => ({
              ...prev,
              pincode: pincode,
              city: postOfficeData.District,
              state: postOfficeData.State,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCouponCodeChange = (event) => {
    ReactGA.event({
      category: "couponCodeChange",
      action: `${event}`,
      label: "Coupon Code",
      value: event.target.value,
    });

    const query = event.target.value;
    setCouponCode(query);

    if (query.trim().length === 0) {
      setRecommendedCoupons(coupons);
    } else {
      fetchRecommendedCoupons(query);
    }
  };

  const fetchRecommendedCoupons = (query) => {
    ReactGA.event({
      category: "fetchRecommendedCoupons",
      action: `${query} recommended coupons`,
      label: "Fetch Recommended Coupons",
      value: `${query}`,
    });

    api
      .get(`/coupons/recommendedCoupons?type=product&codeName=${query}`)
      .then((response) => {
        const data = response.data.coupons;
        setRecommendedCoupons(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackspace = () => {
    if (couponCode.trim().length === 0) {
      setRecommendedCoupons(coupons);
    }
  };

  const applyCoupon = async (coupon) => {
    ReactGA.event({
      category: "coupon",
      action: `apply ${coupon?.name}`,
      label: "apply coupon",
      value: `${coupon?.name}`,
    });

    if (appliedCoupon?._id !== coupon?._id || appliedCoupon === null) {
      setAppliedCoupon((appliedCoupon) => setAppliedCoupon(coupon));
      dispatch(setSelectedCoupon({ ...coupon }));
    } else {
      setAppliedCoupon(null);
    }
  };

  const navigate = useNavigate();

  const removeFromCart = async (id) => {
    ReactGA.event({
      category: "shopping",
      action: "Remove from Cart",
      label: "Remove" + id,
      value: id,
    });
    const res = await api.delete(`/carts/${id}`);
    if (res.data.error) {
      alert.error(res.data.error);
    } else {
      dispatch(removeFromCartAction(id));
      cart.length > 1 ? updateCartData(false) : updateCartData(false, false);
    }
  };

  function cantFetchCartItems(res, showError = true) {
    showError && alert.error(res.data.error);
    dispatch(setCartData([]));
    setCart([]);
    setBackendMrp(0);
    setBackendDiscountedPrice(0);
    setBackendDiscount(0);
    setBackendGst(0);
    setApplicability(5);
    setLoadingUnloadingPrice(0);
    setDeliveryPrices([0, 0, 0, 0]);
    setDeliveryPricesWithGst([0, 0, 0, 0]);
    setNumberOfDeliveryVehicles([0, 0, 0, 0]);
    setLaborPrices([]);
    setTotalLaborCost(0);
  }

  async function onChangeQuantity(cartItemId, newQuantity) {
    ReactGA.event({
      category: "shopping-cart",
      action: "change quantity" + "" + newQuantity,
      label: "change quantity" + newQuantity,
      value: newQuantity,
    });
    cart.find((o, i) => {
      if (o._id === cartItemId) {
        let newCart = cart;
        newCart[i].quantity = newQuantity;
        setCart([...newCart]);
      }
    });
    updateCartData(false);
  }

  useEffect(() => {
    async function getData() {
      await updateCartData(true);
      if (state.user?.addresses.length < 1) {
        setUserLocation({
          latitude: 18.5204,
          longitude: 73.8567,
          accuracy: ``,
          zoom: 17,
        });
      } else {
        setUserLocation({
          latitude: cartAddress?.lat || 18.5204,
          longitude: cartAddress?.lng || 73.8567,
          zoom: 17,
          accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
        });
      }
      try {
        const res = await api.get("/coupons/getAllCoupons?type=product");
        setCoupon(res?.data?.coupons);
        setRecommendedCoupons(res?.data?.coupons);
      } catch (err) {
      }
    }
    getData();
  }, []);

  const showAlertModal = () => {
    setAlertModal(true);
  };

  async function onClickMap(event) {
    let oldLat = userLocation.latitude,
      oldLng = userLocation.longitude;
    setUserLocation({
      ...userLocation,
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      accuracy: "Accuracy: 100% (Item will be delivered at selected location)",
    });
    const index = state.user.addresses.findIndex(
      (x) => x.address === cartAddress.address
    );
    const address = {
      _id: cartAddress?._id,
      name: cartAddress?.name,
      address: cartAddress?.address,
      mobile: cartAddress?.mobile,
      city: cartAddress?.city,
      landmark: cartAddress?.landmark,
      state: cartAddress?.state,
      pincode: cartAddress?.pincode,
      addType: cartAddress?.addType,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    await api
      .patch("/me/addresses", {
        addresses: state.user.addresses,
        index,
        address,
      })
      .then(async (response) => {
        if (response.data.error) {
          alert.error(response.data.msg);
          return setUserLocation({
            ...userLocation,
            latitude: oldLat,
            longitude: oldLng,
            accuracy:
              "Accuracy: 100% (Item will be delivered at selected location)",
          });
        }
        updateCartData(false);
      });
    dispatch(updateAddress(index, address));
  }

  const hideAlertModal = () => {
    setAlertModal(false);
    setAddressData({
      name: "",
      address: "",
      mobile: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      addType: "",
    });
  };

  const handleAddressTypeChange = (event) => {
    // setAddressData(event.target.value);
    setAddressData((prev) => ({
      ...prev,
      addType: event.target.value,
    }));
  };

  const addAddressHandler = async () => {
    if (
      addressData.name !== "" &&
      addressData.mobile !== "" &&
      addressData.address !== "" &&
      addressData.landmark !== "" &&
      addressData.city !== "" &&
      addressData.state !== "" &&
      addressData.pincode !== "" &&
      addressData.addType !== ""
    ) {
      let localLatitude, localLongitude;
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address:
              addressData.address +
              ", " +
              addressData.landmark +
              ", " +
              addressData.city +
              ", " +
              addressData.state +
              ", " +
              addressData.pincode,
            key: REACT_APP_GOOGLE_MAPS_KEY,
          },
        }
      );
      if (response.data?.results.length > 0) {
        localLatitude = response.data.results[0].geometry.location.lat;
        localLongitude = response.data.results[0].geometry.location.lng;
        setUserLocation({
          longitude: response.data.results[0].geometry.location.lng,
          latitude: response.data.results[0].geometry.location.lat,
          accuracy:
            "Estimated delivery location from address. You can move the pin to change exact delivery location",
          zoom: 15,
        });
      }
      const res = await api.post("/me/addresses", {
        address: {
          address: addressData.address.trim(),
          ...addressData,
          address: addressData.address.trim(),
          _id: undefined,
        },
        lat: localLatitude,
        lng: localLongitude,
      });
      if (res.status === 200) {
        alert.success(res.data.msg);
        dispatch(
          addAddress(state.user.addresses, {
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            floor: 1,
            address: addressData.address.trim(),
            _id: res.data.addressId,
          })
        );
        setAddressData({
          name: "",
          address: "",
          mobile: "",
          landmark: "",
          city: "",
          state: "",
          pincode: "",
          country: "",
          addType: "Home",
          lat: localLatitude,
          lng: localLongitude,
          floor: 1,
        });
        dispatch(
          setSelectedAdd({
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            address: addressData.address.trim(),
            floor: 1,
            _id: res.data.addressId,
          })
        );
        setCartAddress(oldAddress => ({
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            address: addressData.address.trim(),
            floor: 1,
            _id: res.data.addressId,
          }));
        updateCartData(true);
      } else {
        alert.error(res.data.error);
        updateCartData(true);
      }
    } else {
      alert.error("Fields are empty");
    }
  };

  function numberWithCommas(x, y) {
    if (y) {
      x = Math.round(x * 1e2) / 1e2;
      x = x.toFixed(2);
    }
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  function onClickDeliveryVehicleButton(event, deliveryVehicleNumber) {
    event.preventDefault();
    if (deliveryVehicleNumber < applicability) {
      alert.error("Vehicle is too small for cart items");
    } else {
      setIsOpen(true);
      setSelectedVehicle(deliveryVehicleNumber);
    }
    dispatch(setSelectedVehicleAction(deliveryVehicleNumber));
  }

  async function onChangeAddress(address) {
    if (
      state.user.addresses.findIndex((x) => x.address === address.address) < 0
    ) {
      alert.error("Please add address");
      return;
    }
    dispatch(setSelectedAdd(address));
    setCartAddress((oldAddress) => ({...address}));
    const addressResponse = await api.patch("/me/addresses/", {
      address: address,
    });
    if (address?.lat) {
      setUserLocation({
        latitude: address.lat || 18.5204,
        longitude: address.lng || 73.8567,
        zoom: 14,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
      });
    }
    setAddState(true);
    updateCartData(true, undefined, address?._id);
  }

  useEffect(() => {
    let tot = 0;
    let calcTotal = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (isLaborRequired) {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        totalLaborCost +
        loadingUnloadingPrice;
    } else {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice;
    }
    if (appliedCoupon) {
      let appliedDiscount = (appliedCoupon?.discountPercentage / 100) * tot;
      if (
        (appliedDiscount > appliedCoupon?.discountMaxValue &&
          appliedCoupon?.discountMaxValue !== 0) ||
        appliedCoupon?.discountPercentage === 0
      ) {
        appliedDiscount = appliedCoupon?.discountMaxValue;
      }
      calcTotal = tot - appliedDiscount;
      gstVal = calcTotal * 0.18;
      totalPrice = parseFloat(calcTotal) + parseFloat(gstVal);
      setAppliedCouponDiscount(appliedDiscount);
    } else {
      setAppliedCouponDiscount(0);
      gstVal = tot * 0.18;
      totalPrice = parseFloat(tot) + parseFloat(gstVal);
    }
    setGst(gstVal.toFixed(2));
    setTotal(totalPrice.toFixed(2));
  }, [
    appliedCoupon,
    backendDiscountedPrice,
    deliveryPrices,
    selectedVehicle,
    loadingUnloadingPrice,
    totalLaborCost,
  ]);

  const labourCostHandler = () => {
    if (totalLaborCost === 0 && cartAddress.floor !== 0) {
      setIsLaborRequired(false);
      alert.error("Labour is not applicable ");
      return;
    }
    dispatch(setIsLaborRequiredState(!isLaborRequired));
    setIsLaborRequired(!isLaborRequired);
  };

  const calculateUptoOffForCoupon = (coupon) => {
    let tot = 0;
    let calcTotal = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (selectedVehicle === 0) {
      return "Select Delivery Vehicle First";
    }
    if (isLaborRequired) {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        totalLaborCost +
        loadingUnloadingPrice;
    } else {
      tot =
        backendDiscountedPrice +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice;
    }

    let appliedDiscount = (coupon?.discountPercentage / 100) * tot;
    if (
      (appliedDiscount > coupon?.discountMaxValue &&
        coupon?.discountMaxValue !== 0) ||
      coupon?.discountPercentage === 0
    ) {
      appliedDiscount = coupon?.discountMaxValue;
    }
    calcTotal = tot - appliedDiscount;
    gstVal = calcTotal * 0.18;
    totalPrice = parseFloat(calcTotal) + parseFloat(gstVal);

    return appliedDiscount.toFixed(2) + " OFF";
  };

  function formatMaxUses(maxUses) {
    if (maxUses === 0) {
      return "No Limitation";
    } else {
      return `${maxUses} times`;
    }
  }

  function LoadingShimmer() {
    return (
      <>
        <div className="loading-shimmer">
          <div className="shimmer-image"></div>
          <div className="shimmer-details">
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
          </div>
        </div>
        <div className="loading-shimmer">
          <div className="shimmer-image"></div>
          <div className="shimmer-details">
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
            <div className="shimmer-line"></div>
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    if (state?.user?.addresses?.length == 0) {
      showAlertModal();
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="MobileView">
          <span className="headingView">
            <BsArrowLeft
              onClick={() => navigate(-1)}
              style={{ fontSize: "30px" }}
            />{" "}
            Cart
          </span>
        </div>
      ) : (
        ""
      )}
      <CartNavbar currentStep="1" />
      {/* <NavRoute title="cart" /> */}
      <div className="container threeimage_container pt-2 mb-5 pb-5 px-2 px-lg-0">
        <div className="d-flex flex-lg-row flex-column">
          {/* <SideBar title="cart" /> */}
          <div className="col-lg-8 col-12 overflowY-auto">
            {state.user.addresses.length > 0 ? (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData">
                    <h2>Deliver to </h2>
                    <span>{<>{cartAddress.addType}</>}</span>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#checkout"
                    onClick={setAddress}
                    className="changeAddressButton"
                  >
                    CHANGE
                  </div>
                </div>
                <p>
                  <b className="checkoutAddressName">{cartAddress.name}</b>,{" "}
                  {cartAddress.address}, {cartAddress.landmark},{" "}
                  {cartAddress.city}, {cartAddress.state} -{cartAddress.pincode}
                </p>
                <p className="m-0 checkoutAddressName">{cartAddress.mobile}</p>
              </div>
            ) : (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData" style={{ flex: "1" }}>
                    <h2 className="m-0">No Address Added Yet</h2>
                  </div>
                  <div
                    onClick={() => showAlertModal()}
                    className="changeAddressButton hoverAddAddress px-2 py-1"
                  >
                    Add Address
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <div>{LoadingShimmer()}</div>
            ) : cart.length === 0 ? (
              <div>No products in your cart</div>
            ) : (
              <div>
                {cart?.length === 0 ? (
                  <h4 className="text-center">No Products in cart</h4>
                ) : (
                  cart?.map((item, cartItemIndex) => {
                    const p = item.product_id;
                    return (
                      <div className="">
                        <div className="borderedContainer px-2 py-2 d-flex flex-row flex-wrap">
                          <div className="w-100 col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                            <div className="d-flex flex-row flex-wrap align-items-center justify-content-start col-sm-8 col-12">
                              <Link to={`/product/${item?.product_id?._id}`}>
                                <img
                                  src={
                                    item?.variation
                                      ? item.product_id.vars[
                                          Object.keys(item.product_id.vars)[0]
                                        ].filter(
                                          (x) =>
                                            x.attrs[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ] ===
                                            item?.variation[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ]
                                        )[0].attrs.imgs
                                        ? item.product_id.vars[
                                            Object.keys(item.product_id.vars)[0]
                                          ].filter(
                                            (x) =>
                                              x.attrs[
                                                Object.keys(
                                                  item.product_id.vars
                                                )[0]
                                              ] ===
                                              item?.variation[
                                                Object.keys(
                                                  item.product_id.vars
                                                )[0]
                                              ]
                                          )[0].attrs.imgs
                                        : item.product_id?.attrs?.imgs[0]
                                      : item.product_id?.attrs?.imgs[0]
                                  }
                                  alt=""
                                  className="cartImage"
                                />
                              </Link>
                              <div className="d-flex flex-column ps-2 flex1Inner">
                                <p className="m-0 p-0">
                                  <Link
                                    className="checkoutProductLink"
                                    to={`/product/${item?.product_id?._id}`}
                                  >
                                    {p?.product_name}
                                  </Link>
                                </p>
                                <p className="cartBrand m-0 subtext">
                                  {item?.product_id?.brand?.Brand_name}
                                </p>
                                {item?.variation &&
                                  Object.entries(item?.variation).map(
                                    ([variationKey, variationValue]) => (
                                      <>
                                        <div className="subtext">
                                          {variationKey}: {variationValue}
                                        </div>
                                      </>
                                    )
                                  )}
                                <p className="m-0 p-0">
                                  <span className="m-0 p-0 productDiscountedPrice text">
                                    Rs.{" "}
                                    {item?.variation
                                      ? item.product_id.vars[
                                          Object.keys(item.product_id.vars)[0]
                                        ].filter(
                                          (x) =>
                                            x.attrs[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ] ===
                                            item?.variation[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ]
                                        )[0].discounted_price
                                        ? numberWithCommas(
                                            item.product_id.vars[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ].filter(
                                              (x) =>
                                                x.attrs[
                                                  Object.keys(
                                                    item.product_id.vars
                                                  )[0]
                                                ] ===
                                                item?.variation[
                                                  Object.keys(
                                                    item.product_id.vars
                                                  )[0]
                                                ]
                                            )[0].discounted_price
                                          )
                                        : "N/A"
                                      : numberWithCommas(
                                          p?.discounted_price
                                        )}{" "}
                                  </span>
                                  <span className="m-0 p-0 productPrice subtext">
                                    Rs.{" "}
                                    {item?.variation
                                      ? item.product_id.vars[
                                          Object.keys(item.product_id.vars)[0]
                                        ].filter(
                                          (x) =>
                                            x.attrs[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ] ===
                                            item?.variation[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ]
                                        )[0].price
                                        ? numberWithCommas(
                                            item.product_id.vars[
                                              Object.keys(
                                                item.product_id.vars
                                              )[0]
                                            ].filter(
                                              (x) =>
                                                x.attrs[
                                                  Object.keys(
                                                    item.product_id.vars
                                                  )[0]
                                                ] ===
                                                item?.variation[
                                                  Object.keys(
                                                    item.product_id.vars
                                                  )[0]
                                                ]
                                            )[0].price
                                          )
                                        : "N/A"
                                      : numberWithCommas(p?.price)}{" "}
                                  </span>
                                  <span className="m-0 p-0 productDiscountedPercent subtext">
                                    -
                                    {item?.variation
                                      ? numberWithCommas(
                                          item.product_id.vars[
                                            Object.keys(item.product_id.vars)[0]
                                          ].filter(
                                            (x) =>
                                              x.attrs[
                                                Object.keys(
                                                  item.product_id.vars
                                                )[0]
                                              ] ===
                                              item?.variation[
                                                Object.keys(
                                                  item.product_id.vars
                                                )[0]
                                              ]
                                          )[0].discounted_percent
                                        )
                                      : numberWithCommas(p?.discounted_percent)}
                                    %
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="align-self-start col-sm-4 col-12">
                              {item?.product_id?.loadingUnloadingPrice ? (
                                <>
                                  {item?.product_id?.loadingUnloadingPrice >
                                  0 ? (
                                    <p className="subtext m-0 p-0 text-sm-end text-start">
                                      LOADING UNLOADING APPLICABLE
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {isLaborRequired &&
                              laborPrices[cartItemIndex] > 0 ? (
                                <>
                                  <p className="subtext m-0 p-0 text-sm-end text-start">
                                    LABOR APPLICABLE
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="w-100 col-12 subtext d-flex flex-row align-items-center justify-content-between">
                            <div>
                              <div>
                                Quantity:
                                <ProductCounter
                                  hide={false}
                                  cart={cart}
                                  removeFromCart={removeFromCart}
                                  quantity={item.quantity}
                                  cartItemId={item?._id}
                                  product_id={item?.product_id?._id}
                                  variation={item?.variation}
                                  onChangeQuantity={(cartItemId, newQuantity) =>
                                    onChangeQuantity(cartItemId, newQuantity)
                                  }
                                />
                              </div>
                              <div>
                                <p className="m-0 p-0">
                                  Delivery by {deliveryDate.getDate()}{" "}
                                  {deliveryDate.toLocaleString("default", {
                                    month: "long",
                                  })}
                                </p>
                              </div>
                            </div>
                            <div
                              className="cart_info_quantity_remove m-0 align-self-end"
                              data-toggle="modal"
                              data-target="#deleteItemFromCart"
                              onClick={() => setSelectedItem(item)}
                            >
                              <img src={bin} />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-4">
            <div className="w-100">
              <div className="renderside_cartside_total ms-lg-3 ms-0 pb-2">
                {/* <button
                  type="button"
                  className="addUpdateButton px-2 py-1 mt-1 mb-2"
                  style={{ fontSize: "var(--desktop-text)" }}
                  onClick={(e) => {
                    setIsMapEditable(!isMapEditable);
                  }}
                >
                  {isMapEditable
                    ? "Confirm this location"
                    : "Edit location manually"}
                </button> */}
                {/* <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_KEY}>
                  <GoogleMap
                    onClick={(e) =>
                      isMapEditable
                        ? onClickMap(e)
                        : alert.error(
                            "Click edit button to change location manually"
                          )
                    }
                    clickableIcons={false}
                    zoom={userLocation.zoom}
                    center={{
                      lat: userLocation.latitude || 18.5204,
                      lng: userLocation?.longitude || 73.8567,
                    }}
                    mapContainerClassName="map-container"
                  >
                    {userLocation?.latitude && (
                      <Marker
                        position={{
                          lat: userLocation?.latitude || 18.5204,
                          lng: userLocation?.longitude || 73.8567,
                        }}
                      ></Marker>
                    )}
                  </GoogleMap>
                </LoadScript> */}
                <p className="p-0 m-0 mt-3 cartSidebarSubheadings">
                  CHOOSE TRANSPORT
                </p>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-0">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Bike x {numberOfDeliveryVehicles[0]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      3 Wheeler x {numberOfDeliveryVehicles[1]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Tempo x {numberOfDeliveryVehicles[2]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Pickup x {numberOfDeliveryVehicles[3]}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1">
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[0] === 0 ||
                          deliveryPrices[0] === undefined ||
                          deliveryPrices.length === 0
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 1
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 1);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Bike} />
                        {(deliveryPrices[0] === 0 ||
                          deliveryPrices[0] === undefined) && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[0]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[1] === 0 ||
                          deliveryPrices[1] === undefined
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 2
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 2);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Auto} />
                        {(deliveryPrices[1] === 0 ||
                          deliveryPrices[1] === undefined) && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[1]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[2] === 0 ||
                          deliveryPrices[2] === undefined
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 3
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 3);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Pickup} />
                        {(deliveryPrices[2] === 0 ||
                          deliveryPrices[2] === undefined) && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[2]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[3] === 0 ||
                          deliveryPrices[3] === undefined
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 4
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 4);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Truck} />
                        {(deliveryPrices[3] === 0 ||
                          deliveryPrices[3] === undefined) && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[3]}
                      </p>
                    </div>
                  </div>
                </div>
                {selectedVehicle === 0 ? <></> : ""}
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-between gx-1 mt-3">
                  <div className="col-8">
                    <p className="p-0 m-0 cartside_total_text">
                      Include labor?
                    </p>
                    <p className="p-0 m-0 cartSubtext">
                      Extra help for loading and unloading
                    </p>
                  </div>
                  <div className="col-2">
                    <Switch
                      checked={isLaborRequired}
                      onChange={labourCostHandler}
                    />
                  </div>
                </div>
                {isLaborRequired && (
                  <>
                    <div className="d-flex flex-row flex-row flex-wrap align-items-center gx-1 mt-3">
                      {/* <p className="w-auto pe-3 m-0">Floor: </p> */}
                      <div className="col-12 text-end cartSubtext">
                        Floor * Labor Price (per floor) * Quantity = Labor cost
                      </div>
                      <input
                        type="number"
                        value={parseInt(cartAddress.floor)}
                        onChange={(e) => {
                          onChangeAddress({
                            ...cartAddress,
                            floor: parseInt(e.target.value),
                          });
                        }}
                        className="w-100 floorInputBox px-2 py-1"
                        placeholder="Select number of floors"
                      />
                    </div>
                    <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-3">
                      {cart?.map((cartItem, cartIndex) => (
                        <>
                          <div className="col-6 py-1">
                            <div className="">
                              <p className="p-0 m-0 cartLaborProduct">
                                <Link
                                  className="checkoutProductLink"
                                  to={`/product/${cartItem?.product_id?._id}`}
                                >
                                  {cartItem.product_id?.product_name}
                                </Link>
                              </p>
                              {cartItem?.variation &&
                                Object.entries(cartItem?.variation).map(
                                  ([variationKey, variationValue]) => (
                                    <>
                                      <p className="m-0 p-0 subtext cartLaborVariation">
                                        {variationKey}: {variationValue}
                                      </p>
                                    </>
                                  )
                                )}
                            </div>
                          </div>
                          <div className="col-6 py-1 text-end align-self-start subtext">
                            {cartAddress.floor} * Rs.{" "}
                            {cartItem?.variation
                              ? cartItem.product_id.vars[
                                  Object.keys(cartItem.product_id.vars)[0]
                                ].filter(
                                  (x) =>
                                    x.attrs[
                                      Object.keys(cartItem.product_id.vars)[0]
                                    ] ===
                                    cartItem.variation[
                                      Object.keys(cartItem.product_id.vars)[0]
                                    ]
                                )[0].laborPerFloor
                              : cartItem.product_id.laborPerFloor}{" "}
                            * {cartItem.quantity} = Rs. {laborPrices[cartIndex]}
                          </div>
                        </>
                      ))}
                      <hr className="w-100 mt-2 mb-0" />
                      <div className="col-6 mt-2 align-self-end">
                        Total labor cost
                      </div>
                      <div className="col-6 text-end align-self-start mt-3">
                        Rs. {numberWithCommas(totalLaborCost, 2)}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="coupon_side ms-lg-3 mx-0 d-flex justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#couponModal"
              >
                VIEW COUPONS
              </div>
              <div className="renderside_cartside_total ms-lg-3 ms-0">
                <div className="cartside_total_heading">ORDER SUMMARY</div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">
                    Total MRP ({cart?.length} items)
                  </div>
                  <div
                    className="cartside_total_amount"
                    style={{ textDecoration: "line-through" }}
                  >
                    Rs. {numberWithCommas(backendMrp, 2)}
                  </div>
                </div>
                <div className="cartside_total_discount">
                  <div className="cartside_total_text">Total Selling Price</div>
                  <div className="cartside_total_amount">
                    Rs. {numberWithCommas(backendDiscountedPrice, 2)}
                  </div>
                </div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Delivery</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1 ? (
                      "Select a delivery vehicle"
                    ) : (
                      <>
                        Rs.{" "}
                        {numberWithCommas(
                          deliveryPrices[selectedVehicle - 1],
                          2
                        )}
                      </>
                    )}
                  </div>
                </div>
                {loadingUnloadingPrice > 0 && (
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">Loading/Unloading</div>
                    <div className="cartside_total_amount">
                      Rs. {numberWithCommas(loadingUnloadingPrice, 2)}
                    </div>
                  </div>
                )}
                <div className="cartside_total_total">
                  <div className="cartside_total_text">
                    Labor{" "}
                    {isLaborRequired ? (
                      <>({cartAddress.floor} floors)</>
                    ) : (
                      <>(Not opted)</>
                    )}
                  </div>
                  <div className="cartside_total_amount">
                    {isLaborRequired ? (
                      <>Rs. {numberWithCommas(totalLaborCost, 2)}</>
                    ) : (
                      "Rs. 0"
                    )}
                  </div>
                </div>
                <div className="cartside_total_total">
                  {appliedCoupon && (
                    <div className="cartside_total_text">
                      Coupon ({appliedCoupon.codeName})
                    </div>
                  )}
                  <div className="cartside_total_total">
                    {appliedCoupon && (
                      <div className="cartside_total_amount">
                        {selectedVehicle < 1 ? (
                          "Select a delivery vehicle"
                        ) : (
                          <>- Rs. {appliedCouponDiscount.toFixed(2)}</>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="cartside_total_total mb-4">
                  <div className="cartside_total_text">GST (18%)</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1
                      ? "Select a delivery vehicle"
                      : `Rs. ${gst}`}
                  </div>
                </div>
                <div className="cartside_total_subtotal">
                  <div className="cartside_total_text">Total amount</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1 ? (
                      "Select a delivery vehicle"
                    ) : (
                      <>Rs. {total}</>
                    )}
                  </div>
                </div>
                <button
                  className="cartside_total_checkout"
                  onClick={async () => {
                    if (state.user.addresses.length === 0) {
                      alert.error("Please add Address!");
                    } else if (cart.length === 0) {
                      alert.error("Your cart is empty!");
                    } else {
                      navigate("/ordersummary");
                    }
                  }}
                  disabled={loading}
                >
                  {loading ? "LOADING..." : "CHECKOUT"}
                </button>
                <span className="d-flex align-items-center">
                  <BsAsterisk
                    style={{
                      marginRight: "5px",
                      fontSize: "11px",
                      fill: "#FF7253",
                    }}
                  />
                  <span
                    type="button"
                    className="loginPopupForgotPasswordButton"
                    onClick={() => {
                      navigate("/terms-and-condition");
                    }}
                  >
                    T&C Apply
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* vehicle-popup */}
      <FModal
        visible={isOpen}
        width={!isMobile ? "545" : "360"}
        height={!isMobile ? "580" : "500"}
        effect="fadeInUp"
        onClickAway={() => {
          setIsOpen(false);
        }}
      >
        <div className="login-box">
          <div className="deliveryItemBox">
            {selectedVehicle === 1 ? (
              <TwoWheelerDelivered />
            ) : selectedVehicle === 2 ? (
              <ThreeWheelerDelivered />
            ) : selectedVehicle === 3 ? (
              <FourWheelerDelivered />
            ) : (
              <PickUpTruckDelivered />
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "15px",
                fontSize: !isMobile ? "20px" : "14px",
              }}
            >
              <span style={{ color: "#7A7585", fontWeight: "400" }}>
                Capacity
              </span>
              <span>
                {selectedVehicle === 1
                  ? "25 kg"
                  : selectedVehicle === 2
                  ? "510 kg"
                  : selectedVehicle === 3
                  ? "1000 kg"
                  : "1500 kg"}
              </span>
            </div>
            <hr style={{ color: "rgb(208 203 203)" }} />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "15px",
                fontSize: !isMobile ? "20px" : "14px",
              }}
            >
              <span style={{ color: "#7A7585", fontWeight: "400" }}>Size</span>
              <span>
                {selectedVehicle === 1
                  ? "40 cm x 40cm x40cm"
                  : selectedVehicle === 2
                  ? "5.5ft x 4.5ft x 5ft"
                  : selectedVehicle === 3
                  ? "7ft x 4.8ft x 4.8ft"
                  : "8ft x 4.8ft x 4.8ft"}
              </span>
            </div>
            <hr style={{ color: "rgb(208 203 203)" }} />
            <div
              style={{
                fontWeight: "400",
                fontSize: !isMobile ? "18px" : "12px",
                overflowY: "scroll",
                maxHeight: "200px",
              }}
            >
              {selectedVehicle === 1 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : selectedVehicle === 2 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : selectedVehicle === 3 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              )}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                padding: "10px",
                backgroundColor: "#FF7703",
                border: "1px solid #FF7703",
                color: "#ffffff",
              }}
              onClick={() => setIsOpen(false)}
            >
              Continue
            </button>
          </div>
        </div>
      </FModal>
      {/* modals ------- */}
      <div
        className="modal fade"
        id="checkout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content change_delivery_address">
            <div
              className={addState ? `modal-header` : ``}
              style={
                !addState
                  ? {
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "15px",
                    }
                  : { border: "none", width: "95%" }
              }
            >
              <h5
                className="modal-title delivery_address_heading"
                style={!addState ? { fontSize: "21px" } : {}}
                id="deleteAddress"
              >
                {!addState
                  ? "SELECT DELIVERY ADDRESS"
                  : // "Address Details"}
                    "CONFIRM ADDRESS"}
              </h5>
              {!addState ? (
                <div
                  style={{ width: "35%", height: "36px" }}
                  onClick={() => showAlertModal()}
                  className="changeAddressButton hoverAddAddress px-2 py-1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Add Address
                </div>
              ) : (
                ""
              )}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddState(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-body"> */}
            <div className="checkoutModalBody" style={{ width: "100%" }}>
              {addState ? (
                <>
                  <div className="profile_form_content_inside_data">
                    <div className="select_address w-100 ms-2">
                      <div className="select_address_mid">
                        <div className="inside_address_mid">
                          <span className="address_name_mid">
                            {cartAddress.name}
                          </span>
                          <span className="address_type_mid">
                            {cartAddress.addType}
                          </span>
                        </div>
                        <span className="address_add_mid">
                          {cartAddress?.address}, {cartAddress?.landmark},{" "}
                          {cartAddress?.city}, {cartAddress?.state}-
                          {cartAddress?.pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="orderButtons">
                    <button
                      type="button"
                      className="CloseaddUpdateButton"
                      onClick={() => {
                        setAddState(false);
                      }}
                    >
                      Return
                    </button>
                    <button
                      type="button"
                      className="addUpdateButton"
                      data-dismiss="modal"
                      onClick={() => {
                        setAddState(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {state.user.addresses?.map((address, i) => (
                    <div
                      className="select_address"
                      onClick={async () => {
                        onChangeAddress(address);
                      }}
                    >
                      <div className="profile_form_content_inside_data">
                        <div className="select_address_left">
                          <IoRadioButtonOffSharp />
                        </div>
                        <div className="select_address_mid">
                          <div className="inside_address_mid">
                            <span className="address_name_mid">
                              {address.name}
                            </span>
                            <span className="address_type_mid">
                              {address.addType}
                            </span>
                          </div>
                          <span className="address_add_mid">
                            {address?.address}, {address?.landmark},{" "}
                            {address?.city}, {address?.state}-{address?.pincode}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  {/* <div
                    className="checkAddress"
                    style={{ padding: "5px", borderTop: "1px solid #878787" }}
                  > */}
                  {/* <h6>Use pincode</h6>
                    <span>
                      <input disabled={true} placeholder="Enter pincode" />
                      <button disabled={true}>Check</button>
                    </span> */}
                  {/* </div> */}
                  {/* <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                    We are currently delivering only in Pune, India.
                  </p> */}
                </>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteItemFromCart"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content password_change_popup">
            <div className="modal-header headingADeleteAddress">
              <h5
                className="modal-title"
                id="deleteItemFromCart"
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  fontFamily: "Poppins",
                }}
              >
                Are you sure to delete?
              </h5>
            </div>
            <div className="modal-body">
              <div className="deleteAddressModalBody">
                <button
                  className="addUpdateButton"
                  type="button"
                  data-dismiss="modal"
                  onClick={() => removeFromCart(selectedItem._id)}
                >
                  Yes
                </button>
                <button
                  className="cancelAddUpdate"
                  type="button"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="couponModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                AVAILABLE COUPONS
              </h4>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="search-bar d-flex ">
                <input
                  className="search-input"
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    textDecoration: "none",
                  }}
                  type="text"
                  placeholder="Enter the Coupon Code"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  onKeyDown={handleBackspace}
                />
                <div style={{ padding: "5px" }}>
                  <NewSearchIcon />
                </div>
              </div>

              <div class="coupon-list">
                {recommendedCoupons?.map((coupon, index) => (
                  <div className="coupon-item" key={index}>
                    <div className="coupon-item-details">
                      <div className="coupon-details">
                        <h6 className="coupon-title">{coupon?.codeName}</h6>
                        <p className="coupon-description">
                          {calculateUptoOffForCoupon(coupon)}
                        </p>
                      </div>
                      <div className="coupon-details">
                        <p className="coupon-description">
                          {coupon?.codePublicDescription}
                        </p>
                      </div>
                      <button
                        className="coupon-apply-button"
                        onClick={() => {
                          applyCoupon(coupon);
                        }}
                        style={{ color: "black" }}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {appliedCoupon?._id === coupon?._id
                          ? "Applied"
                          : "Apply"}
                      </button>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal && (
        <Modal
          show={alertModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="alertModalName"
          onHide={hideAlertModal}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title id="contained-modal-title-vcenter">
                        Review
                    </Modal.Title> */}
            <h1 className="alertModalh1">ADD ADDRESS</h1>
          </Modal.Header>
          <Modal.Body>
            {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
            <label className="inputLabels" id="NameId">
              Name
            </label>
            <input
              id="NameId"
              type="text"
              value={addressData.name}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="MobileId">
              Mobile
            </label>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <span className="addressMobileNumberInputPrefix">+91</span>
              <span className="addressMobileNumberInputWrapper">
                <input
                  id="MobileId"
                  type="text"
                  value={addressData.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                      setAddressData((prev) => ({
                        ...prev,
                        mobile: e.target.value,
                      }));
                    }
                  }}
                />
              </span>
            </div>
            <label className="inputLabels" id="AddressId">
              Address( House No., Building, Street, Area )
            </label>
            <input
              id="AddressId"
              value={addressData.address}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="LandmarkId">
              Landmark
            </label>
            <input
              id="LandmarkId"
              type="text"
              value={addressData.landmark}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  landmark: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="CityId">
              City
            </label>
            <input
              id="CityId"
              type="text"
              value={addressData.city}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
              }}
            />
            <div className="InsideModalSpace">
              <span>
                <label className="inputLabels" id="StateId">
                  State
                </label>
                <input
                  id="StateId"
                  type="text"
                  value={addressData.state}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }));
                  }}
                />
              </span>
              <span>
                <label className="inputLabels" id="PincodeId">
                  Pincode
                </label>
                <input
                  id="PincodeId"
                  type="text"
                  value={addressData.pincode}
                  onChange={handlePincodeChange}
                />
              </span>
            </div>
            <div className="address_type">
              <span>Type of Address</span>
              <br />
              <FormControl>
                <RadioGroup
                  row={true}
                  className="AddressRadio m-0"
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={addressData.addType}
                  onChange={handleAddressTypeChange}
                >
                  <FormControlLabel
                    value="Home"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    value="Site"
                    control={<Radio />}
                    label="Site"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {/* )} */}
          </Modal.Body>
          <div className="AdressModalSubmit">
            <button
              className="CloseaddUpdateButton"
              onClick={() => hideAlertModal()}
            >
              Discard
            </button>
            <button
              className="addUpdateButton"
              onClick={() => {
                addAddressHandler();
                hideAlertModal();
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default CurrentOrder;
