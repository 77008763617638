import React from "react";
import { ReactComponent as Cancel } from "../img/Filter/Cancel.svg";
function Price({ setColor, color, colorIndex, setColorIndex, setCurrentPage }) {

    const colorList = ["yellow", "brown", "white", "red", "black", "silver", "green", "blue", "pink", "grey"];
    return (
        <div>
            <table class="table my-0">
                <thead>
                    <tr>
                        <th scope="col" className="ps-0" style={{borderBottom:"none"}}>COLORS</th>
                        <td className="clearFilterButton" style={{borderBottom:"none"}}>
                            {/* <img src={x} alt=""  */}
                            <Cancel
                                onClick={() => {
                                    setColor([])
                                    setColorIndex([]);
                                }} style={{cursor: "pointer"}} />
                        </td>
                    </tr>
                </thead>
            </table>
            <div className="color_container my-0">
                {
                    colorList.map((colourListColour, i) => {
                        let colorr = colourListColour;
                        switch (colourListColour) {
                            case "yellow":
                                colorr = "#FFF06D"
                                break;
                            case "red":
                                colorr = "#ff4040"
                                break;
                            case "black":
                                colorr = "#000000"
                                break;
                            case "orange":
                                colorr = "#FF7253"
                                break;
                            case "blue":
                                colorr = "#8FBCFF"
                                break;
                            case "green":
                                colorr = "#9EFF00BF"
                                break;
                            case "brown":
                                colorr = "#8A5B40"
                                break;
                            case "white":
                                colorr = "#EBEBEB"
                                break;
                            case "grey":
                                colorr = "#878787"
                                break;
                            case "light grey":
                                colorr = "#C4C4C4"
                                break;
                        
                            default:
                                break;
                        }
                        return (
                            <div class="color_color" style={colorIndex.includes(i) ? {border: `2px solid #fd7e14`} : {}}>
                                <div
                                    className={`color_circle`}
                                    style={{ backgroundColor: colorr, cursor: "pointer" }}
                                    onClick={() => {
                                        if (color.includes(colourListColour)){
                                            setColor(color.filter(c => c !== colourListColour));
                                            setColorIndex(colorIndex.filter(index => index !== i));
                                        } else {
                                            setColor([...color, colourListColour]);
                                            setColorIndex([...colorIndex, i])
                                        }
                                    }}
                                >
                                    </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Price;
