import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { useStore } from "../../../../../store/store";
import Footer from "../../../../global_component/Footer/Footer";
import Loader from "../../../../global_component/Loader/Loader";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import "../sidebar.css";
import "./notification.css";
import NotifOrderComponent from "./NotifOrderComponent";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function CurrentOrder() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch] = useStore();

  ReactGA.send({
    hitType: "pageview",
    page: "/notif",
    title: "Notification",
  });

  const apiCall = async () => {
    setIsLoading(true);
    const res = await axios.get(`${API_URL}/notification/byUser`, {
      headers: {
        authorization: `Bearer ${state.accessToken}`,
      },
    });
    setData(res.data.reverse());
    setIsLoading(false);
    // return res.data
  };
  useEffect(() => {
    apiCall();
    document.title = `Notifications - PLYPICKER`;
  }, []);
  const getOrderDate = (date) => {
    var deliver = new Date(date);
    deliver.setDate(deliver.getDate() + 2);
    var dd = String(deliver.getDate()).padStart(2, "0");
    var mm = String(deliver.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = deliver.getFullYear();

    deliver = dd + "/" + mm + "/" + yyyy;
    return deliver;
  };

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  // const { isLoading, error, data } = useQuery(['repoData'], apiCall,{staleTime:1})
  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {isMobile ? (
            <div className="MobileView">
              <span className="headingView">
                <BsArrowLeft
                  onClick={() => navigate(-1)}
                  style={{ fontSize: "30px" }}
                />{" "}
                Notification
              </span>
              <span
                style={{ padding: "4px", backgroundColor: "#E5E5E5" }}
              ></span>
            </div>
          ) : (
            <NavRoute title="notification" />
          )}
          <div
            className="sidebarProfilePageContainer w-100 px-0"
            style={!isMobile ? { borderTop: "1px solid black" } : {}}
          >
            <div className="d-flex flex-row flex-wrap m-0 p-0 w-100">
              <SideBar title="notif" />
              <div className="col-sm-9 col-12 d-flex flex-column px-lg-3 px-2 justify-content-between">
                {data?.length === 0 ? (
                  <>
                    <h4 className="text-center mt-5 pt-4">
                      No notifications yet!
                    </h4>
                  </>
                ) : (
                  <>
                    {data.map((order, index) => (
                      <div id={order?.orderId?._id}>
                        {order?.orderId?.delivery.status !== "delivered" ? (
                          <div
                            className="col-12 container ps-lg-4 ps-2 my-4 pe-0"
                            key={order?.orderId?._id}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                              <div className="detailColumn container m-0 p-0">
                                <div className="d-flex flex-wrap">
                                  <div
                                    className="ps-4 truncateEllipsis d-flex flex-row align-items-center justify-content-between w-100"
                                    style={{ flexWrap: "wrap" }}
                                  >
                                    <div
                                      className="current_orders_orderID"
                                      // style={{width:'350px' , textAlign:'left'}}
                                    >
                                      Order ID: {order?.orderId?.orderid}
                                    </div>
                                    <div
                                      className="current_orders_title_rightside ms-auto me-3"
                                      style={{
                                        color: order.title.includes(
                                          "Out for Delivery"
                                        )
                                          ? "#FF7703"
                                          : "#00BB07",
                                        fontWeight: "400",
                                        // width:'300px',
                                        textAlign: "left",
                                      }}
                                    >
                                      {order.title}
                                    </div>
                                  </div>
                                  <div className="ps-3 d-lg-block truncateEllipsis"></div>
                                </div>
                                {/* <hr /> */}
                                <NotifOrderComponent order={order.orderId} />
                                {/* {order.delivery.status !== "rejected" && <div className="w-75 mx-auto py-3 yourOrderDeliveryStepperDiv">
                            <Stepper activeStep={getActiveDeliveryStep(order)} alternativeLabel>
                              {deliverySteps.map((label) => (
                                <Step key={label}>
                                  <StepLabel 
                                    icon={" "}>
                                      {label}
                                  </StepLabel>
                                </Step>
                              ))}
                            </Stepper>
                          </div>} */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-12 container ps-lg-4 ps-2 my-4 pe-0"
                            key={order._id}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                              <div className="detailColumn container m-0 p-0">
                                <div className="d-flex flex-wrap">
                                  <div
                                    className="ps-4 truncateEllipsis d-flex flex-row align-items-center justify-content-between w-100s"
                                    style={{ flexWrap: "wrap" }}
                                  >
                                    <div
                                      className="current_orders_orderID"
                                      // style={{width:'350px' , textAlign:'left'}}
                                    >
                                      Order ID: {order.orderId.orderid}
                                    </div>
                                    <div
                                      className="current_orders_title_rightside ms-auto me-3"
                                      style={{
                                        color: order.title.includes(
                                          "Out for Delivery"
                                        )
                                          ? "#FF7703"
                                          : "#00BB07",
                                        fontWeight: "400",
                                        // width:'300px',
                                        textAlign: "left",
                                      }}
                                    >
                                      {order.title}
                                    </div>
                                  </div>
                                  <div className="ps-3 d-lg-block truncateEllipsis"></div>
                                </div>
                                <br />
                                <NotifOrderComponent order={order?.orderId} />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
          <LowerNavigation />
          <Footer />
        </>
      )}
    </>
  );
}

export default CurrentOrder;
