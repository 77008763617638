import { useEffect, useState } from "react";
import "./Pagination.css";

function Pagination({ data, RenderComponent, pageLimit, count, setCurrentPage, currentPage,location }) {
  const [pages,setPages] = useState(0);
  useEffect(() => {
    setPages(Math.ceil(count / 12));
  }, [count])
  
  let PageArray=[];
  for (let index = 1; index <=pages; index++) {
    PageArray.push(index);
  }
  const [visited, setVisited] = useState([0]);
  const [disableColor, setDisableColor] = useState("")
  function goToNextPage() {
    const visIndex = visited.findIndex((x) => x === currentPage);
    if (visIndex < 0) {
      setVisited(arr => [...arr, currentPage]);
    }
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  const getPages = () => {
    const filteredArray = getPaginationGroup().filter(value => PageArray.includes(value));
    return filteredArray;
  };
// useEffect(() => {
//   const getPageCount = (type)=>{
//    return data[0][type]
//   }
//   console.log(getPageCount(location.search.split("=")[0].substring(1)))
// }, [location]);
  // console.log("location.search.split",data[0][location.search.split("=")[0].substring(1)].Brand_name);
  return (
    <>
      {/* show the posts, 10 posts at a time */}
      <div className="dataContainer">
        {/* <div className="row"> */}
          {data.map((d, idx) => (
            <RenderComponent key={idx} product={d} />
          ))}
        {/* </div> */}
      </div>

      {/* show the pagiantion
          it consists of next and previous buttons
          along with page numbers, in our case, 5 page
          numbers at a time
      */}

      <div className="pagination mx-auto">
        {/* previous button */}
        {currentPage !== 1 ? (
          <button
            onClick={() => { window.scroll(0,0); goToPreviousPage() }}
            className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 13L1 7L7 1" stroke="black" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        ) : ""}
        {/* show page numbers */}
        {getPages().map((item, index) => (
          <button
            key={index}
            onClick={(event) => {
              const pageNumber = Number(event.target.textContent);
              const visIndex = visited.findIndex((x) => x === pageNumber);
              if (visIndex < 0) {
                setVisited(arr => [...arr, index]);
              }
              if (data.length === 12 || visited.includes(index)) {
                setCurrentPage(pageNumber);
              } else {
                setCurrentPage(currentPage);
                // setDisableColor("set")
              }
              window.scroll(0,0);
            }}
            className={`paginationItem ${currentPage === item ? 'active' : null}`}
            // disabled={data.length !== 30 && !visited.includes(item) && !PageArray.includes(index) ? true : false}
          >
            <span>{item}</span>
          </button>
        ))}

        {/* next button */}
        {currentPage !== pages && data.length === 12 ? (
          <button
            onClick={() => { goToNextPage(); window.scroll(0,0); }}
            className={`next ${currentPage === pages ? 'disabled' : ''}`}
          >
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke="black" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        ) : ""}
      </div>
<div style={{width:'100%'}}>
      {data.length>0?(<h2 className=" mb-5 ms-4 countData">{data[0][location.search.split("=")[0].substring(1)]?.name?data[0][location.search.split("=")[0].substring(1)]?.name:(<>{data[0][location.search.split("=")[0].substring(1)]?.Sub_Category_name?data[0][location.search.split("=")[0].substring(1)]?.Sub_Category_name:data[0][location.search.split("=")[0].substring(1)]?.Brand_name?data[0][location.search.split("=")[0].substring(1)]?.Brand_name:data[0][location.search.split("=")[0].substring(1)]?.Subgroup_name?data[0][location.search.split("=")[0].substring(1)]?.Subgroup_name:data[0][location.search.split("=")[0].substring(1)]?.Group_name}</>)} : {count.toLocaleString()} items</h2>):(<span></span>)}
</div>
    </>
  );
}

export default Pagination;