import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const FAQ = () => {

  ReactGA.send({
    hitType: 'pageview',
    page: `/faq`,
    title : 'FAQ'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const AccordionFunction = ({ques,ans,index})=>{
    return (<Accordion style={{boxShadow:"none"}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${index}a-content`}
      id={`panel${index}a-header`}
    >
      <Typography style={{fontWeight:"bold", fontFamily:"poppins"}}>{ques}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography>
        {ans}
      </Typography>
    </AccordionDetails>
  </Accordion>)
  }

  return (
    <>
      <NavRoute title="FAQ" policy navRouter link="faq" />
      <div className="TC container">
      <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
          FAQ List
        </span>
        <br />
      <AccordionFunction ques="Q: Is it compulsory to have an unloading charge?" ans="A: Yes" index={1}/>
      <AccordionFunction ques="Q: What are the factors on which delivery charges depend?" ans="A: Your distance from our warehouse , mode of delivery transport, quantity of material." index={2}/>
      <AccordionFunction ques="Q: How long does it take to complete delivery?" ans="A: 24 hrs." index={3}/>
      <AccordionFunction ques="Q: How to seek help or any kind of support regarding any information or troubleshooting?" ans="A: There are three sections at the bottom of the homepage where you can seek assistance." index={4}/>
      <AccordionFunction ques="Q: How to get help in case of online payment failure?" ans="A: 3 sections are there on the bottom of the homepage to seek help." index={5}/>
      <AccordionFunction ques="Q: What will happen if I receive a defective product?" ans="A: Refer to our refund and return policy (link that page)" index={6}/>
      <AccordionFunction ques="Q: How to add location while placing order from cart?" ans="A: There is an add address section on the top of the cart which allows you to give your address from gps or manually." index={7}/>
      <AccordionFunction ques="Q: How can I see the price of a product which has a price button?" ans="A: Press on the get price button of the product and we will update the price within 2hr till then you can add it in your wish list so that you can't miss that product." index={8}/>
      <AccordionFunction ques="Q: Is picking a mode of transportation required?" ans="A: Yes" index={9}/>
      <AccordionFunction ques="Q: How can I place an order on your website?" ans="A: To place an order on our website, simply add the desired items to your cart and proceed to checkout. You will be prompted to enter your shipping and billing information, as well as choose a shipping method. Once this information is entered, you can review your order and submit it for processing." index={10}/>
      <AccordionFunction ques="Q: What forms of payment do you accept?" ans="A: We accept all major credit cards." index={11}/>
      <AccordionFunction ques="Q: What is your return policy?" ans="A: You can refer it from (link the return policy page)" index={12}/>
      <AccordionFunction ques="Q: How can I track my order?" ans="A: Once your order has been shipped, you can track your order from your orders section" index={13}/>
      <AccordionFunction ques="Q: How can I contact customer service?" ans={<>"A: You can contact our customer service team by email at <a href="info:info@plypicker.com">
      info@plypicker.com
          </a> or by phone at <a href="tel:18002088886">18002088886</a>."</>} index={14}/>
      <AccordionFunction ques="Q: Can I cancel or change my order?" ans="A: Yes, you can cancel or change your order as long as it has not yet been shipped. To do so, please contact our customer service team as soon as possible. For more info about our cancellation policy please (Cancellation policy link)" index={15}/>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <Footer />
    </>
  );
};

export default FAQ;
