import "./Turnkey.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../../constants";
import Modal from "react-awesome-modal";
import { AiOutlineClose } from "react-icons/ai";
import { useAlert } from "react-alert";

export default function Turnkey({ data }) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setformData] = useState({ name: "", email: "", phoneNumber: "", projectLocation: "", carpetUnit: "", specificRequirement: "", budget: "", comments: "" });
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await axios.post(`${API_URL}/book-visit-mail`, formData, {
            // name:'xyz'
        });
        const result = res.data;
        if (result.success) {
            setformData({ name: "", email: "", phoneNumber: "", projectLocation: "", carpetUnit: "", specificRequirement: "", budget: "", comments: "" });
            setIsOpen(false);
        } else {
            alert.success(result.msg);
        }
    };
    return <>
        <div className="turnKeyFormModal">
            {isOpen && <Modal
                visible={isOpen}
                width="650"
                height="730"
                effect="fadeInUp"
                className="bookVisitModal"
                onClickAway={() => {
                    setIsOpen(false);
                    setformData({ name: "", email: "", phoneNumber: "", projectLocation: "", carpetUnit: "", specificRequirement: "", budget: "", comments: "" });
                }}
            >
                <button type="button" className="close closeChatBotButton" aria-label="Close" onClick={() => setIsOpen(false)}><span aria-hidden="true"><AiOutlineClose /></span></button>
                <div className="login-box" style={{ overflowY: 'auto' }}>
                    <h2>Details</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="user-box">
                            <input
                                type="text"
                                name=""
                                value={formData.name}
                                onChange={(e) => {
                                    setformData({ ...formData, name: e.currentTarget.value });
                                }}
                                required
                            />
                            <label>Name</label>
                        </div>
                        <div className="user-box">
                            <input
                                type="email"
                                name=""
                                value={formData.email}
                                onChange={(e) => {
                                    setformData({ ...formData, email: e.currentTarget.value });
                                }}
                                required
                            />
                            <label>Email</label>
                        </div>
                        <div className="user-box">
                            <input
                                type="text"
                                name=""
                                value={formData.phoneNumber}
                                onChange={(e) => {
                                    setformData({
                                        ...formData,
                                        phoneNumber: e.currentTarget.value,
                                    });
                                }}
                                required
                            />
                            <label>Phone Number</label>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="user-box" style={{ width: "45%" }}>
                                <input
                                    type="text"
                                    name=""
                                    value={formData.projectLocation}
                                    onChange={(e) => {
                                        setformData({ ...formData, projectLocation: e.currentTarget.value });
                                    }}
                                    required
                                />
                                <label>Project Location</label>
                            </div>
                            <div className="user-box" style={{ width: "45%" }}>
                                <input
                                    type="text"
                                    name=""
                                    value={formData.carpetUnit}
                                    onChange={(e) => {
                                        setformData({ ...formData, carpetUnit: e.currentTarget.value });
                                    }}
                                    required
                                />
                                <label>Carpet Area in Sq Ft</label>
                            </div>
                        </div>
                        <div className="user-box">
                            <input
                                type="text"
                                name=""
                                value={formData.specificRequirement}
                                onChange={(e) => {
                                    setformData({ ...formData, specificRequirement: e.currentTarget.value });
                                }}
                                required
                            />
                            <label>Specific Requirement</label>
                        </div>
                        <div className="user-box">
                            <input
                                type="text"
                                name=""
                                value={formData.budget}
                                onChange={(e) => {
                                    setformData({ ...formData, budget: e.currentTarget.value });
                                }}
                                required
                            />
                            <label>Budget</label>
                        </div>
                        <label style={{ color: '#878787' }}>Additional Comments</label>
                        <div className="user-box">
                            <textarea rows='10' columns='10' style={{ border: '1px solid #000' }} value={formData.comments}
                                onChange={(e) => {
                                    setformData({ ...formData, comments: e.currentTarget.value });
                                }}></textarea>
                        </div>
                        <p className="m-0">Fill all the fields, our experts will contact you soon!</p>
                        <button
                            type="submit"
                            className="addUpdateButton"
                            onClick={() => setformData({ ...formData })}
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </Modal>}
        </div>
        <div className="cascade-slider_container" id="cascade-slider">
            <div className="cascade-slider_slides">
                <Swiper
                    effect={'cards'}
                    grabCursor={true}
                    modules={[EffectCards]}
                    className="mySwiper"
                >
                    {data && data.map(x => <>
                        <SwiperSlide>
                            <img src={x?.url} style={{ cursor: "pointer", width: "90%" }} onClick={() => setIsOpen(true)} />
                        </SwiperSlide>
                    </>)}
                </Swiper>
            </div>
        </div>
    </>
}
