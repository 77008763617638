import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import "./TC.css";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function TC() {

  ReactGA.send({
    hitType: 'pageview',
    page: `/terms-and-condition`,
    title : 'Services'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <NavRoute
        title="TERMS & CONDITIONS"
        policy
        navRouter
        link="terms-and-condition"
      />
      <div className="TC container">
        <span>Dated: January 05, 2023</span>
        <span>PlyPicker</span>
        <span>Version 1.0.1</span>
        <br />
        <p style={{ fontWeight: "bold" }}>
          PlyPicker is an interior designing & service providing platform owned
          and operated by PlyPicker Private Limited (“Company” or “We” or “Us”
          or “Our”), whose registered address is at Sr No. 251/4, Vrindavan
          Nagar, Pune, Maharashtra, India - 411021.
        </p>
        <p>
          This page and all the contents of this page are collectively called as
          "Terms & Conditions" or "T&C". These Terms & Conditions(T&C) are a
          part of the PlyPicker App & PlyPicker Website (
          <a href="https://www.plypicker.com" target="_blank">
            https://www.plypicker.com
          </a>
          ), jointly known as “PlyPicker” and hold same validity as held by this
          version of the PlyPicker App or the PlyPicker Website.
        </p>
        <p style={{ fontWeight: "bold" }}>
          This version of the PlyPicker is currently valid to be used only in
          the State of Maharashtra (India).
        </p>
        <p style={{ fontWeight: "bold" }}>
          Please read this page (T&C) carefully before proceeding on the
          PlyPicker App or the PlyPicker Website. By accessing and/or Using
          PlyPicker, you indicate that you accept (unconditionally and
          irrevocably) the T&C. If you do not agree to T&C, please refrain from
          Using PlyPicker and exit immediately.
        </p>
        <p style={{ fontWeight: "bold" }}>
          T&C should be read and understood as a whole and shall be governed by
          and interpreted in accordance with the laws of India.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The “Privacy Policy” provided on a separate page, is also a part of
          the Terms & Conditions and must be read, understood and interpreted
          together with and in accordance with the T&C.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Introduction
        </span>
        <br />
        <p>
          Thank You for using PlyPicker and services provided by it
          (collectively "Service").
        </p>
        <p>
          Service only includes using of PlyPicker, providing delivery of
          skilled or unskilled workers (only mentioned in and provided by
          PlyPicker) and Delivery of Products (only mentioned in the PlyPicker
          Website & PlyPicker App and provided by PlyPicker).
        </p>
        <p>
          "You", "Your", "Yourself" or “User” refers to you, the person
          accessing, Using or interacting with, or expresses his/her intention
          to become the customer of the PlyPicker or the Service. "You" does not
          include the Company. The T&C applies to You.
        </p>
        <p>
          "Use", "Uses", “Used” or "Using" refers to utilising the Service
          through the PlyPicker only, including interaction with any feature of
          the PlyPicker or some or all of the Service. By Using the PlyPicker or
          the Service, You indicate that Your such Use is completely valid,
          legal and accordance with the laws of India and You are not barred in
          any way for making such Use of PlyPicker.
        </p>
        <p>
          “Products” only includes products and appliances (electrical or
          non-electrical) that are available to be sold on PlyPicker and are
          related to sanitary, home décor/design, plumbing, locks, hardware,
          tiles, and similar items as well as related accessories. The Products
          are sold by the respective brands and not by Us (or not by the
          PlyPicker itself). PlyPicker is just a facilitating platform for this
          purpose. We or the PlyPicker will not be in anyway liable for any or
          all types of Warranty, Guarantee and related liabilities arising out
          of the Products once they are purchased by You. You may contact the
          respective brands or the manufacturers for the same.
        </p>
        <p>
          “Delivery of Products” only means transporting the Products to the
          doorstep of the address provided by You while Using PlyPicker.
        </p>
        <p>
          “Worker” or “Workers” only include the Architect(s), Designer(s),
          Consultant(s) or similar skilled or unskilled workers provided only by
          PlyPicker. The Worker will interact with You only through PlyPicker
          and as per rules & wages decided by Us time to time and as per the
          situation.
        </p>
        <p>
          PlyPicker works on the wilful interaction between You and the
          PlyPicker.
        </p>
        <p>All rights are reserved.</p>
        <p>
          Any query, suggestion, request, or complaint regarding the Service or
          the PlyPicker can be mailed at{" "}
          <a href="mailto:info@plypicker.com">info@plypicker.com</a>
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Using PlyPicker
        </span>
        <br />
        <p>
          The PlyPicker App & PlyPicker Website, which can run on Android and
          iOS Devices, consists of a well- designed user interface which can be
          Used by You to hire or connect to different types of Workers (only as
          per provided by PlyPicker) based on their availability in Your city or
          area.
        </p>
        <p>
          You can also buy certain interior designing and related Products, only
          those provided by PlyPicker, through PlyPicker.
        </p>
        <p>
          The payments for the same Products can be done online through a
          Third-Party Payment Portal (henceforth “Payment Portal”). We or the
          PlyPicker will not be in any way responsible for anything that happens
          after You proceed (consciously or unconsciously) for buying any
          provided Products and so are directed from PlyPicker towards the
          Payment Portal window or page.
        </p>
        <p>
          However, in case of Products, We or the PlyPicker will only be
          responsible for the Delivery of Products at the doorstep of the
          address provided by You. Upon the Delivery of Products, You are bound
          to make the full payment (in cash or as per demanded by PlyPicker) as
          quickly as possible.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Rules of Conduct
        </span>
        <br />
        <p>
          You agree to Use the PlyPicker only for lawful purposes. You shall not
          post or transmit through the PlyPicker any material which violates or
          infringes in any way upon the rights of others, or which is unlawful,
          threatening, abusive, defamatory, invasive of privacy or publicity
          rights, vulgar, obscene, profane, or otherwise objectionable, contains
          injurious formulas, recipes, or instructions, which encourages conduct
          that would constitute a criminal offense, give rise to civil liability
          or otherwise violate any law.
        </p>
        <p>
          You shall not Use the PlyPicker to advertise or perform any commercial
          solicitation, including, but not limited to, the solicitation of users
          to become subscribers of other online services,
        </p>
        <p>
          You shall not Use the PlyPicker to impersonate any person or entity,
          or falsely state or otherwise misrepresent Your affiliation with a
          person or entity.
        </p>
        <p>
          You shall not Use the PlyPicker to upload, post, email, transmit or
          otherwise make available any content that You do not have a right to
          make available under any law or under contractual or fiduciary
          relationships (such as inside information, proprietary and
          confidential information learned or disclosed as part of employment
          relationships or under nondisclosure agreements).
        </p>
        <p>
          You shall not Use the PlyPicker to upload, post, email, transmit or
          otherwise make available any content that infringes any patent,
          trademark, trade secret, copyright or other proprietary rights of any
          party.
        </p>
        <p>
          You shall not Use the PlyPicker to upload, post, email, transmit or
          otherwise make available any unsolicited or unauthorized advertising,
          promotional materials, "junk mail," "spam," "chain letters," "pyramid
          schemes," or any other form of solicitation.
        </p>
        <p>
          You shall not Use the PlyPicker to upload, post, email, transmit or
          otherwise make available any material that contains software viruses
          or any other computer code, files or programs designed to interrupt,
          destroy or limit the functionality of any computer software or
          hardware or telecommunications equipment.
        </p>
        <p>
          You shall not interfere with or disrupt the PlyPicker or servers or
          networks connected to the PlyPicker, or disobey any requirements,
          procedures, policies or regulations of networks connected to the
          PlyPicker.
        </p>
        <p>
          You shall not intentionally or unintentionally violate any applicable
          local, state, national or international law or regulation.
        </p>
        <p>You shall not Use the PlyPicker to stalk or harass another.</p>
        <p>You shall not collect or store personal data about other users.</p>
        <p>
          You shall not Use the PlyPicker to promote or encourage illegal
          activities.
        </p>
        <p>
          We reserve the right to terminate Your Use of the PlyPicker at any
          time, without notice, for any reason, including, but not limited to,
          violation of these rules of conduct.
        </p>
        <br />
        <span>User Restrictions</span>
        <br />
        <p>1. You must be at least 18 years of age to Use the PlyPicker.</p>
        <p>
          2. You must not be in any way disqualified by the law to Use PlyPicker
          or while utilising the Service.
        </p>
        <p>
          3. You must provide accurate and complete information when creating an
          account and purchasing the Products or the Service through the
          PlyPicker.
        </p>
        <p>
          4. You are solely responsible for maintaining the confidentiality of
          Your account and password and for restricting access to your computer
          or mobile device, and You agree to accept responsibility for all
          activities that occur under Your account or password.
        </p>
        <p>
          5. You may not Use the PlyPicker or the Service for any illegal or
          unauthorized purpose. You must comply with all local, state, national
          and international laws, regulations and rules when using the platform.
        </p>
        <p>
          6. You may not Use the PlyPicker to promote or engage in any illegal
          or fraudulent activity or to promote any illegal or unauthorized
          copies of another person's intellectual property.
        </p>
        <p>
          7. We reserve the right to terminate Your Use of the PlyPicker at any
          time, without notice, for any reason and for whatever time period we
          decide, including, but not limited to, violation of these user
          restrictions.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Limitation of Liability
        </span>
        <br />
        <p>
          We are liable or responsible only up to the performance of that part
          of the Service for which You have opted for and made full payment
          prior of receiving it or have promised to make full payment upon its
          completion.
        </p>
        <p>
          In no event shall PlyPicker, nor any of Us, shall be held liable for
          anything arising out of or in any way connected with Your Use of the
          PlyPicker or the Service whether such liability is under contract or
          not. We or the PlyPicker shall not be held liable for any indirect,
          consequential, or special liability arising out of or in any way
          related to your use of the Service. We express no representations or
          warranties, of any kind related to the PlyPicker or the Service. Also,
          nothing contained on the PlyPicker shall be interpreted as advising
          You.
        </p>
        <p>
          The part of the Service providing for any type of 'Consultation' is
          merely a suggestion from the PlyPicker (or Us) to You, which You are
          NOT ADVISED to compulsorily follow or adopt. Following such suggestion
          or acting as per the 'Consultation', You strongly express and indicate
          that You think in the same way and You have done proper research
          regarding that and You are wilfully doing so, without any intent,
          knowledge or consideration to get compensated in anyway if anything
          goes wrong or otherwise.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Your use of the Service or the PlyPicker
        </span>
        <br />
        <p>
          The Worker, once hired by You (through PlyPicker only) is only
          responsible to perform the concerned Service or part of the Service as
          opted by You, and it is Your sole responsibility and liability to pay
          the agreed amount of money/consideration (as decided by PlyPicker) for
          the Service performed by the Worker (communicated through PlyPicker
          only) to the Worker. We are not liable or responsible for the payment
          of any of the Service.
        </p>
        <p>
          The scope, course and subject of employment of any Worker will be
          solely decided by Us time to time and as we wish, keeping Your demands
          in mind and the type and level of skill held by the Worker. PlyPicker
          or We are not responsible or liable for anything done by the Worker
          outside his/her scope, course or subject of employment (as decided by
          us).
        </p>
        <p>
          You are entirely responsible for taking the necessary precautions to
          protect both personal safety and any involved property when engaging
          with the Service provided by the Worker. It is Your responsibility to
          ensure a safe and healthy environment for the Worker while engaging
          with him/her during the Service.
        </p>
        <p>
          Other demands or considerations of You, if any, are the matters of
          formal discussion and agreement between You and Us only. No third
          party can interfere.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Disclaimer of Warranties
        </span>
        <br />
        <p>
          We or the PlyPicker is not responsible to provide any kind of work
          force, workers, experts, technicians, tools, equipment, raw material,
          fares or wages or any such thing other than the Service provided and
          opted by You on the PlyPicker. Also, it must be clear to You that the
          Service is subject to availability of Workers & Products, and
          availability of any other related resources.
        </p>
        <p>
          We and the PlyPicker also have the right to terminate the Service
          provided to You, if you violate any provision of the T&C or other
          agreement between You and Us, immediately and anytime We think fit and
          reasonable to do so. We are not bound to serve You any notice or give
          You any opportunity to prove Yourself prior to such termination.
        </p>
        <p>
          We are not responsible or liable to compensate anyone for any injury
          or damage occured due to the negligence or any bad or illegal conduct
          of You or the Worker during the course of employment of the Worker or
          while Your Use of the Service or the PlyPicker.
        </p>
        <p>
          The Products and the Service provided by PlyPicker are provided "as
          is" and without warranties of any kind, either express or implied.
        </p>
        <p>
          We do not warrant that the Products and the Service provided by
          PlyPicker will meet Your requirements or expectations, or that the
          PlyPicker will be uninterrupted, timely, secure, or error-free.
        </p>
        <p>
          We do not warrant the accuracy, completeness, or usefulness of any
          information provided through PlyPicker, and We are not responsible for
          any errors or omissions in the information.
        </p>
        <p>
          We do not make any warranties regarding the quality, safety, or
          legality of the Products and the Service provided through PlyPicker,
          and We are not responsible for any damages or injuries resulting from
          the Use of the Products or the Service.
        </p>
        <p>
          We do not make any warranties regarding the results that may be
          obtained from the Use of the Products or the Service, or regarding the
          accuracy or reliability of any information obtained through the Use of
          the Products or the Service.
        </p>
        <p>
          We do not make any warranties regarding the performance or
          non-performance of any third-party providers, and We are not
          responsible for any damages or losses resulting from the Use of any
          third-party providers.
        </p>
        <p>
          You acknowledge that We cannot control the conduct of other users of
          the PlyPicker and We are not responsible for such conduct.
        </p>
        <p>
          To the fullest extent permissible by law, We exclude all
          representations, warranties, conditions, and terms (whether express or
          implied by statute, common law or otherwise), including but not
          limited to the implied warranties of merchantability, fitness for a
          particular purpose, and non-infringement of proprietary rights.
        </p>
        <p>
          We shall not be liable for any indirect, special, incidental,
          consequential, punitive, or exemplary damages arising out of or in
          connection with the Use of the PlyPicker or any Products or the
          Service provided through the PlyPicker, including but not limited to,
          loss of profits, loss of business, loss of data, or loss of goodwill.
        </p>
        <p>
          You acknowledge and confirm that You are an independent person or
          group of persons and nothing in these T&C or the Service that You are
          provided by the Worker (through PlyPicker) is intended to, or shall be
          deemed to establish any relationship of: joint partnership; agency;
          employer and employee; or other between You and Us or You and
          PlyPicker under any circumstances.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          External Links
        </span>
        <br />
        <p>
          Our Service may contain external links that are not operated by Us. If
          You click on those links, You may be directed to a third party's site
          or elsewhere for any purpose may or may not related to Your Use of the
          Service or the PlyPicker. We strongly advise You to review the privacy
          policy and terms and conditions of every site You visit. We have no
          control over, and assume no responsibility for the content, privacy
          policies or practices of any third party sites, products or their
          services.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Intellectual Property Rights
        </span>
        <br />
        <p>
          You acknowledge that all present and future copyright and other
          intellectual property rights subsisting in, or used in connection
          with, the PlyPicker and the Service (the “Intellectual Property”),
          including the manner in which the PlyPicker is presented or appears
          and all information and documentation relating to the PlyPicker, is
          Our property and remains vested in Us at all times and nothing in
          these T&C shall be taken to transfer any Intellectual Property to You.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Our Rights and Liabilities
        </span>
        <br />
        <p>
          It is Our soul right to cancel, continue, discontinue, terminate or
          restart the working of the PlyPicker and some or all of the Service
          provided under it as and when required or when We think fit. We may
          try to keep in mind the interests of everyone connected to and/or
          through PlyPicker while doing so. IT IS OUR SOUL RIGHT TO MAKE ANY
          TYPE OF CHANGE OR CHANGES IN PLYPICKER OR ANY OF ITS PART OR IN
          SERVICE OR ANY OF ITS PART. We can make changes to any or all the
          features of PlyPicker or add or reduce or modify certain Service
          provided by the PlyPicker whenever We want and without any external
          restrictions or obligations and without any prior notice or
          permission.
        </p>
        <p>
          We reserve the right at all times to edit, refuse to post or remove
          from the PlyPicker any profile, information (including Your
          Information) or materials for any reason whatsoever, and to disclose
          any information (including Your Information) We deem appropriate to
          satisfy any obligation We may have under applicable laws, regulatory
          requirements, legal processes, or to satisfy any request of the
          police, government or any regulatory body or to satisfy the
          requirements of the Service. For the avoidance of doubt, we reserve
          the right to remove any review that We believe in Our sole discretion
          is inappropriate.
        </p>
        <p>
          The Terms & Conditions ("Terms & Conditions" or "T&C") can also be
          revised, updated, changed or renewed partially or completely by Us and
          whenever We think fit and by using PlyPicker You are expected to
          review T&C each and every time You Use or interact with the Service or
          the PlyPicker. Any grammatical or typing error in the T&C or any of
          its contents shall be excused.
        </p>
        <p>
          As no technology is completely error free, so the server might be down
          sometimes. And thus, We make no guarantee or warranty that the
          PlyPicker will provide uninterrupted Service or be error free or that
          any defects will be corrected instantly.
        </p>
        <p>
          So, We REQUEST FOR YOUR GOOD COOPERATION. In case any such problem
          arises while the working of PlyPicker or the Service, We may try to
          solve it. But, however, if it is not occurred due to Us or due to the
          PlyPicker or any of its part and/or if We are unable to solve such
          problem, then We are not responsible to You or anyone for anything in
          that situation and We have no liability to You or anyone suffering
          from that problem or any injury or damage hence occurred. Though We
          will always try to provide You the best of the Service, We are not
          liable or responsible to You or anyone for the quality of work done or
          period of time taken or behaviour or any other bad conduct done or
          breach of one's duty by either You or the Worker or both or anyone
          interacting with or through the PlyPicker or using the Service.
        </p>
        <br />
        <span className="subheading" style={{ fontWeight: "bold" }}>
          Declaration
        </span>
        <br />
        <p>
          You hereby indemnify to the fullest extent Us and the PlyPicker from
          and against any and/or all liabilities, costs, demands, causes of
          action, damages and expenses arising in any way related to your breach
          of any of the provisions of these Terms & Conditions.
        </p>
        <p>
          If any of these T&C are held to be illegal or unenforceable for any
          reasons, such provisions shall be severed and the rest of the T&C
          shall remain in full force and effect.
        </p>
        <p>
          We may assign, transfer or sub-contract all or any of Our rights to
          anyone We have faith in and at any time without Your consent or
          without giving You any information.
        </p>
        <p>
          A person who is not a party to these T&C shall not have any rights
          under to enforce any provision in these T&C.
        </p>
        <p>
          These T&C shall be subject to the laws of the Republic of India and
          the parties shall submit to the exclusive jurisdiction of the{" "}
          <span style={{ fontWeight: "bold" }}>High Court of Bombay</span> in
          the Republic of India.
        </p>
        <p>
          By reading the Terms & Conditions ("Terms & Conditions" or "T&C") and
          Using PlyPicker and/or by continuing to navigate the Service or
          PlyPicker without accepting the Terms & Conditions, You hereby
          acknowledge and agree to PlyPicker's Terms & Conditions and You also
          affirm that You are fully competent to Use PlyPicker, You have no any
          objection to the Terms & Conditions or to PlyPicker or to Us, and You
          abide by and comply with and are satisfied with the Terms & Conditions
          completely and Use PlyPicker with your own wish and responsibility.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default TC;
