import React from "react";
import "./StarRating.css";
import Rating from '@mui/material/Rating';

function StarRating({ stars,avgRate, start }) {
    // console.log("stars value",stars)
    stars=stars?stars:0;
    // console.log("stars",stars)
    return (
        <div className={start ? "starRating_main justify-content-md-start justify-content-start" : "starRating_main justify-content-md-start justify-content-end"}>
            <Rating name="half-rating-read" value={stars} precision={0.5} readOnly size={avgRate?"large":"medium"}/>
        </div>
    )
}

export default StarRating;
