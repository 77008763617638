import React from 'react'
import { Form } from "react-bootstrap";
import "./editInput.css"

const EditInput = (props) => {
  return (
    <Form.Group className='inputFields_Outside'>
      <Form.Control
        className='inputFields'
        type={props?.type}
        placeholder={props?.placeholder}
        value={props?.value}
        onChange={props?.onChange}
      />
      <Form.Text className="text-muted">
        {props?.errorMessage}
      </Form.Text>
    </Form.Group>
  )
}

export default EditInput