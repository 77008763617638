import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../../constants";
import "./LowerNavigation.css";

export default function LowerNavigation() {
    const [categories, setCategories] = useState([])
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    useEffect(() => {
        (async () => {
            const cat = await axios.get(`${API_URL}/navbar`, {
                headers: {
                    // name:'xyz'
                }
            });
            let array = cat.data
            cat.data.map((category, categoryIndex) => {
                if (category.name === "Locks") {
                    let element = array[array.length - 1];
                    array[array.length - 1] = category;
                    array[categoryIndex] = element
                }
            })
            setCategories(array)
        })();
    }, [])
    return <>
        <div className="w-100 py-5 position-relative" style={{ background: "#ebebeb" }}>
            <div className="container">
                <div className="row p-relative w-100">
                    <div className="col-lg-3 col-6">
                        {categories.slice(0, 5).map((category, categoryIndex) => (
                            <>
                                <div className="col-12 my-3">
                                    <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/productlist?category=${category._id}`}>
                                        <h2 className="footer_column_heading lowerNavigationHeading mb-1">{category.name}</h2>
                                    </Link>
                                    {category.subCategories.map((subcategory, subcategoryIndex) => (
                                        <>
                                            <Link className="lowerNavigation" to={`/productlist?subcategory=${subcategory._id}`}>{subcategory.Sub_Category_name}</Link> <br />
                                        </>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="col-lg-3 col-6">
                        {categories.slice(5, 8).map((category, categoryIndex) => (
                            <>
                                <div className="col-12 my-3">
                                    <Link style={{ color: 'inherit', textDecoration: 'inherit' }} to={`/productlist?category=${category._id}`}>
                                        <h2 className="footer_column_heading lowerNavigationHeading mb-1">{category.name}</h2>
                                    </Link>
                                    {category.subCategories.map((subcategory, subcategoryIndex) => (
                                        <>
                                            <Link className="lowerNavigation" to={`/productlist?subcategory=${subcategory._id}`}>{subcategory.Sub_Category_name}</Link> <br />
                                        </>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="col-lg-6 d-lg-block d-none lowerNavigationImg">
                    </div>
                </div>
            </div>
        </div>
    </>
}