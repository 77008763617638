import { Block } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import "./cityModal.css";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import { useStore } from "../../../store/store";
import axios from "axios";
import useAxios from "../../../utils/useAxios";
import {
    setSelectedAdd,
    updateSelectedCity,
    updateSelectedCityName,
} from "../../../store/reducers/userActions";
import { API_URL } from "../../../constants";
import { cityReducer } from "../../../store/reducers/userReducer";
import { useAlert } from "react-alert";

const CityModal = ({ onClose, isServicesCartPage = false }) => {
    const api = useAxios();
    const alert = useAlert();
    const modalRef = useRef(null);
    const [state, dispatch] = useStore();
    const [popularCities, setPopularCities] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("Select the city");
    const [isSelected, setIsSelectedAddress] = useState("Select the city");
    const [showCities, setShowcities] = useState(false);
    const [showModal, setShowModal] = useState(state.selectedCity ? false : true);
    const [showMore, setShowMore] = useState(false);
    const [selected, setSelected] = useState(true);
    const [submitCity, setSubmitCity] = useState([]);
    const [submitCityName, setSubmitCityName] = useState([]);
    const [defaultCity, setDefaultCity] = useState([]);
    const [fetchedAddress, setFetchedAddress] = useState([]);
    const [NewAddress, setNewAddress] = useState(false);
    useEffect(() => {
        const fetchCities = async () => {
            try {
                const cityResponse = await axios.get(`${API_URL}/city`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${state.accessToken}`,
                    },
                });
                setPopularCities(cityResponse.data);
                if (state.authenticated) {
                    setDefaultCity(cityResponse.data.addresses[0].cityid._id);
                } else {
                    setDefaultCity(cityResponse.data.defaultCity._id);
                }
                setFetchedAddress(cityResponse.data.addresses);
            } catch (error) {
            }
        };
        async function getUserLocation() {
            if (!state.authenticated) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords;
                        try {
                            const response = await axios.post(`${API_URL}/location`, {
                                latitude,
                                longitude,
                            });
                            if (
                                response.data.gmapsCity.gmapsCityId &&
                                response.data.gmapsCity.gmapsCityName
                            ) {
                                dispatch(
                                    updateSelectedCity(response.data.gmapsCity.gmapsCityId)
                                );
                                dispatch(
                                    updateSelectedCityName(response.data.gmapsCity.gmapsCityName)
                                );
                            } else {
                                alert.error(
                                    `We Currently Do Not Deliver To Pincode:${response.data.gmapsCity.gmapsPincode}`
                                );
                            }
                        } catch (error) {
                            console.log("Error while making API request:", error);
                        }
                    },
                    (error) => {
                        console.log("There was an error", error);
                    }
                );
            }
        }
        fetchCities();
        !state.selectedCity && getUserLocation();
    }, [state.selectedCity]);

    useEffect(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
            modalElement.classList.add("show"); // Show the modal on page load
            modalElement.style.display = "block"; // Ensure the modal is displayed
            modalElement.setAttribute("aria-modal", "true");
        }
    }, []);

    const renderedData = showMore ? fetchedAddress : fetchedAddress?.slice(0, 2);

    const selectCityHandler = (selectedAddress) => {
        dispatch(updateSelectedCity(selectedAddress));
    };

    const selectCityNameHandler = (cityName) => {
        dispatch(updateSelectedCityName(cityName));
    };
    // const setDefaultHandeler = () => {
    //   if(!state.selectedCity)
    //   {
    //   dispatch(updateSelectedCity(defaultCity));
    //    dispatch(updateSelectedCityName("BANGLORE"));
    //   }

    // };
    const hideModalHandler = () => {
        if (selectedAddress == "Select the city") {
            setSelected(false);
            return;
        } else {
            setShowModal(false);
            
        }
    };
    const hideModalHandler1 = () => {
        setShowModal(false);
        
    };
    const toggleShowMoreHandler = () => {
        setShowMore(!showMore);
    };

    const selectAddressHandler = (address) => {
        dispatch(
            setSelectedAdd({
                ...address,
            })
        );
    };
    return (
        <div
            className="modal fade"
            ref={modalRef}
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ backdropFilter: "brightness(80%) blur(5px)" }}
        >
            <div
                className="modal-dialog"
                role="document"
                style={{ maxWidth: "820px" }}
            >
                <div
                    className="modal-content password_change_popup px-2 py-2 "
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        padding: "2%",
                    }}
                >
                    {state?.authenticated && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            }}
                        >
                            {/* <div></div> */}
                            <div style={{ fontSize: "large", fontWeight: "bold" }}>
                                SELECT ADDRESS
                            </div>
                            {/* Close button for the modal */}
                            {/* <div>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    hideModalHandler1();
                    setDefaultHandeler();
                    selectAddressHandler(renderedData[0]);
                  }}
                ></button>
              </div> */}
                        </div>
                    )}
                    {state.authenticated && (
                        <div
                            style={{
                                fontSize: "small",
                                color: "#FF7703",
                                wordSpacing: "2px",
                            }}
                        >
                            We are available only in limited cities and pincodes
                        </div>
                    )}

                    <div style={{ width: "100%" }}>
                        {/*If User is logged in*/}
                        <div className="homeModalWrapper">
                            {state.authenticated && (
                                <div
                                    className="userAddresses homeModalLeft"
                                    style={{
                                        height: showMore ? "32vh" : "100%",
                                        overflowY: "auto",
                                    }}
                                >
                                    {renderedData?.map((address, i) => (
                                        <div
                                            className="select_address"
                                            style={{ border: "none" }}
                                            onClick={() => {
                                                const radioBtn = document.getElementById(`radio_${i}`);
                                                if (radioBtn) {
                                                    radioBtn.checked = true;
                                                }

                                                setSubmitCity(address.cityid._id);
                                                setSubmitCityName(address.cityid.cityName);
                                                setSelectedAddress(address.name);
                                                selectAddressHandler(address);
                                            }}
                                        >
                                            <div className="profile_form_content_inside_data">
                                                <div className="select_address_left">
                                                    <input
                                                        type="radio"
                                                        id={`radio_${i}`}
                                                        name="fav_language"
                                                        style={{ backgroundColor: "red" }}
                                                    />
                                                </div>
                                                <div className="select_address_mid">
                                                    <div className="inside_address_mid">
                                                        <span className="address_name_mid">
                                                            {address.name}
                                                        </span>
                                                        <span className="address_type_mid">
                                                            {address.addType}
                                                        </span>
                                                    </div>
                                                    <span className="address_add_mid">
                                                        {address?.address}, {address?.landmark},{" "}
                                                        {address?.city}, {address?.state}-{address?.pincode}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {state.authenticated && fetchedAddress.length > 2 && (
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    style={{
                                        marginTop: "1%",
                                        backgroundColor: "#FF7703",
                                        border: "none",
                                        boxShadow: "none",
                                        borderRadius: "0px",
                                    }}
                                    onClick={toggleShowMoreHandler}
                                >
                                    {showMore ? "Show Less" : "Show More"}
                                </button>
                            </div>
                        )}
                    </div>

                    {state.authenticated && (
                        <div
                            style={{
                                display: "flex",
                                textAlign: "center",
                                justifyContent: "center",
                                marginTop: "1%",
                                width: "100%",
                            }}
                        >
                            <div style={{ width: "100%", marginRight: "2%" }}>
                                <hr style={{ border: "2px solid #999" }} />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                OR
                            </div>
                            <div style={{ width: "100%", marginLeft: "2%" }}>
                                <hr style={{ border: "2px solid #999" }} />
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: !state.authenticated ? "100%" : "",
                        }}
                    >
                        {!state.authenticated && <div></div>}
                        <div style={{ fontSize: "large", fontWeight: "bold" }}>
                            SELECT CITY
                        </div>
                        {/*close button for the modal */}
                        {/* {!state.authenticated && (
              <div>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  onClick={() => {
                    hideModalHandler1();
                    setDefaultHandeler();
                  }}
                ></button>
              </div>
            )} */}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-around",
                            marginLeft: "4%",
                            marginRight: "4%",
                            marginTop: "2%",
                            alignItems: "baseline"
                        }}
                    >
                        {popularCities.cities?.map((city, i) => (
                            <div
                                onClick={() => {
                                    setIsSelectedAddress(city.cityName);
                                    setSelectedAddress(city.cityName);
                                    setSubmitCity(city._id);
                                    setSubmitCityName(city.cityName);
                                    setShowcities(!showCities);
                                    setNewAddress(true);
                                }}
                                style={city.cityName === submitCityName ? {
                                    cursor: "pointer",
                                    background: "gray",
                                    color:"#ffffffa7",
                                    borderRadius: "4px"
                                } : {
                                    cursor: "pointer",
                                    borderRadius: "4px"
                                }}
                                className="p-2"
                            >
                                <div>
                                    {" "}
                                    <img className="cityImages" src={city.image} style={city?.cityName === submitCityName ? { mixBlendMode: "multiply" } : {}}></img>
                                </div>
                                <div style={{ textAlign: "center" }}> {city.cityName}</div>
                            </div>
                        ))}
                    </div>
                    {!selected && (
                        <div style={{ color: "red" }}>*Please Select a city</div>
                    )}

                    {/*-------------------*/}
                    <div
                        style={{
                            width: "94%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "6%",
                            marginBottom: "4%",
                        }}
                    >
                        <button
                            type="button"
                            class="btn btn-primary btn-lg"
                            style={{
                                backgroundColor: "#FF7703",
                                border: "none",
                                boxShadow: "none",
                                borderRadius: "0px",
                            }}
                            onClick={() => {
                                hideModalHandler();
                                if (selectedAddress != "Select the city") {
                                    selectCityHandler(submitCity);
                                    selectCityNameHandler(submitCityName);
                                    dispatch(setSelectedAdd(null));
                                }
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    {
        // return (
        //   <div
        //     className="modal fade"
        //     ref={modalRef}
        //     tabindex="-1"
        //     role="dialog"
        //     aria-labelledby="exampleModalLabel"
        //     aria-hidden="true"
        //     style={{ backdropFilter: "blur(8px)" }}
        //   >
        //     <div className="modal-dialog" role="document">
        //       <div
        //         className="modal-content password_change_popup px-2 py-2 "
        //         style={{ width: "100%" }}
        //       >
        //         <div className="modal-header headingADeleteAddress">
        //           <h5
        //             className="modal-title"
        //             style={{
        //               fontSize: "21px",
        //               fontWeight: "bold",
        //               border: "2px solid black",
        //               padding: "0.5%",
        //               marginRight: "12%",
        //             }}
        //           >
        //             Select the city
        //           </h5>
        //         </div>
        //         <div className="homeModalHeading">
        //           {state.authenticated && (
        //             <div
        //               style={{
        //                 width: "100%",
        //                 fontWeight: "bold",
        //                 textDecoration: "underline",
        //               }}
        //             >
        //               Saved Addresses
        //             </div>
        //           )}
        //           <div
        //             style={{
        //               width: "100%",
        //               fontWeight: "bold",
        //               textDecoration: "underline",
        //             }}
        //           >
        //             {" "}
        //             Popular Cities
        //           </div>
        //         </div>
        //         <div className="homeModalBorder">
        //           <div className="homeModalWrapper">
        //             {state.authenticated && (
        //               <div className="userAddresses homeModalLeft">
        //                 {state.user.addresses?.map((address, i) => (
        //                   <div
        //                     className="select_address"
        //                     style={{ height: "100%" }}
        //                     onClick={() => {
        //                       // console.log("hello",address)
        //                       // setSelectedAddress(address);
        //                       selectCityHandler(address.city);
        //                       hideModalHandler();
        //                     }}
        //                   >
        //                     <div className="profile_form_content_inside_data">
        //                       <div className="select_address_left">
        //                         <IoRadioButtonOffSharp />
        //                       </div>
        //                       <div className="select_address_mid">
        //                         <div className="inside_address_mid">
        //                           <span className="address_name_mid">
        //                             {address.name}
        //                           </span>
        //                         </div>
        //                         <span className="address_add_mid">
        //                           {address?.address}, {address?.landmark},{" "}
        //                           {address?.city}, {address?.state}-{address?.pincode}
        //                         </span>
        //                       </div>
        //                     </div>
        //                   </div>
        //                 ))}
        //               </div>
        //             )}
        //             {/*Right Side*/}
        //             <div className="userAddresses homeModalLeft">
        //               {popularCities.cities?.map((city, i) => (
        //                 <div
        //                   className="select_address"
        //                   style={{ height: "100%" }}
        //                   onClick={() => {
        //                     // setSelectedAddress(city.cityName);
        //                     selectCityHandler(city.cityName);
        //                     hideModalHandler();
        //                   }}
        //                 >
        //                   <div className="profile_form_content_inside_data">
        //                     <div className="select_address_left">
        //                       <IoRadioButtonOffSharp />
        //                     </div>
        //                     <div className="select_address_mid">
        //                       <div className="inside_address_mid">
        //                         <span className="address_name_mid">
        //                           {city.cityName}
        //                         </span>
        //                       </div>
        //                       <span className="address_add_mid">
        //                         {city.state.stateName}
        //                       </span>
        //                     </div>
        //                   </div>
        //                 </div>
        //               ))}
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // );
    }
};

export default CityModal;
