import React from "react";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import "../sidebar.css";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function Review() {

  ReactGA.send({
    hitType: 'pageview',
    page: '/review',
    title : 'Review'
  })

  return (
    <>
      <NavRoute title="review"/>
      <div className="container w-100 px-5" style={{borderTop:"1px solid black"}}>
        <div className="profile_main">
        <SideBar title="review" />
          <div className="profile_renderside">Review</div>
        </div>
      </div>
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default Review;
