import axios from "axios";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../constants";
import { useStore } from "../../../store/store";
import {AiOutlineClose} from "react-icons/ai"
import Page_info from "./img/page_info.png"
import "./ChatBot.css";

export default function ChatBot({ pageType, product }){
    const [isChatbotOpened, setIsChatbotOpened] = useState(false);
    const [state, dispatch] = useStore();
    const [name, setName] = useState(state?.user?.name || "");
    const [mobile, setMobile] = useState(state?.user?.mobile_number || "+91");
    const [loading, setLoading] = useState(false);
    const [productName, setProductName] = useState(pageType==="NPD" && state.product?.product_name || pageType==="NPL" && document.title.slice(0, document.title.length - 12 ) || "");
    const [enquiry, setEnquiry] = useState("");
    const navigate = useNavigate();
    const alert = useAlert();
    async function handleChatBotSubmission(e){
        e.preventDefault();
        setLoading(true);
        const res = await axios.post(`${API_URL}/products/enquire`, {
            name, mobile, productName, enquiry
        });
        if (res.data.success){
            alert.success(res.data.msg);
            setLoading(false);
            setIsChatbotOpened(false);
            setName(state?.user?.name || "");
            setMobile(state?.user?.mobile_number || "")
            setProductName(pageType==="NPD" && state?.product?.product_name || pageType==="NPL" && document.title.slice(0, document.title.length - 12 || ""));
            setEnquiry("")
        } else {
            setLoading(false);
            alert.error("Request could not be processed.")
        }
        setLoading(false);
    }
    useEffect(() => {
        setIsChatbotOpened(false);
    }, [pageType])
    return <>
        <div className="chatBotWrapper">
            {!isChatbotOpened && <button className="openChatBotButton shadow" onClick={(e) => { e.preventDefault(); setIsChatbotOpened(true) }}><img src={Page_info} height={25} width={25}/></button>}
            {isChatbotOpened && <>
                <div className="chatBotDiv shadow px-2 pt-3 pb-2">
                    <h4>Chat with us</h4>
                    <button className="closeChatBotButton" onClick={(e) => { setIsChatbotOpened(false)}}><AiOutlineClose/></button>
                    <form onSubmit={(e) => { handleChatBotSubmission(e) }}>
                        <div className="chatBotInputDiv mt-1">
                            <label className="chatBotFormLabel">Name</label>
                        </div>
                            <input required className="chatBotInput chatBotName" max={12} value={name} onChange={(e) => { e.preventDefault(); setName(e.target.value)}} />
                        
                        <div className="chatBotInputDiv mt-2">
                            <label className="chatBotFormLabel">Mobile no.</label>
                        </div>
                            <input required className="chatBotInput chatBotMobile" value={mobile} onChange={(e) => {
                                e.preventDefault();
                                if (e.target.value.length < 14){
                                    if (mobile.length > 1 || e.target.value[0] === "+"){
                                        if (!isNaN(e.target.value.slice(1, e.target.value.length))){
                                            setMobile(e.target.value);
                                        }
                                    }
                                }
                            }} />
                        
                        <div className="chatBotInputDiv mt-2">
                            <label className="chatBotFormLabel">Product</label>
                        </div>
                            <input required className="chatBotInput chatBotProduct" value={productName} onChange={(e) => { e.preventDefault(); setProductName(e.target.value)}} />
                        
                        <div className="chatBotInputDiv mt-2">
                            <label className="chatBotFormLabel">Enquiry (optional)</label>
                        </div>
                            <textarea className="chatBotInput chatBotEnquiry" value={enquiry} onChange={(e) => { e.preventDefault(); setEnquiry(e.target.value)}} />
                        <div className="mt-4">
                            <button type="button" className="CloseaddUpdateButton px-1 ms-0" onClick={(e) => { e.preventDefault(); setIsChatbotOpened(false) }}>Discard</button>
                            <button type="submit" disabled={loading} className="addUpdateButton px-1">{loading ? "Submitting..." : "Submit"}</button>
                        </div>
                    </form>
                </div>
            </>}
        </div>
    </>
}