import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Modal from "react-bootstrap/Modal";
import { BsAsterisk } from "react-icons/bs";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  API_URL,
  REACT_APP_GOOGLE_MAPS_KEY,
  RZR_PAY_KEY_ID,
  REACT_APP_GA_MEASUREMENT_ID,
} from "../../../constants";
import { ReactComponent as NewSearchIcon } from "../../global_component/Navbar/NavbarUpper/NewSearchIcon.svg";

import {
  addAddress,
  setIsLaborRequiredState,
  setSelectedAdd,
  setSelectedCoupon,
  updateAddress,
} from "../../../store/reducers/userActions";

import FModal from "react-awesome-modal";
import { useStore } from "../../../store/store";
import useAxios from "../../../utils/useAxios";
import "./BuyNow.css";
import { ReactComponent as FourWheelerDelivered } from "./img/FourWheelerDelivered.svg";
import { ReactComponent as PickUpTruckDelivered } from "./img/PickUpTruckDelivered.svg";
import { ReactComponent as ThreeWheelerDelivered } from "./img/ThreeWheelerDelivered.svg";
import { ReactComponent as TwoWheelerDelivered } from "./img/TwoWheelerDelivered.svg";
import Auto from "./img/auto.PNG";
import Bike from "./img/bike.PNG";
import Pickup from "./img/pickup.PNG";
import Truck from "./img/truck.PNG";
import plypickerLogo from "./plypicker_logo.png";
import thankYou from "./thankYou.png";
import ReactGA from "react-ga4";

// ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const RZR_PAY_ID = RZR_PAY_KEY_ID;
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function BuyNow() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const alert = useAlert();
  const [paymentMethod, setPaymentMethod] = useState("upi");
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [alertModal, setAlertModal] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [finalPrice, setFinalPrice] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [gstRate, setGstRate] = useState(0.18);
  const [calculatedGst, setCalculatedGst] = useState(null);
  const [subTotal, setSubTotal] = useState(0);
  const [backendGst, setBackendGst] = useState(0);
  const [state, dispatch] = useStore();
  const [discountMaxValue, setDiscountMaxValue] = useState(0);
  const [couponPercent, setCouponPercent] = useState(null);

  const [laborPrices, setLaborPrices] = useState([0, 0, 0, 0]);
  const [totalLaborCost, setTotalLaborCost] = useState(0);
  const [isLaborRequired, setIsLaborRequired] = useState(
    state.isLaborRequiredState || true
  );

  const [selectedVehicle, setSelectedVehicle] = useState(0);
  const [deliveryPrices, setDeliveryPrices] = useState([0, 0, 0, 0]);
  const [deliveryPricesWithGst, setDeliveryPricesWithGst] = useState([
    0, 0, 0, 0,
  ]);
  const [numberOfDeliveryVehicles, setNumberOfDeliveryVehicles] = useState([
    0, 0, 0, 0,
  ]);
  const [isMapEditable, setIsMapEditable] = useState(false);
  const [applicability, setApplicability] = useState(1);
  const [loadingUnloadingPrice, setLoadingUnloadingPrice] = useState(0);
  const [buyNowAddress, setBuyNowAddress] = useState(
    state?.selectedAddress || { ...state?.user?.addresses[0] }
  );

  const [coupons, setCoupon] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [recommendedCoupons, setRecommendedCoupons] = useState([]);
  const [appliedCoupon, setAppliedCoupon] = useState(
    state?.appliedCoupon || null
  );
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [gst, setGst] = useState(null);
  const [total, setTotal] = useState(null);

  const [userLocation, setUserLocation] = useState({
    zoom: 14,
    latitude: null,
    longitude: null,
    accuracy: null,
  });
  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressData, setAddressData] = useState({
    name: "",
    address: "",
    mobile: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    addType: "",
  });
  const [address, setAddress] = useState({});
  const [phone, setPhone] = useState({});
  const [payment, setPayment] = useState("COD");
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [tax, setTax] = useState(0);
  const api = useAxios();
  const [loading, setLoading] = useState(true);
  const [selectBuyCall, setSelectBuyCall] = useState("");
  const [addState, setAddState] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [product, setProduct] = useState({});
  const [variation, setVariation] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [productDiscountedPrice, setProductDiscountedPrice] = useState(0);
  const [productDiscountedPercent, setProductDiscountedPercent] = useState(0);
  const [productName, setProductName] = useState("");
  const [image, setImage] = useState("");
  const [productImage, setProductImage] = useState("");
  const [commonPaymentModes, setCommonPaymentModes] = useState([]);
  const navigate = useNavigate();
  const today = new Date();
  let deliveryDate = new Date(today);
  deliveryDate.setDate(deliveryDate.getDate() + 2);

  const showAlertModal = () => {
    setAlertModal(true);
  };

  useEffect(() => {
    if (state?.user?.addresses.length === 0) {
      showAlertModal();
    }
  }, []);

  // ReactGA.send({
  //     hitType: 'pageview',
  //     page: '/buynow',
  //     title : 'Buy Now'
  //   })

  function cantFetchBuyNowItems(res) {
    alert.error(res.data.error);
    setDeliveryPrices([0, 0, 0, 0]);
    setDeliveryPricesWithGst([0, 0, 0, 0]);
    setBackendGst(0);
    setNumberOfDeliveryVehicles([0, 0, 0, 0]);
    setApplicability(5);
    setLoadingUnloadingPrice(0);
    setLaborPrices([]);
    setTotalLaborCost(0);
  }

  const handleCouponCodeChange = (event) => {
    const query = event.target.value;
    setCouponCode(query);
    if (query.trim().length === 0) {
      setRecommendedCoupons(coupons);
    } else {
      fetchRecommendedCoupons(query);
    }
  };

  const handleBackspace = () => {
    if (couponCode.trim().length === 0) {
      setRecommendedCoupons(coupons);
    }
  };

  const fetchRecommendedCoupons = (query) => {
    api
      .get(`/coupons/recommendedCoupons?type=product&codeName=${query}`)
      .then((response) => {
        const data = response.data.coupons;
        setRecommendedCoupons(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyCoupon = async (coupon) => {
    if (appliedCoupon?._id !== coupon?._id || appliedCoupon === null) {
      setAppliedCoupon((appliedCoupon) => setAppliedCoupon(coupon));
      // const res = await api.get("/carts", {
      //     headers: {
      //         addressId: buyNowAddress?._id
      //     }
      // });
      dispatch(setSelectedCoupon({ ...coupon }));
    } else {
      setAppliedCoupon(null);
    }
  };

  useEffect(() => {
    document.title = "Buy Now - PLYPICKER";
    async function getData() {
      setLoading(true);
      const res = await axios.get(`${API_URL}/products/${query.get("p_id")}`, {
        headers: {
          _id: appliedCoupon?._id,
        },
      });
      if (res.data.error) {
        return;
      }
      try {
        const couponResponse = await api.get(
          "/coupons/getAllCoupons?type=product"
        );
        setCoupon(couponResponse?.data?.coupons);
        setRecommendedCoupons(couponResponse?.data?.coupons);
      } catch (err) {
        console.log("Error retriving the coupons");
      }
      document.title = `Buy ${res.data?.product_name} - PLYPICKER`;
      setProduct(res.data);
      setProductPrice(res.data?.price);
      setProductDiscountedPrice(res.data?.discounted_price);
      setProductDiscountedPercent(res.data?.discounted_percent);
      setImage(res.data?.attrs?.imgs[0]);
      if (res?.data?.payment_modes?.length === 1) {
        setPaymentMethod(res?.data?.payment_modes[0]);
      }
      setLoading(false);
      if (res.data.vars !== undefined && res.data.vars !== null) {
        Object.entries(res.data.vars).map(([key, value], index) => {
          if (query.get(`${key}`)) {
            if (value.some((o) => o.attrs[key] === query.get(`${key}`))) {
              variation[key] = query.get(`${key}`);
            } else {
              variation[key] = value[0].attrs[key];
            }
          } else {
            variation[key] = value[0].attrs[key];
          }
          setVariation({ ...variation });
          if (index === 0) {
            const variationIndex = value.findIndex(
              (o) => o.attrs[key] === variation[key]
            );
            value[variationIndex].price &&
              setProductPrice(value[variationIndex].price);
            value[variationIndex].discounted_price &&
              setProductDiscountedPrice(value[variationIndex].discounted_price);
            value[variationIndex].discounted_percent &&
              setProductDiscountedPercent(
                value[variationIndex].discounted_percent
              );
            value[variationIndex]?.attrs?.imgs &&
              setImage(value[variationIndex]?.attrs?.imgs);
          }
        });
      }
      setTotal(total);
      setDiscount(discount);
      setGst((total - discount) * gstRate);
      setSubTotal(subTotal);
      setLoading(false);
      if (res.data.vars) {
        const apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: Object.keys(res.data.vars)[0] || null,
              variationvalue: variation[Object.keys(res.data.vars)[0]] || null,
              new: "true",
            },
          }
        );
        if (!apiResponse.error) {
          setDeliveryPrices(apiResponse.data.deliveryPrice);
          setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
          setBackendGst(apiResponse.data.totalGst);
          setNumberOfDeliveryVehicles(
            apiResponse.data.numberOfDeliveryVehicles
          );
          setApplicability(apiResponse.data.vehicle);
          setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
          setLaborPrices(apiResponse.data.priceForLabor);
          setTotalLaborCost(apiResponse.data.totalPriceForLabor);

          if (apiResponse?.data?.commonPaymentModes?.length === 1) {
            setCommonPaymentModes(apiResponse?.data?.commonPaymentModes[0]);
          }

          if (apiResponse.data.totalPriceForLabor === 0) {
            setIsLaborRequired(false);
            dispatch(setIsLaborRequiredState(isLaborRequired));
          }
        } else {
          cantFetchBuyNowItems(apiResponse);
        }
      } else {
        const apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: null,
              variationvalue: null,
              new: "true",
            },
          }
        );
        if (!apiResponse.error) {
          setDeliveryPrices(apiResponse.data.deliveryPrice);
          setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
          setBackendGst(apiResponse.data.totalGst);
          setNumberOfDeliveryVehicles(
            apiResponse.data.numberOfDeliveryVehicles
          );
          setApplicability(apiResponse.data.vehicle);
          setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);

          setLaborPrices(apiResponse.data.priceForLabor);
          setTotalLaborCost(apiResponse.data.totalPriceForLabor);
          if (apiResponse.data.totalPriceForLabor === 0) {
            setIsLaborRequired(false);
            dispatch(setIsLaborRequiredState(isLaborRequired));
          }
        } else {
          cantFetchBuyNowItems(apiResponse);
        }
      }
      setUserLocation({
        latitude: buyNowAddress?.lat,
        longitude: buyNowAddress?.lng,
        zoom: 17,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
      });
    }
    getData();
  }, []);

  useEffect(() => {
    if (addressData.pincode.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${addressData.pincode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data && data[0] && data[0].Status === "Success") {
            const postOfficeData = data[0].PostOffice[0];
            setAddressData((prev) => ({
              ...prev,
              city: postOfficeData.District,
              state: postOfficeData.State,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAddressData((prev) => ({
        ...prev,
        city: "",
        state: "",
      }));
    }
  }, [addressData?.pincode]);

  const handlePincodeChange = (e) => {
    let pincode = e.target.value;
    setAddressData((prev) => ({ ...prev, pincode }));
    if (pincode.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${pincode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data && data[0] && data[0].Status === "Success") {
            const postOfficeData = data[0].PostOffice[0];
            setAddressData((prev) => ({
              ...prev,
              pincode: pincode,
              city: postOfficeData.District,
              state: postOfficeData.State,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    document.title = "Buy Now - PLYPICKER";
    async function getData() {
      const res = await axios.get(`${API_URL}/products/${query.get("p_id")}`, {
        headers: {
          // name:'xyz'
        },
      });
      if (res.data.error) {
        return;
      }
      document.title = `Buy ${res.data?.product_name} - PLYPICKER`;
      setProduct(res.data);
      setProductPrice(res.data?.price);
      setProductDiscountedPrice(res.data?.discounted_price);
      setProductDiscountedPercent(res.data?.discounted_percent);
      setImage(res.data?.attrs?.imgs[0]);
      if (res.data.vars !== undefined && res.data.vars !== null) {
        Object.entries(res.data.vars).map(([key, value], index) => {
          if (query.get(`${key}`)) {
            if (value.some((o) => o.attrs[key] === query.get(`${key}`))) {
              variation[key] = query.get(`${key}`);
            } else {
              variation[key] = value[0].attrs[key];
            }
          } else {
            variation[key] = value[0].attrs[key];
          }
          setVariation({ ...variation });
          if (index === 0) {
            const variationIndex = value.findIndex(
              (o) => o.attrs[key] === variation[key]
            );
            value[variationIndex].price &&
              setProductPrice(value[variationIndex].price);
            value[variationIndex].discounted_price &&
              setProductDiscountedPrice(value[variationIndex].discounted_price);
            value[variationIndex].discounted_percent &&
              setProductDiscountedPercent(
                value[variationIndex].discounted_percent
              );
            value[variationIndex]?.attrs?.imgs &&
              setImage(value[variationIndex]?.attrs?.imgs);
          }
        });
      }
      if (res.data.vars) {
        const apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: Object.keys(res.data?.vars)[0] || null,
              variationvalue: variation[Object.keys(res.data?.vars)[0]] || null,
              new: "true",
            },
          }
        );
        if (!apiResponse.error) {
          setDeliveryPrices(apiResponse.data.deliveryPrice);
          setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
          setBackendGst(apiResponse.data.totalGst);
          setNumberOfDeliveryVehicles(
            apiResponse.data.numberOfDeliveryVehicles
          );
          setApplicability(apiResponse.data.vehicle);
          setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
          setLaborPrices(apiResponse.data.priceForLabor);
          setTotalLaborCost(apiResponse.data.totalPriceForLabor);
        } else {
          cantFetchBuyNowItems(apiResponse);
        }
      } else {
        const apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: null,
              variationvalue: null,
              new: "true",
            },
          }
        );
        if (!apiResponse.error) {
          setDeliveryPrices(apiResponse.data.deliveryPrice);
          setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
          setBackendGst(apiResponse.data.totalGst);
          setNumberOfDeliveryVehicles(
            apiResponse.data.numberOfDeliveryVehicles
          );
          setApplicability(apiResponse.data.vehicle);
          setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
          setLaborPrices(apiResponse.data.priceForLabor);
          setTotalLaborCost(apiResponse.data.totalPriceForLabor);
        } else {
          cantFetchBuyNowItems(apiResponse);
        }
      }
      setUserLocation({
        latitude: buyNowAddress?.lat,
        longitude: buyNowAddress?.lng,
        zoom: 17,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
      });
    }
    getData();
  }, [quantity]);

  async function changeVariationHandler(
    targetVariationKey,
    targetVariationValue
  ) {
    setVariation({
      ...variation,
      [targetVariationKey]: targetVariationValue,
    });
    if (Object.keys(product?.vars)[0] === targetVariationKey) {
      let indexOfQueryVar = product?.vars[targetVariationKey].findIndex(
        (o) => o.attrs[targetVariationKey] === targetVariationValue
      );
      if (indexOfQueryVar > -1) {
        product?.vars[targetVariationKey][indexOfQueryVar]?.price &&
          setProductPrice(
            product?.vars[targetVariationKey][indexOfQueryVar]?.price
          );
        product?.vars[targetVariationKey][indexOfQueryVar]?.discounted_price &&
          setProductDiscountedPrice(
            product?.vars[targetVariationKey][indexOfQueryVar]?.discounted_price
          );
        product?.vars[targetVariationKey][indexOfQueryVar]
          ?.discounted_percent &&
          setProductDiscountedPercent(
            product?.vars[targetVariationKey][indexOfQueryVar]
              ?.discounted_percent
          );
        product?.vars[targetVariationKey][indexOfQueryVar]?.attrs?.imgs &&
          setImage(
            product?.vars[targetVariationKey][indexOfQueryVar]?.attrs?.imgs
          );
      }
    }
    if (query.get([targetVariationKey])) {
      searchParams.set([targetVariationKey], targetVariationValue);
    } else {
      searchParams.append([targetVariationKey], targetVariationValue);
    }
    if (targetVariationKey === Object.keys(variation)[0]) {
      const apiResponse = await api.get(
        `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
        {
          headers: {
            addressId: buyNowAddress._id,
            quantity,
            variationname: targetVariationKey,
            variationvalue: targetVariationValue,
          },
        }
      );
      if (!apiResponse.error) {
        setApplicability(apiResponse.data.vehicle);
        setDeliveryPrices(apiResponse.data.deliveryPrice);
        setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
        setBackendGst(apiResponse.data.totalGst);
        setNumberOfDeliveryVehicles(apiResponse.data.numberOfDeliveryVehicles);
        setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
        setLaborPrices(apiResponse.data.priceForLabor);
        setTotalLaborCost(apiResponse.data.totalPriceForLabor);
      } else {
        cantFetchBuyNowItems(apiResponse);
      }
    }
    setSearchParams(searchParams);
  }

  async function onClickMap(event) {
    let oldLat = userLocation.latitude,
      oldLng = userLocation.longitude;
    setUserLocation({
      ...userLocation,
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      accuracy: "Accuracy: 100% (Item will be delivered at selected location)",
    });

    const index = state.user.addresses.findIndex(
      (x) => x.address === buyNowAddress.address
    );
    const address = {
      _id: buyNowAddress._id,
      name: buyNowAddress?.name,
      address: buyNowAddress?.address,
      mobile: buyNowAddress?.mobile,
      city: buyNowAddress?.city,
      landmark: buyNowAddress?.landmark,
      state: buyNowAddress?.state,
      pincode: buyNowAddress?.pincode,
      addType: buyNowAddress?.addType,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    await api
      .patch("/me/addresses", {
        address,
      })
      .then(async (response) => {
        if (response.data.error) {
          alert.error(response.data.msg);
          return setUserLocation({
            ...userLocation,
            latitude: oldLat,
            longitude: oldLng,
            accuracy:
              "Accuracy: 100% (Item will be delivered at selected location)",
          });
        }
        const apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: Object.keys(variation)[0] || null,
              variationvalue: variation[Object.keys(variation)[0]] || null,
            },
          }
        );
        if (!apiResponse.error) {
          setApplicability(apiResponse.data.vehicle);
          setDeliveryPrices(apiResponse.data.deliveryPrice);
          setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
          setBackendGst(apiResponse.data.totalGst);
          setNumberOfDeliveryVehicles(
            apiResponse.data.numberOfDeliveryVehicles
          );
          setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
          setLaborPrices(apiResponse.data.priceForLabor);
          setTotalLaborCost(apiResponse.data.totalPriceForLabor);
        } else {
          cantFetchBuyNowItems(apiResponse);
        }
      });
    dispatch(updateAddress(address));
  }

  function onClickDeliveryVehicleButton(event, deliveryVehicleNumber) {
    event.preventDefault();
    if (deliveryVehicleNumber < applicability) {
      alert.error("Vehicle is too small for cart items");
    } else {
      setIsOpen(true);
      setSelectedVehicle(deliveryVehicleNumber);
    }
  }

  const hideAlertModal = () => {
    setAlertModal(false);
    setAddressData({
      name: "",
      address: "",
      mobile: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      addType: "",
    });
  };

  const handleAddressTypeChange = (event) => {
    // setAddressData(event.target.value);
    setAddressData((prev) => ({
      ...prev,
      addType: event.target.value,
    }));
  };

  const addAddressHandler = async () => {
    if (
      addressData.name !== "" &&
      addressData.mobile !== "" &&
      addressData.address !== "" &&
      addressData.landmark !== "" &&
      addressData.city !== "" &&
      addressData.state !== "" &&
      addressData.pincode !== "" &&
      addressData.addType !== ""
    ) {
      let localLatitude, localLongitude;
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address:
              addressData.address +
              ", " +
              addressData.landmark +
              ", " +
              addressData.city +
              ", " +
              addressData.state +
              ", " +
              addressData.pincode,
            key: REACT_APP_GOOGLE_MAPS_KEY,
          },
        }
      );
      if (response.data?.results.length > 0) {
        localLatitude = response.data.results[0].geometry.location.lat;
        localLongitude = response.data.results[0].geometry.location.lng;
        setUserLocation({
          longitude: response.data.results[0].geometry.location.lng,
          latitude: response.data.results[0].geometry.location.lat,
          accuracy:
            "Estimated delivery location from address. You can move the pin to change exact delivery location",
          zoom: 15,
        });
      }
      const res = await api.post("/me/addresses", {
        address: {
          address: addressData.address.trim(),
          ...addressData,
          address: addressData.address.trim(),
          _id: undefined,
        },
        lat: localLatitude,
        lng: localLongitude,
      });
      let apiResponse;
      if (res.status === 200) {
        alert.success(res.data.msg);
        apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: res.data.addressId,
              quantity,
              variationname: Object.keys(variation)[0] || null,
              variationvalue: variation[Object.keys(variation)[0]] || null,
            },
          }
        );
        dispatch(
          addAddress(state.user.addresses, {
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            address: addressData.address.trim(),
            floor: 1,
            _id: res.data.addressId,
          })
        );
        setAddressData({
          name: "",
          address: "",
          mobile: "",
          landmark: "",
          city: "",
          state: "",
          pincode: "",
          country: "",
          addType: "Home",
          lat: localLatitude,
          lng: localLongitude,
          floor: 1,
        });
        dispatch(
          setSelectedAdd({
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            address: addressData.address.trim(),
            floor: 1,
            _id: res.data.addressId,
          })
        );
        setBuyNowAddress({
          address: addressData.address.trim(),
          ...addressData,
          lat: localLatitude,
          lng: localLongitude,
          address: addressData.address.trim(),
          floor: 1,
          _id: res.data.addressId,
        });
      } else {
        alert.error(res.data.error);
        apiResponse = await api.get(
          `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
          {
            headers: {
              addressId: buyNowAddress._id,
              quantity,
              variationname: Object.keys(variation)[0] || null,
              variationvalue: variation[Object.keys(variation)[0]] || null,
            },
          }
        );
      }
      if (!apiResponse.error) {
        setDeliveryPrices(apiResponse.data.deliveryPrice);
        setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
        setBackendGst(apiResponse.data.totalGst);
        setNumberOfDeliveryVehicles(apiResponse.data.numberOfDeliveryVehicles);
        setApplicability(apiResponse.data.vehicle);
        setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
        setLaborPrices(apiResponse.data.priceForLabor);
        setTotalLaborCost(apiResponse.data.totalPriceForLabor);
      } else {
        cantFetchBuyNowItems(apiResponse);
      }
    } else {
      alert.error("Fields are empty");
    }
  };

  async function onChangeAddress(address) {
    if (state.user.addresses.findIndex((x) => x._id === address._id) < 0) {
      alert.error("Please add address");
      return;
    }
    dispatch(setSelectedAdd(address));
    setBuyNowAddress(address);
    const addressResponse = await api.patch("/me/addresses/", {
      address: address,
    });
    setBuyNowAddress(address);
    if (address?.lat) {
      setUserLocation({
        latitude: address.lat,
        longitude: address.lng,
        zoom: 14,
        accuracy: `Accuracy: 100% (Item will be delivered at selected location on map)`,
      });
    }
    setAddState(true);
    const apiResponse = await api.get(
      `${API_URL}/orders/buynow?p_id=${query.get("p_id")}`,
      {
        headers: {
          addressId: address._id,
          quantity,
          variationname: Object.keys(variation)[0] || null,
          variationvalue: variation[Object.keys(variation)[0]] || null,
        },
      }
    );
    if (!apiResponse.error) {
      setDeliveryPrices(apiResponse.data.deliveryPrice);
      setDeliveryPricesWithGst(apiResponse.data.deliveryPricesWithGst);
      setBackendGst(apiResponse.data.totalGst);
      setNumberOfDeliveryVehicles(apiResponse.data.numberOfDeliveryVehicles);
      setApplicability(apiResponse.data.vehicle);
      setLoadingUnloadingPrice(apiResponse.data.loadingUnloadingPrice);
      setLaborPrices(apiResponse.data.priceForLabor);
      setTotalLaborCost(apiResponse.data.totalPriceForLabor);
    } else {
      cantFetchBuyNowItems(apiResponse);
    }
  }

  const createOrder = async () => {
    if (selectedVehicle == 0) {
      alert.error("Please select a vehicle");
      return;
    } else {
      setIsCreatingOrder(true);
      setPayment("COD");
      // return;
      if (state.user.addresses.length === 0) {
        alert.error("Add an address before checkout");
        setIsCreatingOrder(false);
        setLoading(false);
        return;
      }
      const res = await api.post(`/orders/buynow`, {
        address: buyNowAddress,
        addressId: buyNowAddress._id,
        phone:
          buyNowAddress.mobile ||
          (state.selectedAddress
            ? state.selectedAddress.mobile
            : state.user.addresses[0].mobile),
        deliveryVehicle: selectedVehicle,
        payment_mode: "COD",
        quantity: quantity,
        p_id: query.get("p_id"),
        variation: variation || {},
        isLaborRequired: isLaborRequired,
        couponCodeId: appliedCoupon?._id || null,
      });
      if (!res.data.error && res.data.order_id) {
        alert.success("Order Placed Successfully");
        setLoading(false);
        setIsCreatingOrder(false);
        setShowThankYou(true);
      } else {
        setShowThankYou(false);
        setIsCreatingOrder(false);
        setLoading(false);
        if (res.data.error) {
          alert.error(res.data.error);
        } else {
          alert.error("An error occoured.");
        }
      }
      setIsCreatingOrder(false);
    }
  };

  const payAndCheckout = async () => {
    if (selectedVehicle == 0) {
      alert.error("Please select a vehicle");
      return;
    }
    setIsCreatingOrder(true);
    setSelectedAddress(state.user.addresses[0]);
    setPayment("online");
    if (state.user.addresses.length === 0) {
      alert.error("Add an address before checkout");
      setIsCreatingOrder(false);
      return;
    }
    const result = await api.post("/orders/buynow", {
      address: buyNowAddress,
      addressId: buyNowAddress._id,
      phone:
        buyNowAddress.mobile ||
        (state.selectedAddress
          ? state.selectedAddress.mobile
          : state.user.addresses[0].mobile),
      deliveryVehicle: selectedVehicle,
      payment_mode: "ONLINE",
      quantity: quantity,
      p_id: query.get("p_id"),
      variation: variation || {},
      isLaborRequired: isLaborRequired,
      couponCodeId: appliedCoupon?._id || null,
    });
    const { payment_url } = result.data;
    window.location.href = payment_url;
  };

  useEffect(() => {
    let totalPriceWithoutGst = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (isLaborRequired) {
      totalPriceWithoutGst =
        productDiscountedPrice * quantity +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice +
        totalLaborCost;
    } else {
      totalPriceWithoutGst =
        productDiscountedPrice * quantity +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice;
    }
    if (appliedCoupon) {
      let appliedDiscount =
        (appliedCoupon?.discountPercentage / 100) * totalPriceWithoutGst;
      if (
        (appliedDiscount > appliedCoupon?.discountMaxValue &&
          appliedCoupon?.discountMaxValue !== 0) ||
        appliedCoupon?.discountPercentage === 0
      ) {
        appliedDiscount = appliedCoupon?.discountMaxValue;
      }
      if (isLaborRequired) {
        gstVal =
          (productDiscountedPrice * quantity +
            totalLaborCost -
            appliedDiscount) *
            gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice +
          totalLaborCost -
          appliedDiscount +
          ((productDiscountedPrice * quantity +
            totalLaborCost -
            appliedDiscount) *
            gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      } else {
        gstVal =
          (productDiscountedPrice * quantity - appliedDiscount) * gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice -
          appliedDiscount +
          ((productDiscountedPrice * quantity - appliedDiscount) * gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      }
      setAppliedCouponDiscount(appliedDiscount);
    } else {
      setAppliedCouponDiscount(0);
      if (isLaborRequired) {
        gstVal =
          (productDiscountedPrice * quantity + totalLaborCost) * gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice +
          totalLaborCost +
          ((productDiscountedPrice * quantity + totalLaborCost) * gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      } else {
        gstVal =
          productDiscountedPrice * quantity * gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice +
          (productDiscountedPrice * quantity * gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      }
    }
    setCalculatedGst(gstVal.toFixed(2));
    setFinalPrice(totalPrice.toFixed(2));
  }, [
    appliedCoupon,
    productDiscountedPrice,
    deliveryPrices,
    selectedVehicle,
    loadingUnloadingPrice,
    quantity,
    totalLaborCost,
  ]);

  const calculateUptoOffForCoupon = (coupon) => {
    let totalPriceWithoutGst = 0;
    let gstVal = 0;
    let totalPrice = 0;

    if (selectedVehicle === 0) {
      return "Select Delivery Vehicle First";
    }

    if (isLaborRequired) {
      totalPriceWithoutGst =
        productDiscountedPrice * quantity +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice +
        totalLaborCost;
    } else {
      totalPriceWithoutGst =
        productDiscountedPrice * quantity +
        deliveryPrices[selectedVehicle - 1] +
        loadingUnloadingPrice;
    }
    if (appliedCoupon) {
      let appliedDiscount =
        (coupon?.discountPercentage / 100) * totalPriceWithoutGst;
      if (
        (appliedDiscount > coupon?.discountMaxValue &&
          coupon?.discountMaxValue !== 0) ||
        coupon?.discountPercentage === 0
      ) {
        appliedDiscount = coupon?.discountMaxValue;
      }
      if (isLaborRequired) {
        gstVal =
          (productDiscountedPrice * quantity +
            totalLaborCost -
            appliedDiscount) *
            gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice +
          totalLaborCost -
          appliedDiscount +
          ((productDiscountedPrice * quantity +
            totalLaborCost -
            appliedDiscount) *
            gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      } else {
        gstVal =
          (productDiscountedPrice * quantity - appliedDiscount) * gstRate +
          backendGst +
          (deliveryPricesWithGst[selectedVehicle - 1] -
            deliveryPrices[selectedVehicle - 1]);
        totalPrice =
          productDiscountedPrice * quantity +
          deliveryPrices[selectedVehicle - 1] +
          loadingUnloadingPrice -
          appliedDiscount +
          ((productDiscountedPrice * quantity - appliedDiscount) * gstRate +
            backendGst +
            (deliveryPricesWithGst[selectedVehicle - 1] -
              deliveryPrices[selectedVehicle - 1]));
      }
      return appliedDiscount.toFixed(2) + " OFF";
    }
  };
  function formatMaxUses(maxUses) {
    if (maxUses === 0) {
      return "No Limitation";
    } else {
      return `${maxUses} times`;
    }
  }

  function formatMaxUses(maxUses) {
    if (maxUses === 0) {
      return "No Limitation";
    } else {
      return `${maxUses} times`;
    }
  }

  function numberWithCommas(x, y) {
    if (y) {
      x = Math.round(x * 1e2) / 1e2;
      // x = x.toFixed(2);
    }
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  const labourCostHandler = () => {
    if (totalLaborCost === 0 && product?.laborPerFloor === 0) {
      setIsLaborRequired(false);
      alert.error("Labour is not applicable ");
      return;
    }

    setIsLaborRequired(!isLaborRequired);
    dispatch(setIsLaborRequiredState(isLaborRequired));
  };

  function LoadingShimmer() {
    return (
      <div className="loading-shimmer">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-line"></div>
          <div className="shimmer-line"></div>
          <div className="shimmer-line"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {showThankYou && (
        <>
          <div className="thankYouLargeContainer">
            <div className="thankYouContainer shadow d-flex flex-column align-items-center justify-content-center p-0">
              <div className="col-12 d-flex align-items-center justify-content-center mb-4 px-3">
                <img src={thankYou} className="thankYouImage" />
              </div>
              <p>If you want to claim GST, please check your email</p>
              <div className="col-12 d-flex align-items-center justify-content-center mt-4 px-3">
                <button
                  className="thankYouButton py-2"
                  onClick={() => {
                    navigate("/");
                    setShowThankYou(false);
                  }}
                >
                  Home Page
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="row align-items-baseline maxWidth100 justify-content-between py-3">
        <div className="col-sm-3 col-6 order-1 py-sm-0 py-2">
          <Link to="/" className="maxWidth100">
            <img
              className="ms-lg-4 ms-2 upperPlypickerLogo maxWidth100"
              src={plypickerLogo}
              alt=""
            />
          </Link>
        </div>
      </div>
      <div className="container threeimage_container pt-2 mb-5 pb-5 px-2 px-lg-0">
        <div className="d-flex flex-lg-row flex-column">
          <div className="col-lg-8 col-12 overflow-y-auto">
            {state?.user?.addresses.length > 0 ? (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData">
                    <h2>Deliver to</h2>
                    <span>{<>{buyNowAddress.addType}</>}</span>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#checkout"
                    onClick={setAddress}
                    className="changeAddressButton"
                  >
                    Change
                  </div>
                </div>
                <p>
                  <b className="checkoutAddressName">{buyNowAddress.name}</b>,{" "}
                  {buyNowAddress.address}, {buyNowAddress.landmark},{" "}
                  {buyNowAddress.city}, {buyNowAddress.state} -
                  {buyNowAddress.pincode}
                </p>
                <p className="m-0 checkoutAddressName">
                  {buyNowAddress.mobile}
                </p>
              </div>
            ) : (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData" style={{ flex: "1" }}>
                    <h2 className="m-0">No Address Added Yet</h2>
                  </div>
                  <div
                    onClick={() => showAlertModal()}
                    className="changeAddressButton hoverAddAddress px-2 py-1"
                  >
                    Add Address
                  </div>
                </div>
              </div>
            )}
            {loading && (
              <>
                <div>
                  <h4 className="text-center">{LoadingShimmer()}</h4>
                </div>
              </>
            )}
            {!loading && (
              <>
                <div className="">
                  <div className="borderedContainer px-2 py-2 d-flex flex-row flex-wrap">
                    <div className="w-100 col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row flex-wrap align-items-center justify-content-start col-sm-8 col-12">
                        <Link to={`/product/${product?._id}`}>
                          <img src={image} alt="" className="cartImage" />
                        </Link>
                        <div className="d-flex flex-column ps-2 flex1Inner">
                          <p className="mb-0">
                            <Link
                              className="checkoutProductLink"
                              to={`/product/${product?._id}`}
                            >
                              {product?.product_name}
                            </Link>
                          </p>
                          <p className="cartBrand m-0 subtext">
                            {product?.brand?.Brand_name}
                          </p>
                          {
                            <>
                              {Object.entries(variation).map(([key, value]) => (
                                <div className="subtext">
                                  {key}:{" "}
                                  <select
                                    className="subtext"
                                    style={{
                                      cursor: "pointer",
                                      border: "none",
                                    }}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      changeVariationHandler(
                                        key,
                                        event.target.value
                                      );
                                    }}
                                  >
                                    <option className="subtext" value={value}>
                                      {value}
                                    </option>
                                    {product?.vars[key].map(
                                      (singleVariation) => {
                                        return (
                                          singleVariation.attrs[key] !==
                                            value && (
                                            <>
                                              <option
                                                className="subtext"
                                                value={
                                                  singleVariation.attrs[key]
                                                }
                                              >
                                                {singleVariation.attrs[key]}
                                              </option>
                                            </>
                                          )
                                        );
                                      }
                                    )}
                                  </select>
                                </div>
                              ))}
                            </>
                          }
                          <p className="m-0 p-0">
                            <span className="m-0 p-0 productDiscountedPrice text">
                              Rs. {productDiscountedPrice}{" "}
                            </span>
                            <span className="m-0 p-0 productPrice subtext">
                              Rs. {productPrice}{" "}
                            </span>
                            <span className="m-0 p-0 productDiscountedPercent subtext">
                              -{productDiscountedPercent}%
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="align-self-start col-sm-4 col-12">
                        {product?.loadingUnloadingPrice ? (
                          <>
                            {product?.loadingUnloadingPrice > 0 ? (
                              <p className="subtext m-0 p-0 text-sm-end text-start">
                                LOADING UNLOADING APPLICABLE
                              </p>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {isLaborRequired && laborPrices[0] > 0 ? (
                          <>
                            <p className="subtext m-0 p-0 text-sm-end text-start">
                              LABOR APPLICABLE
                            </p>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="w-100 col-12 subtext">
                      <div>
                        Quantity:{" "}
                        <span className="productCounterCenter">
                          <input
                            className="counterValue cartCounterValueInput"
                            type="number"
                            min={0}
                            value={quantity}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.value < 0 || isNaN(e.target.value)) {
                              } else {
                                setQuantity(e.target.value);
                              }
                            }}
                          />
                          <button
                            className={
                              quantity === 1
                                ? "counterButtonDisable"
                                : "counterButton"
                            }
                            onClick={() => {
                              setQuantity(quantity - 1);
                            }}
                            disabled={quantity === 1 ? true : false}
                          >
                            -
                          </button>
                          <button
                            className="counterButton"
                            onClick={() => {
                              // setApiRe(false)
                              setQuantity(quantity + 1);
                            }}
                          >
                            +
                          </button>
                        </span>
                      </div>
                      <div>
                        <p className="m-0p-0">
                          Delivery by {deliveryDate.getDate()}{" "}
                          {deliveryDate.toLocaleString("default", {
                            month: "long",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <h2 className="choosePaymentOptionHeading">
                    CHOOSE PAYMENT OPTION
                  </h2>
                  <button
                    className="paymentOption px-md-4 py-md-3 px-2 py-2"
                    style={{ textAlign: "start" }}
                    onClick={(e) => setPaymentMethod("online")}
                    disabled={commonPaymentModes === "COD"}
                  >
                    <input
                      type="radio"
                      id="ONLINE"
                      checked={paymentMethod === "online"}
                      value="online"
                      className="paymentRadioInputButton me-1"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      disabled={commonPaymentModes === "COD"}
                    />
                    <label
                      className="ms-sm-0 ms-3 paymentOptionOption"
                      htmlFor="ONLINE"
                    >
                      Online Payment
                    </label>
                  </button>
                  <button
                    disabled={commonPaymentModes === "online"}
                    className="paymentOption px-md-4 py-md-3 px-2 py-2"
                    onClick={(e) => setPaymentMethod("COD")}
                    style={{ textAlign: "start" }}
                  >
                    <input
                      type="radio"
                      id="COD"
                      checked={paymentMethod === "COD"}
                      value="COD"
                      className="paymentRadioInputButton me-1"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      disabled={commonPaymentModes === "online"}
                    />
                    <label
                      className="ms-sm-0 ms-3 paymentOptionOption"
                      htmlFor="COD"
                    >
                      Cash on Delivery (UPI/Cash/Cards accepted)
                    </label>
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-4">
            <div className="w-100">
              <div className="renderside_cartside_total ms-lg-3 ms-0">
                {/* <button
                  type="button"
                  className="addUpdateButton px-2 py-1 mt-1 mb-2"
                  style={{ fontSize: "var(--desktop-text)" }}
                  onClick={(e) => {
                    setIsMapEditable(!isMapEditable);
                  }}
                >
                  {isMapEditable
                    ? "Confirm this location"
                    : "Edit location manually"}
                </button> */}
                {/* <LoadScript googleMapsApiKey={REACT_APP_GOOGLE_MAPS_KEY}>
                  <GoogleMap
                    onClick={(e) =>
                      isMapEditable
                        ? onClickMap(e)
                        : alert.error(
                            "Click edit button to change location manually"
                          )
                    }
                    clickableIcons={false}
                    zoom={userLocation.zoom}
                    center={{
                      lat: userLocation.latitude || 18.5204,
                      lng: userLocation?.longitude || 73.8567,
                    }}
                    mapContainerClassName="map-container"
                  >
                    {userLocation?.latitude && (
                      <Marker
                        position={{
                          lat: userLocation?.latitude || 18.5204,
                          lng: userLocation?.longitude || 73.8567,
                        }}
                      ></Marker>
                    )}
                  </GoogleMap>
                </LoadScript> */}
                <p className="p-0 m-0 mt-3 cartSidebarSubheadings">
                  CHOOSE TRANSPORT
                </p>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-0">
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Bike x {numberOfDeliveryVehicles[0]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      3 Wheeler x {numberOfDeliveryVehicles[1]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Tempo x {numberOfDeliveryVehicles[2]}
                    </p>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center">
                    <p className="p-0 mx-auto my-0 text-center row subtext">
                      Pickup x {numberOfDeliveryVehicles[3]}
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-1">
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[0] === 0
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 1
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 1);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Bike} />
                        {deliveryPrices[0] === 0 && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[0]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[1] === 0
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 2
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 2);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Auto} />
                        {deliveryPrices[1] === 0 && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[1]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[2] === 0
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 3
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 3);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Pickup} />
                        {deliveryPrices[2] === 0 && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[2]}
                      </p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="px-1">
                      <button
                        style={
                          deliveryPrices[3] === 0
                            ? { border: "2px solid #FF3A44" }
                            : {}
                        }
                        className={
                          selectedVehicle === 4
                            ? "deliveryVehicleButton deliveryVehicleButtonActive position-relative p-1"
                            : "deliveryVehicleButton p-1 position-relative"
                        }
                        onClick={(e) => {
                          onClickDeliveryVehicleButton(e, 4);
                        }}
                      >
                        <img className="deliveryVehicleImg" src={Truck} />
                        {deliveryPrices[3] === 0 && (
                          <p
                            className="m-0 p-0 deliveryNotApplicable"
                            style={{
                              fontSize: "9px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%,-50%)",
                            }}
                          >
                            Not applicable
                          </p>
                        )}
                      </button>
                      <p className="p-0 mx-auto my-auto text-center subtext">
                        Rs. {deliveryPrices[3]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row flex-row flex-wrap align-items-center gx-1 mt-1">
                  <div className="col-8">
                    <p className="p-0 m-0 cartside_total_text">
                      Include labor?
                    </p>
                    <p className="p-0 m-0 cartSubtext">
                      Extra help for loading and unloading
                    </p>
                  </div>
                  <div className="col-2">
                    <Switch
                      checked={isLaborRequired}
                      onChange={labourCostHandler}
                    />
                  </div>
                </div>
                {isLaborRequired && (
                  <>
                    <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-between gx-1 mt-3">
                      <div className="col-12 text-end cartSubtext">
                        Floor * Labor Price (per floor) * Quantity = Labor cost
                      </div>
                      <input
                        type="number"
                        value={parseInt(buyNowAddress.floor)}
                        onChange={(e) => {
                          onChangeAddress({
                            ...buyNowAddress,
                            floor: parseInt(e.target.value),
                          });
                        }}
                        className="w-100 floorInputBox px-2 py-1"
                        placeholder="Select number of floors"
                      />
                    </div>
                    <div className="d-flex flex-row flex-row flex-wrap align-items-center justify-content-center gx-1 mt-3">
                      <div className="col-6">
                        <div className="">
                          <p className="p-0 m-0 cartLaborProduct">
                            <Link
                              className="checkoutProductLink"
                              to={`/product/${product?._id}`}
                            >
                              {product?.product_name}
                            </Link>
                          </p>
                          {variation &&
                            Object.entries(variation).map(
                              ([variationKey, variationValue]) => (
                                <>
                                  <p className="m-0 p-0 subtext cartLaborVariation">
                                    {variationKey}: {variationValue}
                                  </p>
                                </>
                              )
                            )}
                        </div>
                      </div>
                      <div className="col-6 text-end align-self-start subtext">
                        {buyNowAddress.floor} * Rs.{" "}
                        {product?.vars
                          ? product.vars[Object.keys(product.vars)[0]].filter(
                              (x) =>
                                x.attrs[Object.keys(product.vars)[0]] ===
                                variation[Object.keys(product.vars)[0]]
                            )[0].laborPerFloor
                          : product?.laborPerFloor}{" "}
                        * {quantity} = Rs. {laborPrices[0]}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="coupon_side                 ms-lg-3 ma-0 d-flex justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#couponModal"
              >
                VIEW COUPONS
              </div>
              <div className="renderside_cartside_total ms-lg-3 ms-0 mt-2">
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Total MRP (1 item)</div>
                  <div
                    className="cartside_total_amount"
                    style={{ textDecoration: "line-through" }}
                  >
                    {isNaN(productPrice * quantity) ? (
                      <>Loading...</>
                    ) : (
                      <>Rs. {numberWithCommas(productPrice * quantity, 2)}</>
                    )}
                  </div>
                </div>
                <div className="cartside_total_discount">
                  <div className="cartside_total_text">Total Selling Price</div>
                  <div className="cartside_total_amount">
                    {isNaN(productDiscountedPrice * quantity) ? (
                      <>Loading...</>
                    ) : (
                      <>
                        Rs.{" "}
                        {numberWithCommas(productDiscountedPrice * quantity, 2)}
                      </>
                    )}
                  </div>
                </div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text">Delivery</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1 ? (
                      "Select a delivery vehicle"
                    ) : (
                      <>
                        Rs.{" "}
                        {numberWithCommas(
                          deliveryPrices[selectedVehicle - 1],
                          2
                        )}
                      </>
                    )}
                  </div>
                </div>
                {loadingUnloadingPrice > 0 && (
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">Loading/Unloading</div>
                    <div className="cartside_total_amount">
                      Rs. {loadingUnloadingPrice}
                    </div>
                  </div>
                )}
                <div className="cartside_total_total">
                  <div className="cartside_total_text">
                    Labor{" "}
                    {isLaborRequired ? (
                      <>({buyNowAddress.floor} floors)</>
                    ) : (
                      <>(Not opted)</>
                    )}
                  </div>
                  <div className="cartside_total_amount">
                    {isLaborRequired ? <>Rs. {totalLaborCost}</> : "Rs. 0"}
                  </div>
                </div>
                <div className="cartside_total_total">
                  {appliedCoupon && (
                    <div className="cartside_total_text">
                      Coupon ({appliedCoupon?.codeName})
                    </div>
                  )}
                  <div className="cartside_total_total">
                    {appliedCoupon && (
                      <div className="cartside_total_amount">
                        {selectedVehicle < 1 ? (
                          "Select a delivery vehicle"
                        ) : (
                          <>- Rs. {appliedCouponDiscount}</>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cartside_total_total">
                  <div className="cartside_total_text mb-4">GST (18%)</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1
                      ? "Select a delivery vehicle"
                      : `Rs. ${calculatedGst}`}
                  </div>
                </div>
                <div className="cartside_total_subtotal">
                  <div className="cartside_total_text">Total amount</div>
                  <div className="cartside_total_amount">
                    {selectedVehicle < 1 ? (
                      <>Select a delivery vehicle</>
                    ) : (
                      <>Rs. {finalPrice}</>
                    )}
                  </div>
                </div>
                <button
                  className="cartside_total_checkout"
                  onClick={() => {
                    if (paymentMethod === "COD") {
                      createOrder();
                    } else {
                      payAndCheckout();
                    }
                  }}
                  disabled={loading}
                >
                  {loading || isCreatingOrder ? (
                    <>
                      <CircularProgress
                        style={{ color: "white" }}
                        size="30px"
                      />
                    </>
                  ) : (
                    "BUY NOW"
                  )}
                </button>
                <span className="d-flex align-items-center">
                  <BsAsterisk
                    style={{
                      marginRight: "5px",
                      fontSize: "11px",
                      fill: "#FF7253",
                    }}
                  />
                  <span
                    type="button"
                    className="loginPopupForgotPasswordButton"
                    onClick={() => {
                      navigate("/cancellation-refund-policy");
                    }}
                  >
                    Cancellation/Refund Policy
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* vehicle-popup */}
      <FModal
        visible={isOpen}
        width={!isMobile ? "545" : "360"}
        height={!isMobile ? "580" : "500"}
        effect="fadeInUp"
        onClickAway={() => {
          setIsOpen(false);
        }}
      >
        <div className="login-box">
          <div className="deliveryItemBox">
            {selectedVehicle === 1 ? (
              <TwoWheelerDelivered />
            ) : selectedVehicle === 2 ? (
              <ThreeWheelerDelivered />
            ) : selectedVehicle === 3 ? (
              <FourWheelerDelivered />
            ) : (
              <PickUpTruckDelivered />
            )}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "15px",
                fontSize: "20px",
              }}
            >
              <span style={{ color: "#7A7585", fontWeight: "400" }}>
                Capacity
              </span>
              <span>
                {selectedVehicle === 1
                  ? "25 kg"
                  : selectedVehicle === 2
                  ? "510 kg"
                  : selectedVehicle === 3
                  ? "1000 kg"
                  : "1500 kg"}
              </span>
            </div>
            <hr style={{ color: "rgb(208 203 203)" }} />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: "15px",
                fontSize: "20px",
              }}
            >
              <span style={{ color: "#7A7585", fontWeight: "400" }}>Size</span>
              <span>
                {selectedVehicle === 1
                  ? "40 cm x 40cm x40cm"
                  : selectedVehicle === 2
                  ? "5.5ft x 4.5ft x 5ft"
                  : selectedVehicle === 3
                  ? "7ft x 4.8ft x 4.8ft"
                  : "8ft x 4.8ft x 4.8ft"}
              </span>
            </div>
            <hr style={{ color: "rgb(208 203 203)" }} />
            <div
              style={{
                fontWeight: "400",
                fontSize: !isMobile ? "18px" : "12px",
                overflowY: "scroll",
                maxHeight: "200px",
              }}
            >
              {selectedVehicle === 1 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : selectedVehicle === 2 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : selectedVehicle === 3 ? (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              ) : (
                <>
                  <p>• Fare includes free Waiting time of 20 mins</p>
                  <p>• Extra time would be charged per minute</p>
                  <p>• Fare may change if location changes or route</p>
                  <p>
                    • Fare doesn't include parking/toll charges it should be
                    paid by customer on the spot
                  </p>
                  <p>• Overloading isn't allowed</p>
                  <p>
                    • Please provide OTP only after checking for originality,
                    damage of the product to be eligible for return.
                  </p>
                </>
              )}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              style={{
                padding: "10px",
                backgroundColor: "#FF7703",
                border: "1px solid #FF7703",
                color: "#ffffff",
              }}
              onClick={() => setIsOpen(false)}
            >
              Continue
            </button>
          </div>
        </div>
      </FModal>
      <div
        className="modal fade"
        id="checkout"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content pt-3 pb-2 change_delivery_address">
            <div
              className={addState ? `modal-header` : ``}
              style={
                !addState
                  ? {
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "15px",
                    }
                  : { border: "none", width: "95%" }
              }
            >
              <h5
                className="modal-title delivery_address_heading"
                style={!addState ? { fontSize: "21px" } : {}}
                id="deleteAddress"
              >
                {!addState
                  ? "SELECT DELIVERY ADDRESS"
                  : // "Address Details"}
                    "CONFIRM ADDRESS"}
              </h5>
              {!addState ? (
                <div
                  style={{ width: "35%", height: "36px" }}
                  onClick={() => showAlertModal()}
                  className="changeAddressButton hoverAddAddress px-2 py-1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Add address
                </div>
              ) : (
                ""
              )}
              <button
                type="button"
                className="close"
                style={{ color: "#000000" }}
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddState(false);
                  setSelectBuyCall("");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <br />
              <br />
              <br />
            </div>

            {/* <div className="modal-body"> */}
            <div className="checkoutModalBody" style={{ width: "100%" }}>
              {addState ? (
                <>
                  <div className="profile_form_content_inside_data">
                    <div className="select_address w-100 ms-2">
                      <div className="select_address_mid">
                        <div className="inside_address_mid">
                          <span className="address_name_mid">
                            {buyNowAddress.name}
                          </span>
                          <span className="address_type_mid">
                            {buyNowAddress.addType}
                          </span>
                        </div>
                        <span className="address_add_mid">
                          {buyNowAddress?.address}, {buyNowAddress?.landmark},{" "}
                          {buyNowAddress?.city}, {buyNowAddress?.state}-
                          {buyNowAddress?.pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="orderButtons">
                    <button
                      type="button"
                      className="CloseaddUpdateButton"
                      onClick={() => {
                        setAddState(false);
                        setSelectBuyCall("");
                      }}
                    >
                      Return
                    </button>
                    <button
                      type="button"
                      className="addUpdateButton"
                      data-dismiss="modal"
                      onClick={() => {
                        setAddState(false);
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {state.user.addresses?.map((address, i) => (
                    <div
                      className="select_address"
                      onClick={() => {
                        onChangeAddress(address);
                      }}
                    >
                      <div className="profile_form_content_inside_data">
                        <div className="select_address_left">
                          <IoRadioButtonOffSharp />
                        </div>
                        <div className="select_address_mid">
                          <div className="inside_address_mid">
                            <span className="address_name_mid">
                              {address.name}
                            </span>
                            <span className="address_type_mid">
                              {address.addType}
                            </span>
                          </div>
                          <span className="address_add_mid">
                            {address?.address}, {address?.landmark},{" "}
                            {address?.city}, {address?.state}-{address?.pincode}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  {/* <div
                                        className="checkAddress"
                                        style={{ padding: "5px", borderTop: "1px solid #878787" }}
                                    > */}
                  {/* <h6>Use pincode</h6>
                      <span>
                        <input disabled={true} placeholder="Enter pincode" />
                        <button disabled={true}>Check</button>
                      </span> */}
                  {/* </div>
                                    <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>We are currently delivering only in Pune, India.</p> */}
                </>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="couponModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                AVAILABLE COUPONS
              </h4>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="search-bar d-flex ">
                <input
                  className="search-input"
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    textDecoration: "none",
                  }}
                  type="text"
                  placeholder="Enter the Coupon Code"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  onKeyDown={handleBackspace}
                />
                <div style={{ padding: "5px" }}>
                  <NewSearchIcon />
                </div>
              </div>

              <div className="coupon-list">
                {recommendedCoupons?.map((coupon, index) => (
                  <div className="coupon-item" key={index}>
                    <div className="coupon-item-details">
                      <div className="coupon-details">
                        <h6 className="coupon-title">{coupon?.codeName}</h6>
                        <p className="coupon-description">
                          {calculateUptoOffForCoupon(coupon)}
                        </p>
                      </div>
                      <div className="coupon-details">
                        <p className="coupon-description">
                          {coupon?.codePublicDescription}
                        </p>
                      </div>
                      <button
                        data-dismiss="modal"
                        aria-label="Close"
                        className="coupon-apply-button"
                        style={{ color: "black" }}
                        onClick={() => applyCoupon(coupon)}
                      >
                        {appliedCoupon?._id === coupon?._id
                          ? "Applied"
                          : "Apply"}
                      </button>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal && (
        <Modal
          show={alertModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="alertModalName"
          onHide={hideAlertModal}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title id="contained-modal-title-vcenter">
                          Review
                      </Modal.Title> */}
            <h1 className="alertModalh1 m-0">Add Address</h1>
          </Modal.Header>
          <Modal.Body className="pb-0">
            {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
            <label className="inputLabels" id="NameId">
              Name
            </label>
            <input
              id="NameId"
              type="text"
              value={addressData.name}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="MobileId">
              Mobile
            </label>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <span className="addressMobileNumberInputPrefix">+91</span>
              <span className="addressMobileNumberInputWrapper">
                <input
                  id="MobileId"
                  type="text"
                  value={addressData.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                      setAddressData((prev) => ({
                        ...prev,
                        mobile: e.target.value,
                      }));
                    }
                  }}
                />
              </span>
            </div>
            <label className="inputLabels" id="AddressId">
              Address( House No., Building, Street, Area )
            </label>
            <input
              id="AddressId"
              value={addressData.address}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="LandmarkId">
              Landmark
            </label>
            <input
              id="LandmarkId"
              type="text"
              value={addressData.landmark}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  landmark: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="CityId">
              City
            </label>
            <input
              id="CityId"
              type="text"
              value={addressData.city}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
              }}
            />
            <div className="InsideModalSpace">
              <span>
                <label className="inputLabels" id="StateId">
                  State
                </label>
                <input
                  id="StateId"
                  type="text"
                  value={addressData.state}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }));
                  }}
                />
              </span>
              <span>
                <label className="inputLabels" id="PincodeId">
                  Pincode
                </label>
                <input
                  id="PincodeId"
                  type="text"
                  value={addressData.pincode}
                  onChange={handlePincodeChange}
                />
              </span>
            </div>
            <div className="address_type">
              <span>Type of Address</span>
              <br />
              <FormControl>
                <RadioGroup
                  row={true}
                  className="AddressRadio ms-0"
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={addressData.addType}
                  onChange={handleAddressTypeChange}
                >
                  <FormControlLabel
                    value="Home"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    value="Site"
                    control={<Radio />}
                    label="Site"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {/* )} */}
          </Modal.Body>
          <div className="AdressModalSubmit pb-2">
            <button
              className="CloseaddUpdateButton"
              onClick={() => hideAlertModal()}
            >
              Discard
            </button>
            <button
              className="addUpdateButton"
              onClick={() => {
                addAddressHandler();
                hideAlertModal();
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}

export default BuyNow;
