import { createRef, useEffect, useState } from "react";
import { useStore } from "../../../../store/store";
import useAxios from "../../../../utils/useAxios";
import { useParams } from "react-router-dom";
import LoginRegisterPopup from "../../../global_component/LoginRegisterPopup/LoginRegisterPopup";
import { PDFExport } from "@progress/kendo-react-pdf";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ServiceTC() {
  const [state, dispatch] = useStore();
  const api = useAxios();
  const { aid } = useParams();
  const ref = createRef();
  const [service, setService] = useState({});
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);

  ReactGA.send({
    hitType: 'pageview',
    page: `/service/terms-and-condition/:${aid}`,
    title : 'Service TC'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useState(() => {
    async function getInvoice() {
      if (!state.authenticated || !state.accessToken) {
        setIsLoginPopupVisible(true);
      } else {
        const res = await api.get(`/servicesorder/${aid}`);
        if (res.data.err) {
          setIsError(true);
          setError(res.data.msg);
        } else {
          setService(res.data);
        }
      }
    }
    getInvoice();
  }, []);

  useEffect(() => {
    async function getInvoice() {
      if (!isLoginPopupVisible && !state.accessToken) {
        setIsError(true);
        setError("Sign in to view this Agreement")
      } else {
        const res = await api.get(`/servicesorder/${aid}`);
        if (res.data.err) {
          setIsError(true);
          setError(res.data.msg);
        } else {
          setService(res.data);
        }
      }
    }
    getInvoice();
  }, [isLoginPopupVisible])

  const getDate = (string) => {
    let date = new Date(string);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return (dt + '-' + month + '-' + year);
  };

  const getTime = (string) =>{
    var time = new Date(string);
    return (time.getHours() + ':' + time.getMinutes() + ":" + time.getSeconds());
  }

  return (<>
    <LoginRegisterPopup
      isVisible={isLoginPopupVisible}
      setIsVisible={setIsLoginPopupVisible}
    />
    <>{isError && <><p className="mx-auto mt-3 text-center" style={{ fontWeight: "bold" }}>{error}</p></>}</>
    {!isError && <>
      <div className="mx-auto" style={{ maxWidth: "1000px" }}>
        <button className="changeAddressButton mt-3" style={{ marginLeft: "10%" }} onClick={() => {
                    if (ref.current) {
                        ref.current.save();
                    }
                }}>Download Agreement as PDF</button>
      </div>
    </>}
    <PDFExport paperSize="A3" margin="0cm" author="Plypicker" creator="Plypicker" fileName="Agreement.pdf" scale={1} ref={ref}>
    {service?.data && <div className="TC container">
      <br />
      <p>
        {`This Agreement (the "Agreement") is made and entered into on ${getDate(service?.data?.createdAt)} by and between ${state?.user?.name} (the "Customer") and Plypicker (the "Service Provider"), collectively referred to as the "Parties".`}
      </p>
      <p>
        1.	Booking and Refunds: The Customer shall be entitled to a refund of the booking amount subject to the following conditions: (a) If the Customer cancels the work before 72 hours of the scheduled commencement, they shall be entitled to a 100% refund of the booking amount. (b) If the Customer cancels the work before 48 hours of the scheduled commencement, they shall be entitled to a 50% refund of the booking amount. (c) If the Customer cancels the work 24 hours before or less, they are not eligible for any refund.
      </p>
      <p>
        2.	Modification: The Customer may modify the date and time schedule before 72 hours of the scheduled commencement. Any modifications made thereafter shall render the booking non-refundable. Once the Customer has successfully modified the booking, they are not eligible for cancellation or refund.
      </p>
      <p>
        3.	Change Orders: Once the work and design have been approved and finalized by the Customer, any change requested shall be charged extra.
      </p>
      <p>4.	Commencement and Pause: Once the work have commenced, it cannot be paused. In such a case, the Customer shall not be eligible for a refund. To resume the work, the Customer shall have to place a new order.</p>
      <p>5.	Minor Changes: Genuine minor changes shall be allowed after the completion of the work. Our team shall agree that the extra work requested by the Customer is genuinely minor. In case our team finds that it is not minor, completing the extra task shall be charged extra.</p>
      <p>6.	Inspection and Complaints: The Customer shall inspect the work daily or alternatively. If they find any poor work or quality issues, or have any complaints, they shall email us at <a href="mailto:info@plypicker.com">info@plypicker.com</a> within 24 hours. After 24 hours, if the complaint has been raised, it shall not be our responsibility.</p>
      <p>7.	Booking Amount: The booking amount is fixed and non-negotiable.</p>
      <p>8.	Payment: The Customer shall pay a minimum of 30% to complete Phase 1 of the work. If the Customer chooses to pay less than 30% while booking the order, they shall have to pay the rest of the percentage amount within 3 days after the commencement of the work.</p>
      <p>9.	Delay in Payment: If the Customer fails to make the payments on due dates, we shall not be responsible for any delay in the work. It shall be adjusted according to the availability of the workers and laborers.</p>
      <p>10.	Responsibility for Material: If the material is provided by us, it is our responsibility if anything wrong happens under the warranty period, excluding physical damage. However, if the material for the work is provided by the Customer, it is their full responsibility. We shall not be responsible for the quality of work or any mishappening. It is recommended to provide good quality material for the work.</p>
      <p>11.	Responsibility for Material Handling: We shall not be responsible for any damage or mishappening of the material while handling it to the construction site if the Customer has not opted for loading and unloading feature while booking the order.</p>
      <p>12.	Permission: The Customer shall have valid permission for the construction work at their society. They shall mail the permission proof at <a href="mailto:info@plypicker.com">info@plypicker.com</a> with their order ID and schedule date of work. After verifying all the details, we shall start the work on time.</p>
      <p>13.	Electricity and Water Supply: The Customer shall provide electricity supply, drinking water, and domestic use water supply sponsored by them. Any failure in doing this might lead to a delay in work.</p>
      <p>14.	Dumping Area: The customer shall provide a dumping area for us to dispose of all the waste generated during the construction process. We shall not be responsible for disposing of any waste materials from your home compound or society.</p>
      <p>15.	We shall undertake cleaning of the premises after the completion of the work. However, if you require deep cleaning services, it shall be charged extra as per the prevailing market rate.</p>
      <p>16.	Any dispute arising out of this agreement shall be settled through arbitration, and the decision of the arbitrator shall be final and binding on both parties.</p>
      <p>17.	This agreement shall be governed and construed in accordance with the laws of [insert the name of the state or country where your business is registered], and any legal action arising out of this agreement shall be instituted in the courts of [insert the name of the state or country where your business is registered].
      </p>
      <p>18.	This agreement constitutes the entire agreement between you and us and supersedes all prior negotiations, understandings, and agreements between the parties, whether written or oral, relating to the subject matter of this agreement.
      </p>
      <p>19.	You acknowledge that you have read this agreement, understood its terms and conditions, and agree to be bound by them. You further warrant that you have the authority to enter into this agreement.
      </p>
      <p>20.	Any breach of this agreement by you shall entitle us to terminate this agreement, cancel the work, and retain any payments made by you as liquidated damages. We shall also have the right to take any legal action against you for any damages caused to us due to such breach
      </p>
      <br />
      <p>IN WITNESS WHEREOF, the parties hereto have executed this agreement as of the date and year first above written.</p>
      <p>{state?.user?.name}</p>
      <p>I agree to all the terms and conditions mentioned in this agreement and confirm that I have read, understood, and signed this agreement voluntarily and without any undue influence.</p>
      <p>Date: {getDate(service?.data?.createdAt)} Time: {getTime(service?.data?.createdAt)}</p>
    </div>}
    </PDFExport>  
  </>
  )
}

export default ServiceTC