import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Modal from "react-bootstrap/Modal";
import { BsArrowLeft } from "react-icons/bs";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import {
  REACT_APP_GOOGLE_MAPS_KEY,
  RZR_PAY_KEY_ID,
  REACT_APP_GA_MEASUREMENT_ID,
} from "../../../../../constants";
import { ReactComponent as NewSearchIcon } from "../../../../global_component/Navbar/NavbarUpper/NewSearchIcon.svg";
import {
  addAddress,
  setSelectedAdd,
  setServicesCartData,
  setSelectedCoupon,
  updateSelectedCity,
  updateSelectedCityName,
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import ServiceCounter from "../../../../global_component/ServiceCounter/ServiceCounter.js";
import "../Cart/Cart.css";
import bin from "./bin.png";
import "./ServicesCart.css";
import { BsAsterisk } from "react-icons/bs";
import ModalTC from "react-awesome-modal";
import { AiOutlineClose } from "react-icons/ai";
import CityModal from "../../../../global_component/Modal/cityModal";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const RZR_PAY_ID = RZR_PAY_KEY_ID;
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function ServicesCart() {
  const alert = useAlert();
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [backendMrp, setBackendMrp] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(true);
  const [backendDiscountedPrice, setBackendDiscountedPrice] = useState(0);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [backendDiscount, setBackendDiscount] = useState(
    backendMrp - backendDiscountedPrice
  );
  const [advancePaymentPercentage, setAdvancePaymentPercentage] =
    useState("50");
  const [datePicker, setDatePicker] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
  );
  const [timePicker, setTimePicker] = useState(new Date());
  const [backendGst, setBackendGst] = useState(0);
  const [alertModal, setAlertModal] = useState(false);
  const [state, dispatch] = useStore();
  const [address, setAddress] = useState({});
  const api = useAxios();
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [recommendedCoupons, setRecommendedCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(
    state?.appliedCoupon || null
  );
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState(0);
  const [calGst, setCalGst] = useState(0);
  const [calTotal, setCalTotal] = useState(0);
  const [showModal, setShowModal] = useState(state.selectedCity ? false : true);
  const [defaultCity, setDefaultCity] = useState(false);
  const [addState, setAddState] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [cartAddress, setCartAddress] = useState(state?.selectedAddress);

  const [addressData, setAddressData] = useState({
    name: "",
    address: "",
    mobile: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    addType: "",
  });

  ReactGA.send({
    hitType: "pageview",
    page: "/services/cart",
    title: "Service Cart",
  });

  function cantFetchCartItems(res) {
    alert.error(res.data.error);
    setCart([]);
    setBackendMrp(0);
    setBackendDiscountedPrice(0);
    setBackendDiscount(0);
    setBackendGst(0);
    setCalGst(0);
    setCalTotal(0);
  }

  const closeModalHandler = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (addressData.pincode.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${addressData.pincode}`)
        .then((res) => res.json())
        .then((data) => {
          if (data && data[0] && data[0].Status === "Success") {
            const postOfficeData = data[0].PostOffice[0];
            setAddressData((prev) => ({
              ...prev,
              city: postOfficeData.District,
              state: postOfficeData.State,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAddressData((prev) => ({
        ...prev,
        city: "",
        state: "",
      }));
    }
  }, [addressData?.pincode]);

  const handleChange = (e) => {
    const pincode = e.target.value;
    if (pincode.length <= 6 && !isNaN(pincode)) {
      setAddressData((prev) => ({
        ...prev,
        pincode: pincode,
      }));
    }
  };

  async function updateServicesCartData(
    showShimmer = false,
    fetchCoupons = true
  ) {
    window.scroll(0, 0);
    let isAddressFound = false;
    showShimmer && setLoading(true);
    if (state.selectedCity && state.selectedCityName && state.selectedAddress) {
      setAlertModal(false);
    } else {
      for (const singleAddress of state?.user?.addresses) {
        if (singleAddress.cityid === state.selectedCity) {
          dispatch(setSelectedAdd({ ...singleAddress }));
          isAddressFound = true;
          break;
        }
      }
      if (!isAddressFound) {
        dispatch(setSelectedAdd(null));
        setAlertModal(true);
        return showShimmer && setLoading(true);
      }
    }
    if (state.selectedAddress) {
      const res = await api.get("/servicescart", {
        headers: {
          cityId: state?.selectedCity,
        },
      });
      if (res.data.error) {
        cantFetchCartItems(res);
      } else {
        dispatch(setServicesCartData(res.data.cartItems));
        res.data.cartItems ? setCart(res.data.cartItems) : setCart([]);
        setBackendMrp(res.data.mrpTotal);
        setBackendDiscountedPrice(res.data.total);
        setBackendDiscount(backendMrp - backendDiscountedPrice);
        setBackendGst(res.data.totalGst);
        setCalGst(res.data.totalGst);
      }
      if (fetchCoupons) {
        try {
          const res = await api.get("/coupons/getAllCoupons?type=service");
          setCoupons(res.data.coupons);
          setRecommendedCoupons(res?.data?.coupons);
        } catch (err) {
        }
      }
    }
    showShimmer && setLoading(false);
  }

  useEffect(() => {
    async function getData() {
      await updateServicesCartData(true);
    }
    state.selectedCity && getData();
  }, [state.selectedCity, state.selectedAddress]);

  useEffect(() => {
    async function getData() {
      await updateServicesCartData(true);
    }
    state.selectedCity && getData();
  }, []);

  useEffect(() => {
    if (state.selectedAddress) setCartAddress(state.selectedAddress);
  }, [state.selectedAddress]);

  function numberWithCommas(x, y) {
    if (y) {
      x = Math.round(x * 1e2) / 1e2;
      x = x.toFixed(2);
    }
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  async function onChangeQuantity(cartItemId, newQuantity) {
    cart.find((o, i) => {
      if (o._id === cartItemId) {
        let newCart = cart;
        newCart[i].quantity = newQuantity;
        setCart([...newCart]);
      }
    });
    const res = await api.get("/servicescart", {
      headers: {
        cityId: state?.selectedCity,
      },
    });
    if (res.data.error) {
      cantFetchCartItems(res);
    } else {
      res.data.cartItems ? setCart(res.data.cartItems) : setCart([]);
      setBackendMrp(res.data.mrpTotal);
      setBackendDiscountedPrice(res.data.total);
      setBackendDiscount(backendMrp - backendDiscountedPrice);
      setBackendGst(res.data.totalGst);
      setCalGst(res.data.totalGst);
    }
  }

  const handleAddressTypeChange = (event) => {
    // setAddressData(event.target.value);
    setAddressData((prev) => ({
      ...prev,
      addType: event.target.value,
    }));
  };

  const addAddressHandler = async () => {
    if (
      addressData.name !== "" &&
      addressData.mobile !== "" &&
      addressData.address !== "" &&
      addressData.landmark !== "" &&
      addressData.city !== "" &&
      addressData.state !== "" &&
      addressData.pincode !== "" &&
      addressData.addType !== ""
    ) {
      let localLatitude, localLongitude;
      const response = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json",
        {
          params: {
            address:
              addressData.address +
              ", " +
              addressData.landmark +
              ", " +
              addressData.city +
              ", " +
              addressData.state +
              ", " +
              addressData.pincode,
            key: REACT_APP_GOOGLE_MAPS_KEY,
          },
        }
      );
      if (response.data?.results.length > 0) {
        localLatitude = response.data.results[0].geometry.location.lat;
        localLongitude = response.data.results[0].geometry.location.lng;
      }
      const res = await api.post("/me/addresses", {
        address: {
          address: addressData.address.trim(),
          ...addressData,
          address: addressData.address.trim(),
        },
        lat: localLatitude,
        lng: localLongitude,
      });
      if (res.status === 200) {
        alert.success(res.data.msg);
        dispatch(
          addAddress(state.user.addresses, {
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            floor: 1,
            address: addressData.address.trim(),
          })
        );
        setAddressData({
          name: "",
          address: "",
          mobile: "",
          landmark: "",
          city: "",
          state: "",
          pincode: "",
          country: "",
          addType: "Home",
          lat: localLatitude,
          lng: localLongitude,
          floor: 1,
        });
        dispatch(
          setSelectedAdd({
            address: addressData.address.trim(),
            ...addressData,
            lat: localLatitude,
            lng: localLongitude,
            address: addressData.address.trim(),
            floor: 1,
          })
        );
        setCartAddress({
          address: addressData.address.trim(),
          ...addressData,
          lat: localLatitude,
          lng: localLongitude,
          address: addressData.address.trim(),
          floor: 1,
        });
      } else {
        alert.error(res.data.error);
      }
    } else {
      alert.error("Fields are empty");
    }
  };

  async function onChangeAddress(address) {
    if (
      state.user.addresses.findIndex((x) => x.address === address.address) < 0
    ) {
      alert.error("Please add address");
      return;
    }
    dispatch(setSelectedAdd({ ...address }));
    setCartAddress(address);
    setAddState(true);
  }

  const hideAlertModal = () => {
    if (!state.selectedAddress) {
      dispatch(setSelectedAdd(state.user.addresses[0]));
      dispatch(updateSelectedCity(state.user.addresses[0]?.cityid));
      dispatch(updateSelectedCityName(state.user.addresses[0]?.city));
    }
    setAlertModal(false);
    setAddressData({
      name: "",
      address: "",
      mobile: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      addType: "",
    });
    setDefaultCity(false);
  };

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  async function payAndCheckout() {
    setIsCreatingOrder(true);
    if (
      !cartAddress ||
      !advancePaymentPercentage ||
      !datePicker ||
      !timePicker
    ) {
      alert.error("Add all details before checking out");
      setIsCreatingOrder(false);
      return;
    }
    const result = await api.post("/servicesorder", {
      addressId: cartAddress?._id,
      contact_number: cartAddress?.mobile,
      advancePaymentAmountPercentage: advancePaymentPercentage,
      serviceDate: datePicker,
      serviceTime: timePicker,
      couponCodeId: appliedCoupon?._id || null,
    });

    if (result.data.err) {
      alert.error(result.data.msg);
      setIsCreatingOrder(false);
      return;
    }

    const { payment_url } = result.data;

    window.location.href = payment_url;
    setIsCreatingOrder(false);
  }

  async function removeFromCart(cartItemId) {
    const res = await api.delete(`/servicescart/${cartItemId}`);
    if (res.status === 200) {
      alert.success(res.data.msg);
      await updateServicesCartData(false, false);
    } else {
      alert.error(res.data.msg);
    }
  }

  const getDate = () => {
    const d = new Date(Date.now()).toLocaleDateString();
    const date = d.split("/");
    let temp = date[1];
    date[1] = date[0];
    date[0] = temp;
    return date.join("-");
  };

  const handleCouponCodeChange = (event) => {
    const query = event.target.value;
    setCouponCode(query);

    if (query.trim().length === 0) {
      setRecommendedCoupons(coupons);
    } else {
      fetchRecommendedCoupons(query);
    }
  };

  const fetchRecommendedCoupons = (query) => {
    api
      .get(`/coupons/recommendedCoupons?type=service&codeName=${query}`)
      .then((response) => {
        const data = response.data.coupons;
        setRecommendedCoupons(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBackspace = () => {
    if (couponCode.trim().length === 0) {
      setRecommendedCoupons(coupons);
    }
  };

  const applyCoupon = async (coupon) => {
    if (appliedCoupon?._id !== coupon?._id || appliedCoupon === null) {
      setAppliedCoupon((appliedCoupon) => setAppliedCoupon(coupon));
      dispatch(setSelectedCoupon({ ...coupon }));
    } else {
      setAppliedCoupon(null);
    }
  };

  useEffect(() => {
    let tot = 0;
    let gstVal = 0;
    let totalPrice = 0;
    if (appliedCoupon) {
      let appliedDiscount =
        (appliedCoupon?.discountPercentage / 100) * backendDiscountedPrice;
      if (
        (appliedDiscount > appliedCoupon?.discountMaxValue &&
          appliedCoupon?.discountMaxValue !== 0) ||
        appliedCoupon?.discountPercentage === 0
      ) {
        appliedDiscount = appliedCoupon?.discountMaxValue;
      }
      setAppliedCouponDiscount(appliedDiscount);
      tot = backendDiscountedPrice - appliedDiscount;
      gstVal = tot * 0.18;
      totalPrice = tot + gstVal;
      setCalGst(gstVal);
      setCalTotal(totalPrice);
    } else {
      setCalGst(backendGst);
      setCalTotal(backendDiscountedPrice + backendGst);
    }
  }, [appliedCoupon, backendDiscountedPrice]);

  const calculateUptoOffForCoupon = (coupon) => {
    let tot = 0;
    let gstVal = 0;
    let totalPrice = 0;
    let appliedDiscount =
      (coupon?.discountPercentage / 100) * backendDiscountedPrice;
    if (
      (appliedDiscount > coupon?.discountMaxValue &&
        coupon?.discountMaxValue !== 0) ||
      coupon?.discountPercentage === 0
    ) {
      appliedDiscount = coupon?.discountMaxValue;
    }
    tot = backendDiscountedPrice - appliedDiscount;
    gstVal = tot * 0.18;
    totalPrice = tot + gstVal;
    return appliedDiscount.toFixed(2) + " OFF";
  };

  const handleTimeChange = (e) => {
    if (datePicker <= new Date()) {
      const selectedTime = new Date(e.$d);
      const currentTime = new Date();
      if (
        selectedTime.toDateString() === currentTime.toDateString() &&
        selectedTime < currentTime
      ) {
        return;
      }
    }
    setTimePicker(e.$d);
  };

  function LoadingShimmer() {
    return (
      <div className="loading-shimmer">
        <div className="shimmer-image"></div>
        <div className="shimmer-details">
          <div className="shimmer-line"></div>
          <div className="shimmer-line"></div>
          <div className="shimmer-line"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!state.selectedCity && (
        <CityModal
          onClose={closeModalHandler}
          isServicesCartPage={true}
        ></CityModal>
      )}
      {isMobile ? (
        <div className="MobileView">
          <span className="headingView">
            <BsArrowLeft
              onClick={() => navigate(-1)}
              style={{ fontSize: "30px" }}
            />{" "}
            Services Cart
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="container threeimage_container pt-2 mb-5 pb-5 px-2 px-lg-0">
        <div className="d-flex flex-lg-row flex-column">
          <div className="col-lg-8 col-12 overflowY-auto">
            {state.user.addresses.length > 0 ? (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData">
                    <h2>Deliver to </h2>
                    <span>{<>{cartAddress?.addType}</>}</span>
                  </div>
                  <div
                    data-toggle="modal"
                    data-target="#checkout"
                    onClick={setAddress}
                    className="changeAddressButton"
                  >
                    CHANGE
                  </div>
                </div>
                {cartAddress && (
                  <>
                    <p>
                      <b className="checkoutAddressName">{cartAddress?.name}</b>
                      , {cartAddress?.address}, {cartAddress?.landmark},{" "}
                      {cartAddress?.city}, {cartAddress?.state} -
                      {cartAddress?.pincode}
                    </p>
                    <p className="m-0 checkoutAddressName">
                      {cartAddress?.mobile}
                    </p>
                  </>
                )}
              </div>
            ) : (
              <div className="cartBox borderedContainer p-4">
                <div className="addressTypeData d-flex justify-content-between">
                  <div className="inside_addressTypeData" style={{ flex: "1" }}>
                    <h2 className="m-0">No Address Added Yet</h2>
                  </div>
                  <div
                    onClick={() => setAlertModal(true)}
                    className="changeAddressButton hoverAddAddress px-2 py-1"
                  >
                    Add Address
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <>
                <>
                  <div>
                    <h4 className="text-center">{LoadingShimmer()}</h4>
                    <h4 className="text-center">{LoadingShimmer()}</h4>
                  </div>
                </>
              </>
            ) : (
              <>
                {cart.length === 0 && (
                  <>
                    <div>No services in your cart</div>
                  </>
                )}
              </>
            )}

            {cart.map((item, cartItemIndex) => {
              const p = item.service_id;
              return (
                <div className="">
                  <div className="borderedContainer px-2 py-2 d-flex flex-row flex-wrap">
                    <div className="w-100 col-12 d-flex flex-row flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-row flex-wrap align-items-center justify-content-start col-sm-8 col-12">
                        <Link
                          to={`/hire-artisans/service/${item?.service_id?._id}`}
                        >
                          <img
                            src={
                              Object.keys(item.service_id.vars).length > 0
                                ? item.service_id?.vars[
                                    Object.keys(item.service_id?.vars)[0]
                                  ].filter(
                                    (x) =>
                                      x.attrs[
                                        Object.keys(item.service_id?.vars)[0]
                                      ] ===
                                      item?.variation[
                                        Object.keys(item.service_id?.vars)[0]
                                      ]
                                  )[0].attrs.imgs
                                  ? item.service_id.vars[
                                      Object.keys(item.service_id?.vars)[0]
                                    ].filter(
                                      (x) =>
                                        x.attrs[
                                          Object.keys(item.service_id?.vars)[0]
                                        ] ===
                                        item?.variation[
                                          Object.keys(item.service_id?.vars)[0]
                                        ]
                                    )[0].attrs.imgs
                                  : item.service_id?.attrs?.imgs[0]
                                : item.service_id?.attrs?.imgs[0]
                            }
                            alt=""
                            className="cartImage"
                          />
                        </Link>
                        <div className="d-flex flex-column ps-2 flex1Inner">
                          <p className="m-0 p-0">
                            <Link
                              className="checkoutProductLink"
                              to={`/hire-artisans/service/${item?.service_id?._id}`}
                            >
                              {p?.serviceName}
                            </Link>
                          </p>
                          {item?.variation &&
                            Object.entries(item?.variation).map(
                              ([variationKey, variationValue]) => (
                                <>
                                  <div className="subtext">
                                    {variationKey}: {variationValue}{" "}
                                    {(variationKey === "length" ||
                                      variationKey === "width") &&
                                      "ft"}
                                  </div>
                                </>
                              )
                            )}
                          <p className="m-0 p-0">
                            <span className="m-0 p-0 productDiscountedPrice text">
                              Rs. {numberWithCommas(item?.finalPrice)}{" "}
                            </span>
                            {item.service_id?.unit !== "" &&
                              item.service_id?.unit !== undefined &&
                              item.service_id?.unit !== null && (
                                <Tooltip
                                  title={`Rs. ${
                                    Object.keys(item.service_id.vars).length > 0
                                      ? item.service_id?.vars[
                                          Object.keys(item.service_id?.vars)[0]
                                        ].filter(
                                          (x) =>
                                            x.attrs[
                                              Object.keys(
                                                item.service_id?.vars
                                              )[0]
                                            ] ===
                                            item?.variation[
                                              Object.keys(
                                                item.service_id?.vars
                                              )[0]
                                            ]
                                        )[0].discounted_price
                                        ? numberWithCommas(
                                            item.service_id?.vars[
                                              Object.keys(
                                                item.service_id?.vars
                                              )[0]
                                            ].filter(
                                              (x) =>
                                                x.attrs[
                                                  Object.keys(
                                                    item.service_id?.vars
                                                  )[0]
                                                ] ===
                                                item?.variation[
                                                  Object.keys(
                                                    item.service_id?.vars
                                                  )[0]
                                                ]
                                            )[0].discounted_price
                                          )
                                        : "N/A"
                                      : numberWithCommas(p?.discounted_price)
                                  } ${
                                    item.service_id.unit === "Sq ft"
                                      ? "per sq ft"
                                      : ""
                                  } ${
                                    item.service_id.unit === "R ft"
                                      ? "per running ft"
                                      : ""
                                  }`}
                                >
                                  <IconButton>
                                    <InfoIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {/* <span className="m-0 p-0 productPrice subtext">
                                                        Rs.{" "}
                                                        {item?.variation
                                                            ? item.service_id.vars[
                                                                Object.keys(item.service_id?.vars)[0]
                                                            ].filter(
                                                                (x) =>
                                                                    x.attrs[
                                                                    Object.keys(item.service_id?.vars)[0]
                                                                    ] ===
                                                                    item?.variation[
                                                                    Object.keys(item.service_id?.vars)[0]
                                                                    ]
                                                            )[0].price
                                                                ? numberWithCommas(
                                                                    item.service_id.vars[
                                                                        Object.keys(item.service_id?.vars)[0]
                                                                    ].filter(
                                                                        (x) =>
                                                                            x.attrs[
                                                                            Object.keys(item.service_id?.vars)[0]
                                                                            ] ===
                                                                            item?.variation[
                                                                            Object.keys(item.service_id?.vars)[0]
                                                                            ]
                                                                    )[0].price
                                                                )
                                                                : "N/A"
                                                            : numberWithCommas(p?.price)}{" "}
                                                    </span>
                                                    <span className="m-0 p-0 productDiscountedPercent subtext">
                                                        -
                                                        {item?.variation
                                                            ? numberWithCommas(
                                                                item.service_id.vars[
                                                                    Object.keys(item.service_id?.vars)[0]
                                                                ].filter(
                                                                    (x) =>
                                                                        x.attrs[
                                                                        Object.keys(item.service_id?.vars)[0]
                                                                        ] ===
                                                                        item?.variation[
                                                                        Object.keys(item.service_id?.vars)[0]
                                                                        ]
                                                                )[0].discounted_percent
                                                            )
                                                            : numberWithCommas(p?.discounted_percent)}
                                                        %
                                                    </span> */}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-100 col-12 subtext d-flex flex-row align-items-center justify-content-between">
                      <div>
                        <div>
                          Quantity:
                          <ServiceCounter
                            hide={false}
                            cart={cart}
                            // removeFromCart={removeFromCart}
                            quantity={item.quantity}
                            cartItemId={item?._id}
                            service_id={item?.service_id?._id}
                            variation={item?.variation}
                            onChangeQuantity={(cartItemId, newQuantity) => {
                              onChangeQuantity(cartItemId, newQuantity);
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="cart_info_quantity_remove m-0 align-self-end"
                        data-toggle="modal"
                        data-target="#deleteItemFromCart"
                        onClick={() => setSelectedItem(item)}
                      >
                        <img src={bin} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-lg-4 col-12 mt-lg-0 mt-4 serviceCartDateTimeDiv">
            <div className="w-100">
              <div className="renderside_cartside_total ms-lg-3 ms-0 pb-2">
                <p className="p-0 m-0 cartside_total_heading">
                  SCHEDULE DATE & TIME
                </p>
                <p className="m-0">Date</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    className="w-100 mb-2"
                    inputFormat="MM/DD/YYYY"
                    value={datePicker}
                    onChange={(e) => {
                      setDatePicker(e.$d);
                    }}
                    disablePast
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </LocalizationProvider>
                <p className="m-0">Time</p>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    className="w-100"
                    value={timePicker}
                    onChange={handleTimeChange}
                    disablePast
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div
                className="coupon_side ms-lg-3 mx-0 d-flex justify-content-center align-items-center"
                data-toggle="modal"
                data-target="#couponModal"
              >
                VIEW COUPONS
              </div>
              <div className="renderside_cartside_total ms-lg-3 ms-0 pb-2">
                <p className="p-0 m-0 cartside_total_heading">
                  ADVANCE PAYMENT
                </p>
                <div className="mb-1 d-flex">
                  <input
                    onClick={(e) => setAdvancePaymentPercentage(e.target.value)}
                    type="radio"
                    id="10"
                    value="10"
                    className="me-2"
                    checked={advancePaymentPercentage === "10"}
                  />
                  <label for="10">
                    Pay 10% in advance and rest after completion
                  </label>
                </div>
                <div className="mb-1 d-flex">
                  <input
                    onClick={(e) => setAdvancePaymentPercentage(e.target.value)}
                    type="radio"
                    id="30"
                    value="30"
                    className="me-2"
                    checked={advancePaymentPercentage === "30"}
                  />
                  <label for="30">
                    Pay 30% in advance and rest after completion
                  </label>
                </div>
                <div className="mb-1 d-flex">
                  <input
                    onClick={(e) => setAdvancePaymentPercentage(e.target.value)}
                    type="radio"
                    id="50"
                    value="50"
                    className="me-2"
                    checked={advancePaymentPercentage === "50"}
                  />
                  <label for="50">
                    Pay 50% in advance and rest after completion
                  </label>
                </div>
                <div className="mb-1 d-flex">
                  <input
                    onClick={(e) => setAdvancePaymentPercentage(e.target.value)}
                    type="radio"
                    id="90"
                    value="90"
                    className="me-2"
                    checked={advancePaymentPercentage === "90"}
                  />
                  <label for="90">
                    Pay 90% in advance and rest after completion
                  </label>
                </div>
              </div>
              {showOrderDetail ? (
                <div className="renderside_cartside_total ms-lg-3 ms-0">
                  <div className="cartside_total_heading">ORDER SUMMARY</div>
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">
                      Total MRP ({cart.length} items)
                    </div>
                    <div
                      className="cartside_total_amount"
                      style={{ textDecoration: "line-through" }}
                    >
                      Rs. {numberWithCommas(backendMrp, 2)}
                    </div>
                  </div>
                  <div className="cartside_total_discount">
                    <div className="cartside_total_text">
                      Total Selling Price
                    </div>
                    <div className="cartside_total_amount">
                      Rs. {numberWithCommas(backendDiscountedPrice, 2)}
                    </div>
                  </div>
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">Shipping</div>
                    <div className="cartside_total_amount">Free</div>
                  </div>
                  <div className="cartside_total_total">
                    {appliedCoupon && (
                      <div className="cartside_total_text">
                        Coupon ({appliedCoupon.codeName})
                      </div>
                    )}
                    <div className="cartside_total_total">
                      {appliedCoupon && (
                        <div className="cartside_total_amount">
                          - Rs. {appliedCouponDiscount.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">GST (18%)</div>
                    <div className="cartside_total_amount">
                      Rs. {numberWithCommas(calGst, 2)}
                    </div>
                  </div>
                  <div className="cartside_total_total">
                    <div className="cartside_total_text">Total Amount</div>
                    <div className="cartside_total_amount">
                      Rs. <>{numberWithCommas(calTotal, 2)}</>
                    </div>
                  </div>
                  <div className="cartside_total_subtotal">
                    <div className="cartside_total_text">
                      Advance Amount ({advancePaymentPercentage}%)
                    </div>
                    <div className="cartside_total_amount">
                      Rs.{" "}
                      <>
                        {numberWithCommas(
                          calTotal * (parseInt(advancePaymentPercentage) / 100),
                          2
                        )}
                      </>
                    </div>
                  </div>
                  <button
                    className="cartside_total_checkout"
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                    disabled={isCreatingOrder}
                  >
                    {isCreatingOrder ? (
                      <>
                        <CircularProgress
                          style={{ color: "white" }}
                          size="30px"
                        />
                      </>
                    ) : (
                      "CHECKOUT"
                    )}
                  </button>
                  <span className="d-flex align-items-center">
                    <BsAsterisk
                      style={{
                        marginRight: "5px",
                        fontSize: "11px",
                        fill: "#FF7253",
                      }}
                    />
                    <span
                      type="button"
                      className="loginPopupForgotPasswordButton"
                      onClick={() => {
                        navigate("/terms-and-condition");
                      }}
                    >
                      T&C Apply
                    </span>
                  </span>
                </div>
              ) : (
                <div className="ms-lg-3 ms-0">
                  Agree to terms and conditon{" "}
                  <button
                    style={{
                      color: "white",
                      backgroundColor: "#FF7703",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "16px",
                      padding: "4px 10px",
                      border: "1px solid #FF7703",
                    }}
                  >
                    Ok
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="checkout"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content change_delivery_address">
            <div
              className={addState ? `modal-header` : ``}
              style={
                !addState
                  ? {
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: "8px",
                      marginBottom: "15px",
                    }
                  : { border: "none", width: "95%" }
              }
            >
              <h5
                className="modal-title delivery_address_heading"
                style={!addState ? { fontSize: "21px" } : {}}
                id="deleteAddress"
              >
                {!addState
                  ? "SELECT DELIVERY ADDRESS"
                  : // "Address Details"}
                    "CONFIRM ADDRESS"}
              </h5>
              {!addState ? (
                <div
                  style={{ width: "35%", height: "36px" }}
                  onClick={() => setAlertModal(true)}
                  className="changeAddressButton hoverAddAddress px-2 py-1"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Add Address
                </div>
              ) : (
                ""
              )}
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setAddState(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {/* <div className="modal-body"> */}
            <div className="checkoutModalBody" style={{ width: "100%" }}>
              {addState ? (
                <>
                  <div className="profile_form_content_inside_data">
                    <div className="select_address w-100 ms-2">
                      <div className="select_address_mid">
                        <div className="inside_address_mid">
                          <span className="address_name_mid">
                            {cartAddress?.name}
                          </span>
                          <span className="address_type_mid">
                            {cartAddress?.addType}
                          </span>
                        </div>
                        <span className="address_add_mid">
                          {cartAddress?.address}, {cartAddress?.landmark},{" "}
                          {cartAddress?.city}, {cartAddress?.state}-
                          {cartAddress?.pincode}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="orderButtons">
                    <button
                      type="button"
                      className="CloseaddUpdateButton"
                      onClick={() => {
                        setAddState(false);
                      }}
                    >
                      Return
                    </button>
                    <button
                      type="button"
                      className="addUpdateButton"
                      data-dismiss="modal"
                      onClick={() => {
                        setAddState(false);

                        dispatch(setSelectedAdd({ ...cartAddress }));
                        dispatch(updateSelectedCity(cartAddress?.cityid));
                        dispatch(updateSelectedCityName(cartAddress?.city));
                      }}
                    >
                      Yes
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {state.user.addresses?.map((address, i) => (
                    <div
                      className="select_address"
                      onClick={async () => {
                        onChangeAddress(address);
                      }}
                    >
                      <div className="profile_form_content_inside_data">
                        <div className="select_address_left">
                          <IoRadioButtonOffSharp />
                        </div>
                        <div className="select_address_mid">
                          <div className="inside_address_mid">
                            <span className="address_name_mid">
                              {address.name}
                            </span>
                            <span className="address_type_mid">
                              {address.addType}
                            </span>
                          </div>
                          <span className="address_add_mid">
                            {address?.address}, {address?.landmark},{" "}
                            {address?.city}, {address?.state}-{address?.pincode}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <br />
                  {/* <div
                                    className="checkAddress"
                                    style={{ padding: "5px", borderTop: "1px solid #878787" }}
                                > */}
                  {/* <h6>Use pincode</h6>
                    <span>
                      <input disabled={true} placeholder="Enter pincode" />
                      <button disabled={true}>Check</button>
                    </span> */}
                  {/* </div> */}
                  {/* <p className="mb-0 mt-2" style={{ fontWeight: "bold" }}>
                                    We are currently delivering only in Pune, India.
                                </p> */}
                </>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteItemFromCart"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content password_change_popup">
            <div className="modal-header headingADeleteAddress">
              <h5
                className="modal-title"
                id="deleteItemFromCart"
                style={{
                  fontWeight: "bold",
                  fontSize: "24px",
                  fontFamily: "Poppins",
                }}
              >
                Are you sure to delete?
              </h5>
            </div>
            <div className="modal-body">
              <div className="deleteAddressModalBody">
                <button
                  className="addUpdateButton"
                  type="button"
                  data-dismiss="modal"
                  onClick={() => removeFromCart(selectedItem._id)}
                >
                  Yes
                </button>
                <button
                  className="cancelAddUpdate"
                  type="button"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="couponModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalLabel">
                AVAILABLE COUPONS
              </h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="search-bar d-flex ">
                <input
                  className="search-input"
                  style={{
                    padding: "5px",
                    borderBottom: "none",
                    textDecoration: "none",
                  }}
                  type="text"
                  placeholder="Enter the Coupon Code"
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                  onKeyDown={handleBackspace}
                />
                <div style={{ padding: "5px" }}>
                  <NewSearchIcon />
                </div>
              </div>
              <div class="coupon-list">
                {recommendedCoupons?.map((coupon, index) => (
                  <div className="coupon-item" key={index}>
                    <div className="coupon-item-details">
                      <div className="coupon-details">
                        <h6 className="coupon-title">{coupon?.codeName}</h6>
                        <p className="coupon-description">
                          {calculateUptoOffForCoupon(coupon)}
                        </p>
                      </div>
                      <div className="coupon-details">
                        <p className="coupon-description">
                          {coupon?.codePublicDescription}
                        </p>
                      </div>
                      <button
                        className="coupon-apply-button"
                        style={{ color: "black" }}
                        onClick={() => {
                          applyCoupon(coupon);
                        }}
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {appliedCoupon?._id === coupon?._id
                          ? "Applied"
                          : "Apply"}
                      </button>
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal && (
        <Modal
          show={alertModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="alertModalName"
          onHide={hideAlertModal}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title id="contained-modal-title-vcenter">
                        Review
                    </Modal.Title> */}
            <h1 className="alertModalh1">ADD ADDRESS</h1>
          </Modal.Header>
          <Modal.Body>
            {/* <TextField id="standard-basic" label="Standard" variant="standard" /> */}
            <label className="inputLabels" id="NameId">
              Name
            </label>
            <input
              id="NameId"
              type="text"
              value={addressData.name}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="MobileId">
              Mobile
            </label>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <span className="addressMobileNumberInputPrefix">+91</span>
              <span className="addressMobileNumberInputWrapper">
                <input
                  id="MobileId"
                  type="text"
                  value={addressData.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                      setAddressData((prev) => ({
                        ...prev,
                        mobile: e.target.value,
                      }));
                    }
                  }}
                />
              </span>
            </div>
            <label className="inputLabels" id="AddressId">
              Address( House No., Building, Street, Area )
            </label>
            <input
              id="AddressId"
              value={addressData.address}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="LandmarkId">
              Landmark
            </label>
            <input
              id="LandmarkId"
              type="text"
              value={addressData.landmark}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  landmark: e.target.value,
                }));
              }}
            />
            <label className="inputLabels" id="CityId">
              City
            </label>
            <input
              id="CityId"
              type="text"
              value={addressData.city || state.selectedCityName}
              onChange={(e) => {
                setAddressData((prev) => ({
                  ...prev,
                  city: e.target.value || " ",
                }));
              }}
            />
            <div className="InsideModalSpace">
              <span>
                <label className="inputLabels" id="StateId">
                  State
                </label>
                <input
                  id="StateId"
                  type="text"
                  value={addressData.state}
                  onChange={(e) => {
                    setAddressData((prev) => ({
                      ...prev,
                      state: e.target.value,
                    }));
                  }}
                />
              </span>
              <span>
                <label className="inputLabels" id="PincodeId">
                  Pincode
                </label>
                <input
                  id="PincodeId"
                  type="text"
                  value={addressData.pincode}
                  onChange={handleChange}
                />
              </span>
            </div>
            <div className="address_type">
              <span>Type of Address</span>
              <br />
              <FormControl>
                <RadioGroup
                  row={true}
                  className="AddressRadio m-0"
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={addressData.addType}
                  onChange={handleAddressTypeChange}
                >
                  <FormControlLabel
                    value="Home"
                    control={<Radio />}
                    label="Home"
                  />
                  <FormControlLabel
                    value="Site"
                    control={<Radio />}
                    label="Site"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {/* )} */}
          </Modal.Body>
          <div className="AdressModalSubmit">
            <button
              className="CloseaddUpdateButton"
              onClick={() => hideAlertModal()}
            >
              Discard
            </button>
            <button
              className="addUpdateButton"
              onClick={() => {
                addAddressHandler();
                hideAlertModal();
              }}
            >
              Save
            </button>
          </div>
        </Modal>
      )}
      <ModalTC
        visible={isOpenModal}
        width={isMobile ? "350" : "700"}
        height={isMobile ? "570" : "660"}
        effect="fadeInUp"
        className="tcmodal"
        style={{ zIndex: "100" }}
        onClickAway={() => {
          setIsOpenModal(false);
        }}
      >
        <button
          type="button"
          className="close closeChatBotButton"
          aria-label="Close"
          onClick={() => setIsOpenModal(false)}
        >
          <span aria-hidden="true">
            <AiOutlineClose />
          </span>
        </button>
        <div className="login-box">
          <h2>Agreement</h2>
          <div className="agreementDiv">
            <p>
              {`This Agreement (the "Agreement") is made and entered into on ${getDate()} by and between ${
                state?.user?.name
              } (the "Customer") and Plypicker (the "Service Provider"), collectively referred to as the "Parties".`}
            </p>
            <p>
              1. Booking and Refunds: The Customer shall be entitled to a refund
              of the booking amount subject to the following conditions: (a) If
              the Customer cancels the work before 72 hours of the scheduled
              commencement, they shall be entitled to a 100% refund of the
              booking amount. (b) If the Customer cancels the work before 48
              hours of the scheduled commencement, they shall be entitled to a
              50% refund of the booking amount. (c) If the Customer cancels the
              work 24 hours before or less, they are not eligible for any
              refund.
            </p>
            <p>
              2. Modification: The Customer may modify the date and time
              schedule before 72 hours of the scheduled commencement. Any
              modifications made thereafter shall render the booking
              non-refundable. Once the Customer has successfully modified the
              booking, they are not eligible for cancellation or refund.
            </p>
            <p>
              3. Change Orders: Once the work and design have been approved and
              finalized by the Customer, any change requested shall be charged
              extra.
            </p>
            <p>
              4. Commencement and Pause: Once the work have commenced, it cannot
              be paused. In such a case, the Customer shall not be eligible for
              a refund. To resume the work, the Customer shall have to place a
              new order.
            </p>
            <p>
              5. Minor Changes: Genuine minor changes shall be allowed after the
              completion of the work. Our team shall agree that the extra work
              requested by the Customer is genuinely minor. In case our team
              finds that it is not minor, completing the extra task shall be
              charged extra.
            </p>
            <p>
              6. Inspection and Complaints: The Customer shall inspect the work
              daily or alternatively. If they find any poor work or quality
              issues, or have any complaints, they shall email us at{" "}
              <a href="mailto:info@plypicker.com">info@plypicker.com</a> within
              24 hours. After 24 hours, if the complaint has been raised, it
              shall not be our responsibility.
            </p>
            <p>
              7. Booking Amount: The booking amount is fixed and non-negotiable.
            </p>
            <p>
              8. Payment: The Customer shall pay a minimum of 30% to complete
              Phase 1 of the work. If the Customer chooses to pay less than 30%
              while booking the order, they shall have to pay the rest of the
              percentage amount within 3 days after the commencement of the
              work.
            </p>
            <p>
              9. Delay in Payment: If the Customer fails to make the payments on
              due dates, we shall not be responsible for any delay in the work.
              It shall be adjusted according to the availability of the workers
              and laborers.
            </p>
            <p>
              10. Responsibility for Material: If the material is provided by
              us, it is our responsibility if anything wrong happens under the
              warranty period, excluding physical damage. However, if the
              material for the work is provided by the Customer, it is their
              full responsibility. We shall not be responsible for the quality
              of work or any mishappening. It is recommended to provide good
              quality material for the work.
            </p>
            <p>
              11. Responsibility for Material Handling: We shall not be
              responsible for any damage or mishappening of the material while
              handling it to the construction site if the Customer has not opted
              for loading and unloading feature while booking the order.
            </p>
            <p>
              12. Permission: The Customer shall have valid permission for the
              construction work at their society. They shall mail the permission
              proof at{" "}
              <a href="mailto:info@plypicker.com">info@plypicker.com</a> with
              their order ID and schedule date of work. After verifying all the
              details, we shall start the work on time.
            </p>
            <p>
              13. Electricity and Water Supply: The Customer shall provide
              electricity supply, drinking water, and domestic use water supply
              sponsored by them. Any failure in doing this might lead to a delay
              in work.
            </p>
            <p>
              14. Dumping Area: The customer shall provide a dumping area for us
              to dispose of all the waste generated during the construction
              process. We shall not be responsible for disposing of any waste
              materials from your home compound or society.
            </p>
            <p>
              15. We shall undertake cleaning of the premises after the
              completion of the work. However, if you require deep cleaning
              services, it shall be charged extra as per the prevailing market
              rate.
            </p>
            <p>
              16. Any dispute arising out of this agreement shall be settled
              through arbitration, and the decision of the arbitrator shall be
              final and binding on both parties.
            </p>
            <p>
              17. This agreement shall be governed and construed in accordance
              with the laws of [insert the name of the state or country where
              your business is registered], and any legal action arising out of
              this agreement shall be instituted in the courts of [insert the
              name of the state or country where your business is registered].
            </p>
            <p>
              18. This agreement constitutes the entire agreement between you
              and us and supersedes all prior negotiations, understandings, and
              agreements between the parties, whether written or oral, relating
              to the subject matter of this agreement.
            </p>
            <p>
              19. You acknowledge that you have read this agreement, understood
              its terms and conditions, and agree to be bound by them. You
              further warrant that you have the authority to enter into this
              agreement.
            </p>
            <p>
              20. Any breach of this agreement by you shall entitle us to
              terminate this agreement, cancel the work, and retain any payments
              made by you as liquidated damages. We shall also have the right to
              take any legal action against you for any damages caused to us due
              to such breach
            </p>
            <br />
            <p>
              IN WITNESS WHEREOF, the parties hereto have executed this
              agreement as of the date and year first above written.
            </p>
            <p>{state?.user?.name}</p>
            <p>
              I agree to all the terms and conditions mentioned in this
              agreement and confirm that I have read, understood, and signed
              this agreement voluntarily and without any undue influence.
            </p>
          </div>
          <button
            onClick={() => {
              if (state.user.addresses.length === 0) {
                alert.error("Please add Address!");
              } else if (cart.length === 0) {
                alert.error("Your cart is empty!");
              } else {
                payAndCheckout();
              }
              setIsOpenModal(true);
              setIsOpenModal(false);
              setShowOrderDetail(true);
            }}
            style={{
              color: "white",
              backgroundColor: "#FF7703",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              padding: "4px 10px",
              border: "1px solid #FF7703",
            }}
          >
            Agree & Checkout
          </button>
        </div>
      </ModalTC>
    </>
  );
}

export default ServicesCart;
