import { CircularProgress } from "@mui/material";
import Rating from "@mui/material/Rating";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Countdown from "react-countdown";
import { BsSuitHeart, BsSuitHeartFill } from "react-icons/bs";
import ReactImageMagnify from "react-image-magnify";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useMediaQuery } from "react-responsive";
import ProductError from "../../../global_component/ErrorPage/ProductError.js";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { API_URL } from "../../../../constants";
import {
  addToCart,
  addToCart2,
  deleteReview,
  editReview,
  removeFromWishlistAction,
  setAvgRating,
  setProductData,
  setRoute,
} from "../../../../store/reducers/userActions";
import { useStore } from "../../../../store/store";
import useAxios from "../../../../utils/useAxios";
import ChatBot from "../../../global_component/ChatBot/ChatBot";
import LoginRegisterPopup from "../../../global_component/LoginRegisterPopup/LoginRegisterPopup";
import NavRoute from "../../Nav_Route/NavRoute";
import StarRating from "../Products_Detail/StarRating/StarRating";
import { ReactComponent as AddImage } from "./img/AddImage.svg";
import bathroom1 from "./img/bathroom1.png";
import coupon from "./img/coupon.png";
import { ReactComponent as EditButton } from "./img/EditButton.svg";
import { ReactComponent as Heart } from "./img/heart.svg";
import { ReactComponent as Share } from "./img/share.svg";
import shoppingCart from "./img/shopping-cart.png";
import star from "./img/star.png";
import { ReactComponent as TrashButton } from "./img/TrashButton.svg";
import "./NewProductDetail.css";
import { Helmet } from "react-helmet-async";

function NewProductDetail() {
  const [product, setProduct] = useState({});
  const [variations, setVariations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState(0);
  const [reviewModal, setReviewModal] = useState(false);
  const [reviewState, setReviewState] = useState(true);
  const [newRating, setNewRating] = useState(0);
  const [reviewImage, setReviewImage] = useState("");
  const [isBuyingNow, setIsBuyingNow] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [selectedReview, setSelectedReview] = useState({});
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);
  const [isGettingPrice, setIsGettingPrice] = useState(false);
  const [isdropdown, setdropdown] = useState(false);
  const [isTruncated, setIsTruncated] = useState(true);
  const [isReviewDropdown, setIsReviewDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVariation, setCurrentVariation] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [graphRatingCound, setGraphRatingCound] = useState([0, 0, 0, 0, 0]);
  const [isSpecificationsTruncated, setIsSpecificationsTruncated] =
    useState(true);
  const [oneRating, setOneRating] = useState(0);
  const [twoRating, setTwoRating] = useState(0);
  const [threeRating, setThreeRating] = useState(0);
  const [fourRating, setFourRating] = useState(0);
  const [fiveRating, setFiveRating] = useState(0);
  const [checkRating, setCheckRating] = useState(false);
  const [thisReviewRating, setThisReviewRating] = useState();
  const [selectedVariation, setSelectedVariation] = useState({});
  const [allVariations, setAllVariations] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [getPriceTimestamp, setGetPriceTimestamp] = useState(null);
  const [currentImages, setCurrentImages] = useState([]);
  const [productName, setProductName] = useState("");
  const [productPrices, setProductPrices] = useState({
    price: 0,
    discountedPrice: 0,
    discountedPercent: 0,
  });
  const [imageField, setImageField] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageTarget, setImageTarget] = useState(null);
  const [imageStatus, setImageStatus] = useState(null);
  const [notfound, setNotFound] = useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  let isSmall = useMediaQuery({
    query: "(max-width:992px)",
  });

  const alert = useAlert();
  const navigate = useNavigate();
  const api = useAxios();
  const { p_id } = useParams();
  const [state, dispatch] = useStore();

  const [specifications, setSpecifications] = useState([]);

  const addToCartHandler = async (product) => {
    setIsAddingToCart(true);
    if (state?.accessToken === null || !state?.authenticated) {
      setIsLoginPopupVisible(true);
    } else {
      if (state?.product?.vars === undefined || state?.product?.vars === null) {
        const res = await api.post(
          `/carts`,
          {
            product_id: product["id"],
            variation: {},
          },
          {
            headers: {
              // name:'xyz'
            },
          }
        );
        if (!res.data?.error) {
          dispatch(addToCart2(product));
          alert.success(res.data?.msg);
          navigate("/cart");
        } else {
          alert.error(res.data?.error);
        }
      } else {
        if (
          Object.keys(selectedVariation)?.length ===
          Object.keys(state?.product?.vars)?.length
        ) {
          const res = await api.post(
            `/carts`,
            {
              product_id: product["id"],
              variation: selectedVariation,
            },
            {
              headers: {
                // name:'xyz'
              },
            }
          );
          if (!res.data?.error) {
            dispatch(addToCart2(product));
            alert.success(res.data?.msg);
            navigate("/cart");
          } else {
            alert.error(res.data?.error);
          }
        } else {
          for (const [variationKey, variationValue] of Object.entries(
            state?.product?.vars
          )) {
            if (variationKey in selectedVariation === false) {
              alert.error(
                `Please select ${variationKey} before adding to cart!`
              );
            }
          }
        }
      }
    }
    setIsAddingToCart(false);
  };

  // TODO: get this to remember which variation is in the wishlist 😫
  const addToWishListHandler = async (p_id) => {
    if (state?.accessToken === null || !state?.authenticated) {
      setIsLoginPopupVisible(true);
      alert.error("PLEASE SIGN IN FIRST!");
    } else {
      const res = await api.post(
        `/wishlist`,
        {
          p_id: p_id,
        },
        {
          headers: {
            // name:'xyz'
          },
        }
      );
      if (!res.data?.error) {
        alert.success(res.data?.msg);
      } else {
        alert.error(res.data?.error);
      }
      dispatch(addToCart(product?._id));
    }
  };

  function removeFromWishlistHandler(pid) {
    if (state?.accessToken === null || !state?.authenticated) {
      setIsLoginPopupVisible(true);
    } else {
      removeFromWishlist(pid);
    }
  }

  async function getPrice(id) {
    setIsGettingPrice(true);
    if (state.accessToken === null || !state.authenticated) {
      setIsLoginPopupVisible(true);
      setIsGettingPrice(false);
    } else {
      const res = await api.get(`${API_URL}/products/getPrice`, {
        headers: {
          id: id,
        },
      });
      if (res.data.success === true) {
        alert.success(res.data.msg);
        setGetPriceTimestamp(res.data.dueTimestamp);
        setTimeout(() => {
          alert.success("Till then you can add this in your wishlist");
        }, 1250);
        setIsGettingPrice(false);
      } else {
        alert.error("An error occoured");
        setIsGettingPrice(false);
      }
    }
    setIsGettingPrice(false);
  }

  const removeFromWishlist = async (id) => {
    try {
      const res = await api.delete(`/wishlist/${id}`, {
        headers: {
          // name:'xyz'
        },
      });
      dispatch(removeFromWishlistAction(id));
      if (res.data?.error) {
        alert.error(res.data?.error);
      } else {
        alert.success("Removed from wishlist");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const buyNowHandler = async (product) => {
    setIsBuyingNow(true);
    if (state.accessToken === null || !state.authenticated) {
      setIsLoginPopupVisible(true);
      setIsBuyingNow(false);
    } else {
      let navigationString = `/buynow?p_id=${product.id}`;
      for (const [variationKey, variationValue] of Object.entries(
        selectedVariation
      )) {
        navigationString = navigationString.concat(
          `&${variationKey}=${variationValue}`
        );
      }
      navigate(navigationString);
      setIsBuyingNow(false);
    }
  };
  let ratingCount = [0, 0, 0, 0, 0];

  async function checkEditOrAdd() {
    try {
      const res = await axios.get(`${API_URL}/review/editOrAdd/${p_id}`, {
        headers: {
          authorization: `Bearer ${state.accessToken}`,
        },
      });
      if (res.data.error) {
        navigate("/error");
      }
      setThisReviewRating(res.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    checkEditOrAdd();
  }, []);

  let isDesktop = useMediaQuery({
    query: "min-width: 992px",
  });

  let isTablet = useMediaQuery({
    query: "in-width: 576px;",
  });

  let isMobile = useMediaQuery({
    query: "max-width: 576px",
  });

  function slideDecider() {
    if (isDesktop) {
      return null;
    }
    if (isTablet) {
      return 4;
    }
    if (isMobile) {
      return 3;
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let res;

      if (state.accessToken === null || !state.authenticated) {
        try {
          res = await axios.get(`${API_URL}/products/${p_id}`, {
            headers: {
              // name: 'xyz'
            },
          });
        } catch (error) {
          setNotFound(true);
        }
      } else {
        try {
          if (state.accessToken === null || state.authenticated) {
            res = await axios.get(`${API_URL}/products/${p_id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${state.accessToken}`,
              },
            });
          }
        } catch (error) {
          console.log(error);
          setNotFound(true);
        }
      }
      if (res?.data.error) {
        setNotFound(true);
      }
      document.title = `Buy ${res.data?.product_name} - PLYPICKER`;
      res.data.rating_and_review?.forEach((e) => {
        ratingCount[e.rating - 1] = ratingCount[e.rating - 1] + 1;
      });
      setGraphRatingCound(ratingCount);
      setProduct((product) => {
        setProduct(res.data);
      });
      setProductName(res.data?.product_name);
      setSpecifications(res.data?.desc);
      setProductDescription(res.data?.product_description);
      setCurrentImages(res.data?.attrs?.imgs);
      setImage(res.data?.attrs?.imgs[0]);
      setSelectedImage(0);
      if (res.data.discounted_price !== null) {
        setProductPrices({
          price: res.data?.price,
          discountedPrice: res.data?.discounted_price,
          discountedPercent: res.data?.discounted_percent,
        });
      } else {
        setProductPrices({
          price: 0,
          discountedPrice: 0,
          discountedPercent: 0,
        });
      }
      setIsLoading(false);
      if (
        res.data.vars !== undefined &&
        res.data.vars !== null &&
        res.data.vars !== {}
      ) {
        setAllVariations(res.data.vars);
        Object.entries(res.data?.vars).map(([key, value], index) => {
          if (
            index === 0 &&
            value.some((o) => o.attrs[key] === query.get(`${key}`))
          ) {
            // check if URL var from query string is available in vars
            let indexOfQueryVar = value.findIndex(
              (o) => o.attrs[key] === query.get(`${key}`)
            );
            if (indexOfQueryVar > -1) {
              value[indexOfQueryVar]?.product_name &&
                setProductName(value[indexOfQueryVar]?.product_name);
              value[indexOfQueryVar]?.desc &&
                setSpecifications(value[indexOfQueryVar]?.desc);
              value[indexOfQueryVar]?.product_description &&
                setProductDescription(
                  value[indexOfQueryVar]?.product_description
                );
              value[indexOfQueryVar].attrs?.imgs &&
                setCurrentImages([`${value[indexOfQueryVar].attrs?.imgs}`]);
              value[indexOfQueryVar].attrs?.imgs &&
                setImage(value[indexOfQueryVar].attrs?.imgs);
              if (value[indexOfQueryVar]?.discounted_price > 0) {
                setProductPrices({
                  price: value[indexOfQueryVar]?.price,
                  discountedPrice: value[indexOfQueryVar]?.discounted_price,
                  discountedPercent: value[indexOfQueryVar]?.discounted_percent,
                });
              } else {
                setProductPrices({
                  price: 0,
                  discountedPrice: 0,
                  discountedPercent: 0,
                });
              }
            }
          }
          // select variations available in URL query
          if (query.get(`${key}`)) {
            if (value.some((o) => o.attrs[key] === query.get(`${key}`))) {
              selectedVariation[key] = query.get(`${key}`);
            }
          }
          setSelectedVariation({ ...selectedVariation });
        });
      }
      dispatch(setProductData(res.data));
      setVariations(res.data.filters);
      setCurrentVariation("NONE");
      setLoading(false);
      if (res.data.rating_and_review?.length > 0) {
        for (const rating of res.data.rating_and_review) {
          if (rating.rating === 1) {
            setOneRating((oneRating) => {
              setOneRating(oneRating + 1);
            });
          } else if (rating.rating === 2) {
            setTwoRating((twoRating) => {
              setTwoRating(twoRating + 1);
            });
          } else if (rating.rating === 3) {
            setThreeRating((threeRating) => {
              setThreeRating(threeRating + 1);
            });
          } else if (rating.rating === 4) {
            setFourRating((fourRating) => {
              setFourRating(fourRating + 1);
            });
          } else if (rating.rating === 5) {
            setFiveRating((fiveRating) => {
              setFiveRating(fiveRating + 1);
            });
          }
        }
      }
      dispatch(
        setRoute({
          categoryName: res.data.category.name,
          categoryId: res.data.category._id,
          subCategoryId: res.data.subcategory._id,
          subCategoryName: res.data.subcategory.Sub_Category_name,
          productName: res.data?.product_name,
          productId: res.data._id,
        })
      );
    })();
    window.scroll(0, 0);
  }, [p_id, checkRating]);
  function changeVariationHandler(targetVariationName, targetVariationValue) {

    setSelectedVariation({
      ...selectedVariation,
      [targetVariationName]: targetVariationValue,
    });
    if (Object.keys(allVariations)[0] === targetVariationName) {
      let indexOfQueryVar = allVariations[targetVariationName].findIndex(
        (o) => o.attrs[targetVariationName] === targetVariationValue
      );
      if (indexOfQueryVar > -1) {
        allVariations[targetVariationName][indexOfQueryVar]?.product_name &&
          setProductName(
            allVariations[targetVariationName][indexOfQueryVar]?.product_name
          );
        allVariations[targetVariationName][indexOfQueryVar]?.desc &&
          setSpecifications(
            allVariations[targetVariationName][indexOfQueryVar]?.desc
          );
        allVariations[targetVariationName][indexOfQueryVar]
          ?.product_description &&
          setProductDescription(
            allVariations[targetVariationName][indexOfQueryVar]
              ?.product_description
          );
        allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs &&
          setCurrentImages([
            `${allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs}`,
          ]);
        allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs
          ? setImage(
              `${allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs}`
            )
          : setImage(currentImages[0]);
        allVariations[targetVariationName][indexOfQueryVar].attrs?.imgs &&
          setSelectedImage(0);
        if (
          allVariations[targetVariationName][indexOfQueryVar]
            ?.discounted_price > 0
        ) {
          setProductPrices({
            price: allVariations[targetVariationName][indexOfQueryVar]?.price,
            discountedPrice:
              allVariations[targetVariationName][indexOfQueryVar]
                ?.discounted_price,
            discountedPercent:
              allVariations[targetVariationName][indexOfQueryVar]
                ?.discounted_percent,
          });
        } else {
          setProductPrices({
            price: 0,
            discountedPrice: 0,
            discountedPercent: 0,
          });
        }
      }
    }
    if (query.get([targetVariationName])) {
      searchParams.set([targetVariationName], targetVariationValue);
    } else {
      searchParams.append([targetVariationName], targetVariationValue);
    }
    setSearchParams(searchParams);
  }

  function deselectVariationHandler(targetVariationName, targetVariationValue) {
    searchParams.delete([targetVariationName]);
    setSearchParams(searchParams);
    let newVariationObject = selectedVariation;
    delete newVariationObject[targetVariationName];
    setSelectedVariation({ ...newVariationObject });
    if (targetVariationName === Object.keys(product?.vars)[0]) {
      if (product?.discounted_price > 0) {
        setProductPrices({
          price: product?.price,
          discountedPrice: product?.discounted_price,
          discountedPercent: product?.discounted_percent,
        });
      } else {
        setProductPrices({
          price: 0,
          discountedPrice: 0,
          discountedPercent: 0,
        });
      }
      setProductName(product?.product_name);
      setSpecifications(product?.desc);
      setProductDescription(product.product_description);
      product?.attrs?.imgs.length > 0 &&
        setCurrentImages([...product?.attrs?.imgs]);
      product?.attrs?.imgs.length > 0 && setImage(product?.attrs?.imgs[0]);
      product?.attrs?.imgs.length > 0 && setSelectedImage(0);
      setSearchParams(searchParams);
    }
  }
  let chartThree = {
    series: [
      {
        name: "series-1",
        data: graphRatingCound,
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        id: "basic-bar",
        height: "5px",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: ["#000"],
      xaxis: {
        categories: [1, 2, 3, 4, 5],
      },
    },
  };
  useEffect(() => {
    // setIsLoading(true)
    // state?.product?.rating_and_review?.forEach((x) => {
    //     if (x.user_id?._id !== state.user?._id) {
    //         setReviewState(false);
    //     }else{
    //         setReviewState(true);
    //     }
    // })
    setGetPriceTimestamp(null);
    state?.product?.rating_and_review.some(function (x) {
      if (x.user_id?._id === state.user?._id) {
        setReviewState(false);
        return true;
      } else {
        setReviewState(true);
      }
    });
    // setIsLoading(false)
  }, [state?.product]);

  const [img, setimg] = useState({ bathroom1 });

  function truncate(text) {
    return text?.length > 200 ? text.substring(0, 200) + "..." : text;
  }

  function truncate2(text) {
    return text?.length > 150 ? text.substring(0, 150) + "..." : text;
  }

  const showdropdown = () => {
    setdropdown(!isdropdown);
    !isdropdown && setProductDescription(productDescription);
  };

  const showReviewModal = () => {
    setReviewModal(true);
  };
  const hideReviewModal = () => {
    setReviewModal(false);
    setNewRating(0);
    setReviewText("");
    setDeleteModalStatus(false);
    setImageStatus(null);
    setImageField(null);
    setImageUrl(null);
    setImageTarget(null);
  };

  const updateReviewHander = async (r_id) => {
    try {
      if ((newRating !== 0) & (reviewText?.length > 0)) {
        const res = await api.patch(
          `/review/${r_id}`,
          {
            rating: newRating,
            review: reviewText,
            reviewImage,
          },
          {
            headers: {
              // name:'xyz'
            },
          }
        );
        if (res.status === 200) {
          alert.success(res.data.message);
          setCheckRating(!checkRating);
          setOneRating(0);
          setTwoRating(0);
          setThreeRating(0);
          setFourRating(0);
          setFiveRating(0);
        } else {
          alert.error(res.data.error);
        }
        setReviewModal(false);
        dispatch(setAvgRating(res.data?.averageRating));
        dispatch(editReview(res.data?.review));
      } else {
        alert.error("Please Add New Rating / Review");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleImageEdit = async (e) => {
    let file = e.target;
    const { url, fields } = await fetch(`${API_URL}/review/addImage`).then(
      (response) => response.json()
    );
    setReviewImage(
      `https://${fields.bucket}.s3.ap-south-1.amazonaws.com/${fields.key}`
    );
    setImageStatus(URL.createObjectURL(e.target.files[0]));
    setImageField(fields);
    setImageUrl(url);
    setImageTarget(file);
  };

  const uploadImageSubmit = async () => {
    const data = {
      bucket: imageField.bucket,
      ...imageField,
      "Content-Type": imageTarget.files[0].type,
      //   'ACL':'public-read-write',
      file: imageTarget.files[0],
    };
    const formData = new FormData();
    for (const name in data) {
      formData.append(name, data[name]);
    }

    const res = await axios.post(`${imageUrl}`, formData);
    if (res.status === 204) {
      alert.success("Image Uploaded Successfully");
    }
  };

  const deleteReviewHander = async (p_id, r_id) => {
    try {
      const res = await api.delete(
        `/review/${r_id}`,
        {
          productId: p_id,
        },
        {
          headers: {
            // name:'xyz'
          },
        }
      );
      if (res.status === 200) {
        alert.success(res.data?.message);
        setCheckRating(!checkRating);
        setOneRating(0);
        setTwoRating(0);
        setThreeRating(0);
        setFourRating(0);
        setFiveRating(0);
        // setDeleteReviews(true);
        setReviewState(true);
      } else {
        alert.error(res.data?.error);
      }
      setReviewModal(false);
      checkEditOrAdd();
      dispatch(setAvgRating(res.data?.averageRating));
      dispatch(deleteReview(res.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
  const diff = (updatedDate) => {
    let today = new Date();
    return moment(today).diff(moment(updatedDate), "days", false);
  };
  function addReviewModalHandler() {
    if (state?.authenticated || !state?.accessToken === null) {
      showReviewModal();
    } else {
      setIsLoginPopupVisible(true);
    }
  }

  function numberWithCommas(x) {
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  // useEffect(() => {
  //     let index = state?.product?.rating_and_review?.findIndex(x=>x?.user_id === state.user?._id);

  // }, [state?.product?.rating_and_review])

  let arr = state?.product?.rating_and_review?.sort(function (x, y) {
    return x?.user_id?._id === state.user?._id
      ? -1
      : y?.user_id?._id === state.user?._id
      ? 1
      : 0;
  });
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content={`Plypicker.com | ${product?.category?.name}`}
        />
        <meta
          name="description"
          content={`plypicker.com: Only genuine products. Delivery within 48hr. only available on plypicker https://plypicker.com/product/${product?._id} `}
        />
        <meta
          name="keywords"
          content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
        />
        <meta property="og:image" content="LOGO" />
        <link
          rel="canonical"
          href={`https://plypicker.com/product/${product?._id}`}
        />
      </Helmet>

      {!isLoading && <ChatBot pageType={"NPD"} product={product} />}
      {notfound ? (
        <ProductError />
      ) : isLoading ? (
        <div className="loadingDetaildiv">
          <h4 className="m-0 px-1">Loading your product...</h4>
          <h4 className="m-0 px-1">
            <CircularProgress style={{ color: "#ff7703" }} />
          </h4>
        </div>
      ) : (
        <>
          <LoginRegisterPopup
            isVisible={isLoginPopupVisible}
            setIsVisible={setIsLoginPopupVisible}
          />
          {reviewModal && (
            <Modal
              show={reviewModal}
              size={deleteModalStatus ? "md" : "lg"}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={hideReviewModal}
            >
              {/* <Modal.Header closeButton> */}
              {/* <Modal.Title id="contained-modal-title-vcenter"> */}

              {/* </Modal.Title> */}
              {/* </Modal.Header> */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <span
                  style={{
                    margin: "0px auto",
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: 600,
                    lineHeight: "36px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}
                >
                  {deleteModalStatus ? "Delete Review" : "RATING AND REVIEW"}{" "}
                </span>
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  color="#000000"
                  onClick={() => hideReviewModal()}
                ></button>
              </div>
              <Modal.Body>
                <div className="addRatingModalBody">
                  {deleteModalStatus ? (
                    <div className="deleteReviewBody">
                      <h5>Are you sure want to delete this review?</h5>
                      <div className="deleteReview_Actions">
                        <button
                          className="CloseaddUpdateButton"
                          style={{ borderRadius: "0px" }}
                          onClick={() => hideReviewModal()}
                        >
                          Close
                        </button>
                        <button
                          className="addUpdateButton"
                          style={{ borderRadius: "0px" }}
                          onClick={() => {
                            setDeleteModalStatus(false);
                            deleteReviewHander(
                              product?._id,
                              selectedReview._id
                            );
                            hideReviewModal();
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="reviewBody"
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <img
                          src={product?.attrs?.imgs[0]}
                          alt={product?.product_name}
                          height="126px"
                          width="104px"
                        />
                        <div
                          className="starRating_master"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "82%",
                            justifyContent: "space-between",
                            marginLeft: "8px",
                            wordBreak: "break-all",
                          }}
                        >
                          <span>
                            {productDescription?.length > 0 ? (
                              productDescription?.length > 150 ? (
                                <>
                                  <p
                                    style={{
                                      fontFamily: "Poppins",
                                      fontSize: "16px",
                                      fontWeight: 300,
                                      lineHeight: "24px",
                                      letterSpacing: "0em",
                                      textAlign: "left",
                                    }}
                                  >
                                    {isTruncated
                                      ? truncate2(productDescription)
                                      : productDescription}
                                  </p>
                                </>
                              ) : (
                                <p
                                  style={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    lineHeight: "24px",
                                    letterSpacing: "0em",
                                    textAlign: "left",
                                  }}
                                >
                                  {productDescription}
                                </p>
                              )
                            ) : (
                              product?.product_name
                            )}
                          </span>
                          <Rating
                            name="simple-controlled"
                            value={newRating}
                            size="large"
                            onChange={(event, newValue) => {
                              setNewRating(newValue);
                            }}
                          />
                        </div>
                      </div>
                      <Form style={{ marginTop: "30px" }}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            as="textarea"
                            placeholder="Please write the review here"
                            height="244px"
                            value={reviewText}
                            onChange={(e) => setReviewText(e.target.value)}
                            rows={3}
                            style={{ height: "250px" }}
                          />
                        </Form.Group>
                      </Form>

                      {/* <Tooltip title={(selectedReview.reviewImage === "" || selectedReview.length === 0) ? "Add Image" : "Change Image"} placement="right"> */}
                      <div className="uploadPhoto">
                        {reviewImage ? (
                          <label
                            htmlFor="reviewImage"
                            style={{ padding: "4px" }}
                          >
                            <Tooltip title="Edit Image">
                              <span>
                                <span
                                  style={{
                                    alignItems: "center",
                                    border: "1px dashed rgb(0, 0, 0)",
                                    display: "flex",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                    cursor: "pointer",
                                  }}
                                >
                                  <AddImage />
                                </span>
                                <img
                                  src={!imageStatus ? reviewImage : imageStatus}
                                  alt="+"
                                  style={{
                                    height: "200px",
                                    width: "160px",
                                    padding: "4px",
                                  }}
                                />
                              </span>
                            </Tooltip>
                          </label>
                        ) : (
                          <label htmlFor="reviewImage">
                            {" "}
                            + <br />
                          </label>
                        )}
                        <input
                          type="file"
                          id="reviewImage"
                          accept="image/*"
                          onChange={handleImageEdit}
                        />
                      </div>
                      {/* </Tooltip> */}
                      <span style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                          className="submitReview"
                          onClick={() => {
                            hideReviewModal();
                            uploadImageSubmit();
                            updateReviewHander(selectedReview?._id);
                          }}
                        >
                          Submit
                        </Button>
                      </span>
                    </>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          )}
          <NavRoute navRouter title="Product Route" productId={p_id} />
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12 position-relative imageColumnNPD">
                <div className="mobileFavourite d-md-none d-block position-absolute end-0 me-4 mt-1">
                  {state.user?.wishlist.includes(product?._id) ? (
                    <BsSuitHeartFill
                      onClick={() => removeFromWishlistHandler(product._id)}
                      style={{
                        color: "#FF7253",
                        transform: "scale(1.5)",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <BsSuitHeart
                      onClick={() => addToWishListHandler(product._id)}
                      style={{
                        color: "#ff7253",
                        transform: "scale(1.5)",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
                <div className="d-flex justify-content-center">
                  {isSmall ? (
                    <InnerImageZoom
                      src={
                        image
                          ? image
                          : currentImages[0] || product?.attrs?.imgs[0]
                      }
                      className="newProductDetailBigImg"
                      zoomScale={1.5}
                      hideHint={true}
                      hasSpacer={true}
                    />
                  ) : (
                    <ReactImageMagnify
                      style={{ zIndex: 2 }}
                      {...{
                        smallImage: {
                          alt: product?.product_name,
                          isFluidWidth: true,
                          src: image ? image : currentImages[0],
                        },
                        largeImage: {
                          src: image ? image : currentImages[0],
                          width: 700,
                          height: 700,
                        },
                      }}
                    />
                  )}
                </div>
                <div className="shopbycategory_boxes smallerimg col-12 col-lg-12 col-md-12 col-sm-12">
                  {currentImages.map((image, i) => (
                    <img
                      className={i === selectedImage ? "active-image" : ""}
                      src={image}
                      alt=""
                      style={{ cursor: "pointer", objectFit: "cover" }}
                      onClick={(e) => {
                        setImage(image);
                        setSelectedImage(i);
                      }}
                    />
                  ))}
                </div>
                {productPrices.discountedPrice > 0 && (
                  <div className="row d-flex justify-content-center align-items-center">
                    <div
                      className="newProductBuyNowButton buynow"
                      type="button"
                      onClick={() => {
                        if (productPrices.discountedPrice > 0) {
                          setIsBuyingNow(true);
                          buyNowHandler({
                            id: product._id,
                            var: currentVariation,
                          });
                          setIsBuyingNow(false);
                        } else {
                          alert.error(
                            "This product doesn't have a price at this moment"
                          );
                        }
                      }}
                    >
                      {isBuyingNow ? (
                        <>
                          <CircularProgress
                            style={{ color: "white" }}
                            size="25px"
                          />
                        </>
                      ) : (
                        <>BUY NOW</>
                      )}
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        if (productPrices.discountedPrice > 0) {
                          setIsAddingToCart(true);
                          addToCartHandler({
                            id: product._id,
                            var: currentVariation,
                          });
                          setIsAddingToCart(false);
                        } else {
                          alert.error(
                            "This product doesn't have a price at this moment"
                          );
                        }
                      }}
                      className="newProductAddToCartButton"
                      disabled={isAddingToCart}
                    >
                      <img src={shoppingCart} />
                    </button>
                  </div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <div className="row newProductStarRatingRow">
                  <div className="col-8">
                    <StarRating stars={state?.product?.averageRating} avgRate />
                  </div>
                  <div className="col-4 text-end d-md-block d-none">
                    <Share
                      style={{
                        fill: "#878787",
                        transform: "scale(0.8)",
                        cursor: "pointer",
                      }}
                      className="me-2"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        alert.success("Link copied to clipboard");
                      }}
                    />
                    {state?.user?.wishlist.includes(state?.product?._id) ? (
                      <Heart
                        style={{ cursor: "pointer", fill: "#FF7253" }}
                        className="borderColor_orange"
                        onClick={() => removeFromWishlist(state?.product?._id)}
                      />
                    ) : (
                      <Heart
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          addToWishListHandler(state?.product?._id)
                        }
                      />
                    )}
                  </div>
                </div>
                <h2 className="newProductTitle">{productName}</h2>
                <h3 className="newProductSubtitle">
                  {product?.brand?.Brand_name}
                </h3>
                <p className="mb-3">{product?.product_id}</p>
                <div className="rowDetail">
                  {!getPriceTimestamp && (
                    <div>
                      {productPrices.discountedPrice > 0 ? (
                        <span className="price">
                          Rs. {numberWithCommas(productPrices?.discountedPrice)}
                        </span>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="getPriceNPD"
                            onClick={(e) => {
                              e.preventDefault();
                              getPrice(product?._id);
                            }}
                            disabled={isGettingPrice}
                          >
                            {isGettingPrice
                              ? "Getting price..."
                              : "Get price now"}
                          </button>
                        </>
                      )}
                      {productPrices.discountedPrice > 0 && (
                        <span className="reducedprice">
                          Rs. {numberWithCommas(productPrices.price)}
                        </span>
                      )}
                      {productPrices.discountedPrice > 0 && (
                        <span className="discount">
                          {/* {getDiscountPercent(state?.product?.price, state?.product?.discounted_price)} */}
                          -
                          {productPrices.discountedPercent > 1
                            ? Math.round(productPrices.discountedPercent)
                            : productPrices.discountedPercent}
                          %
                        </span>
                      )}
                    </div>
                  )}
                  {getPriceTimestamp && (
                    <div className="">
                      {getPriceTimestamp > new Date().getTime() ? (
                        <p className="m-0 countdownTimerNPD">
                          We will contact you with the price before{" "}
                          <Countdown date={getPriceTimestamp} />
                        </p>
                      ) : (
                        <p className="m-0">
                          Thank you for your interest in this product.
                        </p>
                      )}
                    </div>
                  )}
                </div>
                {specifications !== undefined ? (
                  <>
                    <h3 className="newProductSpecificationsTitle">
                      SPECIFICATIONS
                    </h3>
                    <div className="row newProductSpecificationDiv">
                      <div className="col-sm-4 col-6 newProductSpecificationDivHeading">
                        Heading
                      </div>
                      <div className="col-sm-8 col-6 newProductSpecificationDivHeading">
                        Specs
                      </div>
                    </div>
                    <hr className="newProductDetailHr" />
                    {isSpecificationsTruncated ? (
                      <>
                        {Object.entries(specifications)?.map(
                          ([item, index]) => (
                            <>
                              <div className="row newProductSpecificationDiv">
                                <div className="col-sm-4 col-6 newProductSpecificationSpec">
                                  {item}
                                </div>
                                <div className="col-sm-8 col-6 newProductSpecificationSpec">
                                  {index}
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </>
                    ) : (
                      <>
                        {Object.entries(specifications)?.map(
                          ([item, index]) => (
                            <>
                              <div className="row newProductSpecificationDiv">
                                <div className="col-4 newProductSpecificationSpec">
                                  {item}
                                </div>
                                <div className="col-8 newProductSpecificationSpec">
                                  {index}
                                </div>
                              </div>
                            </>
                          )
                        )}
                      </>
                    )}
                    {Object.keys(specifications)?.length > 5 && (
                      <>
                        {isSpecificationsTruncated ? (
                          <>
                            <p
                              onClick={() =>
                                setIsSpecificationsTruncated(false)
                              }
                              className="newProductSeeMore"
                            >
                              See more...
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              onClick={() => setIsSpecificationsTruncated(true)}
                              className="newProductSeeMore"
                            >
                              See less...
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className="variationsSection">
                  {Object.entries(allVariations || {}).map(
                    ([variationName, variationData]) => {
                      if (
                        variationName !== "color" ||
                        variationName !== "colour"
                      ) {
                        return (
                          <>
                            <h3 className="newProductSpecificationsTitle">
                              {variationName}
                            </h3>
                            <div className="d-flex flex-row flex-wrap">
                              {variationData.map(
                                (variationQuantity, variationIndex) => (
                                  <>
                                    <div
                                      className={
                                        selectedVariation[variationName] ===
                                        variationQuantity?.attrs[variationName]
                                          ? `newProductSelectedVariation newProductVariationBox`
                                          : `newProductVariationBox`
                                      }
                                      onClick={() => {
                                        selectedVariation[variationName] ===
                                        variationQuantity?.attrs[variationName]
                                          ? deselectVariationHandler(
                                              variationName,
                                              variationQuantity?.attrs[
                                                variationName
                                              ]
                                            )
                                          : changeVariationHandler(
                                              variationName,
                                              variationQuantity.attrs[
                                                variationName
                                              ]
                                            );
                                      }}
                                    >
                                      {variationQuantity?.attrs[variationName]}
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          </>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  )}
                </div>
                {productDescription?.length > 1 && (
                  <>
                    <h3 className="newProductSpecificationsTitle">
                      DESCRIPTION
                    </h3>
                    <p
                      className="newProductDescriptionDescription"
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 300,
                        lineHeight: "24px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      {isTruncated ? (
                        <>
                          {truncate2(productDescription)}{" "}
                          {productDescription?.length > 150 && (
                            <span
                              className="newProductReadMore"
                              onClick={() => {
                                setIsTruncated(false);
                              }}
                            >
                              Read more
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          {productDescription}{" "}
                          {productDescription?.length > 150 && (
                            <span
                              className="newProductReadMore"
                              onClick={() => {
                                setIsTruncated(true);
                              }}
                            >
                              Read less
                            </span>
                          )}
                        </>
                      )}
                    </p>
                    {/* <hr className="newProductDetailHr" /> */}
                  </>
                )}
                {/* <h3 className="newProductSpecificationsTitle">COLOURS</h3>
                        <div className="colorCol"></div>
                        <h3 className="newProductSpecificationsTitle">SIZE</h3>
                        <hr className="newProductDetailHr" />*/}
                {/* <h3 className="newProductSpecificationsTitle">OFFERS</h3>
                <div className="d-flex flex-row align-items-center">
                  <div className="">
                    <img src={coupon} className="newProductDetailCouponImg" />
                  </div>
                  <div className="">
                    <p className="newProductDetailCoupon m-0">
                      10% discount AXIS Bank debit card
                    </p>
                    <Link className="newProductViewCriteria" to={`#`}>
                      View Eligibility criteria
                    </Link>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="">
                    <img src={coupon} className="newProductDetailCouponImg" />
                  </div>
                  <div className="">
                    <p className="newProductDetailCoupon m-0">
                      10% discount AXIS Bank debit card
                    </p>
                    <Link className="newProductViewCriteria" to={`#`}>
                      View Eligibility criteria
                    </Link>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="">
                    <img src={coupon} className="newProductDetailCouponImg" />
                  </div>
                  <div className="">
                    <p className="newProductDetailCoupon m-0">
                      10% discount AXIS Bank debit card
                    </p>
                    <Link className="newProductViewCriteria" to={`#`}>
                      View Eligibility criteria
                    </Link>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <div className="">
                    <img src={coupon} className="newProductDetailCouponImg" />
                  </div>
                  <div className="">
                    <p className="newProductDetailCoupon m-0">
                      10% discount AXIS Bank debit card
                    </p>
                    <Link className="newProductViewCriteria" to={`#`}>
                      View Eligibility criteria
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row w-100 m-0 mt-5 p-0">
            <h3 className="newProductSpecificationsTitle">
              RATINGS AND REVIEW
            </h3>
            <hr className="newProductDetailHr" />
            <div className="d-flex flex-column">
              <div className="newProductRatingDiv d-flex flex-row align-items-center">
                <h1 className="newProductAverageRating">
                  {state?.product?.averageRating}
                </h1>
                <div className="ms-3">
                  <span className="newProductRatingDiv_span row">
                    <p className="col-2 newProductRatingNumber">5</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={fiveRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">4</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={fourRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">3</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={threeRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">2</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={twoRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">1</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={oneRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                </div>
              </div>
              {thisReviewRating && thisReviewRating.addModal ? (
                <>
                  <button
                    className="newProductBuyNowButton btn"
                    style={{
                      width: "200px",
                      border: "1px solid #ff7703",
                      color: "#ffffff",
                      backgroundColor: "#ff7703",
                      borderRadius: "0px",
                      marginLeft: "3.5%",
                    }}
                    onClick={() => {
                      navigate(`/rating-review/${p_id}`);
                    }}
                  >
                    Add Review
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="newProductReviewDiv row row-cols-4">
              {!isReviewDropdown ? (
                <>
                  {state?.product?.rating_and_review
                    ?.slice(0, 4)
                    .map((review) => (
                      <>
                        <div className="col-lg-3 col-sm-6 col-12 newProductReviewSingleDiv">
                          <div className="newProductReviewInnerDiv d-flex flex-column">
                            <img
                              src={review?.reviewImage}
                              className="newProductReviewUserImage"
                              width="100%"
                            />
                            <div className="newProductReviewInsideDiv">
                              <h4 className="text-center newProductReviewAuthor">
                                {review?.user_id?.name}
                              </h4>
                              <p className="text-center newProductReviewUserDescription">
                                {review?.description}
                              </p>
                            </div>
                            <div className="newProductReviewFooter row">
                              <div className="col">
                                <span className="newProductReviewDate">
                                  {diff(review?.updatedAt) === 0
                                    ? "Just Now"
                                    : diff(review?.updatedAt) + " days ago"}
                                </span>
                                <span className="newProductReviewRatingSmall">
                                  {review?.rating}{" "}
                                  <img
                                    src={star}
                                    className="newProductReviewRatingStar"
                                  />
                                </span>
                              </div>
                              <div className="col text-end">
                                {review?.user_id?._id === state?.user?._id ? (
                                  <div className="reviewsAction hoverMe">
                                    <Tooltip title="Edit">
                                      <span>
                                        <EditButton
                                          className="editReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setSelectedReview(review);
                                            setNewRating(review?.rating);
                                            setReviewText(review?.description);
                                            setReviewImage(review?.reviewImage);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <span>
                                        <TrashButton
                                          className="deleteReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setDeleteModalStatus(true);
                                            setSelectedReview(review);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </>
              ) : (
                <>
                  {state?.product?.rating_and_review
                    ?.sort(function (x, y) {
                      return x?.user_id?._id === state.user?._id
                        ? -1
                        : y?.user_id?._id === state.user?._id
                        ? 1
                        : 0;
                    })
                    .map((review) => (
                      <>
                        <div className="col-lg-3 col-sm-6 col-12 newProductReviewSingleDiv">
                          <div className="newProductReviewInnerDiv d-flex flex-column">
                            <img
                              src={review?.reviewImage}
                              className="newProductReviewUserImage"
                              width="100%"
                            />
                            <div className="newProductReviewInsideDiv">
                              <h4 className="text-center newProductReviewAuthor">
                                {review?.user_id?.name}
                              </h4>
                              <p className="newProductReviewUserDescription text-center">
                                {review?.description}
                              </p>
                            </div>
                            <div className="newProductReviewFooter row">
                              <div className="col">
                                <span className="newProductReviewDate">
                                  {diff(review?.updatedAt) === 0
                                    ? "Just Now"
                                    : diff(review?.updatedAt) + " days ago"}
                                </span>
                                <span className="newProductReviewRatingSmall">
                                  {review?.rating}{" "}
                                  <img
                                    src={star}
                                    className="newProductReviewRatingStar"
                                  />
                                </span>
                              </div>
                              <div className="col text-end">
                                {review?.user_id?._id === state?.user?._id ? (
                                  <div className="reviewsAction hoverMe">
                                    <Tooltip title="Edit">
                                      <span
                                        className="editReview hoverMe"
                                        style={{ fontSize: "1.7rem" }}
                                      >
                                        <EditButton
                                          className="editReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setSelectedReview(review);
                                            setNewRating(review.rating);
                                            setReviewText(review.description);
                                            setReviewImage(review.reviewImage);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <span
                                        className="deleteReview hoverMe"
                                        style={{ fontSize: "1.7rem" }}
                                      >
                                        <TrashButton
                                          className="deleteReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setDeleteModalStatus(true);
                                            setSelectedReview(review);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </>
              )}
            </div>
            {state?.product?.rating_and_review?.length > 4 && (
              <>
                <button
                  className="newProductReviewDropdownButton"
                  type="button"
                  onClick={() => {
                    setIsReviewDropdown(!isReviewDropdown);
                  }}
                >
                  {isReviewDropdown ? <>VIEW LESS</> : <>VIEW ALL</>}
                </button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default NewProductDetail;
