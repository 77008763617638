import { useEffect, useState } from "react";
import useAxios from "../../../../../utils/useAxios";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { useNavigate, useParams, Link } from "react-router-dom";
import NavRoute from "../../../Nav_Route/NavRoute";
import SideBar from "../../../../global_component/SideBar/SideBar";
import { useMediaQuery } from "react-responsive";
import { CircularProgress, Step, StepLabel, Stepper } from "@mui/material";
import OrderComponent from "./OrderComponent";
import { ReactComponent as NotdeliveredIcon } from "./icons/NotdeliveredIcon.svg";
import { ReactComponent as DeliveredIcon } from "./icons/DeliveredIcon.svg";
import { BiDownload } from "react-icons/bi";
import { useStore } from "../../../../../store/store";
import axios from "axios";
import Modal from "react-awesome-modal";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function CancelModal({ isOpen, setIsOpen, cancelOrderId }) {
  return (
    <Modal
      visible={isOpen}
      width="450"
      height="270"
      effect="fadeInUp"
      style={{ borderRadius: "0px" }}
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      <div
        className="px-3 py-4 d-flex flex-column justify-content-center align-items-center login-box"
        style={{ height: "100%", borderRadius: 0 }}
      >
        <h3 className="text-center my-3">Do You Want to Delete this order?</h3>
        <div
          class="d-flex flex-row align-items-center w-100"
          style={{ justifyContent: "space-evenly" }}
        >
          <button
            class="CloseaddUpdateButton ms-2"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            No
          </button>
          <button
            class="addUpdateButton"
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              const res = await axios.post(`${API_URL}/orders/cancelRequest`, {
                id: cancelOrderId,
              });
              if (res.data.status) {
                window.alert(res.data.msg);
              }
              setIsOpen(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

function OrderDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState([]);
  const [state, dispatch] = useStore();
  const api = useAxios();
  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });
  const [deliverySteps, setDeliverySteps] = useState([
    "Pending",
    "Processing",
    "Shipped",
    "Delivered",
  ]);
  const [servicesSteps, setServicesSteps] = useState([
    "PARTIAL PAYMENT PENDING",
    "PARTIAL PAYMENT SUCCESSFULL",
    "PAYMENT SUCCESSFULL",
  ]);

  ReactGA.send({
    hitType: "pageview",
    page: "/order/:id",
    title: "Order Details",
  });

  function getActiveDeliveryStep(order) {
    // console.log(order.data.delivery.status + " order.data.delivery.status ")
    if (order.title === "orders") {
      switch (order?.data?.delivery?.status) {
        case "not delivered":
          return 0;
          break;
        case "processing":
          return 1;
          break;
        case "shipped":
          return 3;
          break;
        case "delivered":
          return 3;
          break;
        default:
          break;
      }
    } else {
      switch (order?.data?.payment?.status) {
        case "PARTIAL PAYMENT PENDING":
          return 0;
          break;
        case "PARTIAL PAYMENT SUCCESSFULL":
          return 2;
          break;
        case "PAYMENT SUCCESSFULL":
          return 3;
          break;
        default:
          break;
      }
    }
  }
  function numberWithCommas(x) {
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }
  const getOrderDate = (date) => {
    var deliver = new Date(date);
    deliver.setDate(deliver.getDate() + 2);
    var dd = String(deliver.getDate()).padStart(2, "0");
    var mm = String(deliver.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = deliver.getFullYear();

    deliver = dd + "/" + mm + "/" + yyyy;
    return deliver;
  };
  const getServiceDate = (date) => {
    var deliver = new Date(date);
    var year = deliver.getFullYear();
    var month = deliver.getMonth() + 1;
    var dt = deliver.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${dt}/${month}/${year}`;
  };

  useEffect(() => {
    async function fetch() {
      document.title = `Your Orders - PLYPICKER`;
      setLoading(true);
      const res = await api.get(API_URL + `/orders/${params?.id}`);
      if (!res.data.error) {
        setOrder(res.data);
      } else {
        alert.error(res.data.error);
      }
      setLoading(false);
      window.scroll(0, 0);
    }
    fetch();
  }, []);
  const checkReview = (allRating) => {
    // console.log("testdata",allRating)
    //   allRating?.rating_and_review?.some(function (x) {
    //     if (x.user_id === state.user?._id) {
    //         return true;
    //     } else {
    //       return false;
    //     }
    // })
    if (allRating?.rating_and_review.length > 0) {
      for (let item of allRating?.rating_and_review) {
        if (item.user_id === state.user?._id) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <NavRoute title="Order Details" />
      <div
        className="sidebarProfilePageContainer w-100 px-0"
        style={!isMobile ? { borderTop: "1px solid black" } : {}}
      >
        <div className="row m-0 p-0 w-100">
          <SideBar />
          <div className="col-sm-9 col-12">
            <div className="row m-0 w-100 p-0 currentOrdersScrollbar">
              {loading && (
                <>
                  <div className="mt-5 yourOrdersLoadingDiv">
                    <h4 className="text-center">
                      Loading your orders...{" "}
                      <CircularProgress style={{ color: "#ff7703" }} />
                    </h4>
                  </div>
                </>
              )}
              {!loading ? (
                order.title === "orders" ? (
                  order?.data?.delivery?.status !== "delivered" ? (
                    <div
                      className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                      key={order.data.orderid}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                        <div className="detailColumn container m-0 p-0">
                          <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                            <div>
                              <NotdeliveredIcon
                                style={{ marginRight: "10px" }}
                              />
                              <div>
                                {order?.data?.delivery?.status !==
                                "rejected" ? (
                                  <div className="current_orders_deliverydate current_orders_green">
                                    To be delivered:{" "}
                                    {getOrderDate(order?.data?.createdAt)}
                                  </div>
                                ) : (
                                  <div className="current_orders_deliverydate">
                                    Your order was rejected
                                  </div>
                                )}
                                <div className="current_orders_orderID">
                                  Order ID: {order.data.orderid}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <span style={{ color: "#878787" }}>Amount: </span>
                              Rs. {numberWithCommas(order.data.total)}
                              <br />
                              <a
                                className=""
                                href={`/invoices/${order.data.orderid}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <BiDownload style={{ strokeWidth: "1" }} />
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  Download Invoice
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="col-4 ps-3 d-lg-block "></div>
                          <hr />
                          {order?.data?.delivery?.status !== "rejected" && (
                            <div className="w-md-75 w-100 mx-auto py-3 yourOrderDeliveryStepperDiv">
                              <Stepper
                                activeStep={getActiveDeliveryStep(order)}
                                alternativeLabel
                              >
                                {deliverySteps.map((label) => (
                                  <Step key={label}>
                                    <StepLabel icon={" "}>{label}</StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            </div>
                          )}
                          {order.data.order_items.map((e) =>
                            order.title === "orders" ? (
                              <>
                                <div
                                  className="d-flex flex-row px-2 py-2"
                                  style={{ boder: "1px solid black" }}
                                >
                                  <div className="p-1">
                                    <img
                                      onClick={() =>
                                        navigate(`/product/${e?.product?._id}`)
                                      }
                                      src={e?.image}
                                      className="orderComponentImg"
                                    />
                                  </div>
                                  <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                                    <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                      <div className="mw-100">
                                        <h5
                                          className="orderComponentProductName"
                                          onClick={() =>
                                            navigate(
                                              `/product/${e?.product?._id}`
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {e?.name}
                                        </h5>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              `/productlist?brand=${e?.product?.brand?._id}`
                                            )
                                          }
                                          className="wishlist_product_company"
                                        >
                                          {e?.product?.brand?.Brand_name}
                                        </span>
                                        <div>
                                          {e?.variation &&
                                            Object.entries(e?.variation).map(
                                              ([
                                                variationKey,
                                                variationValue,
                                              ]) => (
                                                <>
                                                  <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                                    {variationKey}:{" "}
                                                    {variationValue}
                                                  </div>
                                                </>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="current_orders_orderID text-dark">
                                          Quantity: {e?.quantity}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                      <p className="m-0 p-0 text-end">
                                        Rs. {numberWithCommas(e?.price)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <span className="d-flex justify-content-sm-end justify-content-start">
                                  {order.data.delivery.status ===
                                  "delivered" ? (
                                    !checkReview(e?.product) ? (
                                      <button
                                        className="CloseaddUpdateButton"
                                        onClick={() => {
                                          navigate(
                                            `/rating-review/${e.product._id}`
                                          );
                                        }}
                                      >
                                        Review Product
                                      </button>
                                    ) : (
                                      <button
                                        className="CloseaddUpdateButton"
                                        onClick={() => {
                                          navigate(
                                            `/rating-review/${e.product._id}`
                                          );
                                        }}
                                      >
                                        Edit Review
                                      </button>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                                <hr
                                  style={{
                                    margin: "0px",
                                    width: "95%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  className="d-flex flex-row px-2 py-2"
                                  style={{ boder: "1px solid black" }}
                                >
                                  <div className="p-1">
                                    <img
                                      onClick={() =>
                                        navigate(
                                          `/hire-artisans/services/${e?.service?._id}`
                                        )
                                      }
                                      src={e?.image}
                                      className="orderComponentImg"
                                    />
                                  </div>
                                  <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                                    <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                      <div className="mw-100">
                                        <h5
                                          className="orderComponentProductName"
                                          onClick={() =>
                                            navigate(
                                              `/hire-artisans/services/${e?.service?._id}`
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {e?.name}
                                        </h5>
                                        <span
                                          onClick={() =>
                                            navigate(
                                              `/hire-artisans/${e?.service?.serviceCategory?._id}/${e?.service?.serviceSubcategory?._id}`
                                            )
                                          }
                                          className="wishlist_product_company"
                                        >
                                          {
                                            e?.service?.serviceSubcategory
                                              ?.serviceSubcategory
                                          }
                                        </span>
                                        <div>
                                          {e?.variation &&
                                            Object.entries(e?.variation).map(
                                              ([
                                                variationKey,
                                                variationValue,
                                              ]) => (
                                                <>
                                                  <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                                    {variationKey}:{" "}
                                                    {variationValue}
                                                  </div>
                                                </>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div>
                                        <div className="current_orders_orderID text-dark">
                                          Quantity: {e?.quantity}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                      <p className="m-0 p-0 text-end">
                                        Rs. {numberWithCommas(e?.price)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <span className="d-flex justify-content-sm-end justify-content-start">
                {order.data.payment.status !== "PENDING" ? !checkReview(e?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Edit Review</button>) : ""}
              </span> */}
                                <hr
                                  style={{
                                    margin: "0px",
                                    width: "95%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </>
                            )
                          )}
                          {order?.data?.delivery?.status === "not delivered" ? (
                            <>
                              <button
                                className="cancelOrderBtn"
                                onClick={(e) => {
                                  // e.preventDefault();
                                  setIsOpenModal(true);
                                  setCancelOrderId(order.data._id);
                                }}
                              >
                                {" "}
                                Cancel Order
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                      key={order.data._id}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                        <div className="detailColumn container m-0 p-0">
                          <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                            <div>
                              <DeliveredIcon style={{ marginRight: "10px" }} />
                              <div
                                className="current_orders_deliverydate current_orders_green"
                                style={{ color: "#000000" }}
                              >
                                Delivered: {getOrderDate(order.data.createdAt)}
                              </div>
                              <div className="current_orders_orderID">
                                Order ID: {order.data._id}
                              </div>
                            </div>
                            <div>
                              <span style={{ color: "#878787" }}>Amount: </span>
                              Rs. {numberWithCommas(order.data.total)}
                              <br />
                              <Link
                                className=""
                                href={`/invoices/${order.data._id}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <BiDownload style={{ strokeWidth: "1" }} />
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  Download Invoice
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div>
                            {order.data.order_items.map((e) =>
                              order.title === "orders" ? (
                                <>
                                  <div
                                    className="d-flex flex-row px-2 py-2"
                                    style={{ boder: "1px solid black" }}
                                  >
                                    <div className="p-1">
                                      <img
                                        onClick={() =>
                                          navigate(
                                            `/product/${e?.product?._id}`
                                          )
                                        }
                                        src={e?.image}
                                        className="orderComponentImg"
                                      />
                                    </div>
                                    <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                                      <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                        <div className="mw-100">
                                          <h5
                                            className="orderComponentProductName"
                                            onClick={() =>
                                              navigate(
                                                `/product/${e?.product?._id}`
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            {e?.name}
                                          </h5>
                                          <span
                                            onClick={() =>
                                              navigate(
                                                `/productlist?brand=${e?.product?.brand?._id}`
                                              )
                                            }
                                            className="wishlist_product_company"
                                          >
                                            {e?.product?.brand?.Brand_name}
                                          </span>
                                          <div>
                                            {e?.variation &&
                                              Object.entries(e?.variation).map(
                                                ([
                                                  variationKey,
                                                  variationValue,
                                                ]) => (
                                                  <>
                                                    <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                                      {variationKey}:{" "}
                                                      {variationValue}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="current_orders_orderID text-dark">
                                            Quantity: {e?.quantity}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                        <p className="m-0 p-0 text-end">
                                          Rs. {numberWithCommas(e?.price)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="d-flex justify-content-sm-end justify-content-start">
                                    {order.data.delivery.status ===
                                    "delivered" ? (
                                      !checkReview(e?.product) ? (
                                        <button
                                          className="CloseaddUpdateButton"
                                          onClick={() => {
                                            navigate(
                                              `/rating-review/${e.product._id}`
                                            );
                                          }}
                                        >
                                          Review Product
                                        </button>
                                      ) : (
                                        <button
                                          className="CloseaddUpdateButton"
                                          onClick={() => {
                                            navigate(
                                              `/rating-review/${e.product._id}`
                                            );
                                          }}
                                        >
                                          Edit Review
                                        </button>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <hr
                                    style={{
                                      margin: "0px",
                                      width: "95%",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <div
                                    className="d-flex flex-row px-2 py-2"
                                    style={{ boder: "1px solid black" }}
                                  >
                                    <div className="p-1">
                                      <img
                                        onClick={() =>
                                          navigate(
                                            `/hire-artisans/services/${e?.service?._id}`
                                          )
                                        }
                                        src={e?.image}
                                        className="orderComponentImg"
                                      />
                                    </div>
                                    <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                                      <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                        <div className="mw-100">
                                          <h5
                                            className="orderComponentProductName"
                                            onClick={() =>
                                              navigate(
                                                `/hire-artisans/services/${e?.service?._id}`
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            {e?.name}
                                          </h5>
                                          <span
                                            onClick={() =>
                                              navigate(
                                                `/hire-artisans/${e?.service?.serviceCategory?._id}/${e?.service?.serviceSubcategory?._id}`
                                              )
                                            }
                                            className="wishlist_product_company"
                                          >
                                            {
                                              e?.service?.serviceSubcategory
                                                ?.serviceSubcategory
                                            }
                                          </span>
                                          <div>
                                            {e?.variation &&
                                              Object.entries(e?.variation).map(
                                                ([
                                                  variationKey,
                                                  variationValue,
                                                ]) => (
                                                  <>
                                                    <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                                      {variationKey}:{" "}
                                                      {variationValue}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="current_orders_orderID text-dark">
                                            Quantity: {e?.quantity}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                        <p className="m-0 p-0 text-end">
                                          Rs. {numberWithCommas(e?.price)}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <span className="d-flex justify-content-sm-end justify-content-start">
                {order.data.payment.status !== "PENDING" ? !checkReview(e?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Edit Review</button>) : ""}
              </span> */}
                                  <hr
                                    style={{
                                      margin: "0px",
                                      width: "95%",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                    }}
                                  />
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "10px",
                  }}
                >
                  <h3>No orders yet!</h3>
                </div>
              )}

              {!loading ? (
                order.title === "services" ? (
                  order?.data?.payment?.status !== "PAYMENT SUCCESSFULL" ? (
                    <div
                      className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                      key={order.data._id}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                        <div className="detailColumn container m-0 p-0">
                          <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                            <div>
                              <NotdeliveredIcon
                                style={{ marginRight: "10px" }}
                              />
                              <div>
                                {order?.data?.payment?.status !==
                                "PARTIAL PAYMENT PENDING" ? (
                                  <div className="current_orders_deliverydate current_orders_green">
                                    To be Completed:{" "}
                                    {getServiceDate(
                                      order.data.serviceScheduledDate
                                    )}
                                  </div>
                                ) : (
                                  <div className="current_orders_deliverydate">
                                    Your order was rejected
                                  </div>
                                )}
                                <div className="current_orders_orderID">
                                  Order ID: {order.data?.orderid}
                                </div>
                              </div>
                            </div>
                            <div className="">
                              <span style={{ color: "#878787" }}>Amount: </span>
                              Rs. {numberWithCommas(order.data.total)}
                              <br />
                              {/* <a
                                  className=""
                                  href={`/invoices/${order.data._id}`}
                                  target="_blank"
                                  style={{
                                    color: "#8A5B40",
                                    textDecoration: "none",
                                  }}
                                >
                                  <BiDownload style={{ strokeWidth: "1" }} />
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    Download Invoice
                                  </span>
                                </a> */}
                              <br />
                              <a
                                href={`/service/terms-and-condition/${order.data._id}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  View Agreement
                                </span>
                              </a>
                              <br />
                              <a
                                href={`/serviceinvoices/${order.data.orderid}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  View Invoice
                                </span>
                              </a>
                            </div>
                          </div>
                          <div className="col-4 ps-3 d-lg-block "></div>
                          <hr />
                          {order.title === "services" && (
                            <div className="w-md-75 w-100 mx-auto py-3 yourOrderDeliveryStepperDiv">
                              <Stepper
                                activeStep={getActiveDeliveryStep(order)}
                                alternativeLabel
                              >
                                {servicesSteps.map((label, i) => (
                                  <Step key={label}>
                                    <StepLabel icon={" "}>
                                      {label}
                                      {getActiveDeliveryStep(order) === 2 &&
                                        i === 1 && (
                                          <p>
                                            {order.data.advancePercentagePaid}%
                                            paid via {order?.data?.payment?.mode}
                                          </p>
                                        )}
                                    </StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            </div>
                          )}
                          <div
                            className="d-flex flex-row px-2 py-2"
                            style={{ boder: "1px solid black" }}
                          >
                            <div className="p-1">
                              <img
                                onClick={() =>
                                  navigate(
                                    `/hire-artisans/services/${order.data.order_items[0]?.service?._id}`
                                  )
                                }
                                src={order.data.order_items[0]?.image}
                                className="orderComponentImg"
                              />
                            </div>
                            <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                              <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                <div className="mw-100">
                                  <h5
                                    className="orderComponentProductName"
                                    onClick={() =>
                                      navigate(
                                        `/hire-artisans/services/${order.data.order_items[0]?.service?._id}`
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {order.data.order_items[0]?.name}
                                  </h5>
                                  <span
                                    onClick={() =>
                                      navigate(
                                        `/hire-artisans/${order.data.order_items[0]?.service?.serviceCategory?._id}/${order.data.order_items[0]?.service?.serviceSubcategory?._id}`
                                      )
                                    }
                                    className="wishlist_product_company"
                                  >
                                    {
                                      order.data.order_items[0]?.service
                                        ?.serviceSubcategory?.serviceSubcategory
                                    }
                                  </span>
                                  <div>
                                    {order.data.order_items[0]?.variation &&
                                      Object.entries(
                                        order.data.order_items[0]?.variation
                                      ).map(
                                        ([variationKey, variationValue]) => (
                                          <>
                                            <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                              {variationKey}: {variationValue}
                                            </div>
                                          </>
                                        )
                                      )}
                                  </div>
                                </div>
                                <div>
                                  <div className="current_orders_orderID text-dark">
                                    Quantity:{" "}
                                    {order.data.order_items[0]?.quantity}
                                  </div>
                                </div>
                              </div>
                              <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                <p className="m-0 p-0 text-end">
                                  Rs.{" "}
                                  {numberWithCommas(
                                    order.data.order_items[0]?.price
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          {order?.data?.payment?.status ===
                          "PARTIAL PAYMENT SUCCESSFULL" ? (
                            <>
                              <button
                                className="cancelOrderBtn"
                                onClick={(e) => {
                                  // e.preventDefault();
                                  setIsOpenModal(true);
                                  setCancelOrderId(order.data._id);
                                }}
                              >
                                {" "}
                                Cancel Order
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                      key={order.data._id}
                    >
                      <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                        <div className="detailColumn container m-0 p-0">
                          <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                            <div>
                              <DeliveredIcon style={{ marginRight: "10px" }} />
                              <div
                                className="current_orders_deliverydate current_orders_green"
                                style={{ color: "#000000" }}
                              >
                                Completed:{" "}
                                {getServiceDate(
                                  order.data.serviceScheduledDate
                                )}
                              </div>
                              <div className="current_orders_orderID">
                                Order ID: {order.data?.orderid}
                              </div>
                            </div>
                            <div>
                              <span style={{ color: "#878787" }}>Amount: </span>
                              Rs. {numberWithCommas(order.data.total)}
                              <br />
                              {/* <a
                                  className=""
                                  href={`/invoices/${order.data._id}`}
                                  target="_blank"
                                  style={{
                                    color: "#8A5B40",
                                    textDecoration: "none",
                                  }}
                                >
                                  <BiDownload style={{ strokeWidth: "1" }} />
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    Download Invoice
                                  </span>
                                </a> */}
                              <br />
                              <a
                                href={`/service/terms-and-condition/${order.data._id}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  View Agreement
                                </span>
                              </a>
                              <br />
                              <a
                                href={`/serviceinvoices/${order.data._id}`}
                                target="_blank"
                                style={{
                                  color: "#8A5B40",
                                  textDecoration: "none",
                                }}
                              >
                                <span
                                  style={{
                                    textDecoration: "underline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  View Invoice
                                </span>
                              </a>
                            </div>
                          </div>
                          <div>
                            <div
                              className="d-flex flex-row px-2 py-2"
                              style={{ boder: "1px solid black" }}
                            >
                              <div className="p-1">
                                <img
                                  onClick={() =>
                                    navigate(
                                      `/hire-artisans/services/${order.data.order_items[0]?.service?._id}`
                                    )
                                  }
                                  src={order.data.order_items[0]?.image}
                                  className="orderComponentImg"
                                />
                              </div>
                              <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                                <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                                  <div className="mw-100">
                                    <h5
                                      className="orderComponentProductName"
                                      onClick={() =>
                                        navigate(
                                          `/hire-artisans/services/${order.data.order_items[0]?.service?._id}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {order.data.order_items[0]?.name}
                                    </h5>
                                    <span
                                      onClick={() =>
                                        navigate(
                                          `/hire-artisans/${order.data.order_items[0]?.service?.serviceCategory?._id}/${order.data.order_items[0]?.service?.serviceSubcategory?._id}`
                                        )
                                      }
                                      className="wishlist_product_company"
                                    >
                                      {
                                        order.data.order_items[0]?.service
                                          ?.serviceSubcategory
                                          ?.serviceSubcategory
                                      }
                                    </span>
                                    <div>
                                      {order.data.order_items[0]?.variation &&
                                        Object.entries(
                                          order.data.order_items[0]?.variation
                                        ).map(
                                          ([variationKey, variationValue]) => (
                                            <>
                                              <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">
                                                {variationKey}: {variationValue}
                                              </div>
                                            </>
                                          )
                                        )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="current_orders_orderID text-dark">
                                      Quantity:{" "}
                                      {order.data.order_items[0]?.quantity}
                                    </div>
                                  </div>
                                </div>
                                <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                                  <p className="m-0 p-0 text-end">
                                    Rs.{" "}
                                    {numberWithCommas(
                                      order.data.order_items[0]?.price
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <CancelModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        cancelOrderId={cancelOrderId}
      />
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default OrderDetails;
