import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../../../constants";
import { setRoute } from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import "./RecentlyViewedItems.css";


export default function RecentlyViewedItems() {
    const api = useAxios();
    const navigate = useNavigate();
    const [state, dispatch] = useStore();
    const [recentlyViewedItems, setRecentlyViewedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    let isDesktop = useMediaQuery({
        query: "(min-width:1400px)",
    });

    let isTablet = useMediaQuery({
        query: "(max-width:992px)",
    });


    let isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    let minScreen = useMediaQuery({
        maxWidth: 1300,
        minWidth: 993
    })
    const [arraySize] = useState(isMobile ? 2 : (isTablet ? 3 : 5));


    useEffect(() => {
        async function apiCall() {
            const res = await api.get(`${API_URL}/home/rvi`);
            if (res.data.success) {
                setRecentlyViewedItems(res.data?.items);
            } else {
                setRecentlyViewedItems([]);
            }
            setIsLoading(false);
        }
        apiCall();
    }, []);

    function onClickDiv() {
        dispatch(setRoute({}))
    }

    return <>
        {isLoading && <div className="recentlyViewedShimmerWrapper">
            {new Array(arraySize).fill("").map((_, index) => (
                <div
                    className="recentlyViewedShimmer"
                >
                </div>
            ))}
        </div>}
        {recentlyViewedItems.length > 0 && <>
            <div className="container-fluid threeimage_container p-0 newRvi compBottomMargin">
                <div className="shopbycategory_main shopbyprice_main recentlyViewedItemsWrapper m-0">
                    <div className="newHeading">Recently Viewed Items</div>
                    <div className="shopbyprice_image_row_slider">
                        <Splide options={{
                            type: '',
                            perPage: isMobile ? 2 : isTablet ? 2.5 : isDesktop ? 4.5 : minScreen ? 3 : 4,
                            perMove: 1,
                            pagination: false,
                            rewind: true,
                            arrows: false,
                            gap: isDesktop ? "1.5em" : "1em",
                            type: 'loop'
                        }} >
                            {recentlyViewedItems.map(item => <>
                                <SplideSlide>
                                    <div onClick={onClickDiv} className="sliderDiv">
                                        <div className="shopbyprice_product" key={item?._id}>
                                            <div className="shopbyprice_product_image">
                                                <img src={item?.attrs?.imgs[0]}
                                                    onClick={() => { navigate(`/product/${item?._id}`) }}
                                                    alt="" className="recentlyViewedItemsImage" />
                                                <div className="rviProductNameTag">{item?.product_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </SplideSlide>
                            </>)}
                        </Splide>
                    </div>
                </div>
            </div>
        </>}
    </>
}