import "./App.css";
import React, { useEffect, useState } from "react";
import { useStore } from "./store/store";
import axios from "axios";
import useAxios from "./utils/useAxios";
import { API_URL } from "./constants";
import { logout, setAuth, setUser, setCategoryData, getAddresses } from "./store/reducers/userActions";
import AllRoutes from "./utils/Routes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Loader from "./Components/global_component/Loader/Loader";
import ReactGA from "react-ga4";
import { REACT_APP_GA_MEASUREMENT_ID } from "./constants";
import ServerError from "./Components/global_component/ErrorPage/ServerError";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function App() {
  const [categories, setCategories] = useState([]);
  const [, dispatch] = useStore();
  const [loading, setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false); 
  const api = useAxios();

  useEffect(() => {
    async function fetchCategoryData() {
      try {
        const res = await axios.get(`${API_URL}/hierarchy?combined=true`);
        dispatch(setCategoryData(res.data));
      } catch (error) {
        console.log(error);
        setNetworkError(true); 
      }
    }

    async function checkAuth() {
      try {
        const response = await axios.post(
          `${API_URL}/auth/refresh-token`,
          {},
          {
            withCredentials: true,
          }
        );

        if (!response.data.error && response.data.accessToken) {
          dispatch(setAuth(response.data.accessToken, true));
          const res = await api.get("/me");
          const addresses = await api.get("/me/addresses");

          if (!res.data.error) {
            dispatch(setUser(res.data.user));
          }

          if (!addresses.data.error) {
            dispatch(getAddresses(addresses.data?.addresses));
          }
        } else {
          console.log(response.data.error);
          dispatch(logout());
        }
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    }

    checkAuth();
    fetchCategoryData();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const cat = await axios.get(`${API_URL}/navbar`);
        setCategories([...cat.data]);
        dispatch(setCategoryData([...cat.data]));
      } catch (error) {
        console.log(error);
        setNetworkError(true); 
      }
    })();
  }, []);

  return loading
    ?
    <Loader />
    :
    networkError ? <ServerError /> : categories.length > 0
      ?
      <AllRoutes categories={categories} />
      :
      <Loader />;
}

export default App;