import React from "react";
import "./ThreeImages.css";
import ImageCarousel from "../../../global_component/ImageCarousel/ImageCarousel";
import { useMediaQuery } from "react-responsive";
function ThreeImages({pcData , tabletData,mobileData}) {
    // const mobileData = data.filter(e=>e.type==='slider_mobile')
    // const tabletData = data.filter(e=>e.type==='slider_tablet')
    // const pcData = data.filter(e=>e.type==='slider_pc')
    let isTablet = useMediaQuery({
        query: "(max-width:1208px)",
    });
    let isMobile = useMediaQuery({
        query: "(max-width:500px)",
    });
    return (
        <div className="container-fluid threeimage_container firstHomepageContainer mb-md-4 mb-0">
            <div className="threeimages_main">
                <div className="threeimages_first">
                    <ImageCarousel images={isMobile?mobileData:
                        isTablet?tabletData:pcData
                        }  />
                </div>
                {/* <div className="threeimages_second">
                    <div className="threeimages_left">
                        <ImageCarousel images={["https://plypicker.s3.ap-south-1.amazonaws.com/advertisement/Monsoon_2X_HeroBanner01_280722.jpg","https://plypicker.s3.ap-south-1.amazonaws.com/advertisement/Monsoon_2X_HeroBanner03_280722.jpg", "https://plypicker.s3.ap-south-1.amazonaws.com/advertisement/WhatsApp+Image+2022-06-29+at+12.37.53+PM.jpeg"]} id="626e62cf6846040bcd9ed5a0" />
                    </div>
                    <div className="threeimage_right">
                        <Link to="/productlist?group=626e687b7ad4861271f50c08">
                            <img src="https://plypicker.s3.ap-south-1.amazonaws.com/advertisement/RHS_Web_280622g.jpg" alt="" />
                        </Link>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ThreeImages;
