import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "react-responsive";
import "./Component8.css";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Component8({ data }) {
    const [isLoading, setIsLoading] = useState(true);
    const onloading = (index) => {
        if (index == 2) setIsLoading(false);
    };
    let isTablet = useMediaQuery({
        maxWidth: 1050,
        minWidth: 577,
    });

    let isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    let isBigTablet = useMediaQuery({
        query: "(max-width: 1300px)",
    });

    const slideSelector = () => {
        if (isMobile) {
            return 1.5;
        }
        if (isTablet) {
            return 2;
        } else {
            if (isBigTablet) {
                return 4;
            } else {
                return 5;
            }
        }
    };
    const arraysize = isMobile ? 2 : 3
    return (
        <>
            {data && <>
                <div className="compBottomMargin">
                    <div className="ourServiceWrapper">
                        {new Array(arraysize).fill("").map((_, index) => (
                            <div
                                className="ourServiceShimmer"
                                style={{ display: isLoading ? "block" : "none" }}
                            ></div>
                        ))}
                    </div>
                    <div style={{ display: isLoading ? "none" : "block" }}>
                        {!isMobile && !isTablet ? (
                            <>
                                <div className="container-fluid threeimage_container p-0">
                                    <div className="my-0">
                                        <div class="newHeading">Our Services</div>
                                    </div>
                                    <div
                                        className="d-flex flex-row-reverse w-100 justify-content-center gx-2 row m-0"
                                    >
                                        {data?.data?.map((e, index) => (
                                            <>
                                                <div className="col-sm-4 col-12 mb-4 md-sm-0 d-flex flex-row justify-content-center">
                                                    <a
                                                        href={isMobile ? "tel:18002088886" : "/contact-us"}
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        <img
                                                            src={e.image}
                                                            className="mx-auto ourServicesImage"
                                                            onLoad={() => {
                                                                onloading(index);
                                                            }}
                                                        />
                                                        <div className="newSubheading text-dark">
                                                            {e.title}
                                                        </div>
                                                        <span className="newText" style={{color: "black"}}>
                                                            {e.description}
                                                        </span>
                                                    </a>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="newHpOurServices">
                                    <div className="shopbycategory_main my-0">
                                        <div class="newHeading">Our Services</div>
                                    </div>
                                    <div className="w-100 m-0">
                                        <Swiper slidesPerView={isMobile ? 1.5 : 2.5}>
                                            {data?.data.map((e, index) => (
                                                <>
                                                    <SwiperSlide>
                                                        <div className="d-flex flex-row justify-content-center px-2">
                                                            <a
                                                                href="tel:18002088886"
                                                                style={{ textDecoration: "none" }}
                                                            >
                                                                <img
                                                                    src={e.image}
                                                                    className="mx-auto ourServicesImage"
                                                                    onLoad={() => {
                                                                        onloading(index);
                                                                    }}
                                                                />
                                                                <div className="newSubheading text-dark">
                                                                    {e.title}
                                                                </div>
                                                                <span className="newText" style={{color: "black"}}>
                                                                    {e.description}
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </SwiperSlide>
                                                </>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>}
        </>
    );
}
