import React from "react";
import "./ServerError.css";
import error_back from "./error_back.png";
function ServerError() {
  return (
    <>

        <div className="error_page_mainn">
          <div className="error_page_imgg">
            <img src={error_back} alt="" />
            <div className="error_page_inner_contenttt">
              <div style={{marginBottom:"15%"}}>
              <div className="error_page_textt1 " style={{display:"flex",justifyContent:"center",textAlign:"center"}}>Currently,website is down!</div>
              <div className="error_page_textt2" style={{display:"flex",justifyContent:"center",textAlign:"center"}}>Please try after some time.</div>
              </div>
              <div style={{paddingBottom:""}}>
              <div className="error_page_textt1" style={{display:"flex",justifyContent:"center",textAlign:"center"}}>
                For any query feel free to contact on:
              </div>
              <div className="error_page_textt3" style={{display:"flex",justifyContent:"center"}}>
               Email:info@plypicker.com
              </div><div className="error_page_textt3" style={{display:"flex",justifyContent:"center"}}>
               Contact No: +91 80618 34000
              </div>
              </div>
        
            </div>
          </div>
        </div>
    </>
  );
}
export default ServerError;
