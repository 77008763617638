import { useEffect, useRef, useState } from 'react';

export default function IsPopupVisible(initialIsVisible) {
    const [isServicePopupVisible, setIsServicePopupVisible] = useState(initialIsVisible);
    const ref = useRef(null);
    const modalRef = useRef(null);
    const handleClickOutside = (event) => {
        if (modalRef?.current?.dialog){
            if ((modalRef.current && !modalRef.current.contains(event.target))) {
                setIsServicePopupVisible(false);
            }
            if ((ref.current && ref.current.contains(event.target)) || (modalRef.current && modalRef.current.contains(event.target))) {
                setIsServicePopupVisible(true);
            }
        } else {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsServicePopupVisible(false);
            }
            if ((ref.current && ref.current.contains(event.target) )) {
                setIsServicePopupVisible(true);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, isServicePopupVisible, setIsServicePopupVisible, modalRef };
}