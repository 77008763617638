import React, { useEffect, useState } from 'react';
import x from "../img/Filter/x.png";
// import useAxios from "../../../../../utils/useAxios";
import axios from "axios";
import { API_URL } from "../../../../../constants";

function Brand({ Category,setBrandToShow,max,min }) {
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(false);
    // const [brandCount, setBrandCount] = useState([]);
    // let arr=[];
    useEffect(() => {
        (async () => {

            if (Category.get("category")) {
                // console.log("Category",Category.get("category"))
                const res = await axios.get(`${API_URL}/brand/category/${Category.get("category")}?max=${max}&min=${min}`, {
                    headers: {
                        // userToken: localStorage.getItem("userToken"),
                        // name:'xyz'
                    }
                });
                // console.log(res.data.data)
                setBrands(res.data.data);
            }
            else if (Category.get("subcategory")) {
                // console.log("subcategory",Category.get("subcategory"))
                const res = await axios.get(`${API_URL}/brand/subcategory/${Category.get("subcategory")}?max=${max}&min=${min}`, {
                    headers: {
                        // name: 'xyz'
                    }
                }
                );
                // console.log(res.data.data)
                setBrands(res.data.data);
            }
            else if (Category.get("group")) {
                // console.log("group",Category.get("group"))
                const res = await axios.get(`${API_URL}/brand/group/${Category.get("group")}?max=${max}&min=${min}`, {
                    headers: {
                        // name: 'xyz'
                    }
                });
                // console.log(res.data.data)
                setBrands(res.data.data);
            }
            else if (Category.get("subgroup")) {
                // console.log("group",Category.get("group"))
                const res = await axios.get(`${API_URL}/brand/subgroup/${Category.get("subgroup")}?max=${max}&min=${min}`, {
                    headers: {
                        // name: 'xyz'
                    }
                });
                // console.log(res.data.data)
                setBrands(res.data.data);
            }
            else if (Category.get("brand")) {
                // console.log("group",Category.get("group"))
                const res = await axios.get(`${API_URL}/brandcount/${Category.get("brand")}`, {
                    headers: {
                        // name: 'xyz'
                    }
                });
                setBrands(res.data.data);
            }
        })();
    }, [Category,max,min]);

    return (
        <div>
            <table class="table my-0">
                <thead>
                    <tr>
                        <th className="ps-0" style={{borderBottom:"none"}}>BRANDS</th>
                        <td className='clearFilterButton' style={{borderBottom:"none"}}>
                            <img src={x} alt="" onClick={(e) => {
                                e.preventDefault();
                                setBrandToShow("");
                                setSelectedBrand("")
                            }} style={{cursor: "pointer"}} />
                        </td>
                    </tr>
                </thead>
            </table>
                <div className="BrandTable" style={{borderTop:"none"}}>
                {brands.map((brand, i)=> (<>
                    {brand.count>0?(<div className="row py-1 w-100 mx-0" style={{ margin: '-1px',marginRight:"-70px", marginLeft: "-8px",borderBottomWidth:"0px"}}>
                        <div className={selectedBrand === brand.id ? `selected_effect dontbebold d-flex col-10 ps-0` : `dontbebold d-flex col-10 ps-0`} style={{justifyContent: "space-between",width: "100%", cursor: "pointer"}}  onClick={() => { 
                                            setSelectedBrand(brand.id)
                                            setBrandToShow(brand.id);
                                             }}>
                        <span style={{textTransform: "capitalize"}}>{brand.name}</span>   <span style={{textTransform: "capitalize"}}>{brand.count}</span>
                        </div>
                    </div>):""}
                    </>))}
                </div>
        </div>
    )
}

export default Brand

// <tbody className={'BrandTable'}>
//                     <br />
//                     <tr>
//                         {brands.map((brand, i) => (
//                             <>
//                                 {/* <Link to={`?brand=${brand.id}`} > */}
//                                 {brand.count !==0 &&
//                                     <th scope="row" className={selectedBrand === brand.id ? `selected_effect dontbebold d-flex justify-content-between` : `dontbebold d-flex justify-content-between`} style={{ margin: '-15px',marginRight:"-70px", marginLeft: "-8px",borderBottomWidth:"0px"}}
//                                         onClick={() => { 
//                                             setSelectedBrand(brand.id)
//                                             setBrandToShow(brand.id);
//                                              }}>
//                                         <span>{brand.name}</span>   <span>{brand.count}</span>
//                                     </th>
//                                 }
//                                 {/* </Link> */}
//                                 <br />
//                             </>
//                         ))}
//                     </tr>
//                 </tbody>