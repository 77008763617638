import CircularProgress from "@mui/material/CircularProgress";
import TitleImage from "../../../Title.png";
import { useMediaQuery } from "react-responsive";
import "./loader.css";

function Loader() {
  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });
  return (
    <div
      className="circularProgess11"
      // style={{
      //   position: "absolute",
      //   top: "50%",
      //   left: "50%",
      //   "-ms-transform": "translateX(-50%) translateY(-50%)",
      //   "-webkit-transform": "translate(-50%,-50%)",
      //   transform: "translate(-50%,-50%)",
      // }}
    >
      {!isMobile?
      <div className="animatationLogo">
        <img src={TitleImage} alt="logo" />
      </div>:
      <div className="animatationLogoMobile">
      <CircularProgress />
      </div>}
    </div>
    // <div className="loaderMain">
    //   <div class="loaderBody">
    //     <span>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //       <span></span>
    //     </span>
    //     <div class="base">
    //       <span></span>
    //       <div class="face"></div>
    //     </div>
    //   </div>
    //   <div class="longfazers">
    //     <span></span>
    //     <span></span>
    //     <span></span>
    //     <span></span>
    //   </div>
    //   <h1 className="loaderHeading">Redirecting</h1>
    // </div>
  );
}

export default Loader;
