export const setUser = (user) => {
  return {
    type: "SET_USER",
    payload: { user },
  };
};

export const updateWishlist = (wishlist) => {
  return {
    type: "UPDATE_WISHLIST",
    payload: { wishlist },
  };
};

export const setAuth = (accessToken, auth) => {
  return {
    type: "SET_AUTH",
    payload: { accessToken, auth },
  };
}

export const update = (newData) => {
  return {
    type: "UPDATE_USER",
    payload: { newData }
  }
}

export const addToCart=(newData) => {
  return {
    type:"ADD_TO_CART",
    payload: {newData}
  }
}

export const addToCart2=(newData) => {
  return {
    type:"ADDCART",
    payload: {newData}
  }
}

export const setRoute = (newData) => {
  return{
    type: "SET_ROUTE",
    payload: { newData }
  }
}

export const removeFromWishlistAction=(item) => {
  return {
    type:"REMOVE_FROM_WISHLIST",
    payload: {item}
  }
}

export const removeFromCartAction=(item) => {
  return {
    type:"REMOVE_FROM_CART",
    payload: {item}
  }
}

export const updateAddress = (index,address) => {
  return {
    type: "UPDATE_ADDRESS",
    payload : { index, address}
  }
}
export const deleteAddress = (address) => {
  return {
    type: "DELETE_ADDRESS",
    payload : { address }
  }
}


export const logout = () => {
  localStorage.clear();
  return {
    type: "LOGOUT",
  };
};

export const updateSelectedCity = (cityId) => {
  return {
    type: "UPDATE_CITY",
    payload: { selectedCity: cityId }
  };
};


export const updateSelectedCityName = (cityName) => {
  return {
    type: "UPDATE_CITY_NAME",
    payload: { selectedCityName: cityName }
  };
};

export const getAddresses=(addresses)=>{
return {
  type:"GET_ADDRESSES",
  payload:{addresses}
}
}

export const getCity=(city)=>{
  return {
    type:"GET_CITY",
    payload:{city}
  }
  }



export const addAddress = (addresses,address) => {
  return {
    type: "ADD_ADDRESS",
    payload: { addresses,address },
  };
}

export const setCategoryData = (payload) => {
  return {
    type: "SET_CATEGORY_DATA",
    payload,
  }
}

export const setProductData = (product) => {
  return {
    type:"SET_PRODUCT",
    payload: {product}
  }
}

export const setAvgRating = (rating)=>{
  return{
    type:"SET_AVG_RATING",
    payload: {rating}
  };
}

export const addReview = (ratingArray,newReview) => {
  return {
    type: "ADD_PRODUCT_REVIEW",
    payload: {ratingArray,newReview},
  };
}

export const editReview = (newReview) => {
  return {
    type: "EDIT_PRODUCT_REVIEW",
    payload:{newReview}
  }
}

export const editReviewInPage = (ratingArray,newReview) => {
  return {
    type: "EDIT_PRODUCT_REVIEW_IN_PAGE",
    payload:{ratingArray,newReview}
  }
}

export const deleteReview = (oldReview) => {
  return {
    type: "DELETE_PRODUCT_REVIEW",
    payload:{oldReview}
  }
}

export const setCartData = (cartItem) => {
  return {
    type:"SET_CART",
    payload:{cartItem}
  }
}

export const setServicesCartData = (cartItem) => {
  return {
    type: "SET_SERVICES_CART",
    payload: {cartItem}
  }
}

export const setHomeData = (homeData) => {
  return {
    type:"SET_HOME_DATA",
    payload:{homeData}
  }
}

export const setCartDataNull = ()=>{
  return {
    type:"SET_CART_NULL"
  }
}

export const setWishListNull = ()=>{
  return {
    type:"SET_WISHLIST_NULL"
  }
}

export const editCartData = (product_id,quantity) => {
  return {
    type: "EDIT_CART_ITEM",
    payload:{product_id,quantity}
  }
}

export const setSelectedAdd = (selectedAddress) =>{
  return {
    type:"SET_SELECTED_ADDRESS",
    payload:{selectedAddress}
  }
}

export const setSelectedCoupon = (coupon) => {
  return{
    type :"SET_SELECTED_COUPON",
    payload : {coupon}
  }
}

export const setSelectedVehicleAction = (selectedVehicle) =>{
  return {
    type: "SET_SELECTED_VEHICLE",
    payload: { selectedVehicle }
  }
}

export const setIsLaborRequiredState = (isLaborRequiredState) => {
  return {
    type: "SET_LABOR_REQUIRED",
    payload: { isLaborRequiredState }
  }
}