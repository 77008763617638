import React from "react";
import Footer from "../Footer/Footer";
import NavbarUpper from "../Navbar/NavbarUpper/NavbarUpper";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.css";
import error_back from "./error_back.png";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ErrorPage() {
  ReactGA.send({
    hitType: 'pageview',
    page: `*`,
    title : 'Error Page'
  })
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        <div className="error_page_main">
          <div className="error_page_img">
            <img src={error_back} alt="" />
            <div className="error_page_inner_content">
              <div className="error_page_text1">ERROR</div>
              <div className="error_page_text2">404</div>
              <div className="error_page_text3">
                Look's like you have entered the wrong house
              </div>
              <div className="error_page_button" onClick={() => { navigate(`/`)}}>Home</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ErrorPage;
