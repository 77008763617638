import Slider from "@mui/material/Slider";
import { useState } from "react";
import x from "../img/Filter/x.png";
import "./Discount.css";

function Thickness({
  thicknessVal,
  setThicknessVal,
  newThicknessSliderOnChange,
  minThickness,
  maxThickness,
  setMinMaxThicknessZero
}) {
  const [thicknesses, setThickness] = useState([
    {
      thickness: "0-0.5",
      checked: false,
      lowerVal: 0,
      upperVal: 0.5,
    },
    {
      thickness: "0.6-1.0",
      checked: false,
      lowerVal: 0.6,
      upperVal: 1.0,
    },
    {
      thickness: "1.1-1.5",
      checked: false,
      lowerVal: 1.1,
      upperVal: 1.5,
    },
    {
      thickness: "1.6-2.0",
      checked: false,
      lowerVal: 1.6,
      upperVal: 2.0,
    },
    {
      thickness: "2.1-2.5",
      checked: false,
      lowerVal: 2.1,
      upperVal: 2.5,
    },
    {
      thickness: "2.6-3.0",
      checked: false,
      lowerVal: 2.6,
      upperVal: 3.0,
    },
    {
      thickness: "3.1-3.5",
      checked: false,
      lowerVal: 3.1,
      upperVal: 3.5,
    },
    {
      thickness: "3.6-4.0",
      checked: false,
      lowerVal: 3.6,
      upperVal: 4.0,
    },
    {
      thickness: "4.1-4.5",
      checked: false,
      lowerVal: 4.1,
      upperVal: 4.5,
    },
    {
      thickness: "4.6-5.0",
      checked: false,
      lowerVal: 4.6,
      upperVal: 5.0,
    },
  ]);

  // useEffect(() => {
  //   let arr = [];
  //   thicknesses.map((thickness, i) => {
  //     if (thickness.checked) {
  //       arr.push(thickness.lowerVal, thickness.upperVal);
  //     } else {
  //       let idx = arr.findIndex((x) => x === thickness.thickness);
  //       if (idx !== -1) {
  //         arr.splice(arr[idx]);
  //       }
  //     }
  //   });
  //   setThicknessVal([...arr]);
  // }, [thicknesses]);

  const original = [
    {
      thickness: "0-0.5",
      checked: false,
      lowerVal: 0,
      upperVal: 0.5,
    },
    {
      thickness: "0.6-1.0",
      checked: false,
      lowerVal: 0.6,
      upperVal: 1.0,
    },
    {
      thickness: "1.1-1.5",
      checked: false,
      lowerVal: 1.1,
      upperVal: 1.5,
    },
    {
      thickness: "1.6-2.0",
      checked: false,
      lowerVal: 1.6,
      upperVal: 2.0,
    },
    {
      thickness: "2.1-2.5",
      checked: false,
      lowerVal: 2.1,
      upperVal: 2.5,
    },
    {
      thickness: "2.6-3.0",
      checked: false,
      lowerVal: 2.6,
      upperVal: 3.0,
    },
    {
      thickness: "3.1-3.5",
      checked: false,
      lowerVal: 3.1,
      upperVal: 3.5,
    },
    {
      thickness: "3.6-4.0",
      checked: false,
      lowerVal: 3.6,
      upperVal: 4.0,
    },
    {
      thickness: "4.1-4.5",
      checked: false,
      lowerVal: 4.1,
      upperVal: 4.5,
    },
    {
      thickness: "4.6-5.0",
      checked: false,
      lowerVal: 4.6,
      upperVal: 5.0,
    },
  ];
  return (
    <>
      <div>
        <table className="table my-0">
          <thead>
            <tr>
              <th scope="col" className="ps-0">
                THICKNESSES(mm)
              </th>
              <td className="clearFilterButton">
                <img
                  src={x}
                  alt=""
                  onClick={(e) => {
                    e.preventDefault();
                    setThickness(original);
                    setMinMaxThicknessZero()
                  }}
                  style={{ cursor: "pointer" }}
                />
              </td>
            </tr>
          </thead>
        </table>
        <div className="">
          <div className="row w-100 m-0">
            <div className="col p-0 d-flex justify-content-start">
              <button type="button" className="priceFilterButton ms-0 me-1">
                {/* ₹{Math.min(...value)} */}
                {minThickness} mm
              </button>
            </div>
            <div className="col p-0 d-flex justify-content-end">
              <button type="button" className="priceFilterButton ms-1 me-0">
                {/* ₹{Math.max(...value)} */}
                {maxThickness} mm
              </button> 
            </div>
          </div>
          {/* {thicknesses.map((thickness, i) => (<>
                    <div className="row py-1 w-100 mx-0">
                        <div className="col-10 ps-0">
                            {thickness.thickness}
                        </div>
                        <div className="col-2 p-0 d-flex align-items-end justify-content-end">
                            <label className="filterContainer">
                                <input type="checkbox" checked={thickness.checked} onClick={(e) => {
                                    // e.preventDefault();
                                    const newArray = thicknesses;
                                    // newArray[i].checked = !thickness.checked;
                                    if (thickness.checked) {
                                        newArray[i].checked = false;
                                    } else {
                                        const findThickness = newArray.findIndex(c => c.thickness === thickness.thickness);
                                        if(i===findThickness){
                                            newArray.map((x)=>x.checked=false);
                                            newArray[i].checked=!thicknesses[i].checked;
                                        }
                                    }
                                    setThickness([...newArray])
                                }}></input>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </>))} */}
          <Slider
            value={[minThickness, maxThickness]}
            max={5}
            min={0}
            step={0.5}
            marks
            onChange={newThicknessSliderOnChange}
            valueLabelDisplay="off"
          />
        </div>
      </div>
    </>
  );
}

export default Thickness;
