import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from '../../../../constants';
import Footer from '../../../global_component/Footer/Footer';
import LowerNavigation from '../../../global_component/LowerNavigation/LowerNavigation';
import AddVisit from './AddVisit';
import BGIMG from './bgimg.png';
import { useNavigate } from 'react-router-dom';
import CityModal from '../../../global_component/Modal/cityModal';
import { useStore } from '../../../../store/store';
import ReactGA from "react-ga4";
import NavRoute from '../../Nav_Route/NavRoute';
import ServicesCartPopup from './ServicesCartPopup';

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function TopHero({ heroImage }) {
  return (
    <>
      <NavRoute productRouter title="Interior Design" services/>
      <div className="m-auto col-xxl-10 px-4 py-2 mb-5" style={{ background: `url(${BGIMG})`, backgroundPosition: 'bottom', backgroundSize: '100% 71%', backgroundRepeat: 'no-repeat' }}>
        <div className="row flex-lg-row align-items-center g-5 py-5">
          <div className="col-sm-10 col-lg-6 me-5">
            <img
              src={heroImage}
              className="d-block mx-lg-auto img-fluid"
              width="700"
              height="500"
              loading="lazy"
            />
          </div>
          <div className="col-lg-5">
            <h1 className="display-6 fw-bold lh-2 mb-5 text-trilong">
              WHAT’S IN IT FOR YOU?
            </h1>
            <h6 className="lead text-white fs-5" style={{ color: '#C4C4C4' }}>
              Get all your needs met at one place.<br />
              <span className="fs-6 lh-sm" style={{ color: '#C4C4C4' }}>You can easily explore for solutions without switching tabs since
                we put everything from materials, to design at one place.</span>
              <br /><br />
              Hire a designer who is on call around the clock.<br />

              <span className="fs-6 lh-1" style={{ color: '#C4C4C4' }}>Someone who will prioritise you and your desire to create a
                captivating work of art.</span> <br /><br />
              Pay only when the design has been finalized.<br />

              <span className="fs-6 lh-1" style={{ color: '#C4C4C4' }}>Yes, you read that correctly. Only make a payment after you’re
                happy with the design.</span>
            </h6>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start">
              {/* <button type="button" class="btn btn-lg px-4 me-md-2 text-light" style={{backgroundColor:'#FF7703'}}>Schedule Your Visit</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function FeaturesCard({ image, para }) {
  return (
    <>
      <div className="feature col my-5">
        <div className="feature-icon d-flex align-items-center justify-content-center bg-gradient fs-2 mb-3 ">
          <img
            src={image}
            height="70px"
            className="mx-auto"
          />
        </div>
        <p className="text-center m-auto" style={{ width: '180px' }}>
          {para}
        </p>
      </div>
    </>
  );
}

function InteriorDesign() {
  const [VisitModal, setVisitModal] = useState(false);
  const [heroImage, setHeroImage] = useState("");
  const [servicesCategory, setServicesCategory] = useState([]);
  const [isError, setIsError] = useState(false);
  const [issLoadingProducts, setIsLoadingProducts] = useState(false);
  const [cardData, setCardData] = useState()
  const [state,dispatch]=useStore();
  const navigate = useNavigate();


  ReactGA.send({
    hitType: 'pageview',
    page: `/interior-design`,
    title : 'Interior Design'
  })


  useEffect(() => {
    const apiCall = async () => {
      const res = await axios.get(`${API_URL}/home`)
      let result = await res.data;
      setHeroImage(result.filter(e => e.type === 'creative_idea_interior_design' && e.title === 'hero')[0].image)
      setCardData(result.filter(e => e.type === 'creative_idea_interior_design' && e.title === 'cards'))

    }
    apiCall();
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    async function fetchData() {
      window.scroll(0, 0);
      const res = await axios.get(`${API_URL}/services/getServicesCategory`);
      if (res.data?.length === 0) {
        setIsError(true);
        setIsLoadingProducts(false);
      } else {
        setIsError(false);
        setServicesCategory(res.data.filter(data => data.page === "interior-design"));
      }
    }
    fetchData();
  }, []);




//***Modal***
const [showModal,setShowModal]=useState(state.selectedCity?false:true);

  const closeModalHandler = () => {
    setShowModal(false);
  };
  //***** */

  return (
  <>
    {!state.selectedCity && <CityModal onClose={closeModalHandler}></CityModal>}
  
    <div style={{ overflowX: "hidden" }}>
      <div className='interiorTopMargin' ></div>
      <TopHero heroImage={heroImage} />
      <div
        className="d-flex flex-row w-100 flex-wrap justify-content-center mb-5 container mx-auto"
        style={{ marginTop: "50px", rowGap: "50px" }}
      >
        {servicesCategory?.map((serviceCategory) => (
          <div className="col-lg-4 col-md-6 col-12 col-sm-6">
            <div
              className="cardServiceCat box d-flex flex-column mx-lg-5 mx-3"
              onClick={() => {
                navigate(`/hire-artisans/${serviceCategory._id}`);
              }}
            >
              <img
                src={(serviceCategory && serviceCategory?.category_image?.length > 0) && serviceCategory?.category_image[0]}
                alt="" />
              <span
                style={{
                  color: "#8A5B40",
                  fontFamily: "Poppins",
                  fontSize: "22px",
                  fontWeight: 400,
                  textTransform: "uppercase",
                  width: "75%"
                }}
              >
                {serviceCategory?.serviceCategory}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: '150px' }}></div>
      <ServicesCartPopup/>
      <LowerNavigation />
      <Footer />
    </div>
    </>
  )
}

export default InteriorDesign