import plypickerLogo from "./plypicker_logo.png"
import "./CartNavbar.css"
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useNavigate } from "react-router-dom";

function CartNavbar(props) {
    const steps = [
        "Cart",
        "Order Summary",
        "Payment"
    ]
    const navigate = useNavigate();
    const handleStep = (index) => () => {
        if(index === 0){
            navigate("/cart");
        }
        else{
            navigate("/ordersummary")
        }
      };
    return <>
        <div className="cartNavbar w-100 d-flex align-items-center justify-content-center">
                <img onClick={() => { navigate("/") }} className="cartNavbarPlypickerLogo" src={plypickerLogo} />
            <div className="stepper py-md-4 py-3">
                <Stepper activeStep={props.currentStep} alternativeLabel>
                    {steps.map((label,index) => (
                        <Step key={label} className="stepper_div" onClick={(props.currentStep>(index+1) && props.currentStep!==(index+1))?handleStep(index):""} style={(props.currentStep>(index+1) && props.currentStep!==(index+1))?{cursor:"pointer"}:{}}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
        </div>
    </>
}

export default CartNavbar