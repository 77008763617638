import { CircularProgress, Rating } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import Modal from "react-awesome-modal";
import { Form } from "react-bootstrap";
import { BiDownload } from "react-icons/bi";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import "../sidebar.css";
import { ReactComponent as DeliveredIcon } from "./icons/DeliveredIcon.svg";
import { ReactComponent as NotdeliveredIcon } from "./icons/NotdeliveredIcon.svg";
import OrderComponent from "./OrderComponent";
import "./YourOrders.css";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function CancelModal({ isOpen, setIsOpen, cancelOrderId }) {
  return (
    <Modal
      visible={isOpen}
      width="450"
      height="270"
      effect="fadeInUp"
      style={{ borderRadius: "0px" }}
      onClickAway={() => {
        setIsOpen(false);
      }}
    >
      <div
        className="px-3 py-4 d-flex flex-column justify-content-center align-items-center login-box"
        style={{ height: "100%", borderRadius: 0 }}
      >
        <h3 className="text-center my-3">Do You Want to Delete this order?</h3>
        <div
          class="d-flex flex-row align-items-center w-100"
          style={{ justifyContent: "space-evenly" }}
        >
          <button
            class="CloseaddUpdateButton ms-2"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            No
          </button>
          <button
            class="addUpdateButton"
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              const res = await axios.post(`${API_URL}/orders/cancelRequest`, {
                id: cancelOrderId,
              });
              if (res.data.status) {
                window.alert(res.data.msg);
              }
              setIsOpen(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}

function YourOrders() {
  const alert = useAlert();
  const [orders, setOrders] = useState([]);
  const api = useAxios();
  const [state, dispatch] = useStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState([]);
  const [isOrderCollapsed, setIsOrderCollapsed] = useState([]);
  const [newRating, setNewRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [currentProductId, setCurrentProductId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [deliverySteps, setDeliverySteps] = useState([
    "Pending",
    "Processing",
    "Shipped",
    "Delivered",
  ]);
  const [servicesSteps, setServicesSteps] = useState([
    "PARTIAL PAYMENT PENDING",
    "PARTIAL PAYMENT SUCCESSFULL",
    "PAYMENT SUCCESSFULL",
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(1);

  const navigate = useNavigate();

  ReactGA.send({
    hitType: "pageview",
    page: "/orders",
    title: "Your Orders",
  });

  function handlePaginationClick(page) {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      fetchData(page);
    }
  }
  
  const renderPaginationButton = () => {
    const buttons = [];
    const maxButtonsToShow = 15;
    const maxPages = Math.ceil(totalPages / maxButtonsToShow);
    const startPage = Math.max(1, currentPage - Math.floor(maxButtonsToShow / 2));
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);
  
    const nextSet = () => {
      if (endPage < totalPages) {
        handlePaginationClick(endPage + 1);
      }
    };
  
    // Function to handle navigation to the previous set of buttons
    const prevSet = () => {
      if (startPage > 1) {
        handlePaginationClick(Math.max(1, startPage - maxButtonsToShow));
      }
    };
  
    // Adding buttons for the current page set
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => {
            handlePaginationClick(i);
          }}
          className={`pagination-btn ${currentPage === i ? "active" : ""}`}
        >
          {i}
        </button>
      );
    }
  
    // Adding navigation buttons
    if (startPage > 1) {
      buttons.unshift(
        <button key="prev" onClick={prevSet} className="pagination-btn">
          {"<"}
        </button>
      );
    }
    if (endPage < totalPages) {
      buttons.push(
        <button key="next" onClick={nextSet} className="pagination-btn">
          {">"}
        </button>
      );
    }
  
    return <div className="pagination-container">{buttons}</div>;
  };
  

  async function fetchData(page) {
    document.title = `Your Orders - PLYPICKER`;
    setLoading(true);
    const res = await api.get(API_URL + `/orders/getallorders?page=${page}`);
    if (!res.data.error) {
      if (res.data.data.length > 0) {
        let arr = [...new Set(res.data.data)];
        setOrders(arr);
        setCurrentPage(res.data.currentPage);
        setTotalPages(res.data.totalPage);
        setPaginatedData(res.data.data);
        setPerPage(res.data.perPage);
        setIsOrderCollapsed(new Array(orders.length).fill(false));
      } else {
        setOrders([]);
        setCurrentPage(page);
        setTotalPages(res.data.totalPage);
        setIsOrderCollapsed(new Array(orders.length).fill(false));
      }
    } else {
      alert.error(res.data.error);
    }
    const toParams = window.location.href.split("/");
    let idToFind = toParams[toParams.length - 1].split("#");
    let redirectId = idToFind[idToFind.length - 1];
    setLoading(false);
    if (redirectId !== "orders") {
      document.getElementById(redirectId).scrollIntoView();
    } else {
      window.scroll(0, 0);
    }
  }

  useEffect(() => {
    fetchData(1);
  }, []);

  async function addReviewHandler(p_id) {
    try {
      if (newRating !== 0 && reviewText.length > 0) {
        const res = await api.post("/review/", {
          rating: newRating,
          review: reviewText,
          productId: p_id,
        });
        alert.success("Review Added Successfully");
        navigate(`/product/${p_id}`);
      } else {
        alert.error("Please Add Rating and Review");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const hideReviewModal = () => {
    setShowReviewModal(false);
    setNewRating(0);
    setReviewText("");
  };

  const getOrderDate = (date) => {
    var deliver = new Date(date);
    deliver.setDate(deliver.getDate() + 2);
    var dd = String(deliver.getDate()).padStart(2, "0");
    var mm = String(deliver.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = deliver.getFullYear();

    deliver = dd + "/" + mm + "/" + yyyy;
    return deliver;
  };

  const getServiceDate = (date) => {
    var deliver = new Date(date);
    var year = deliver.getFullYear();
    var month = deliver.getMonth() + 1;
    var dt = deliver.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return `${dt}/${month}/${year}`;
  };

  function getActiveDeliveryStep(order) {
    // console.log(order.data.delivery.status + " order.data.delivery.status ")
    if (order.title === "orders") {
      switch (order.data.delivery.status) {
        case "not delivered":
          return 0;
          break;
        case "processing":
          return 1;
          break;
        case "shipped":
          return 3;
          break;
        case "delivered":
          return 3;
          break;
        default:
          break;
      }
    } else {
      switch (order.data.payment.status) {
        case "PARTIAL PAYMENT PENDING":
          return 0;
          break;
        case "PARTIAL PAYMENT SUCCESSFULL":
          return 2;
          break;
        case "PAYMENT SUCCESSFULL":
          return 3;
          break;
        default:
          break;
      }
    }
  }

  function numberWithCommas(x) {
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }
  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  return (
    <>
      {isMobile ? (
        <div className="MobileView">
          <span className="headingView">
            <BsArrowLeft
              onClick={() => navigate(-1)}
              style={{ fontSize: "30px" }}
            />{" "}
            Your Orders
          </span>
        </div>
      ) : (
        <NavRoute title="order" />
      )}
      <div
        className="sidebarProfilePageContainer w-100 px-0"
        style={!isMobile ? { borderTop: "1px solid black" } : {}}
      >
        <div className="row m-0 p-0 w-100">
          <SideBar title="orders" />
          <div className="col-sm-9 col-12">
            <div className="row m-0 w-100 p-0 currentOrdersScrollbar">
              {showReviewModal && (
                <Modal
                  show={showReviewModal}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  onHide={hideReviewModal}
                >
                  <Modal.Header closeButton className="position-relative">
                    <div className="yourOrdersReviewModalHeading text-center">
                      RATING AND REVIEW
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="addRatingModalBody">
                      <>
                        <Rating
                          name="simple-controlled"
                          value={newRating}
                          size="large"
                          onChange={(event, newValue) => {
                            setNewRating(newValue);
                          }}
                        />
                        <Form>
                          <Form.Group className="mb-3">
                            <Form.Label>Review</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={reviewText}
                              onChange={(e) => setReviewText(e.target.value)}
                              rows={3}
                            />
                          </Form.Group>
                        </Form>
                        <button
                          className="submitReview"
                          onClick={() =>
                            (window.location.href = `/rating-review/${currentProductId}`)
                          }
                        >
                          Add Review
                        </button>
                      </>
                    </div>
                  </Modal.Body>
                </Modal>
              )}
              {loading && (
                <>
                  <div className="mt-5 yourOrdersLoadingDiv">
                    <h4 className="text-center">
                      Loading your orders...{" "}
                      <CircularProgress style={{ color: "#ff7703" }} />
                    </h4>
                  </div>
                </>
              )}
              {!loading && orders.length > 0
                ? orders.map((order, index) => (
                    <div id={order.data.orderid} className="pe-lg-2 pe-md-4">
                      {order.title === "orders" ? (
                        order.data.delivery.status !== "delivered" ? (
                          <div
                            className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                            key={order.data.orderid}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                              <div className="detailColumn container m-0 p-0">
                                <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                                  <div>
                                    <NotdeliveredIcon
                                      style={{ marginRight: "10px" }}
                                    />
                                    <div>
                                      {order.data.delivery.status !==
                                      "rejected" ? (
                                        <div className="current_orders_deliverydate current_orders_green">
                                          To be delivered:{" "}
                                          {getOrderDate(order.data.createdAt)}
                                        </div>
                                      ) : (
                                        <div className="current_orders_deliverydate">
                                          Your order was rejected
                                        </div>
                                      )}
                                      <div className="current_orders_orderID">
                                        Order ID: {order.data.orderid}
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="mb-2">
                                      <span style={{ color: "#878787" }}>
                                        Amount:{" "}
                                      </span>
                                      Rs. {numberWithCommas(order.data.total)}
                                    </div>
                                    <span>
                                      <Link
                                        to={`/order/${order.data.orderid}`}
                                        style={{
                                          color: "#8A5B40",
                                          textDecoration: "none",
                                        }}
                                      >
                                        <span style={{ fontWeight: "500" }}>
                                          View Details
                                        </span>{" "}
                                        <BsArrowRight
                                          style={{ strokeWidth: "1" }}
                                        />
                                      </Link>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-4 ps-3 d-lg-block "></div>
                                <hr />
                                {order.data.delivery.status !== "rejected" && (
                                  <div className="w-md-75 w-100 mx-auto py-3 yourOrderDeliveryStepperDiv">
                                    <Stepper
                                      activeStep={getActiveDeliveryStep(order)}
                                      alternativeLabel
                                    >
                                      {deliverySteps.map((label) => (
                                        <Step key={label}>
                                          <StepLabel icon={" "}>
                                            {label}
                                          </StepLabel>
                                        </Step>
                                      ))}
                                    </Stepper>
                                  </div>
                                )}
                                <OrderComponent order={order} />
                                {order.data.delivery.status ===
                                "not delivered" ? (
                                  <>
                                    <button
                                      className="cancelOrderBtn"
                                      onClick={(e) => {
                                        // e.preventDefault();
                                        setIsOpenModal(true);
                                        setCancelOrderId(order.data.orderid);
                                      }}
                                    >
                                      {" "}
                                      Cancel Order
                                    </button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                            key={order.data.orderid}
                          >
                            <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                              <div className="detailColumn container m-0 p-0">
                                <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                                  <div>
                                    <DeliveredIcon
                                      style={{ marginRight: "10px" }}
                                    />
                                    <div
                                      className="current_orders_deliverydate current_orders_green"
                                      style={{ color: "#000000" }}
                                    >
                                      Delivered:{" "}
                                      {getOrderDate(order.data.createdAt)}
                                    </div>
                                    <div className="current_orders_orderID">
                                      Order ID: {order.data.orderid}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="mb-2">
                                      <span style={{ color: "#878787" }}>
                                        Amount:{" "}
                                      </span>
                                      Rs. {numberWithCommas(order.data.total)}
                                    </div>
                                    <Link
                                      to={`/order/${order.data.orderid}`}
                                      style={{
                                        color: "#8A5B40",
                                        textDecoration: "none",
                                      }}
                                    >
                                      <span style={{ fontWeight: "500" }}>
                                        View Details
                                      </span>{" "}
                                      <BsArrowRight
                                        style={{ strokeWidth: "1" }}
                                      />
                                    </Link>
                                    {/* <a
                                    className=""
                                    href={order.data.invoice}
                                    style={{
                                      color: "#8A5B40",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <BiDownload style={{ strokeWidth: "1" }} />
                                    <span
                                      style={{
                                        textDecoration: "underline",
                                        marginLeft: "3px",
                                      }}
                                    >
                                      Download Invoice
                                    </span>
                                  </a> */}
                                  </div>
                                </div>
                                <div>
                                  <OrderComponent order={order} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : order.data.payment.status !==
                        "PAYMENT SUCCESSFULL" ? (
                        <div
                          className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                          key={order.data.orderid}
                        >
                          <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                            <div className="detailColumn container m-0 p-0">
                              <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                                <div>
                                  <NotdeliveredIcon
                                    style={{ marginRight: "10px" }}
                                  />
                                  <div>
                                    {order.data.payment.status !==
                                    "PARTIAL PAYMENT PENDING" ? (
                                      <div className="current_orders_deliverydate current_orders_green">
                                        To be Completed:{" "}
                                        {getServiceDate(
                                          order.data.serviceScheduledDate
                                        )}
                                      </div>
                                    ) : (
                                      <div className="current_orders_deliverydate">
                                        Your order was rejected
                                      </div>
                                    )}
                                    <div className="current_orders_orderID">
                                      Order ID: {order.data.orderid}
                                    </div>
                                  </div>
                                </div>
                                <div className="">
                                  <div className="mb-2">
                                    <span style={{ color: "#878787" }}>
                                      Amount:{" "}
                                    </span>
                                    Rs. {numberWithCommas(order.data.total)}
                                  </div>
                                  <Link
                                    to={`/order/${order.data.orderid}`}
                                    style={{
                                      color: "#8A5B40",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <span style={{ fontWeight: "500" }}>
                                      View Details
                                    </span>{" "}
                                    <BsArrowRight
                                      style={{ strokeWidth: "1" }}
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-4 ps-3 d-lg-block "></div>
                              <hr />
                              {order.title === "services" && (
                                <div className="w-md-75 w-100 mx-auto py-3 yourOrderDeliveryStepperDiv">
                                  <Stepper
                                    activeStep={getActiveDeliveryStep(order)}
                                    alternativeLabel
                                  >
                                    {servicesSteps.map((label, i) => (
                                      <Step key={label}>
                                        <StepLabel icon={" "}>
                                          {label}
                                          {getActiveDeliveryStep(order) === 2 &&
                                            i === 1 && (
                                              <p>
                                                {
                                                  order.data
                                                    .advancePercentagePaid
                                                }
                                                % paid via{" "}
                                                {order?.data?.payment?.mode}
                                              </p>
                                            )}
                                        </StepLabel>
                                      </Step>
                                    ))}
                                  </Stepper>
                                </div>
                              )}
                              <OrderComponent order={order} />
                              {/* {order.data.payment.status === "PARTIAL PAYMENT SUCCESSFULL" ? (
                              <>
                                <button
                                  className="cancelOrderBtn"
                                  onClick={(e) => {
                                    // e.preventDefault();
                                    setIsOpenModal(true);
                                    setCancelOrderId(order.data._id);
                                  }}
                                >
                                  {" "}
                                  Cancel Order
                                </button>
                              </>
                            ) : (
                              <></>
                            )} */}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="col-12 container ps-lg-1 ps-0 my-3 pe-0"
                          key={order.data.orderid}
                        >
                          <div className="d-flex flex-row align-items-center justify-content-evenly p-0 m-0 singleCurrentOrderContainer pt-3 ">
                            <div className="detailColumn container m-0 p-0">
                              <div className="w-100 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-between px-3">
                                <div>
                                  <DeliveredIcon
                                    style={{ marginRight: "10px" }}
                                  />
                                  <div
                                    className="current_orders_deliverydate current_orders_green"
                                    style={{ color: "#000000" }}
                                  >
                                    Completed:{" "}
                                    {getServiceDate(
                                      order.data.serviceScheduledDate
                                    )}
                                  </div>
                                  <div className="current_orders_orderID">
                                    Order ID: {order.data.orderid}
                                  </div>
                                </div>
                                <div>
                                  <div className="mb-2">
                                    <span style={{ color: "#878787" }}>
                                      Amount:{" "}
                                    </span>
                                    Rs. {numberWithCommas(order.data.total)}
                                  </div>
                                  <Link
                                    to={`/order/${order.data.orderid}`}
                                    style={{
                                      color: "#8A5B40",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <span style={{ fontWeight: "500" }}>
                                      View Details
                                    </span>{" "}
                                    <BsArrowRight
                                      style={{ strokeWidth: "1" }}
                                    />
                                  </Link>
                                  {/* <a
                                  className=""
                                  href={order.data.invoice}
                                  style={{
                                    color: "#8A5B40",
                                    textDecoration: "none",
                                  }}
                                >
                                  <BiDownload style={{ strokeWidth: "1" }} />
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      marginLeft: "3px",
                                    }}
                                  >
                                    Download Invoice
                                  </span>
                                </a> */}
                                </div>
                              </div>
                              <div>
                                <OrderComponent order={order} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                : !loading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: "10px",
                      }}
                    >
                      <h3>No orders yet!</h3>
                    </div>
                  )}
              <div style={{ marginBlock: "auto", marginInline: "auto" }}>
                {renderPaginationButton()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CancelModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        cancelOrderId={cancelOrderId}
      />
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default YourOrders;
