import { useState } from "react";
import "./Component4.css";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

function Component4({ data }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const onloading = () => {
    setIsLoading(false);
    // }
  };
  return (
    <>
      <div style={{ display: isLoading ? "block" : "none" }}>
        <div style={{ display: "flex" }}>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
          <div className="comp4-loading-shimmer">
            <div className="comp4-shimmer-image"></div>
            <div className="comp4-shimmer-details">
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
              <div className="comp4-shimmer-line"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 compBottomMargin">
        <h1 className="newHeading m-0 p-0 mb-2">{data && data?.title}</h1>
        <div className="w-100 d-lg-flex d-none flex-row flex-wrap g-4 row m-0 justify-content-start">
          {data &&
            data?.data.map((x) => (
              <>
                <div
                  className="col-lg-3 col-sm-4 col-6"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(x?.redirect)}
                >
                  <img
                    onLoad={() => onloading()}
                    src={x.url}
                    alt="image1"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      height: "100%",
                    }}
                  />
                </div>
              </>
            ))}
        </div>
        <div className="w-100 d-lg-none d-sm-block d-none">
          <Swiper slidesPerView={1.5}>
            {data &&
              data?.data.map((x) => (
                <>
                  <SwiperSlide>
                    <div
                      className="pe-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(x?.redirect)}
                    >
                      <img
                        onLoad={() => onloading()}
                        src={x.url}
                        alt="image1"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          height: "100%",
                          maxHeight: "325px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                </>
              ))}
          </Swiper>
        </div>
        <div className="w-100 d-sm-none d-block">
          <Swiper slidesPerView={2.5}>
            {data &&
              data?.data.slice(0, Math.ceil(data?.data.length / 2)).map((x) => (
                <>
                  <SwiperSlide>
                    <div
                      className="pe-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(x?.redirect)}
                    >
                      <img
                        onLoad={() => onloading()}
                        src={x.url}
                        alt="image1"
                        style={{
                          width: "100%",
                          objectFit: "cover",
                          height: "105px",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                </>
              ))}
          </Swiper>
          <div className="mb-2"></div>
          <Swiper slidesPerView={2.5}>
            {data &&
              data?.data
                .slice(Math.ceil(data?.data.length / 2), data?.data.length)
                .map((x) => (
                  <>
                    <SwiperSlide>
                      <div
                        className="pe-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(x?.redirect)}
                      >
                        <img
                          onLoad={() => onloading()}
                          src={x.url}
                          alt="image1"
                          style={{
                            width: "100%",
                            objectFit: "cover",
                            height: "105px",
                          }}
                        />
                      </div>
                    </SwiperSlide>
                  </>
                ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Component4;
