import React, { useState, useEffect, useRef } from "react";
import "./Component3.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAlert } from "react-alert";
import Modal from "react-awesome-modal";
import axios from "axios";
import { API_URL } from "../../../../../constants";
import { AiOutlineClose } from "react-icons/ai";

const Component3 = ({ data, comp2 }) => {
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({
        query: "(min-width:1400px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width:992px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    const minScreen = useMediaQuery({
        maxWidth: 1300,
        minWidth: 993,
    });
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const smallImages = data?.["part1"];
    const largeImages = data?.["part2"];
    const smallSliderRef = useRef(null);
    const largeSliderRef = useRef(null);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        if (largeSliderRef.current) {
            largeSliderRef.current.go(index);
        }
    };
    const alert = useAlert();
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [formData, setformData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        projectLocation: "",
        carpetUnit: "",
        specificRequirement: "",
        budget: "",
        comments: "",
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        let data = index === 0 ? "Interior Designer End to End Services" : (index === 1 ? "Interior Design and Consultancy" : "Design Material and Labour");
        const res = await axios.post(`${API_URL}/turnkey?card=${data}`, formData, {
            // name:'xyz'
        });
        const result = res.data;
        if (result.success) {
            setformData({
                name: "",
                email: "",
                phoneNumber: "",
                projectLocation: "",
                carpetUnit: "",
                specificRequirement: "",
                budget: "",
                comments: "",
            });
            setIsOpen(false);
        } else {
            alert.success(result.msg);
        }
    };
    const [isLoading, setIsLoading] = useState(true);
    const onloading = () => {
        setIsLoading(false);
        // }
    };
    return (
        <>
            {(isLoading) && <div style={{ display: isLoading ? "block" : "none" }}>
                <div style={{ display: "flex" }}>
                    <div className="loadingg-shimmer">
                        <div className="shimmer-imagee"></div>
                    </div>
                    <div className="loadingg-shimmer">
                        <div className="shimmer-imagee"></div>
                    </div>{" "}
                    <div className="loadingg-shimmer">
                        <div className="shimmer-imagee"></div>
                    </div>
                </div>
                <div className="comp3-loading-shimmer">
                    <div className="comp3-shimmer-image"></div>
                </div>
            </div>}
            {data && (
                <>

                    <div className="compBottomMargin">
                        <span className="newHeading">Hire</span>

                        <div className="d-lg-flex d-none w-100 flex-wrap align-items-center justify-content-start mb-1">
                            {smallImages?.map((item, index) => (
                                <div
                                    className="col-4 py-3 px-2"
                                    style={{ display: "flex", cursor: "pointer", display: isLoading ? "none" : "block" }}

                                >
                                    <img
                                        src={item}
                                        className=""
                                        alt="Interior Design Image"
                                        onClick={() => handleImageClick(index)}
                                        onLoad={() => onloading()}
                                        style={
                                            selectedImageIndex === index
                                                ? {
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "14px",
                                                    objectFit: "cover",
                                                    outline: isMobile
                                                        ? "2px solid #808080b0"
                                                        : "5px solid #808080b0",
                                                }
                                                : {
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: "14px",
                                                    objectFit: "cover",
                                                }
                                        }
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="d-lg-none d-block mb-3">
                            <Splide
                                style={{
                                    overflowX: "scroll",
                                    scrollbarWidth: "none",
                                    "-ms-overflow-style": "none",
                                }}
                                options={{
                                    type: "",
                                    perPage: isMobile
                                        ? 2.3
                                        : isTablet
                                            ? 3
                                            : isDesktop
                                                ? 2.7
                                                : minScreen
                                                    ? 2.5
                                                    : 2.5,
                                    perMove: 1,
                                    pagination: false,
                                    // rewind: true,
                                    arrows: false,
                                    gap: isDesktop ? "1.5em" : isMobile ? "0em" : "1em",
                                }}
                                ref={smallSliderRef}
                                onMoved={(index) => setSelectedImageIndex(index)}
                            >
                                {smallImages?.map((item, index) => (
                                    <SplideSlide key={index}>
                                        <div
                                            className="py-sm-2 pt-sm-0 pt-2 pb-0 my-1 px-1"
                                            style={{ display: "flex", cursor: "pointer" }}
                                        >
                                            <img
                                                src={item}
                                                className="smallImg"
                                                alt="Interior Design Image"
                                                onClick={() => handleImageClick(index)}
                                                style={
                                                    selectedImageIndex === index
                                                        ? {
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "14px",
                                                            objectFit: "cover",
                                                            boxShadow: isMobile
                                                                ? "0 0 0 2px #808080b0"
                                                                : "0 0 0 5px #808080b0",
                                                        }
                                                        : {
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "14px",
                                                            objectFit: "cover",
                                                        }
                                                }
                                            />
                                        </div>
                                    </SplideSlide>
                                ))}
                            </Splide>
                        </div>
                        <Splide
                            style={{
                                overflowX: "scroll",
                                scrollbarWidth: "none",
                                "-ms-overflow-style": "none",
                            }}
                            options={{
                                type: "",
                                perPage: 1,
                                perMove: 1,
                                pagination: false,
                                rewind: true,
                                arrows: true,
                                gap: isDesktop ? "1.5em" : "1em",
                            }}
                            ref={largeSliderRef}
                        >
                            {largeImages?.map((item, index) => (
                                <SplideSlide key={index}>
                                    <img
                                        className="imgg"
                                        src={item?.url}
                                        alt={`Large Image ${index}`}
                                        onClick={() => {
                                            navigate(item?.redirect);
                                        }}
                                        style={{ cursor: "pointer" }}
                                    />
                                </SplideSlide>
                            ))}
                        </Splide>
                    </div>
                </>
            )}
            {comp2 && (
                <>
                    <div className="turnKeyFormModal">
                        {isOpen && (
                            <Modal
                                visible={isOpen}
                                width="650"
                                height="730"
                                effect="fadeInUp"
                                className="bookVisitModal"
                                onClickAway={() => {
                                    setIsOpen(false);
                                    setformData({
                                        name: "",
                                        email: "",
                                        phoneNumber: "",
                                        projectLocation: "",
                                        carpetUnit: "",
                                        specificRequirement: "",
                                        budget: "",
                                        comments: "",
                                    });
                                }}
                            >
                                <button
                                    type="button"
                                    className="close closeChatBotButton"
                                    aria-label="Close"
                                    onClick={() => setIsOpen(false)}
                                >
                                    <span aria-hidden="true">
                                        <AiOutlineClose />
                                    </span>
                                </button>
                                <div className="login-box" style={{ overflowY: "auto" }}>
                                    <h2>Details</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="user-box">
                                            <input
                                                type="text"
                                                name=""
                                                value={formData.name}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        name: e.currentTarget.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <label>Name</label>
                                        </div>
                                        <div className="user-box">
                                            <input
                                                type="email"
                                                name=""
                                                value={formData.email}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        email: e.currentTarget.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <label>Email</label>
                                        </div>
                                        <div className="user-box">
                                            <input
                                                type="text"
                                                name=""
                                                value={formData.phoneNumber}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        phoneNumber: e.currentTarget.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <label>Phone Number</label>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="user-box" style={{ width: "45%" }}>
                                                <input
                                                    type="text"
                                                    name=""
                                                    value={formData.projectLocation}
                                                    onChange={(e) => {
                                                        setformData({
                                                            ...formData,
                                                            projectLocation: e.currentTarget.value,
                                                        });
                                                    }}
                                                    required
                                                />
                                                <label>Project Location</label>
                                            </div>
                                            <div className="user-box" style={{ width: "45%" }}>
                                                <input
                                                    type="text"
                                                    name=""
                                                    value={formData.carpetUnit}
                                                    onChange={(e) => {
                                                        setformData({
                                                            ...formData,
                                                            carpetUnit: e.currentTarget.value,
                                                        });
                                                    }}
                                                    required
                                                />
                                                <label>Carpet Area</label>
                                            </div>
                                        </div>
                                        <div className="user-box">
                                            <input
                                                type="text"
                                                name=""
                                                value={formData.specificRequirement}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        specificRequirement: e.currentTarget.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <label>Specific Requirement</label>
                                        </div>
                                        <div className="user-box">
                                            <input
                                                type="text"
                                                name=""
                                                value={formData.budget}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        budget: e.currentTarget.value,
                                                    });
                                                }}
                                                required
                                            />
                                            <label>Budget</label>
                                        </div>
                                        <label style={{ color: "#878787" }}>
                                            Additional Comments
                                        </label>
                                        <div className="user-box">
                                            <textarea
                                                rows="10"
                                                columns="10"
                                                style={{ border: "1px solid #000" }}
                                                value={formData.comments}
                                                onChange={(e) => {
                                                    setformData({
                                                        ...formData,
                                                        comments: e.currentTarget.value,
                                                    });
                                                }}
                                            ></textarea>
                                        </div>
                                        <p className="m-0">
                                            Fill all the fields, our experts will contact you soon!
                                        </p>
                                        <button
                                            type="submit"
                                            className="addUpdateButton"
                                            onClick={() => setformData({ ...formData })}
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </Modal>
                        )}
                    </div>
                    <div className="d-flex flex-column w-100">
                        <div
                            className="compBottomMargin d-lg-none d-block newHpTabletMapParent w-100 order-sm-1 order-1"
                            onClick={() => navigate("/visit-showroom")}
                        >
                            <img
                                src={comp2?.part2?.banner}
                                className="w-100"
                                style={{ borderRadius: "15px" }}
                            />
                        </div>
                        <div className="compBottomMargin d-lg-none d-block nenewHpMobileServicesParent w-100 order-sm-0 order-0">
                            <h2 className="newHeading">Services</h2>
                            <Swiper slidesPerView={2.5}>
                                {comp2?.part4.map((x, i) => (
                                    <>
                                        <SwiperSlide>
                                            <div
                                                className="w-10 pe-2"
                                                onClick={() => { setIsOpen(true); setIndex(i); }}
                                            >
                                                <img
                                                    src={x?.url}
                                                    className="hpComp3ServicesImg w-100"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    </>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Component3;

// import React, { useState } from "react";
// import "./Component3.css";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import { useMediaQuery } from "react-responsive";
// import NewHireCarousel from "./NewHireCarousel";

// const Component3 = ({ data }) => {
//     let isDesktop = useMediaQuery({
//         query: "(min-width:1400px)",
//     });

//     let isTablet = useMediaQuery({
//         query: "(max-width:992px)",
//     });

//     let isMobile = useMediaQuery({
//         query: "(max-width:576px)",
//     });

//     let minScreen = useMediaQuery({
//         maxWidth: 1300,
//         minWidth: 993,
//     });
//     const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//     const handleImageClick = (index) => {
//         setSelectedImageIndex(index);
//         console.log(index)
//     };

//     const smallImages = data?.["part1"];
//     const lagreImages = data?.["part2"];
//     return (
//         <div>
//             <span style={{ fontWeight: "bold", fontSize: "2.5rem" }}>Hire</span>
//             <Splide
//                 style={{
//                     overflowX: "scroll",
//                     scrollbarWidth: "none",
//                     "-ms-overflow-style": "none",
//                 }}
//                 options={{
//                     type: "",
//                     perPage: isMobile ? 2.3 : isTablet ? 3 : isDesktop ? 2.7 : minScreen ? 2.5 : 2.5,
//                     perMove: 1,
//                     pagination: false,
//                     rewind: true,
//                     arrows: false,
//                     gap: isDesktop ? "1.5em" : "1em",
//                 }}
//             >
//                 {smallImages?.map((item, index) => (
//                     <SplideSlide>
//                         <div style={{ margin: "0.7%", display: "flex" }} key={index}>
//                             <img
//                                 src={item}
//                                 className="smallImg"
//                                 alt="Interior Design Image"
//                                 onClick={() => handleImageClick(index)}
//                                 style={{
//                                     width: "100%",
//                                     height: "100%",
//                                     borderRadius: "14px",
//                                     objectFit: "cover",
//                                 }}
//                             />
//                         </div>
//                     </SplideSlide>
//                 ))}
//             </Splide>
//             <Splide
//     style={{
//         overflowX: "scroll",
//         scrollbarWidth: "none",
//         "-ms-overflow-style": "none",
//     }}
//     options={{
//         type: "",
//         perPage: 1,
//         perMove: 1,
//         pagination: false,
//         rewind: true,
//         arrows: true,
//         gap: isDesktop ? "1.5em" : "1em",
//         start: 2, // Set the start option at this level
//     }}
// >
//     {lagreImages?.map((item, index) => (
//         <SplideSlide key={index}>
//             <img className='imgg' src={item.url} alt={`Large Image ${index}`} />
//         </SplideSlide>
//     ))}
// </Splide>
//             {/* <div style={{ marginTop: "2%" }}>
//                 <NewHireCarousel prop={selectedImageIndex} reqImage={lagreImages} />
//             </div> */}
//         </div>
//     );
// };

// export default Component3;
