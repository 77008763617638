import { Link } from "react-router-dom";
import Slider from "react-slick";
import { setRoute } from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import { useEffect } from "react";
import { useState } from "react";
export default function SimpleSlider(props) {
      const [isLoading,setIsLoading]=useState(true);
      const onloading = () => {
        setIsLoading(false);
        // }
      };
         


        var settings = {
                dots: false,
                infinite: true,
                speed: 2000,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
        };
        const [ state, dispatch] = useStore()
        function onClickImage(){
                dispatch(setRoute({}))
        }
        
    
             
        return (
                <>
                <div className="threeSliderShimmer" style={{ display: isLoading ? "block" : "none" }}></div>
                <Slider {...settings} style={{ display: isLoading ? "none" : "block" }}>
                        {props.images.map((image)=>{return(<>{!image.query?(
                                
                                <>
                            
                                        <img src={image.image} width='100%' alt="" onLoad={() => {
                            onloading();
                          }}/>:
                                        
                                
                                </>
                        ):(
                                <Link to={`/productlist?${image.query}`}>
                               
                                        <img src={image.image} width='100%' onClick={onClickImage} alt="" onLoad={() => {
                            onloading();
                          }}/>:
                                        
                                
                        
                                </Link>
                        )
                        }</>)})}
                </Slider>
                </>
        );
}