import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import apple_logo from "./images/apple_logo.png";
import app_store from "./images/app_store.png";
import background_rectangle from "./images/background_rectangle.png";
import google_play from "./images/google_play.png";
import playstore_logo from "./images/playstore_logo.png";

function Footer() {
  return (
    <>
      <div className="w-100 py-5 position-relative" style={{ background: "#ebebeb" }}>
        <div className="container">
          <div className="row p-relative w-100">
            <div className="col-lg-3 col-6">
              <div className="footer_column_heading">Useful Links</div>
              <ul className="footer_contents">
                <li>
                  <a href="/cart">Cart</a>
                </li>
                <li>
                  <Link to="/about-us">About us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer_column_heading">Services</div>
              <ul className="footer_contents">
                <li>
                  <Link to="/interior-design">Hire an Interior Designer</Link>
                </li>
                <li>
                  <Link to="/visit-showroom">Visit Showroom</Link>
                </li>
                <li>
                  <Link to="/hire-artisans">Hire Artisans</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer_column_heading">Need Help?</div>
              <ul className="footer_contents">
                <li>
                  <Link to="/faq">FAQ's</Link>
                </li>
                <li>
                <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/cancellation-refund-policy">Cancellation/Refund Policy</Link>
                </li>
                <li>
                  <Link to="/shipping-and-delivery-policy">Shipping & Delivery Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-6">
              <div className="footer_column_heading">Download App</div>
              <ul className="footer_contents">
                <li>
                  <a href="https://apps.apple.com/in/app/plypicker/id6448257331" target="_blank">
                    <div className="footer_images_column">
                      <img src={background_rectangle} alt="" />
                      <div className="footer_logo">
                        <img src={apple_logo} alt="" />
                      </div>
                      <div className="footer_text">
                        <img src={app_store} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=com.plypicker.plypickerapp&pli=1" target="_blank">
                    <div className="footer_images_column">
                      <img src={background_rectangle} alt="" />
                      <div className="footer_logo">
                        <img src={playstore_logo} alt="" />
                      </div>
                      <div className="footer_text">
                        <img src={google_play} alt="" />
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
