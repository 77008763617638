import axios from "axios";
import React, { useEffect, useState } from "react";

import { API_URL , REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import { setHomeData } from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import Footer from "../../global_component/Footer/Footer";
import LowerNavigation from "../../global_component/LowerNavigation/LowerNavigation";
import Banner from "./Banner/Banner";
import ThreeImages from "./Component1/ThreeImages";
import "./Component2/ShopbyCategory.css";
import ShopbyPrice from "./Component3/ShopbyPrice";
import TopBrands from "./Component4/TopBrands";
import Icons from "./Component6/Icons";
import CreativeIdeas from "./CreativeIdeas/CreativeIdeas";
import OurServices from "./OurServices/OurServices";
import RecentlyViewedItems from "./RecentlyViewedItems/RecentlyViewedItems";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function Homepage() {
  const [state, dispatch] = useStore();
  const [fetchedData, setFetchedData] = useState([]);
  ReactGA.send({
    hitType: 'pageview',
    page: '/',
    title : 'Home Page'
  })
  useEffect(() => {
    async function apiCall() {
      document.title = `PLYPICKER`;
      const res = await axios.get(`${API_URL}/home`);
      dispatch(setHomeData(res.data));
      setFetchedData(res.data);
    }
    apiCall();
  }, []);

  ReactGA.event({
    category: 'Homepage',
    action:'show',
    label: 'show',
    value: 'homepage'
  })

  function setFilter(filterName) {
    const data = fetchedData.filter((e) => e.type === filterName);
    return data;
  }

  
  return (
    <>
      <Helmet>
        <meta
          name="title"
          content="Online shopping site for buying Laminate, Plywood, wires, sanitary, hardware, and services like carpentry plumbing and more - Best offer!"
        />
        <meta
          name="description"
          content="Maharastras biggest e-commerce for buying Laminate, Plywood, wires, tiles, basin, sanitary, hardware, screws and services like carpentry, plumbin, fabrication and more."
        />
        <meta
          name="keywords"
          content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
        />
        <meta property="og:image" content="LOGO" />
        <link rel="canonical" href="https://plypicker.com/" />
      </Helmet>
      <ThreeImages
        pcData={setFilter("slider_pc")}
        tabletData={setFilter("slider_tablet")}
        mobileData={setFilter("slider_mobile")}
      />
      <div className="d-md-block d-none">
        <Banner data={fetchedData} />
      </div>
      {/* <ShopbyCategory /> */}
      <ShopbyPrice data={setFilter("shop_by_price")} />
      {/* <PickStyle/> */}
      <TopBrands />
      {state.authenticated && <RecentlyViewedItems />}
      {/* <FiveImages /> */}
      <CreativeIdeas CardData={setFilter("creative_ideas")} />
      <OurServices CardData={setFilter("our_services")} />
      <Icons brandData={setFilter("brand_partners")} brandSlider={true} />
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default Homepage;
