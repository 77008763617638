import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
    const scrollRef = useRef();
    useEffect(() => {
        const ref = scrollRef.current;
        if (ref) {
            const onWheel = e => {
                if (e.deltaY === 0) return;
                e.preventDefault();
                ref.scrollTo({
                    left: ref.scrollLeft + (1.5 * (e.deltaY)),
                    behavior: "smooth"
                });
            };
            ref.addEventListener("wheel", onWheel);
            return () => ref.removeEventListener("wheel", onWheel);
        }
    }, []);
    return scrollRef;
}