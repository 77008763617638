import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from "jose";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { fromString } from "uint8arrays/from-string";
import {
    API_URL,
    REACT_APP_HKEY,
    REACT_APP_JKEY,
    REACT_APP_GA_MEASUREMENT_ID
} from "../../../../constants.js";
import { useStore } from "../../../../store/store.js";
import useAxios from "../../../../utils/useAxios.js";
import Footer from "../../../global_component/Footer/Footer";
import LoginRegisterPopup from "../../../global_component/LoginRegisterPopup/LoginRegisterPopup.js";
import { ReactComponent as Share } from "../../IndividualProducts/NewProductDetail/img/share.svg";
import StarRating from "../../IndividualProducts/Products_Detail/StarRating/StarRating";
import NavRoute from "../../Nav_Route/NavRoute";
import CityModal from "../../../global_component/Modal/cityModal.js"
import ReactGA from "react-ga4";
import RelatedServices from "./RelatedServices";
import Countdown from "react-countdown";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function ServiceDetails() {
    const params = useParams();
    const api = useAxios();
    const navigate = useNavigate();
    const alert = useAlert();
    const [state, dispatch] = useStore();
    const [quantity, setQuantity] = useState(1);
    const [lrpVisible, setLrpVisible] = useState(false);
    const [pcharges, setPcharges] = useState(0);
    const [length, setLength] = useState(1);
    const [width, setWidth] = useState(2);
    const [service, setService] = useState({});
    const [productDescription, setProductDescription] = useState([]);
    const [isTruncated, setIsTruncated] = useState(true);
    const [hireModal, setHireModal] = useState(false);
    const [isError, setIsError] = useState(false);
    const [issLoadingProducts, setIsLoadingProducts] = useState(false);
    const [selectedVariation, setSelectedVariation] = useState({});
    const [allVariations, setAllVariations] = useState({});
    const [servicePrice, setServicePrice] = useState(0);
    const [productName, setProductName] = useState("");
    const [totalUnits, setTotalUnits] = useState(length * width * quantity);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isGettingPrice, setIsGettingPrice] = useState(false);
    const [getPriceTimestamp, setGetPriceTimestamp] = useState(null);

    function truncate2(text) {
        return text?.length > 150 ? text.substring(0, 150) + "..." : text;
    }

    const hideReviewModal = () => {
        setHireModal(false);
    };

    function changeVariationHandler(targetVariationName, targetVariationValue) {

        setSelectedVariation({
            ...selectedVariation,
            [targetVariationName]: targetVariationValue,
        });
        if (Object.keys(allVariations)[0] === targetVariationName) {
            let indexOfQueryVar = allVariations[targetVariationName].findIndex(
                (o) => o.attrs[targetVariationName] === targetVariationValue
            );
            if (indexOfQueryVar > -1) {
                setServicePrice(allVariations[targetVariationName][indexOfQueryVar]?.discounted_price);
                setProductName(allVariations[targetVariationName][indexOfQueryVar]?.serviceName)
            } else {
                setServicePrice(0);
            }
        }
    }

    async function getPrice(id) {
        setIsGettingPrice(true);
        if (state.authenticated) {
            if (state.accessToken === null || !state.authenticated) {
                setIsGettingPrice(false);
            } else {
                const res = await api.get(`${API_URL}/services/getservicesprice`, {
                    headers: {
                        id: id,
                    },
                });
                if (res.data.success === true) {
                    alert.success(res.data.msg);
                    setGetPriceTimestamp(res.data.dueTimestamp);
                    setTimeout(() => {
                        alert.success("Till then you can add this in your wishlist");
                    }, 1250);
                    setIsGettingPrice(false);
                } else {
                    alert.error("An error occoured");
                    setIsGettingPrice(false);
                }
            }
            setIsGettingPrice(false);
        } else {
            setIsGettingPrice(false);
        }
    }

    function deselectVariationHandler(targetVariationName, targetVariationValue) {
        let newVariationObject = selectedVariation;
        delete newVariationObject[targetVariationName];
        setSelectedVariation({ ...newVariationObject });
        setServicePrice(service?.discounted_price);
        setProductName(service?.serviceName);
    }

    function onClickHire() {
        if (allVariations === {} || allVariations === undefined || allVariations === null) {
            setHireModal(true);
        } else {
            for (const variationKey of Object.keys(allVariations)) {
                if (!Object.keys(selectedVariation).includes(variationKey)) {
                    alert.error(`Please select ${variationKey}`);
                } else {
                    setHireModal(true);
                }
            }
        }
    }

    ReactGA.send({
        hitType: 'pageview',
        page: `/hire-artisans/service/:${params?.id}`,
        title: 'Service Details'
    })

    useEffect(() => {
        async function fetchData() {
            var dec = genSaltSync(10);
            var hash = hashSync(REACT_APP_HKEY, dec);
            const secret = fromString(REACT_APP_JKEY);
            const twj = await new jose.SignJWT({ expiry: hash })
                .setProtectedHeader({ alg: "HS256" })
                .setExpirationTime("5s")
                .sign(secret);
            const res = await axios.get(
                `${API_URL}/services/getService/${params?.id}`,
                {
                    headers: {
                        "Content-Policy": twj,
                        "cityId": state.selectedCity
                    },
                }
            );

            if (res.data?.length === 0) {
                setIsError(true);
                setIsLoadingProducts(false);
            } else {
                setIsError(false);
                setProductDescription("Work will be completed in 3 visits. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat");
                setService(res.data);
                setProductName(res.data?.serviceName);
                setServicePrice(res.data?.discounted_price);
                setAllVariations(res.data?.vars);
            }
        }
        fetchData();
    }, [state.selectedCity, params?.id]);

    useEffect(() => {
        if (service.unit === "Sq ft") {
            setTotalUnits(length * width * quantity);
            setTotalPrice(length * width * quantity * servicePrice);
        } else if (service.unit === "R ft") {
            setTotalUnits(length * quantity);
            setTotalPrice(length * quantity * servicePrice);
        } else {
            setTotalUnits(quantity);
            setTotalPrice(quantity * servicePrice);
        }
    }, [quantity, length, width, servicePrice]);

    async function addServiceToCart(event) {
        event.preventDefault();
        if (state.authenticated) {
            if (service.unit === "Sq ft") {
                const res = await api.post(`/servicescart`, {

                    quantity,
                    service_id: service._id,
                    variation: {
                        ...selectedVariation,
                        length,
                        width
                    }
                });
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    alert.success(res.data.msg);
                    setTimeout(() => {
                        navigate("/services/cart");
                    }, 1000);
                }
            } else if (service.unit === "R ft") {
                const res = await api.post(`/servicescart`, {
                    quantity,
                    service_id: service._id,
                    variation: {
                        ...selectedVariation,
                        length
                    }
                });
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    alert.success(res.data.msg);
                    setTimeout(() => {
                        navigate("/services/cart");
                    }, 1000);
                }
            } else {
                const res = await api.post(`/servicescart`, {
                    quantity,
                    service_id: service._id,
                    variation: {
                        ...selectedVariation
                    }
                });
                if (res.data.err) {
                    alert.error(res.data.msg);
                } else {
                    alert.success(res.data.msg);
                    setTimeout(() => {
                        navigate("/services/cart");
                    }, 1000);
                }
            }
        } else {
            setLrpVisible(true);
        }
    }

    const [showModal, setShowModal] = useState(state.selectedCity ? false : true);
    const closeModalHandler = () => {
        setShowModal(false);
    };

    return (
        <>
            {!state.selectedCity && <CityModal onClose={closeModalHandler}></CityModal>}
            <LoginRegisterPopup isVisible={lrpVisible} setIsVisible={setLrpVisible} />
            <NavRoute
                productRouter
                services
                title="Hire Artisans"
                serviceId={params?.id}
            />
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12 position-relative imageColumnNPD">
                        <div className="d-flex justify-content-center">
                            <img
                                className="ServiceCategoryBigImage"
                                src={service?.attrs?.imgs[0]}
                            />
                        </div>
                        <div className="row d-flex justify-content-center align-items-center mt-3">
                            {servicePrice ? <div
                                className="newProductBuyNowButton buynow"
                                type="button"
                                onClick={() => onClickHire()}
                                style={{ textTransform: "uppercase", width: "80%" }}
                            >
                                Hire
                            </div> : <div
                                className="newProductBuyNowButton buynow"
                                type="button"
                                onClick={() => getPrice(service?._id)}
                                style={{ textTransform: "uppercase", width: "80%" }}
                            >
                                {isGettingPrice ? "Getting price..." : "Get price"}
                            </div>}
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="row newProductStarRatingRow">
                            <div className="col-8">
                                <StarRating stars={3} avgRate />
                            </div>
                            <div className="col-4 text-end d-md-block d-none">
                                <Share
                                    style={{
                                        fill: "#878787",
                                        transform: "scale(0.8)",
                                        cursor: "pointer",
                                    }}
                                    className="me-2"
                                    onClick={() => {
                                        navigator.clipboard.writeText(window.location.href);
                                        alert.success("Link copied to clipboard");
                                    }}
                                />
                            </div>
                        </div>
                        <h2 className="newProductTitle">{productName}</h2>
                        <p className="price mt-3">{getPriceTimestamp ? <>
                            <div className="">
                                {getPriceTimestamp > new Date().getTime() ? (
                                    <p className="m-0 mt-2 newText">
                                        We will contact you with the price before{" "}
                                        <Countdown date={getPriceTimestamp} />
                                    </p>
                                ) : (
                                    <p className="m-0">
                                        Thank you for your interest in this product.
                                    </p>
                                )}
                            </div>
                        </> : <>{service?.discounted_price && <>Rs. {servicePrice} {service.unit !== "" && <>{" "}</>}{service.unit === "Sq ft" && <>/ sq ft</>}{service.unit === "R ft" && <>/running ft</>}</>}</>}</p>
                        <div className="variationsSection">
                            {Object.entries(allVariations || {}).map(
                                ([variationName, variationData]) => {
                                    if (
                                        variationName !== "color" ||
                                        variationName !== "colour"
                                    ) {
                                        return (
                                            <>
                                                <h3 className="newProductSpecificationsTitle">
                                                    {variationName}
                                                </h3>
                                                <div className="d-flex flex-row flex-wrap">
                                                    {variationData.map(
                                                        (variationQuantity, variationIndex) => (
                                                            <>
                                                                <div
                                                                    className={
                                                                        selectedVariation[variationName] ===
                                                                            variationQuantity?.attrs[variationName]
                                                                            ? `newProductSelectedVariation newProductVariationBox`
                                                                            : `newProductVariationBox`
                                                                    }
                                                                    onClick={() => {
                                                                        selectedVariation[variationName] ===
                                                                            variationQuantity?.attrs[variationName]
                                                                            ? deselectVariationHandler(
                                                                                variationName,
                                                                                variationQuantity?.attrs[
                                                                                variationName
                                                                                ]
                                                                            )
                                                                            : changeVariationHandler(
                                                                                variationName,
                                                                                variationQuantity.attrs[
                                                                                variationName
                                                                                ]
                                                                            );
                                                                    }}
                                                                >
                                                                    {variationQuantity?.attrs[variationName]}
                                                                </div>
                                                            </>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                }
                            )}
                        </div>
                        {productDescription?.length > 1 && (
                            <>
                                <h3 className="newProductSpecificationsTitle">DESCRIPTION</h3>
                                <p
                                    className="newProductDescriptionDescription"
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: "16px",
                                        fontWeight: 300,
                                        lineHeight: "24px",
                                        letterSpacing: "0em",
                                        textAlign: "left",
                                    }}
                                >
                                    {isTruncated ? (
                                        <>
                                            {truncate2(productDescription)}{" "}
                                            {productDescription?.length > 150 && (
                                                <span
                                                    className="newProductReadMore"
                                                    onClick={() => {
                                                        setIsTruncated(false);
                                                    }}
                                                >
                                                    Read more
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {productDescription}{" "}
                                            {productDescription?.length > 150 && (
                                                <span
                                                    className="newProductReadMore"
                                                    onClick={() => {
                                                        setIsTruncated(true);
                                                    }}
                                                >
                                                    Read less
                                                </span>
                                            )}
                                        </>
                                    )}
                                </p>
                                <hr className="newProductDetailHr" />
                            </>
                        )}
                        <h3 className="newProductSpecificationsTitle">
                            TERMS & CONDITIONS
                        </h3>
                        <ul>
                            <li>
                                The booking amount is fixed and non-negotiable                    </li>
                            <li>
                                The Customer shall inspect the work daily or alternatively                    </li>
                            <li>
                                Material is optional                    </li>
                            <li>
                                100% refundable cancellation before 72 hours                    </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className="row w-100 m-0 mt-5 p-0">
            <h3 className="newProductSpecificationsTitle">
              RATINGS AND REVIEW
            </h3>
            <hr className="newProductDetailHr" />
            <div className="d-flex flex-column">
              <div className="newProductRatingDiv d-flex flex-row align-items-center">
                <h1 className="newProductAverageRating">
                  5
                </h1>
                <div className="ms-3">
                  <span className="newProductRatingDiv_span row">
                    <p className="col-2 newProductRatingNumber">5</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={fiveRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">4</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={fourRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">3</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={threeRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">2</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={twoRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                  <span className="row">
                    <p className="col-2 newProductRatingNumber">1</p>
                    <span className="col-8 p-0 d-flex align-items-center justify-content-start">
                      <ProgressBar
                        className="ProgessRating"
                        now={oneRating}
                        min={"0"}
                        max={product?.rating_and_review?.length || 0}
                      />
                    </span>
                  </span>
                </div>
              </div>
              {thisReviewRating && thisReviewRating.addModal ? (
                <>
                  <button
                    className="newProductBuyNowButton btn"
                    style={{
                      width: "200px",
                      border: "1px solid #ff7703",
                      color: "#ffffff",
                      backgroundColor: "#ff7703",
                      borderRadius: "0px",
                      marginLeft: "3.5%",
                    }}
                    onClick={() => {
                      navigate(`/rating-review/${p_id}`);
                    }}
                  >
                    Add Review
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="newProductReviewDiv row row-cols-4">
              {!isReviewDropdown ? (
                <>
                  {state?.product?.rating_and_review
                    ?.slice(0, 4)
                    .map((review) => (
                      <>
                        <div className="col-lg-3 col-sm-6 col-12 newProductReviewSingleDiv">
                          <div className="newProductReviewInnerDiv d-flex flex-column">
                            <img
                              src={review?.reviewImage}
                              className="newProductReviewUserImage"
                              width="100%"
                            />
                            <div className="newProductReviewInsideDiv">
                              <h4 className="text-center newProductReviewAuthor">
                                {review?.user_id?.name}
                              </h4>
                              <p className="text-center newProductReviewUserDescription">
                                {review?.description}
                              </p>
                            </div>
                            <div className="newProductReviewFooter row">
                              <div className="col">
                                <span className="newProductReviewDate">
                                  {diff(review?.updatedAt) === 0
                                    ? "Just Now"
                                    : diff(review?.updatedAt) + " days ago"}
                                </span>
                                <span className="newProductReviewRatingSmall">
                                  {review?.rating}{" "}
                                  <img
                                    src={star}
                                    className="newProductReviewRatingStar"
                                  />
                                </span>
                              </div>
                              <div className="col text-end">
                                {review?.user_id?._id === state?.user?._id ? (
                                  <div className="reviewsAction hoverMe">
                                    <Tooltip title="Edit">
                                      <span>
                                        <EditButton
                                          className="editReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setSelectedReview(review);
                                            setNewRating(review?.rating);
                                            setReviewText(review?.description);
                                            setReviewImage(review?.reviewImage);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <span>
                                        <TrashButton
                                          className="deleteReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setDeleteModalStatus(true);
                                            setSelectedReview(review);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </>
              ) : (
                <>
                  {state?.product?.rating_and_review
                    ?.sort(function (x, y) {
                      return x?.user_id?._id === state.user?._id
                        ? -1
                        : y?.user_id?._id === state.user?._id
                        ? 1
                        : 0;
                    })
                    .map((review) => (
                      <>
                        <div className="col-lg-3 col-sm-6 col-12 newProductReviewSingleDiv">
                          <div className="newProductReviewInnerDiv d-flex flex-column">
                            <img
                              src={review?.reviewImage}
                              className="newProductReviewUserImage"
                              width="100%"
                            />
                            <div className="newProductReviewInsideDiv">
                              <h4 className="text-center newProductReviewAuthor">
                                {review?.user_id?.name}
                              </h4>
                              <p className="newProductReviewUserDescription text-center">
                                {review?.description}
                              </p>
                            </div>
                            <div className="newProductReviewFooter row">
                              <div className="col">
                                <span className="newProductReviewDate">
                                  {diff(review?.updatedAt) === 0
                                    ? "Just Now"
                                    : diff(review?.updatedAt) + " days ago"}
                                </span>
                                <span className="newProductReviewRatingSmall">
                                  {review?.rating}{" "}
                                  <img
                                    src={star}
                                    className="newProductReviewRatingStar"
                                  />
                                </span>
                              </div>
                              <div className="col text-end">
                                {review?.user_id?._id === state?.user?._id ? (
                                  <div className="reviewsAction hoverMe">
                                    <Tooltip title="Edit">
                                      <span
                                        className="editReview hoverMe"
                                        style={{ fontSize: "1.7rem" }}
                                      >
                                        <EditButton
                                          className="editReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setSelectedReview(review);
                                            setNewRating(review.rating);
                                            setReviewText(review.description);
                                            setReviewImage(review.reviewImage);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <span
                                        className="deleteReview hoverMe"
                                        style={{ fontSize: "1.7rem" }}
                                      >
                                        <TrashButton
                                          className="deleteReview hoverMe"
                                          onClick={() => {
                                            showReviewModal();
                                            setDeleteModalStatus(true);
                                            setSelectedReview(review);
                                          }}
                                        />
                                      </span>
                                    </Tooltip>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </>
              )}
            </div>
            {state?.product?.rating_and_review?.length > 4 && (
              <>
                <button
                  className="newProductReviewDropdownButton"
                  type="button"
                  onClick={() => {
                    setIsReviewDropdown(!isReviewDropdown);
                  }}
                >
                  {isReviewDropdown ? <>VIEW LESS</> : <>VIEW ALL</>}
                </button>
              </>
            )}
          </div> */}
            <RelatedServices
                p_id={params?.id}
                s_sc={service?.serviceSubcategory?._id}
            />
            <Modal
                show={hireModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={hideReviewModal}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "10px",
                    }}
                >
                    <span
                        style={{
                            margin: "0px auto",
                            fontFamily: "Poppins",
                            fontSize: "24px",
                            fontWeight: 600,
                            lineHeight: "36px",
                            letterSpacing: "0em",
                            textAlign: "left",
                        }}
                    >
                        PRICE DETAILS
                    </span>
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        color="#000000"
                        onClick={() => hideReviewModal()}
                    ></button>
                </div>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <span className="col-8">
                                <label className="inputLabelsService" id="NameId">
                                    Quantity
                                </label>
                                <input id="NameId" className="inputServiceNum" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                            </span>
                            <span className="col-4">
                                <label className="inputLabelsService" id="NameId">
                                    Per unit charges
                                </label>
                                <input id="NameId" className="inputServiceNum" type="number" value={servicePrice} onChange={(e) => { }} />
                            </span>
                        </div>
                        <div className="row align-items-center">
                            {service.unit !== "" && <span className="col-4">
                                <label className="inputLabelsService" id="NameId">
                                    Length (ft)
                                </label>
                                <input id="NameId" min={1} className="inputServiceNum" type="number" value={length} onChange={(e) => e.target.value > 0 ? setLength(e.target.value) : alert.error("Length should atleast be 1")} />
                            </span>}
                            {service.unit === "Sq ft" && <>
                                <>x</>
                                <span className="col-4">
                                    <label className="inputLabelsService" id="NameId">
                                        Width (ft)
                                    </label>
                                    <input id="NameId" min={1} className="inputServiceNum" type="number" value={width} onChange={(e) => e.target.value > 0 ? setWidth(e.target.value) : alert.error("Width should atleast be 1")} />
                                </span>
                            </>}
                            {service.unit !== "" && <>=</>}
                            <span className="col-3 align-self-end">
                                <label className="inputLabelsService" id="NameId">
                                    Total Units
                                </label>
                                <input id="NameId" value={totalUnits} className="inputServiceNum" type="number" />
                            </span>
                        </div>
                        {service.unit === "Sq ft" && <>
                            <span style={{ color: "#878787", fontSize: "10px", fontWeight: "400", fontFamily: "Poppins" }}>*(Length x Width) x Number of units = Total Units</span>
                        </>}
                        {service.unit === "R ft" && <>
                            <span style={{ color: "#878787", fontSize: "10px", fontWeight: "400", fontFamily: "Poppins" }}>*(Length) x Number of units = Total Units</span>
                        </>}
                        {service.unit === "" && <>
                            <span style={{ color: "#878787", fontSize: "10px", fontWeight: "400", fontFamily: "Poppins" }}></span>
                        </>}
                        <div className="Totalprice my-5">
                            <span className="inputLabelsService">Total Price</span>
                            <span>Rs. {totalPrice}</span>
                        </div>
                        <div style={{ textAlign: "center", marginBottom: "10px" }} onClick={(e) => addServiceToCart(e)}>
                            <Button className="hireConButton">Confirm</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </>
    );
}

export default ServiceDetails;
