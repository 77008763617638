import React from 'react';
import { useAlert } from 'react-alert';
import { useStore } from '../../../store/store';
import useAxios from "../../../utils/useAxios";
import "./ServiceCounter.css";
const ServiceCounter = ({ hide, cart, cartItemId, removeFromCart, item, quantity, service_id,setApiRe , apiRe, variation, onChangeQuantity } ) => {
    const [state, dispatch] = useStore();
    const api = useAxios();
    const alert = useAlert();

    const updateQuantity = async(newQuantity) => {
        try {
            const res = await api.patch(`/servicescart/qty`, {
                service_id: service_id,
                newQuantity,
                variation: variation
            })
            if (res.status === 200) {
                onChangeQuantity(cartItemId, newQuantity);
            } else {
                alert.error(res.data.error)
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    function decrementQuantity() {
        if (quantity === 1) {
            alert.error("Quantity Cannot be 0. Kindly Remove the ")
        } else {
            updateQuantity(quantity - 1)
        }
    }

    function incrementQuantity() {
        updateQuantity(parseInt(quantity) + 1)
    }

    return (
        <>
            {!hide && <span className="productCounterCenter">
                <input className="counterValue cartCounterValueInput" type="number" min={0} value={quantity} onChange={(e) => { e.preventDefault(); updateQuantity(e.target.value) }} />
                <button className={quantity===1?'counterButtonDisable':'counterButton'} onClick={()=>{
                    // setApiRe(false)
                    decrementQuantity();
                }} disabled={quantity===1?true:false}>-</button>
                <button className='counterButton' onClick={()=>{
                    // setApiRe(false)
                    incrementQuantity()
                }}>+</button>
            </span>}
            {hide && <span className="productCounterCenter">
                <span className="counterValue">{quantity}</span>
                {/* <button className={quantity===1?'counterButtonDisable':'counterButton'} onClick={decrementQuantity} disabled={quantity===1?true:false}>-</button>
                <button className='counterButton' onClick={incrementQuantity}>+</button> */}
            </span>}
        </>
    )
}

export default ServiceCounter; 