import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../global_component/Footer/Footer";
import LowerNavigation from "../../global_component/LowerNavigation/LowerNavigation";
import NewProductDetail from "./NewProductDetail/NewProductDetail";
import RelatedProducts from "./RelatedProducts/RelatedProducts";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";
import { initialState } from "../../../store/reducers/userReducer";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function IndividualProducts() {
  const { p_id } = useParams(); 
  ReactGA.send({
    hitType: 'pageview',
    page: `/product/:${p_id}`,
    title : 'Cart'
  })
  return (
    <>
      <NewProductDetail />
      {/* <Product_detail /> */}
      <RelatedProducts p_id={p_id} />
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default IndividualProducts;
