import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Badge from '@mui/material/Badge';
import React, { useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { setCartData } from '../../../../store/reducers/userActions';
import { useStore } from "../../../../store/store";
import useAxios from '../../../../utils/useAxios';
import LoginRegisterPopup from '../../LoginRegisterPopup/LoginRegisterPopup';
import SearchComponent from "./../Search/SearchComponent";
import "./NavbarUpper.css";
import { ReactComponent as Cart } from "./cart.svg";
import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as Heart } from "./heart.svg";
import "./mobileNavbar.css";
import plypicker_logo from "./plypicker_logo.png";
import { ReactComponent as RightArrow } from "./rightArrow.svg";
import { ReactComponent as User } from "./user.svg";

function NavbarUpper({ categories }) {
  const [state, dispatch] = useStore();
  const navigate = useNavigate();
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);
  const [isMobileNavbarVisible, setIsMobileNavbarVisible] = useState(false);
  const [popularBrands, setPopularBrands] = useState([
    {
      brandName: "CERA",
      brandId: "62b0b3262274421b9f0784ce"
    },
    {
      brandName: "PARRYWARE",
      brandId: "62b0b35a2274421b9f0784d0"
    },
    {
      brandName: "JOHNSON",
      brandId: "626d3ae72c2bf0ae926489dc"
    },
    {
      brandName: "KUTCHINA",
      brandId: "626e68ef7ad4861271f50c12"
    },
    {
      brandName: "ROYAL CROWN",
      brandId: "626e67787ad4861271f50bee"
    },
    {
      brandName: "GODREJ",
      brandId: "626e6b857ad4861271f50c2d"
    },
    {
      brandName: "AIROLAM",
      brandId: "626e678c7ad4861271f50bf0"
    },
    {
      brandName: "AMULYA LAMINATES",
      brandId: "626e679d7ad4861271f50bf2"
    },
    {
      brandName: "MERINO LAM",
      brandId: "626e67af7ad4861271f50bf4"
    },
    {
      brandName: "DELTA LAMINATES",
      brandId: "626e67c47ad4861271f50bf6"
    },
    {
      brandName: "PANASONIC",
      brandId: "6399f3194dc01867c6f93cb3"
    },
    {
      brandName: "ELICA",
      brandId: "626e68de7ad4861271f50c10"
    },
    {
      brandName: "SOMANY",
      brandId: "62b0b3402274421b9f0784cf"
    },
    {
      brandName: "KAJARIA",
      brandId: "639af792a556abc97f777a8c"
    },
    {
      brandName: "KOHLER",
      brandId: "62b0affc2274421b9f0784cd"
    },
    {
      brandName: "PARRYWARE",
      brandId: "62b0b35a2274421b9f0784d0"
    },
    {
      brandName: "ORIENTBELL",
      brandId: "62cf0120387382604fabfdff"
    },
    {
      brandName: "SWADESH",
      brandId: "62d0e3e60be76c77669a0824"
    },
    {
      brandName: "CENTURY",
      brandId: "62d0e3830be76c77669a0822"
    }
  ])
  const [hoverState, setHoverState] = useState({
    categoryHoverState: -1,
    subCategoryHoverState: -1,
    groupHoverState: -1
  });
  // console.log("state.user.data", state.user)
  const api = useAxios();
  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });
  // console.log("state.cart" + JSON.stringify(state.cart))

  function userSvgHandler(event) {
    event.preventDefault();
    if (state.authenticated || !state.accessToken === null) {
      navigate("/profile");
    } else {
      setIsLoginPopupVisible(true);
    }
  }

  function wishlistHandler(event) {
    event.preventDefault();
    if (state.authenticated || !state.accessToken === null) {
      navigate("/wishlist");
    } else {
      setIsLoginPopupVisible(true);
    }
  }

  function cartHandler(event) {
    event.preventDefault();
    if (state.authenticated || !state.accessToken === null) {
      navigate("/cart");
    } else {
      setIsLoginPopupVisible(true);
    }
  }


  useEffect(() => {
    async function getData() {
      const res = await api.get("/carts");
      // console.log(res.data, "cart data");
      dispatch(setCartData(res.data?.cartItems || []));
      // setCart(res.data);
      if (res.data.error) {
        return;
      }
    }
    getData();
  }, []);

  function resetHoverState() {
    setHoverState({
      categoryHoverState: -1,
      subCategoryHoverState: -1,
      groupHoverState: -1
    })
  }

  function handleCategoryAccordionChange(e, expanded, categoryIndex) {
    if (hoverState.categoryHoverState === categoryIndex) {
      resetHoverState();
    } else {
      setHoverState({
        ...hoverState,
        categoryHoverState: categoryIndex,
        subCategoryHoverState: 0,
      });
    }
  }

  function handleSubCategoryAccordionChange(categoryIndex, subCategoryIndex) {
    // if (categories[categoryIndex]?.subCategories[subCategoryIndex]?.groups.length > 0){
    //   resetHoverState();
    //   setIsMobileNavbarVisible(false);
    //   navigate(`/productlist?subcategory=${categories[categoryIndex].subCategories[subCategoryIndex]._id}`);
    // } else {
    if (hoverState.subCategoryHoverState !== subCategoryIndex) {
      setHoverState({
        ...hoverState,
        categoryHoverState: categoryIndex,
        subCategoryHoverState: subCategoryIndex,
        groupHoverState: -1
      });
    } else {
      resetHoverState();
      setIsMobileNavbarVisible(false);
      window.scroll(0, 0);
      navigate(`/productlist?subcategory=${categories[categoryIndex].subCategories[subCategoryIndex]._id}`);
    }
    // }
  }

  function handleGroupAccordionChange(event, expanded, categoryIndex, subCategoryIndex, groupIndex) {
    if (categories[categoryIndex].subCategories[subCategoryIndex].groups[groupIndex]?.subGroups.length > 0) {
      if (hoverState.groupHoverState === groupIndex) {
        setHoverState({
          categoryHoverState: categoryIndex,
          subCategoryHoverState: subCategoryIndex,
          groupHoverState: -1
        })
      } else {
        setHoverState({
          categoryHoverState: categoryIndex,
          subCategoryHoverState: subCategoryIndex,
          groupHoverState: groupIndex
        })
      }
    } else {
      resetHoverState();
      setIsMobileNavbarVisible(false);
      window.scroll(0, 0);
      navigate(`/productlist?group=${categories[categoryIndex].subCategories[subCategoryIndex].groups[groupIndex]._id}`)
    }
  }

  function handleSubGroupAccordionChange(categoryIndex, subCategoryIndex, groupIndex, subGroupIndex) {
    resetHoverState();
    setIsMobileNavbarVisible(false);
    window.scroll(0, 0);
    navigate(`/productlist?subgroup=${categories[categoryIndex]?.subCategories[subCategoryIndex]?.groups[groupIndex]?.subGroups[subGroupIndex]?._id}`)
  }

  return (
    <>
      <LoginRegisterPopup isVisible={isLoginPopupVisible} setIsVisible={setIsLoginPopupVisible} />
      <div className="w-100 upperNavbarGap">
        <div className="mx-auto row align-items-baseline maxWidth100 justify-content-between">
          <div className="col-lg-3 col-sm-6 col-12 order-1 py-lg-0 py-2 ps-lg-2 ps-0">
            <span className="ms-2 d-lg-none d-inline upperNavbarHamburger" onClick={() => {
              if (hoverState.categoryHoverState === -1 && hoverState.subCategoryHoverState === -1 && hoverState.groupHoverState === -1 && !isMobileNavbarVisible) {
                setHoverState({
                  categoryHoverState: 0,
                  subCategoryHoverState: 0,
                  groupHoverState: 0
                });
                setIsMobileNavbarVisible(!isMobileNavbarVisible)
              }
              setIsMobileNavbarVisible(!isMobileNavbarVisible)
            }}>
              <Hamburger className="p-2" />
            </span>
            <Link to="/" className="maxWidth100">
              <img className="ms-2 upperPlypickerLogo maxWidth100" src={plypicker_logo} alt="" />
            </Link>
          </div>
          <div className="col-lg-6 col-12 order-lg-2 order-3 py-lg-0 py-2">
            <div className="navupper_search ms-0">
              <>
                <SearchComponent className="searchUpper" />
              </>
            </div>
          </div>
          {!isMobile &&
            <div className="col-lg-3 col-4 py-lg-0 py-2 order-lg-3 order-2 d-flex align-items-center justify-content-center">
              <div className="upperNavbarIconsWrapper d-flex px-0 flex-row align-items-center justify-content-between">
                <IconContext.Provider
                  value={{ color: "black", className: "global-class-name" }}
                >
                  <div onClick={() => { navigate('/notif') }}>
                    <Badge badgeContent={state?.user?.notificationCount ? state?.user?.notificationCount : 0} color="error">
                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-bell text-dark hoverMe" viewBox="0 0 16 16">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                    </Badge>
                  </div>
                  {state.accessToken === null || !state.authenticated || state?.user?.wishlist.length === 0 ? <>
                    <div className="upperNavbarIconCursorPointer" onClick={(event) => { wishlistHandler(event) }}>
                      <Heart />
                    </div></> : <>
                    <div className="upperNavbarIconCursorPointer" onClick={(event) => { wishlistHandler(event) }}>
                      <Badge badgeContent={state?.user?.wishlist.length} color="error">
                        <Heart />
                      </Badge>
                    </div></>}
                  {state.accessToken === null || !state.authenticated || state?.cart?.length === 0 ? <>
                    <div className="upperNavbarIconCursorPointer" onClick={(event) => { cartHandler(event) }}>
                      <Cart />
                    </div>
                  </> : <>
                    <div className="upperNavbarIconCursorPointer" onClick={(event) => { cartHandler(event) }}>
                      <Badge badgeContent={state?.cart?.length} color="error">
                        <Cart />
                      </Badge>
                    </div>
                  </>}
                  <div className="upperNavbarIconCursorPointer" onClick={(event) => { userSvgHandler(event) }}>
                    <User />
                  </div>
                </IconContext.Provider>
              </div>
            </div>
          }
        </div>
      </div>
      <div className={isMobileNavbarVisible ? `mobileNavbarComponent mobileNavbarComponentVisible d-lg-none` : `mobileNavbarComponent d-lg-none`}>
        <div className="w-100 upperNavbarGap">
          <div className="mx-auto row align-items-baseline maxWidth100 justify-content-between">
            <div className="col-lg-3 col-sm-6 col-12 py-lg-0 py-2 ps-0">
              <span className="ms-2 d-lg-none d-inline upperNavbarHamburger" onClick={() => {
                if (hoverState.categoryHoverState === -1 && hoverState.subCategoryHoverState === -1 && hoverState.groupHoverState === -1 && !isMobileNavbarVisible) {
                  setHoverState({
                    categoryHoverState: 0,
                    subCategoryHoverState: 0,
                    groupHoverState: 0
                  });
                  setIsMobileNavbarVisible(!isMobileNavbarVisible)
                }
                setIsMobileNavbarVisible(!isMobileNavbarVisible)
              }}>
                <Hamburger className="p-2" />
              </span>
              <Link to="/" className="maxWidth100">
                <img className="ms-2 upperPlypickerLogo maxWidth100" src={plypicker_logo} alt="" />
              </Link>
            </div>
            {!isMobile &&
              <div className="col-lg-3 col-4 py-lg-0 py-2 d-flex align-items-center justify-content-center">
                <div className="upperNavbarIconsWrapper d-flex px-0 flex-row align-items-center justify-content-between">
                  <IconContext.Provider
                    value={{ color: "black", className: "global-class-name" }}
                  >
                    <div onClick={() => { navigate('/notif'); setIsMobileNavbarVisible(false); }}>
                      <Badge badgeContent={state?.user?.notificationCount ? state?.user?.notificationCount : 0} color="error">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="currentColor" class="bi bi-bell text-dark hoverMe" viewBox="0 0 16 16">
                          <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                        </svg>
                      </Badge>
                    </div>
                    {state.accessToken === null || !state.authenticated || state?.user?.wishlist.length === 0 ? <>
                      <div className="upperNavbarIconCursorPointer" onClick={(event) => { wishlistHandler(event); setIsMobileNavbarVisible(false); window.scroll(0, 0); }}>
                        <Heart />
                      </div></> : <>
                      <div className="upperNavbarIconCursorPointer" onClick={(event) => { wishlistHandler(event); setIsMobileNavbarVisible(false); window.scroll(0, 0); }}>
                        <Badge badgeContent={state?.user?.wishlist.length} color="error">
                          <Heart />
                        </Badge>
                      </div></>}
                    {state.accessToken === null || !state.authenticated || state?.cart?.length === 0 ? <>
                      <div className="upperNavbarIconCursorPointer" onClick={(event) => { cartHandler(event); setIsMobileNavbarVisible(false); window.scroll(0, 0); }}>
                        <Cart />
                      </div>
                    </> : <>
                      <div className="upperNavbarIconCursorPointer" onClick={(event) => { cartHandler(event); setIsMobileNavbarVisible(false); window.scroll(0, 0); }}>
                        <Badge badgeContent={state?.cart?.length} color="error">
                          <Cart />
                        </Badge>
                      </div>
                    </>}
                    <div className="upperNavbarIconCursorPointer" onClick={(event) => { userSvgHandler(event); setIsMobileNavbarVisible(false); window.scroll(0, 0); }}>
                      <User />
                    </div>
                  </IconContext.Provider>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="w-100 m-0 d-flex flex-row flex-wrap mb-0 pb-0 mobileNavbarUpperBorder">
          <div className="col-6 pt-0 mobileNavbarCategoryDiv MuiAccordion-root overflow-auto">
            {categories.map((singleCategory, categoryIndex) => <>
              <Accordion onChange={(event, expanded) => handleCategoryAccordionChange(event, expanded, categoryIndex)} expanded={hoverState.categoryHoverState === categoryIndex}>
                <AccordionSummary
                  expandIcon={categories[categoryIndex]?.subCategories.length > 0 && <ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>{singleCategory.name}</div>
                </AccordionSummary>
                {singleCategory?.subCategories.length > 0 && <>
                  <AccordionDetails>
                    {singleCategory?.subCategories.map((singleSubCategory, subCategoryIndex) => (<>
                      <div className={hoverState.subCategoryHoverState === subCategoryIndex ? "mobileNavbarOrangeText px-2 py-2 d-flex flex-row align-items-center justify-content-between" : "px-2 py-2 d-flex flex-row align-items-center justify-content-between"} onClick={() => { handleSubCategoryAccordionChange(categoryIndex, subCategoryIndex) }}>
                        <p className="m-0">{singleSubCategory.Sub_Category_name}</p>
                        <span>{hoverState.subCategoryHoverState === subCategoryIndex && <RightArrow />}</span>
                      </div>
                    </>))}
                  </AccordionDetails>
                </>}
              </Accordion>
            </>)}
          </div>
          <div className="col-6 pt-0 mobileNavbarGroupDiv MuiAccordion-root-white overflow-auto">
            {categories[hoverState.categoryHoverState]?.subCategories[hoverState.subCategoryHoverState]?.groups.length > 0 && categories[hoverState.categoryHoverState]?.subCategories[hoverState.subCategoryHoverState]?.groups.map((singleGroup, groupIndex) => (<>
              <Accordion onChange={(event, expanded) => { handleGroupAccordionChange(event, expanded, hoverState.categoryHoverState, hoverState.subCategoryHoverState, groupIndex) }}>
                <AccordionSummary
                  expandIcon={categories[hoverState.categoryHoverState]?.subCategories[hoverState.subCategoryHoverState]?.groups[groupIndex]?.subGroups.length > 0 && <ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <div className={hoverState.groupHoverState === groupIndex ? "mobileNavbarOrangeText" : ""}>{singleGroup.Group_name}</div>
                </AccordionSummary>
                {singleGroup?.subGroups.length > 0 && <>
                  <AccordionDetails>
                    {singleGroup?.subGroups.map((singleSubGroup, subGroupIndex) => (<>
                      <div className="px-2 py-2" onClick={() => { handleSubGroupAccordionChange(hoverState.categoryHoverState, hoverState.subCategoryHoverState, groupIndex, subGroupIndex); }}>
                        <p className="m-0">{singleSubGroup.Subgroup_name}</p>
                      </div>
                    </>))}
                  </AccordionDetails>
                </>}
              </Accordion>
            </>))}
          </div>
        </div>
        <div className="w-100 m-0 d-flex flex-row flex-wrap mb-0 pb-0 pt-3 px-3 mobileNavbarUpperBorder">
          <h4>POPULAR BRANDS</h4>
          <div className="row">
            {popularBrands.map(brand => (
              <>
                <div className="col-sm-4 col-6">
                  <div className="lowerNavbarPopularLinkWrapper">
                    <Link className="lowerNavbarPopularLink" to={`/productlist?brand=${brand.brandId}`} onClick={() => { resetHoverState(); setIsMobileNavbarVisible(!isMobileNavbarVisible); window.scroll(0, 0); }}>{<p>{brand.brandName}</p>}</Link>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default NavbarUpper;