import axios from "axios";
import { genSaltSync, hashSync } from "bcryptjs";
import * as jose from "jose";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fromString } from "uint8arrays/from-string";
import { API_URL, REACT_APP_HKEY, REACT_APP_JKEY } from "../../../constants.js";
import {
  setRoute,
  updateSelectedCity,
} from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import "./NavRoute.css";

const showAt = ["/hire-artisans","/interior-design"];

function NavRoute(props) {
  const [state, dispatch] = useStore();
  const [navData, setNavData] = useState([]);
  const [navRouteProdData, setNavRouteProdData] = useState([]);

  const shouldRenderDiv = () => {
    const currentPathname = window.location.pathname;
    return showAt.some((path) => currentPathname.includes(path));
  }


  // console.log('props.categoryId', props.categoryId)
  function onClickGroup() {
    dispatch(
      setRoute({
        categoryName: state.route.categoryName,
        categoryId: state.route.categoryId,
        subCategoryName: state.route.subCategoryName,
        subCategoryId: state.route.subCategoryId,
        groupName: state.route.groupName,
        groupId: state.route.groupId,
      })
    );
  }
  function onClickSubCategory() {
    dispatch(
      setRoute({
        categoryName: state.route.categoryName,
        categoryId: state.route.categoryId,
        subCategoryName: state.route.subCategoryName,
        subCategoryId: state.route.subCategoryId,
      })
    );
  }
  function onClickCategory() {
    dispatch(
      setRoute({
        categoryName: state.route.categoryName,
        categoryId: state.route.categoryId,
      })
    );
  }

  useEffect(() => {
    async function fetchData() {
      var dec = genSaltSync(10);
      var hash = hashSync(REACT_APP_HKEY, dec);
      const secret = fromString(REACT_APP_JKEY);
      const twj = await new jose.SignJWT({ expiry: hash })
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime("5s")
        .sign(secret);

      if (props.serviceCategoryId) {
        const res = await axios.get(
          `${API_URL}/services/navroute?serviceCategoryId=${props.serviceCategoryId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavData(res.data);
        }
      }

      if (props.serviceSubCategoryId) {
        const res = await axios.get(
          `${API_URL}/services/navroute?serviceSubCategoryId=${props.serviceSubCategoryId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavData(res.data);
        }
      }

      if (props.serviceId) {
        const res = await axios.get(
          `${API_URL}/services/navroute?serviceId=${props.serviceId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavData(res.data);
        }
      }
    }
    if (
      props.serviceId ||
      props.serviceSubCategoryId ||
      props.serviceCategoryId
    ) {
      fetchData();
    }
  }, [props.serviceId, props.serviceSubCategoryId, props.serviceCategoryId]);

  useEffect(() => {
    async function fetchData() {
      var dec = genSaltSync(10);
      var hash = hashSync(REACT_APP_HKEY, dec);
      const secret = fromString(REACT_APP_JKEY);
      const twj = await new jose.SignJWT({ expiry: hash })
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime("5s")
        .sign(secret);

      if (props.categoryId) {
        const res = await axios.get(
          `${API_URL}/navroute?categoryId=${props.categoryId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }

      if (props.subCategoryId) {
        const res = await axios.get(
          `${API_URL}/navroute?subCategoryId=${props.subCategoryId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }

      if (props.groupId) {
        const res = await axios.get(
          `${API_URL}/navroute?groupId=${props.groupId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }

      if (props.subGroupId) {
        const res = await axios.get(
          `${API_URL}/navroute?subGroupId=${props.subGroupId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }

      if (props.brandId) {
        const res = await axios.get(
          `${API_URL}/navroute?brandId=${props.brandId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }

      if (props.productId) {
        const res = await axios.get(
          `${API_URL}/navroute?productId=${props.productId}`,
          {
            headers: {
              "Content-Policy": twj,
            },
          }
        );
        if (res.data) {
          setNavRouteProdData(res.data);
        }
      }
    }
    if (
      props.categoryId ||
      props.subCategoryId ||
      props.groupId ||
      props.subGroupId ||
      props.brandId ||
      props.productId
    ) {
      fetchData();
    }
  }, [
    props.categoryId,
    props.subCategoryId,
    props.groupId,
    props.subGroupId,
    props.brandId,
    props.productId,
  ]);

  const openModalHandler = () => {
    dispatch(updateSelectedCity(null));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: window.innerWidth <= 1024 ? "column" : "row",
        }}
      >
        <div style={{ width: "75%" }}>
          {!props.navRouter && !props.productRouter ? (
            <div className="sidebarProfilePageContainer w-100 px-1">
              <div
                className="p-0"
                style={{ marginTop: "45px", marginBottom: "15px" }}
              >
                ACCOUNT: {state?.user?.name}
              </div>
            </div>
          ) : (
            <div className="container-fluid navroute">
              {navRouteProdData[0]?.brandId ? (
                <>
                  <Link className="navRouteLink" to="/">
                    Home
                  </Link>{" "}
                  {"/"}{" "}
                  <Link
                    className="navRouteLink"
                    to={`/productlist?brand=${navRouteProdData[0]?.brandId}`}
                  >
                    {navRouteProdData[0]?.brandName}
                  </Link>
                </>
              ) : props.policy ? (
                <>
                  <Link className="navRouteLink" to="/">
                    Home
                  </Link>{" "}
                  {"/"}{" "}
                  <Link className="navRouteLink" to={`/${props.link}`}>
                    {props.title}
                  </Link>
                </>
              ) : props.services ? (
                <>
                  <Link className="navRouteLink" to="/">
                    Home
                  </Link>{" "}
                  {"/"}{" "}
                  <Link className="navRouteLink" to={`/hire-artisans`}>
                    {props.title}
                  </Link>{" "}
                  /
                  {navData[0]?.serviceCategoryId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/hire-artisans/${navData[0]?.serviceCategoryId}`}
                      >
                        {navData[0]?.serviceCategoryName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navData[0]?.serviceSubCategoryId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/hire-artisans/${navData[0]?.serviceCategoryId}/${navData[0]?.serviceSubCategoryId}`}
                      >
                        {navData[0]?.serviceSubCategoryName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navData[0]?.serviceId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/hire-artisans/service/${navData[0]?.serviceId}`}
                      >
                        {navData[0]?.serviceName}
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Link className="navRouteLink" to="/">
                    Home
                  </Link>{" "}
                  /{" "}
                  {navRouteProdData[0]?.categoryId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/productlist?category=${navRouteProdData[0]?.categoryId}`}
                      >
                        {navRouteProdData[0]?.categoryName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navRouteProdData[0]?.subCategoryId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/productlist?subcategory=${navRouteProdData[0]?.subCategoryId}`}
                      >
                        {navRouteProdData[0]?.subCategoryName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navRouteProdData[0]?.groupId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/productlist?group=${navRouteProdData[0]?.groupId}`}
                      >
                        {navRouteProdData[0]?.groupName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navRouteProdData[0]?.subGroupId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/productlist?subgroup=${navRouteProdData[0]?.subGroupId}`}
                      >
                        {navRouteProdData[0]?.subGroupName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                  {navRouteProdData[0]?.productId && (
                    <>
                      {" "}
                      <Link
                        className="navRouteLink"
                        to={`/product/${navRouteProdData[0]?.productId}`}
                      >
                        {navRouteProdData[0]?.productName}
                      </Link>{" "}
                      /{" "}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {
         shouldRenderDiv() &&  
          <div
            style={{
              width: window.innerWidth <= 1024 ? "100%" : "40%",
              display: "flex",
              marginBottom:"1%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "3.4%",
                
                textAlign:"center"
              }}
            >
              {" "}
              Current City
            </div>
            <div> : {state.selectedCityName}{" "}</div>
            <div
              style={{
                marginRight: window.innerWidth <= 1024 ? "0%" : "7%",
                marginLeft: window.innerWidth <= 1024 ? "4%" : "4%",
              }}
            >
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style={{
                  backgroundColor: "#FF7703",
                  border: "none",
                  boxShadow: "none",
                }}
                onClick={() => {
                  openModalHandler();
                }}
              >
                Change City
              </button>{" "}
            </div>
          </div>
        }
      </div>
    </>
  );
}

export default NavRoute;
