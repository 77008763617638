import axios from "axios";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useLocation } from "react-router-dom";
import "./resetPassword.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import ReactGA from "react-ga4"

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "black",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
});

function ResetPassword() {
  const [user, setUser] = useState({});
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const location = useLocation();
  const alert = useAlert();
  let arr = location.pathname.split("/");

  ReactGA.send({
    hitType: 'pageview',
    page: `reset-password/:${arr[2]}/:${arr[3]}`,
    title : 'Reset Password'
  })

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`${API_URL}/me/${arr[2]}`);
        if (res.data.user) {
          setUser(res.data.user);
        }
      } catch (err) {
        alert.error("Cannot get User");
      }
    };
    getData();
  }, []);

  const submitResetPassword = async () => {
    if(newPassword === confirmPassword){
      try {
        const res = await axios.post(`${API_URL}/auth/reset-password/${arr[2]}/${arr[3]}`, {
          password: newPassword,
        });


        if (!res.data.error) {
          alert.success(res.data.msg);
        } else {
          alert.error(res.data.error);
        }
      } catch (err) {
        alert.error(err);
      }
    }else{
      alert.error("Password and Confirm Password are not same");
    }
    setNewPassword("");
    setConfirmPassword("");
  };
  return (
    <>
      <div className="container nav__route__outer">ACCOUNT: {user?.name}</div>
      <div className="container">
        <div className="middle">
          <div className="reset-password-form">
            <h2>CHANGE PASSWORD</h2>
            <Box
              className="form-box"
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <CssTextField
                type="password"
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="custom-css-outlined-input"
              />
              <CssTextField
                type="password"
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="custom-css-outlined-input"
              />
            </Box>
            <button className="reset-password-form-button" onClick={submitResetPassword}>
              Save Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
