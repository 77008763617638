import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../../constants";
import { useStore } from "../../../../store/store";
import useAxios from "../../../../utils/useAxios";
import { ReactComponent as NewSearchIcon } from "../NavbarUpper/NewSearchIcon.svg";
import "./SearchComponent.css";
import useComponentVisible from "./useComponentVisible";
import { MdDelete } from "react-icons/md";
import axios from "axios";

const SearchComponent = ({ className }) => {
    let isMobileOrTablet = useMediaQuery({
        query: "(max-width:992px)",
    });
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
    const [state] = useStore();
    const timeout = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [productData, setProductData] = useState([]);
    const [recentSearches, setRecentSearches] = useState([]);
    const [searchData , setSearchData] = useState([]);
    const [showRecentSearches , setShowRecentSearches] = useState(false);
    const [thisUrl, setUrl] = useState("");
    const [thisTitle ,setTitle] = useState("");
    const [isSuggestionClicked, setIsSuggestionClicked] = useState(true);
    const [isEnterKeyPressed, setIsEnterKeyPressed] =useState(false);
    const navigate = useNavigate();
    let api = useAxios();

       useEffect(()=>{
        const loadSearches = async () => {
            
                const res = await api.get(`/recentSearch/getRecentSearch`);
                
                setSearchData(res.data.searches);
            
        }
        loadSearches();
        setRecentSearches(searchData);
       },[recentSearches])

       

    const handleInputFocus = () =>{
        setShowRecentSearches(true);
    }

    const handleSearch = async (Url,title) => {
        try{
            await api.post(`/recentSearch/addSearch`, {query : searchValue, url : Url,title:title});
            setSearchValue('');
            // setUrl('');
        }
        catch(err){
            console.log("Error :",err);
        }
    }

    const handleDelete = async (id) => {
        try {
          await api.delete(`/recentSearch/deleteSearch/${id}`);
          setSearchData(searchData.filter((item) => item._id !== id));
        } catch (err) {
          console.log('Error:', err);
        }
      };
    

    useEffect(() => {
        const loadProducts = async () => {
            clearTimeout(timeout.current)
            timeout.current = setTimeout(async () => {
                const res = await fetch(`${API_URL}/search-product?searchString=${searchValue}`, {
                    headers: {
                        // name:'xyz'
                    }
                })
                const result = await res.json();
                // console.log(result);
                setProductData(result.data)
            }, 800);
        }
        loadProducts();
        setSuggestions(productData);
    }, [searchValue]);

    const suggestionHandler = (value , url,title) => {
        setSearchValue(value);
        setUrl(url)
        setTitle(value);
        setSuggestions([]);
    };

    
    // console.log("title" , thisTitle)

    const historyHandler = (value,url,title) => {
        setSearchValue(value);
        setUrl(url);
        setTitle(title);
        setRecentSearches([]);
    }

    useEffect(() => {
        setSearchValue("");
        // setUrl("");
        setSuggestions([])
    }, [isComponentVisible])


    const onChangeHandler = (value) => {
        let matches = [];
        setSearchValue(value);
        // if (value.length > 0) {
        //     matches = productData.filter((subGroup) => {
        //         const regex = new RegExp(`${value}`, "gi");
        //         return subGroup.product_name.match(regex);
        //     });
        // }  
    };
    const clickCheck = () => {
        setProductData([])
        setSearchValue("");
    }
    // document.addEventListener('click',clickCheck)
    const SuggestItem = ({ suggestion,onClick,handleSearch }) => {
        
        const [activeSuggestion, setActiveSuggestion] = useState(false);
        if (suggestion?.type === 'service_category') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/hire-artisans/${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/hire-artisans/${suggestion.id}`,suggestion.title)
                            setProductData([]);
                            setIsSuggestionClicked(true);
                            setIsComponentVisible(false);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/hire-artisans/${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in SERVICE CATEGORY
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion?.type === 'service_subcategory') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/hire-artisans/${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/hire-artisans/${suggestion.id}`,suggestion.title)
                            setProductData([]);
                            setIsSuggestionClicked(true);
                            setIsComponentVisible(false);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/hire-artisans/${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in SERVICE SUBCATEGORY
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion?.type === 'subGroup') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/productlist?subgroup=${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/productlist?subgroup=${suggestion.id}`,suggestion.title)
                            setProductData([]);
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/productlist?subgroup=${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in SUB GROUP
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion.type === 'group') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/productlist?group=${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/productlist?group=${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/productlist?group=${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in GROUP
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion.type === 'subCategory') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/productlist?subcategory=${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/productlist?subcategory=${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/productlist?subcategory=${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in SUBCATEGORY
                        </div>
                    </Link>
                </>
            );
        } else if (suggestion.type === 'category') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/productlist?category=${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/productlist?category=${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/productlist?category=${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in CATEGORY
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion.type === 'brand') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/productlist?brand=${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/productlist?brand=${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/productlist?brand=${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title} in BRAND
                        </div>
                    </Link>
                </>
            );
        }
        else if (suggestion.type === 'notFound') {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                       
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/`,suggestion.title);
                              }}
                        >
                            {suggestion?.title}
                        </div>
                    </Link>
                </>
            );
        }
        else if(suggestion.type==="service"){
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/hire-artisans/service/${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,suggestion.id,`/hire-artisans/service/${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/hire-artisans/service/${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title}
                        </div>
                    </Link>
                </>
            )
        }
        else {
            return (
                <>
                    <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={`/product/${suggestion.id}`}
                        onClick={() => {
                            suggestionHandler(suggestion.title,`/product/${suggestion.id}`,suggestion.title)
                            setProductData([])
                            setIsComponentVisible(false);
                            setIsSuggestionClicked(true);
                        }}
                        
                    >

                        <div
                            className="searchbar_output"
                            style={{
                                cursor: "pointer",
                                fontWeight: activeSuggestion ? "bold" : "inherit",
                            }}
                            onMouseOver={() => setActiveSuggestion(true)}
                            onMouseLeave={() => setActiveSuggestion(false)}
                            onClick={() => {
                                onClick(suggestion.id);
                                handleSearch(`/product/${suggestion.id}`,suggestion.title);
                              }}
                        >
                            {suggestion?.title}
                        </div>
                    </Link>
                </>
            )
        }


    };
    

    const ShowHistory = ({history}) => {
        const [activeShowHistory , setActiveShowHistory] = useState(false);
        

            return(
                <>
                <div style={{ display: "flex" , 
                    justifyContent : "space-between",
                    alignItems : "center"
                }}>

                    <Link style={{
                        textDecoration: "none",
                        color: "inherit",
                        flex : "1"
                        }}
                        to={history.url}
                        onClick={()=>{
                            historyHandler(history.query,history.url,history.title);
                            setActiveShowHistory(true);
                            setIsComponentVisible(false);
                        }}
                    >
                        <div className="history_output" 
                            style={{    
                                cursor:"pointer",
                                fontWeight : activeShowHistory ? "bold" : "inherit",
                                width : "440%"
                            }}
                        >
                            
                            {/* {history && isSuggestionClicked ? history.title : (isEnterKeyPressed) ? history.query : null} */}
                            {history ? history.title : null}
                        </div>
                    </Link>
                    <div style={{
                        marginLeft : "75%",
                    }} className="delete" 
                            onClick={() => handleDelete(history._id)}>
                            <MdDelete className="d-flex align-items-center justify-content-center"/>
                    </div>
                </div>
                </>
            );
    }
    
    return (
        <div className="navupper_search_area d-flex justify-space-between hoverMe" ref={ref}>
            <div style={{ flexGrow: 1 }} className={`${className}`} onClick={() => { setSearchValue(searchValue); setUrl(thisUrl)}}>
                <form class="col-12 col-lg-auto mb-lg-0 me-lg-3" role="search" autoComplete="off" onSubmit={ async(e) => {
                    e.preventDefault();
                    productData[0].type === 'notFound' ?
                        navigate(`/`) :productData[0].type === 'service'?
                        navigate(`/hire-artisans/service/${productData[0]?.id}`):
                        navigate(`${productData[0]?.type.toLowerCase() === "product" ? `/${productData[0]?.type.toLowerCase()}/${productData[0]?.id}` : `/productlist?${productData[0]?.type.toLowerCase()}=${productData[0]?.id}`}`)

                        const url = productData[0]?.type.toLowerCase() === 'product'
                            ? `/${productData[0]?.type.toLowerCase()}/${productData[0]?.id}`
                            : `/productlist?${productData[0]?.type.toLowerCase()}=${productData[0]?.id}`;

                        const title = productData[0] ? productData[0].title : null;

                    await api.post(`/recentSearch/addSearch`, {query : searchValue,url : url,title:title});
                    setIsEnterKeyPressed(true);
                    setSearchValue("");
                }}>
                    {/* <input type="search" class="form-control form-control-dark text-bg-dark" placeholder="Search..." aria-label="Search"/> */}
                    <input
                        className="searchbar_search"
                        id='search123'
                        placeholder="Hop on to your choice"
                        type="text"
                        autoComplete="off"
                        style={{ border: "none", ountline: "none" }}
                        onChange={(event) => {
                            // onChangeHandler(event.target.value)
                            setSearchValue(event.target.value)
                        }}
                        onFocus={handleInputFocus}
                        onKeyPress={(e) => {
                            if (e.key == "Enter" && suggestions?.length > 0) {
                                suggestions ? navigate(`/product/${suggestions[0]._id}`) :suggestions && productData[0].type==="service"?navigate(`/hire-artisans/service/${suggestions[0].id}`): navigate(`/products?name=${searchValue}`)
                            }
                        }}
                        onClick={e => { e.stopPropagation() }}
                        value={searchValue}
                        onBlur={() => {
                            setTimeout(() => {
                                setSuggestions([]);
                                setRecentSearches(false);
                            }, 500);
                        }}
                    />
                </form>
                <div
                    style={{
                        position: "absolute",
                        zIndex: 999999,
                        backgroundColor: "white",
                        width: isMobileOrTablet ? "89%" : "46%",
                        boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.39)",
                    }}
                >
                    {isComponentVisible && (productData.length > 0 ?
                        productData.map((suggestion) => (<>
                            <SuggestItem suggestion={suggestion} key={suggestion.id} onClick={() => handleSearch(suggestion.url,suggestion.title)}
                                handleSearch={handleSearch}/>
                            <hr style={{ margin: '0px', height: '0.5px', marginLeft: "auto", marginRight: "auto", width: "95%", color: "#C4C4C4" }} />
                        </>
                        )) : searchValue !== "" && (<>
                            <div className="searchbar_output">loading...</div>
                        </>))}
                </div>
                <div
                    style={{
                        position: "absolute",
                        zIndex: 999999,
                        backgroundColor: "white",
                        width: isMobileOrTablet ? "89%" : "46%",
                        boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.39)",
                        display: showRecentSearches && searchValue === "" ? "block" : "none",
                    }}
                >
                   {isComponentVisible && searchData.length > 0 && (
                    <>
                    {searchData.map((history) => (
                        
                        <React.Fragment key={history._id}>
                        <ShowHistory history={history} />
                        <hr
                            style={{
                            margin: "0px",
                            height: "0.5px",
                            marginLeft: "auto",
                            width: "95%",
                            color: "#C4C4C4",
                            }}
                        />
                        </React.Fragment>
                    ))}
                    </>
                )}
                </div>
            </div>
            <div className="navupper_search_icon d-flex align-items-center justify-content-center" onClick={(e) => {
                e.preventDefault();
                productData[0].type === 'notFound' ?
                    navigate(`/`) :productData[0].type === 'service'?
                    navigate(`/hire-artisans/service/${productData[0]?.id}`):
                    navigate(`${productData[0]?.type.toLowerCase() === "product" ? `/${productData[0]?.type.toLowerCase()}/${productData[0]?.id}` : `/productlist?${productData[0]?.type.toLowerCase()}=${productData[0]?.id}`}`)
                setSearchValue("");
            }}>
                <NewSearchIcon onClick={handleSearch}/>
            </div>
        </div>
    );
};

export default SearchComponent;