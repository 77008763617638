import React, { useState } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import "./BottomNavbar.css"
import CssBaseline from '@mui/material/CssBaseline';
import { IoIosNotificationsOutline } from "react-icons/io"
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import {ReactComponent as Heart} from './heart.svg'
import {ReactComponent as Cart} from "./cart.svg"
import {ReactComponent as User} from "./user.svg"

import Home from "./home.webp"
import Notif from "./notif.webp"
import Wishlist from "./heart.webp"
import Profile from "./user.webp"




export default function BottomNavbar() {
    const [value, setValue] = useState(0);
    return (
        <Box sx={{ pb: 7 }}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3} className="bottomAppBar">
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >

                    <BottomNavigationAction to="/" component={Link} icon={<img src={Home} style={{ height: "32px", width: "32px" }} />} />
                    <BottomNavigationAction to="/notif" component={Link} icon={<img src={Notif} style={{ height: "32px", width: "32px" }} />} />
                    <BottomNavigationAction to="/wishlist" component={Link} icon={<img src={Wishlist} style={{ width: "30px" }} />} />
                    <BottomNavigationAction to="/account" component={Link} icon={<img src={Profile} style={{ width: "32px" }} />} />

                </BottomNavigation>
            </Paper>
        </Box>
    );
}
