import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from "../../../../constants";
import {
  addToCart,
  removeFromWishlistAction,
} from "../../../../store/reducers/userActions";
import { useStore } from "../../../../store/store";
import useAxios from "../../../../utils/useAxios";
import StarRating from "../Products_Detail/StarRating/StarRating";
import { ReactComponent as Heart } from "../Products_Detail/img/heart.svg";
import "../RelatedProducts/RelatedProducts.css";

function RelatedProducts({ p_id }) {
  const [related, setRelated] = useState(false);
  const [relProd, setRelProd] = useState([]);
  const [loading, setLoading] = useState(true);
  const noOfProductsPerLoad = 10;
  const api = useAxios();
  const navigate = useNavigate();
  const alert = useAlert();
  const [state, dispatch] = useStore();
  const openProduct = (p_id) => {
    window.open(window.location.host + "/product/" + p_id);
  };

  const fetchMoreRelatedProducts = useCallback(async () => {
    const res = await axios.get(
      `${API_URL}/products/related/${p_id}?limit=${
        noOfProductsPerLoad + relProd.length
      }&prods=${relProd.length}`
    );
    if (!res.data.error) {
      setRelProd([...relProd, ...res.data]);
    } else {
    }
    return 0;
  });

  async function addToWishlist(p_id) {
    if (state.accessToken === null || !state.authenticated) {
      navigate("/signin");
    } else {
      const res = await api.post(`/wishlist`, {
        p_id: p_id,
      });

      if (!res.data.error) {
        alert.success(res.data.msg);
      } else {
        alert.error(res.data.error);
      }
      dispatch(addToCart(p_id));
    }
  }

  async function removeFromWishlist(id) {
    try {
      const res = await api.delete(`/wishlist/${id}`);
      dispatch(removeFromWishlistAction(id));
      if (res.data.error) {
        alert.error(res.data.error);
      } else {
        alert.success("Removed from wishlist");
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        `${API_URL}/products/related/${p_id}?limit=${noOfProductsPerLoad}`
      );
      if (!res.data.error) {
        setRelProd(res.data);
      } else {
      }
      setLoading(false);
    };
    fetch();
  }, [p_id]);
  function numberWithCommas(x) {
    return x?.toString().split(".")[0].length > 3
      ? x
          ?.toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x?.toString().substring(x.toString().split(".")[0].length - 3)
      : x?.toString();
  }

  return loading ? null : (
    <div>
      <div className="RelatedProductsContainer mt-5">
        <div className="row w-100 m-0 text-center">
          <div className="related_products_heading">Related Products</div>
        </div>
        <div className="row w-100 m-0 justify-content-center row-cols-lg-5 row-cols-md-3 row-cols-2">
          {relProd.map((product) => (
            <div className="col mb-5">
              <Link
                className="individual_product_image"
                to={`/product/${product._id}`}
                target="_blank"
                // onClick={() => openProduct(product._id)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "340px",
                    height: "340px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  src={product.attrs.imgs[0]}
                  alt=""
                />
              </Link>
              <div className="individual_product_first_line">
                <div
                  className="individual_product_name"
                  onClick={() => navigate(`/product/${product._id}`)}
                >
                  {product.product_name}
                </div>

                <div className="newProductFavourite">
                  {state.user?.wishlist.includes(product._id) ? (
                    <Heart
                      className="selectedHeart"
                      onClick={() => removeFromWishlist(product._id)}
                      style={{ height: "20px", width: "20px" }}
                    />
                  ) : (
                    <Heart
                      className="greyColorHeart"
                      style={{ height: "20px", width: "20px" }}
                      onClick={() => addToWishlist(product._id)}
                    />
                  )}
                </div>
              </div>
              <div
                className="individual_product_company"
                onClick={() => navigate(`/product/${product._id}`)}
              >
                {product.brand?.Brand_name}
              </div>
              <div className="individual_product_price">
                {product?.discounted_price > 0 && (
                  <span className="price">
                    Rs. {numberWithCommas(product.discounted_price)}
                  </span>
                )}
                {product?.discounted_price > 0 && (
                  <span className="reducedprice">
                    Rs. {numberWithCommas(product.price)}
                  </span>
                )}
                {product?.discounted_price > 0 && (
                  <span className="discount">
                    {Math.round(product.discounted_percent)}%
                  </span>
                )}
                {!product?.discounted_price > 0 && (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/product/${product?._id}`);
                      }}
                      className="addUpdateButton"
                    >
                      Get Price
                    </button>
                  </>
                )}
              </div>
              <StarRating
                stars={product.averageRating}
                avgRate
                start={true}
                style={{ marginTop: "6px" }}
              />
            </div>
          ))}
        </div>
        <div
          className="related_show_more_button"
          onClick={fetchMoreRelatedProducts}
        >
          Show more
        </div>
      </div>
    </div>
  );
}

export default RelatedProducts;
