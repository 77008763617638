import { Avatar } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../../../constants";
import { update } from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import EditInput from "../../../../global_component/editInput/EditInput";
import FemaleVector from "../../../../global_component/SideBar/images/FemaleVector.png";
import MaleVector from "../../../../global_component/SideBar/images/MaleVector.png";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import "../sidebar.css";
import "./ProfilePage.css";
// import { styled } from '@mui/material/styles';
import axios from "axios";
import { BsArrowLeft } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import validator from "validator";
import OtpInput from "react-otp-input";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function CurrentOrder() {
  let isMobileOrTablet = useMediaQuery({
    query: "(max-width:768px)",
  });

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  const [state, dispatch] = useStore();
  const api = useAxios();
  const alert = useAlert();

  // const [user, setUser] = useState({});
  const [updateState, setUpdateState] = useState({
    name: false,
    email: false,
    mob: false,
    gender: false,
    dob: false,
    address: [],
  });
  const [addressData, setAddressData] = useState({
    name: "",
    address: "",
    mobile: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    addType: "",
  });
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState("");
  const [mobNum, setMobNum] = useState("");
  const [editProfileState, setEditProfileState] = useState(false);
  const [currentPassword, changeCurrentPassword] = useState("");
  const [newPassword, changeNewPassword] = useState("");
  const [confirmPassword, changeConfirmPassword] = useState("");
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");
  const [gender, setGender] = useState("");
  const [resPassType, setResPassType] = useState("Email ID");
  const [resEmail, setResEmail] = useState("");
  const [otpPopup, setOTPopup] = useState(false);
  const [otpRP, setOtpRP] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const [timer, setTimer] = useState(30);
  const [linkEnabled, setLinkEnabled] = useState(true);
  const OTPRPhandleChange = (e) => setOtpRP(e);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setLinkEnabled(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const navigate = useNavigate();

  ReactGA.send({
    hitType: "pageview",
    page: "/profile",
    title: "Profile",
  });

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const updatePassword = async () => {
    try {
      setIsNewPasswordError(false);
      if (newPassword !== confirmPassword) {
        setIsNewPasswordError(true);
        setNewPasswordError("Confirm password not same as new password!");
        changeNewPassword("");
        changeConfirmPassword("");
      } else {
        const res = await api.patch("/me/password", {
          password: newPassword,
          oldPass: currentPassword,
        });
        if (res.status === 200) {
          setIsNewPasswordError(true);
          setNewPasswordError(res.data.msg);
        } else {
          setIsNewPasswordError(true);
          setNewPasswordError(res.data.error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateHander = async () => {
    const res = await api.patch("/me", {
      name: name,
      email: email,
      mobile_number: mobNum,
      dob: dob,
      gender: gender,
    });
    // console.log(res)
    alert.success("Updated successfully");
    dispatch(
      update({
        name: name,
        email: email,
        mobile_number: mobNum,
        dob: dob,
        gender: gender,
      })
    );
    // } else {
    //   alert.error(`Empty ${props.backendName}`)
    // }
  };
  const forgotPwdHandler = async () => {
    if (!resEmail) {
      alert.error("Please enter Email");
      return;
    }
    const data = {
      email: resEmail,
      type: resPassType,
    };

    axios
      .post(API_URL + "/auth/reset-password", data, {
        headers: {
          "Content-Type": "application/json",
          // name: "xyz",
        },
        withCredentials: true,
      })
      .then((response) => {
        alert.success(response.data.msg);
        if (resPassType === "Mobile Number") {
          setOTPopup(true);
          setOtpNumber(resEmail);
        }
        setResEmail("");
      })
      .catch((error) => {
        alert.error(error.response.data.error);
      });

    setLinkEnabled(false);
    setTimer(30);
  };

  const verifyMobileRP = async () => {
    if (!validator.isMobilePhone(otpNumber)) {
      alert.error("Invalid phone number.");
      return;
    }
    const response = await axios.post(
      API_URL + "/auth/reset-password-otp",
      { mobileNumber: otpNumber, otp: otpRP },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.data.error) {
      alert.success(response.data.msg);
      window.open(response.data.resetLink, "_blank");
      setOTPopup(false);
    } else {
      alert.error(response.data.error);
    }
  };

  useEffect(() => {
    document.title = "Profile - PLYPICKER";
    changeCurrentPassword("");
  }, []);

  const handleResPassTypeChange = (event) => {
    setResPassType(event.target.value);
    setResEmail("");
  };

  const ActionsFunction = ({ type, desc, dest }) => {
    return (
      <div className="Actions_profile" onClick={() => navigate(`/${dest}`)}>
        <div className="Actions_type">
          <span className="Actions_type_one">{type}</span>
          <span className="Actions_type_two">{desc}</span>
        </div>
        <IoIosArrowForward />
      </div>
    );
  };

  // console.log("currentPassword", currentPassword);
  return !isMobile ? (
    <>
      <NavRoute title="profile" />
      <div
        className="sidebarProfilePageContainer w-100 px-0"
        style={{ borderTop: "1px solid black" }}
      >
        <div className="row m-0 p-0 w-100">
          <SideBar title="profile" />
          <div className="col-sm-9 col-12 d-flex flex-column px-lg-3 justify-content-between ps-3 pe-0 py-3">
            <div>
              {!editProfileState ? (
                <div className="renderside_info">
                  <div className="renderproflestyle">
                    <div className="renderproflestyle_coloumn">
                      <span className="style_key">Name: </span>
                      <span className="style_value">{state?.user?.name}</span>
                    </div>
                  </div>
                  <div className="renderproflestyle">
                    <div className="renderproflestyle_coloumn">
                      <span className="style_key">email id: &nbsp;</span>
                      <input
                        style={{
                          border: "none",
                          outline: "none",
                          width: "50%",
                          backgroundColor: "white",
                          color: "black",
                        }}
                        value={state?.user?.email}
                        disabled
                      />
                    </div>
                  </div>
                  {/* <div className="renderproflestyle">
                      <div className="renderproflestyle_coloumn">
                        <span className="style_key">Date of birth:</span>
                        <span className="style_value">{state?.user?.dob}</span>
                      </div>
                    </div> */}
                  <div className="renderproflestyle">
                    <div className="renderproflestyle_coloumn">
                      <span className="style_key">mobile number: </span>
                      <span className="style_value">
                        {state?.user?.mobile_number}
                      </span>
                    </div>
                  </div>
                  <div className="renderproflestyle">
                    <div className="renderproflestyle_coloumn">
                      <span className="style_key">gender:</span>
                      <span className="style_value">{state?.user?.gender}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="profile_form_content">
                    <div className="profile_form_content_inside">
                      <div className="editAddressInputLabel">Name:</div>
                      {/* <span>{state.user.name}</span> */}
                      <EditInput
                        type="text"
                        placeholder="Enter Your Name"
                        value={name}
                        onSet={setName}
                        onChange={(e) => setName(e.target.value)}
                        setUpdateState={setUpdateState}
                        typeData="name"
                        backendName="name"
                      />
                      {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setName("")} /> */}
                    </div>
                  </div>
                  <div className="profile_form_content">
                    <div className="profile_form_content_inside">
                      <div className="editAddressInputLabel">Email:</div>
                      {/* <span>{state.user.name}</span> */}
                      <EditInput
                        type="email"
                        placeholder="Enter Your Email"
                        value={email}
                        onSet={setEmail}
                        onChange={(e) => alert.error("Email is not editable.")}
                        setUpdateState={setUpdateState}
                        typeData="email"
                        backendName="email"
                      />
                      {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setEmail("")} /> */}
                    </div>
                  </div>
                  {/* <div className="profile_form_content">
                    <div className="profile_form_content_inside">
                      <div className="editAddressInputLabel">
                        DOB:
                      </div>
                      <EditInput
                        type="text"
                        placeholder="Enter Your Date of Birth"
                        value={dob}
                        onSet={setDob}
                        onChange={(e) => setDob(e.target.value)}
                        setUpdateState={setUpdateState}
                        typeData="dob"
                        backendName="dob"
                      />
                    </div>
                  </div> */}
                  <div className="profile_form_content">
                    <div className="profile_form_content_inside">
                      <div className="mobileNumberEditAddress editAddressInputLabel">
                        Mobile Number:
                      </div>
                      <EditInput
                        type="text"
                        placeholder="Enter Your Phone Number"
                        value={mobNum}
                        onSet={setMobNum}
                        onChange={(e) => setMobNum(e.target.value)}
                        setUpdateState={setUpdateState}
                        typeData="mob"
                        backendName="mobile_number"
                      />
                      {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setMobNum("")} /> */}
                    </div>
                  </div>
                  <div className="profile_form_content">
                    <div className="profile_form_content_inside">
                      <div className="UserProfileGender m-0">
                        <span>Gender:</span>
                        <FormControl>
                          <RadioGroup
                            row={isMobileOrTablet ? false : true}
                            className="GenderRadio ms-0 ms-2"
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={gender}
                            onChange={handleGenderChange}
                          >
                            <FormControlLabel
                              value="male"
                              control={<Radio />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={<Radio />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="other"
                              control={<Radio />}
                              label="Other"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="profile_handler d-flex flex-wrap flex-row align-items-center justify-content-md-center justify-content-start">
              {editProfileState ? (
                <div className="col-md-6 col-12 px-md-2 px-0">
                  <button
                    className="edit__profile px-4 py-2 float-lg-end"
                    onClick={() => {
                      setEditProfileState(false);
                      updateHander();
                    }}
                  >
                    save
                  </button>
                </div>
              ) : (
                <div className="col-md-6 col-12 px-md-2 px-0">
                  <button
                    className="edit__profile px-4 py-2 float-lg-end"
                    onClick={() => {
                      setEditProfileState(true);
                      setEmail(state.user.email);
                      setName(state.user.name);
                      setDob(state.user.dob);
                      setMobNum(state.user.mobile_number);
                      setGender(state.user.gender);
                    }}
                  >
                    edit
                  </button>
                </div>
              )}
              {editProfileState && (
                <div className="col-md-6 col-12 px-md-2 px-0">
                  <button
                    className="change__password px-4 py-2"
                    onClick={() => setEditProfileState(false)}
                  >
                    cancel
                  </button>
                </div>
              )}

              {!editProfileState && (
                <div className="col-md-6 col-12 px-md-2 px-0">
                  <button
                    className="change__password px-4 py-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    change password
                  </button>
                </div>
              )}
            </div>
          </div>
          {/* <!-- Modal for change password--> */}
          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content password_change_popup">
                <div className="modal-header" style={{ borderBottom: "none" }}>
                  <h5 className="modal-title">Change Password</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <input
                    type="password"
                    placeholder="Current Password"
                    value={currentPassword}
                    autocomplete="new-password"
                    onChange={(e) => changeCurrentPassword(e.target.value)}
                  />
                  <br />
                  <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => changeNewPassword(e.target.value)}
                  />
                  <br />
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => changeConfirmPassword(e.target.value)}
                  />
                  <br />
                </div>
                <div
                  className="modal-footer pb-0 mt-2"
                  style={{ borderTop: "none" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "end",
                      marginBottom: "10px",
                      marginTop: "-12px",
                    }}
                  >
                    <span
                      style={{
                        color: "#FF7253",
                        fontSize: "12px",
                        cursor: "pointer",
                        zIndex: "1000",
                        marginBottom: "8px",
                      }}
                      data-toggle="modal"
                      data-target="#exampleModalForgot"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      Forgot Password?
                    </span>
                    <p className="m-auto">
                      {isNewPasswordError && newPasswordError}
                    </p>
                    <button
                      type="button"
                      style={{
                        padding: "0.55rem 6.7rem",
                        whiteSpace: "nowrap",
                      }}
                      className="changePasswordButton"
                      onClick={updatePassword}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModalForgot"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalForgotLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content password_change_popup">
                <div
                  className="modal-header signin_modal_title"
                  style={{ borderBottom: "none" }}
                >
                  <h5 className="modal-title" id="exampleModalForgotLabel">
                    Forgot Password
                    <br />
                  </h5>
                  <div className="modal_password_reset">
                    We will send you a password reset link.
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {!otpPopup ? (
                    <>
                      <FormControl>
                        <RadioGroup
                          row={false}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={resPassType}
                          onChange={handleResPassTypeChange}
                        >
                          <FormControlLabel
                            value="Email ID"
                            control={<Radio />}
                            label="Email ID"
                          />
                          <FormControlLabel
                            value="Mobile Number"
                            control={<Radio />}
                            label="Mobile Number"
                          />
                        </RadioGroup>
                      </FormControl>
                      <br />
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        {resPassType === "Mobile Number" && (
                          <span
                            style={{ marginBottom: "4px" }}
                            className="addressMobileNumberInputPrefix"
                          >
                            +91
                          </span>
                        )}
                        {resPassType === "Mobile Number" ? (
                          <input
                            type="text"
                            minlength="10"
                            maxlength="10"
                            size="10"
                            value={resEmail}
                            onChange={(e) => setResEmail(e.target.value.trim())}
                            placeholder={resPassType}
                          />
                        ) : (
                          <input
                            type="text"
                            value={resEmail}
                            onChange={(e) => setResEmail(e.target.value.trim())}
                            placeholder={resPassType}
                          />
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <p className="loginPopupInputText">OTP</p>
                      <OtpInput
                        className="OtpInput"
                        value={otpRP}
                        onChange={OTPRPhandleChange}
                        numInputs={6}
                        separator={<span style={{ padding: "5px" }}>-</span>}
                      />
                      <br />
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            display: "flex",
                            gap: "1rem",
                            border: "none",
                            cursor: linkEnabled ? "pointer" : "not-allowed",
                            backgroundColor: "transparent",
                          }}
                          className={
                            linkEnabled
                              ? "loginPopupForgotPasswordButton"
                              : null
                          }
                          disabled={!linkEnabled}
                          onClick={() => {
                            forgotPwdHandler();
                          }}
                        >
                          Resend OTP
                          <p
                            style={{
                              color: "black",
                              fontStyle: "normal",
                              textDecorationLine: "none",
                              ":hover": {
                                textDecorationLine: "none",
                              },
                            }}
                          >
                            in {timer} seconds
                          </p>
                        </button>
                        <button
                          type="button"
                          className="loginPopupForgotPasswordButton"
                          onClick={() => {
                            setOTPopup(false);
                          }}
                        >
                          Back
                        </button>
                      </span>
                    </>
                  )}
                </div>
                <div className="modal-footer" style={{ borderTop: "none" }}>
                  <button
                    type="button"
                    className="addUpdateButton"
                    onClick={otpPopup ? verifyMobileRP : forgotPwdHandler}
                  >
                    Send Reset Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LowerNavigation />
      <Footer />
    </>
  ) : (
    <>
      <div className="MobileView">
        <div
          className="profilePicDiv"
          style={{ backgroundColor: "#D7D9D8", borderBottom: "none" }}
        >
          <BsArrowLeft
            onClick={() => navigate(-1)}
            style={{
              position: "absolute",
              top: "6",
              left: "8",
              fontSize: "30px",
            }}
          />
          <Avatar
            src={
                state?.user?.gender &&
                ["male", "female"].includes(state.user.gender.toLowerCase())
                ? state.user.gender.toLowerCase() === "male"
                    ? MaleVector
                    : FemaleVector
                : MaleVector
            }
            sx={{ width: 150, height: 150 }}
            />
        </div>
        <div className="actionsMobileItems">
          <div style={{ marginTop: "10px" }}>
            {!editProfileState ? (
              <div className="renderside_info">
                <div className="renderproflestyle">
                  <div className="renderproflestyle_coloumn">
                    <span className="style_key">Name: </span>
                    <span className="style_value">{state?.user?.name}</span>
                  </div>
                </div>
                <div className="renderproflestyle">
                  <div className="renderproflestyle_coloumn">
                    <span className="style_key" style={{ height: "100%" }}>
                      email id: &nbsp;
                    </span>
                    <input
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        width: "70%",
                        fontSize: "15px",
                      }}
                      value={state?.user?.email}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="renderproflestyle">
                      <div className="renderproflestyle_coloumn">
                        <span className="style_key">Date of birth:</span>
                        <span className="style_value">{state?.user?.dob}</span>
                      </div>
                    </div> */}
                <div className="renderproflestyle">
                  <div className="renderproflestyle_coloumn">
                    <span className="style_key">mobile number: </span>
                    <span className="style_value">
                      {state?.user?.mobile_number}
                    </span>
                  </div>
                </div>
                <div className="renderproflestyle">
                  <div className="renderproflestyle_coloumn">
                    <span className="style_key">gender:</span>
                    <span className="style_value">{state?.user?.gender}</span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="profile_form_content">
                  <div className="profile_form_content_inside">
                    <div className="editAddressInputLabel">Name:</div>
                    {/* <span>{state.user.name}</span> */}
                    <EditInput
                      type="text"
                      placeholder="Enter Your Name"
                      value={name}
                      onSet={setName}
                      onChange={(e) => setName(e.target.value)}
                      setUpdateState={setUpdateState}
                      typeData="name"
                      backendName="name"
                    />
                    {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setName("")} /> */}
                  </div>
                </div>
                <div className="profile_form_content">
                  <div className="profile_form_content_inside">
                    <div className="editAddressInputLabel">Email:</div>
                    {/* <span>{state.user.name}</span> */}
                    <EditInput
                      type="email"
                      placeholder="Enter Your Email"
                      value={email}
                      onSet={setEmail}
                      onChange={(e) => alert.error("Email is not editable.")}
                      setUpdateState={setUpdateState}
                      typeData="email"
                      backendName="email"
                    />
                    {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setEmail("")} /> */}
                  </div>
                </div>
                <div className="profile_form_content">
                  <div className="profile_form_content_inside">
                    <div className="mobileNumberEditAddress editAddressInputLabel">
                      Mobile Number:
                    </div>
                    <EditInput
                      type="text"
                      placeholder="Enter Your Phone Number"
                      value={mobNum}
                      onSet={setMobNum}
                      onChange={(e) => setMobNum(e.target.value)}
                      setUpdateState={setUpdateState}
                      typeData="mob"
                      backendName="mobile_number"
                    />
                    {/* <MdOutlineCancel style={{ height: 30, width: 30, fill: "#878787", cursor: "pointer" }} onClick={() => setMobNum("")} /> */}
                  </div>
                </div>
                <div className="profile_form_content">
                  <div className="profile_form_content_inside">
                    <div className="UserProfileGender m-0">
                      <span>Gender:</span>
                      <FormControl>
                        <RadioGroup
                          row={true}
                          className="GenderRadio RadioButtonMobile ms-0 ms-2"
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={gender}
                          onChange={handleGenderChange}
                        >
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                          <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label="Other"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="profile_handler d-flex flex-wrap flex-row align-items-center justify-content-md-center justify-content-start">
            {editProfileState ? (
              <div className="col-md-6 col-12 px-md-2 px-0">
                <button
                  className="edit__profile px-4 py-2 float-lg-end"
                  onClick={() => {
                    setEditProfileState(false);
                    updateHander();
                  }}
                >
                  save
                </button>
              </div>
            ) : (
              <div className="col-md-6 col-12 px-md-2 px-0">
                <button
                  className="edit__profile px-4 py-2 float-lg-end"
                  onClick={() => {
                    setEditProfileState(true);
                    setEmail(state.user.email);
                    setName(state.user.name);
                    setDob(state.user.dob);
                    setMobNum(state.user.mobile_number);
                    setGender(state.user.gender);
                  }}
                >
                  edit
                </button>
              </div>
            )}
            {editProfileState && (
              <div className="col-md-6 col-12 px-md-2 px-0">
                <button
                  className="change__password px-4 py-2"
                  onClick={() => setEditProfileState(false)}
                >
                  cancel
                </button>
              </div>
            )}

            {!editProfileState && (
              <div className="col-md-6 col-12 px-md-2 px-0">
                <button
                  className="change__password px-4 py-2"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  change password
                </button>
              </div>
            )}
          </div>
        </div>
        <br />
        <br />
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content password_change_popup">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <h5 className="modal-title">Change Password</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  autocomplete="new-password"
                  onChange={(e) => changeCurrentPassword(e.target.value)}
                />
                <br />
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => changeNewPassword(e.target.value)}
                />
                <br />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => changeConfirmPassword(e.target.value)}
                />
                <br />
              </div>
              <div
                className="modal-footer pb-0 mt-2"
                style={{ borderTop: "none" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    marginBottom: "10px",
                    marginTop: "-12px",
                  }}
                >
                  <span
                    style={{
                      color: "#FF7253",
                      fontSize: "12px",
                      cursor: "pointer",
                      zIndex: "1000",
                      marginBottom: "8px",
                    }}
                    data-toggle="modal"
                    data-target="#exampleModalForgot"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Forgot Password?
                  </span>
                  <p className="m-auto">
                    {isNewPasswordError && newPasswordError}
                  </p>
                  <button
                    type="button"
                    style={{
                      padding: "0.55rem 4rem",
                      whiteSpace: "nowrap",
                    }}
                    className="changePasswordButton"
                    onClick={updatePassword}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalForgot"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalForgotLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content password_change_popup">
              <div
                className="modal-header signin_modal_title"
                style={{ borderBottom: "none" }}
              >
                <h5 className="modal-title" id="exampleModalForgotLabel">
                  Forgot Password
                  <br />
                </h5>
                <div className="modal_password_reset">
                  We will send you a password reset link.
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <FormControl>
                  <RadioGroup
                    row={false}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={resPassType}
                    onChange={handleResPassTypeChange}
                  >
                    <FormControlLabel
                      value="Email ID"
                      control={<Radio />}
                      label="Email ID"
                    />
                    <FormControlLabel
                      value="Mobile Number"
                      control={<Radio />}
                      label="Mobile Number"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {resPassType === "Mobile Number" && (
                    <span
                      style={{ marginBottom: "4px" }}
                      className="addressMobileNumberInputPrefix"
                    >
                      +91
                    </span>
                  )}
                  {resPassType === "Mobile Number" ? (
                    <input
                      type="text"
                      minlength="10"
                      maxlength="10"
                      size="10"
                      value={resEmail}
                      onChange={(e) => setResEmail(e.target.value.trim())}
                      placeholder={resPassType}
                    />
                  ) : (
                    <input
                      type="text"
                      value={resEmail}
                      onChange={(e) => setResEmail(e.target.value.trim())}
                      placeholder={resPassType}
                    />
                  )}
                </span>
              </div>
              <div className="modal-footer" style={{ borderTop: "none" }}>
                <button
                  type="button"
                  className="addUpdateButton"
                  onClick={forgotPwdHandler}
                >
                  Send Reset Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentOrder;
