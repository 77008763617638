import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import validator from "validator";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import { setAuth, setUser } from "../../../store/reducers/userActions";
import { useStore } from "../../../store/store";
import ClosedEye from "./images/ClosedEye.png";
import OpenEye from "./images/OpenEye.png";
import plypickerLogo from "./images/plypicker_logo.png";
import sign_up from "./images/sign_up.png";
import "./SignUp.css";
import { BsAsterisk } from "react-icons/bs";
import ReactGA from "react-ga4";
import { getAddresses } from "../../../store/reducers/userActions";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function SignUp() {
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  const [name, setName] = useState("");
  const [email, setEmail] = useState(query.get("email") || "");
  const [phone, setPhone] = useState(query.get("mobile") || "");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [otp, setOtp] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [state, dispatch] = useStore();
  const navigate = useNavigate();
  const alert = useAlert();
  const [timer, setTimer] = useState(30);
  const [linkEnabled, setLinkEnabled] = useState(true);

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setLinkEnabled(true);
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  ReactGA.send({
    hitType: "pageview",
    page: "/signup",
    title: "Sign Up",
  });

  useEffect(() => {
    document.title = "Sign Up - PLYPICKER";
  }, []);

  const signUpHandler = async () => {
    const data = {
      name,
      mobileNumber: phone,
      email,
      password,
      otp,
      gender,
    };
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    const pw = regex.test(password);
    if (!pw) return alert.error("Password must contain at least one uppercase letter, one number, one special character, and be at least 8 characters long")
    try {
        const response = await axios.post(
          API_URL + "/auth/signup",
          data,
          {
            withCredentials: true,
          },
          {
            headers: {
              // name:'xyz'
            },
          }
        );
        if (response.data.accessToken && response.data.user) {
          alert.success("User registered successfully");
          dispatch(setUser(response.data.user));
          dispatch(setAuth(response.data.accessToken, true));
          navigate("/profile");
        } else {
          throw new Error("User registration failed");
        }
      } catch (error) {
        alert.error("An error occurred while registering user");
      }
  };

  const verifyMobile = async () => {
    if (!validator.isMobilePhone(phone)) {
      alert.error("Invalid phone number.");
      return;
    }
    const response = await axios.post(
      API_URL + "/auth/verify-mobile",
      { mobileNumber: phone },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!response.data.error) {
      setOtpSent(true);
      alert.success(response.data.msg);
    } else {
      alert.error(response.data.error);
    }
    setLinkEnabled(false);
    setTimer(30);
  };

  return (
    <div className="signup_container">
      <Link to="/" className="position-absolute start-0 top-0 p-3">
        <img src={plypickerLogo} width="200px" />
      </Link>
      <div className="signup_image_side">
        <img src={sign_up} alt="" />
      </div>
      <div className="signup_form_side_outer">
        <div className="signup_form_side">
          <div className="signup_form_side_heading">Sign Up</div>
          <div className="signup_form_side_form">
            <form action="#">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())} // tf??
                placeholder="Email ID"
              />
              <div className="form_side_mobile">
                <div className="form_mobile_number_input">
                  <input
                    type="tel"
                    onChange={(e) => {
                      if (
                        (validator.isNumeric(e.target.value) &&
                          e.target.value.length <= 10) ||
                        e.target.value === ""
                      ) {
                        setPhone(e.target.value.trim());
                      }
                    }}
                    value={phone}
                    placeholder="Mobile Number"
                  />
                </div>

                <div
                  className="form_mobile_number_verify"
                  style={{ marginBottom: "0px" }}
                  onClick={() => setOtpSent(true)}
                >
                  {!otpSent ? (
                    <span
                      className="OTP-span"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        verifyMobile();
                      }}
                    >
                      Verify with OTP
                    </span>
                  ) : (
                    <div>
                      <input
                        type="text"
                        className="sendOtp"
                        value={otp}
                        onChange={(e) => {
                          if (
                            (validator.isNumeric(e.target.value) &&
                              e.target.value.length <= 6) ||
                            e.target.value === ""
                          ) {
                            setOtp(e.target.value);
                          }
                        }}
                      />
                      <div style={{ display: "flex", gap: "1.4rem" }}>
                        <span
                          style={{
                            cursor: linkEnabled ? "pointer" : "not-allowed",
                          }}
                          className={linkEnabled ? "resend-otp OTP-span" : null}
                          onClick={() => (linkEnabled ? verifyMobile() : null)}
                        >
                          resend OTP
                        </span>
                        <p>in {timer} seconds</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="position-relative">
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Password"
                />
                {password.length !== 0 && (
                  <button
                    type="button"
                    className="signupPagePasswordVisibleButton"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsPasswordVisible(!isPasswordVisible);
                    }}
                  >
                    {isPasswordVisible ? (
                      <img src={ClosedEye} style={{ width: "100%" }} />
                    ) : (
                      <img src={OpenEye} style={{ width: "100%" }} />
                    )}
                  </button>
                )}
              </div>
              <div className="form_gender">
                Gender
                <div className="form_gender_boxes">
                  <div
                    className={`gender_boxes ${
                      gender === "male" && "gender_boxes_active"
                    }`}
                    onClick={() => setGender("male")}
                  >
                    Male
                  </div>
                  <div
                    className={`gender_boxes ${
                      gender === "female" && "gender_boxes_active"
                    }`}
                    onClick={() => setGender("female")}
                  >
                    Female
                  </div>
                  <div
                    className={`gender_boxes ${
                      gender === "others" && "gender_boxes_active"
                    }`}
                    onClick={() => setGender("other")}
                  >
                    Others
                  </div>
                </div>
              </div>
              {/* <p className="text-center">OR</p>
                <div className="otherSignInOptions">
                    <img src={google} className="otherSignInIcons" />
                    <img src={facebook} className="otherSignInIcons" />
                </div> */}
              {/* <div className="form_terms">
                  Have an account? <Link to="/signin" className="plypicker-link">Sign in</Link>
                </div> */}

              <div className="form_register_button" onClick={signUpHandler}>
                Register
              </div>
              <div
                className="form_login_button"
                onClick={() => navigate("/signin")}
              >
                Already Registered? Login
              </div>
              <span className="d-flex align-items-center">
                <BsAsterisk
                  style={{
                    marginRight: "5px",
                    fontSize: "11px",
                    fill: "#FF7253",
                  }}
                />
                <span
                  type="button"
                  className="loginPopupForgotPasswordButton"
                  onClick={() => {
                    navigate("/terms-and-condition");
                  }}
                >
                  T&C Apply
                </span>
              </span>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
