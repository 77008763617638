import NavRoute from "../../Nav_Route/NavRoute";
import Footer from "../../../global_component/Footer/Footer";
import "./ServiceCategory.css";
import { genSaltSync, hashSync } from "bcryptjs";
import {
  API_URL,
  REACT_APP_HKEY,
  REACT_APP_JKEY,
  REACT_APP_GA_MEASUREMENT_ID
} from "../../../../constants.js";
import { fromString } from "uint8arrays/from-string";
import * as jose from "jose";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import { useStore } from "../../../../store/store";
import CityModal from "../../../global_component/Modal/cityModal";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);


function Services() {
  const [services, setServices] = useState([]);
  const [state,dispatch]=useStore()
  const [serviceSubcategory, setServiceSubcategory] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);

  const navigate = useNavigate();
  const params = useParams();

  ReactGA.send({
    hitType: 'pageview',
    page: `/hire-artisans/:${params?.id}/:${params?.id}`,
    title : 'Services'
  })

  useEffect(() => {
    async function fetchData() {
      var dec = genSaltSync(10);
      var hash = hashSync(REACT_APP_HKEY, dec);
      setIsLoadingProducts(true);
      const secret = fromString(REACT_APP_JKEY);
      const twj = await new jose.SignJWT({ expiry: hash })
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime("5s")
        .sign(secret);
      const res = await axios.get(`${API_URL}/services/getServicesById?serviceSubcategory=${params?.sid}`, {
        headers: {
          "Content-Policy": twj,
        },
      });
      if (res.data?.length === 0) {
        setIsError(true);
        setIsLoadingProducts(false);
      } else {
        setIsError(false);
        setServices(res.data);
      }
      setIsLoadingProducts(false);
    }
    state.selectedCity && fetchData();
  }, [state.selectedCity]);

  useEffect(() => {
    async function fetchData() {
      var dec = genSaltSync(10);
      var hash = hashSync(REACT_APP_HKEY, dec);
      const secret = fromString(REACT_APP_JKEY);
      setIsLoadingProducts(true);
      const twj = await new jose.SignJWT({ expiry: hash })
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime("5s")
        .sign(secret);
      const res = await axios.get(`${API_URL}/services/getServiceSubCat/${params?.sid}`, {
        headers: {
          "Content-Policy": twj,
        },
      });
      if (res.data?.length === 0) {
        setIsError(true);
        setIsLoadingProducts(false);
      } else {
        setIsError(false);
        setServiceSubcategory(res.data)
      }
      setIsLoadingProducts(false);
    }
    state.selectedCity &&  fetchData();
  }, [state.selectedCity])
  return (
    <>

<Helmet>
        <meta
          name="title"
          content={`Plypicker.com | {service name}`}
        />
        <meta
          name="description"
          content={`Only genuine service. Your service at your time book your slot according to your comfort. only available on plypicker {service link}`}
        />
        <meta
          name="keywords"
          content="plypicker, ecommerce, interior designer, interior designing, plywood, basin, faucet, hardware, flat, wardrobe, bathroom, kitchen"
        />
        <meta property="og:image" content=" service image" />
        <link rel="canonical" href={`https://plypicker.com/hire-artisans/63e719c90d680b0dc50920ee`} />
        </Helmet>


        {!state.selectedCity && 
        <>
        <CityModal></CityModal>
        <div style={{ display: isLoadingProducts ? "block" : "none" }}>
      <div style={{ display: "flex" }}>
        <div className="comp4-loading-shimmer">
          <div className="comp4-shimmer-image"></div>
          <div className="comp4-shimmer-details">
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
          </div>
        </div>
        <div className="comp4-loading-shimmer">
          <div className="comp4-shimmer-image"></div>
          <div className="comp4-shimmer-details">
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
          </div>
        </div>
        <div className="comp4-loading-shimmer">
          <div className="comp4-shimmer-image"></div>
          <div className="comp4-shimmer-details">
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
            <div className="comp4-shimmer-line"></div>
          </div>
        </div>
      </div>
      </div>
        </>}
        <div style={{display: isLoadingProducts ? "none" : "block"}} >
      <NavRoute productRouter services title="Hire Artisans" serviceSubCategoryId={params?.sid}/>
      <div className="container-fluid">
      <h3 className="ServiceHeading">{serviceSubcategory && serviceSubcategory?.serviceSubcategory}</h3>
      <div
          className="d-flex flex-row w-100 flex-wrap justify-content-start mb-5"
          style={{ marginTop: "50px",rowGap:"25px" }}
        >
          {services?.map((service) => (
            <div className="col-lg-2 col-md-4 col-12 col-sm-6">
              <div
                className="cardServiceCat box d-flex flex-column mx-2"
                onClick={() => {
                  navigate(`/hire-artisans/service/${service._id}`);
                }}
              >
                <img
                  src={service?.attrs?.imgs[0]}
                  alt=""
                  width="220px"
                  height="315px"
                />
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 400,
                    wordBreak:"break-word",
                    textTransform: "uppercase",
                    width:"90%"
                  }}
                >
                  {service?.serviceName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
      <Footer/>
    </>
  )
}

export default Services