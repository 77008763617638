import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setRoute } from "../../../../../../store/reducers/userActions";
import { useStore } from "../../../../../../store/store";
import "./LowerNavbar.scss";
import { useHorizontalScroll } from "../../../../../../hooks/useHorizontalScroll";

function NewLowerNavbar({ categories, isNavbarScrolled }) {
    const navigate = useNavigate();
    const [state, dispatch] = useStore();
    const [hoverState, setHoverState] = useState({
        categoryHoverState: -1,
        subCategoryHoverState: -1,
        groupHoverState: -1
    });
    const [isFirst, setIsFirst] = useState(true)
    const [firstTimeHover, setFirstTimeHover] = useState(false);
    const [popularBrands, setPopularBrands] = useState([
        {
            brandName: "METRO TILES",
            brandId: "63b4feb5863c9ef271193ac0"
        },
        {
            brandName: "METRO KICH",
            brandId: "63b4fc80863c9ef271193abe"
        },
        {
            brandName: "VIRGOLAM",
            brandId: "63df9d6d6b7dd5c6a6beca78"
        },
        {
            brandName: "VIDYALAM",
            brandId: "63df9c676b7dd5c6a6beca75"
        },
        {
            brandName: "GLORIO",
            brandId: "641ad288de011c16c602cad6"
        },
        {
            brandName: "CRYSTA",
            brandId: "641ad300de011c16c602cad7"
        },
        {
            brandName: "KUTCHINA",
            brandId: "626e68ef7ad4861271f50c12"
        },
        {
            brandName: "EUROPA",
            brandId: "62cf0e70387382604fac4121"
        },
        {
            brandName: "HINDWARE",
            brandId: "62b0af762274421b9f0784cc"
        },
        {
            brandName: "CERA",
            brandId: "62b0b3262274421b9f0784ce"
        },
        {
            brandName: "KOHLER",
            brandId: "62b0affc2274421b9f0784cd"
        },
        {
            brandName: "PRINCE",
            brandId: "6318d9637d43166e00fabdf6"
        },
        {
            brandName: "HETTICH",
            brandId: "6398c1974dc01867c6f938c5"
        },
        {
            brandName: "LATICRATE",
            brandId: "63e5f09aa3c3c7e4d0c11e11"
        },
        {
            brandName: "FALCOFIX",
            brandId: "63eb9a19ee75b89808447a08"
        },
        {
            brandName: "ANCHOR",
            brandId: "62cf1364387382604fac427b"
        },
        {
            brandName: "VIHAN",
            brandId: "6303c1979d8f75e96f56bd4a"
        },
        {
            brandName: "GRAFFIX",
            brandId: "63df9e0a6b7dd5c6a6beca7a"
        },
        {
            brandName: "PRIME CRAFT",
            brandId: "63a54615b27099100a75bb17"
        }
    ])
    const scrollRef = useHorizontalScroll();

    function setCategoryHoverState(event, categoryIndex) {
        event.preventDefault();
        setHoverState({
            categoryHoverState: categoryIndex,
            subCategoryHoverState: 0,
            groupHoverState: 0,
        });
    }

    function setSubCategoryHoverState(event, categoryIndex, subCategoryIndex) {
        event.preventDefault();
        setHoverState({
            categoryHoverState: categoryIndex,
            subCategoryHoverState: subCategoryIndex,
            groupHoverState: 0
        });
    }

    function setGroupHoverState(event, categoryIndex, subCategoryIndex, groupIndex) {
        event.preventDefault();
        setHoverState({
            categoryHoverState: categoryIndex,
            subCategoryHoverState: subCategoryIndex,
            groupHoverState: groupIndex
        });
    }

    function resetHoverState(event) {
        event.preventDefault();
        setHoverState({
            categoryHoverState: -1,
            subCategoryHoverState: -1,
            groupHoverState: -1
        })
    }

    function onPopularBrandClick() {
        dispatch(setRoute({}))
    }

    return (<>
        <div className="newLowerNavbarOuterContainerr d-lg-block d-none" style={isNavbarScrolled ? {background: "white!important"} : {}}>
            <ul className="newLowerNavbarContainerr newLowerNavbarHoverStyle" ref={scrollRef}>
                {categories.map((category, categoryIndex) => (<>
                    <li className="categoryNameBullet" onMouseEnter={(event) => { setCategoryHoverState(event, categoryIndex) }} onClick={(event) => { resetHoverState(event) }}>
                        <Link className="newLowerNavbarCategoryName newText" to={`/productlist?category=${category._id}`}>
                            {category.name}
                        </Link>
                    </li>
                </>))}
            </ul>
            {(hoverState.categoryHoverState !== -1 || isFirst) && <div className="newLowerNavbarDropdown newLowerNavbarHoverStyle" onClick={(event) => { resetHoverState(event); setIsFirst(false) }} onMouseOut={(e) => { setIsFirst(false) }}>
                <div className="w-100 d-flex flex-wrap">
                    {categories[hoverState.categoryHoverState]?.subCategories[hoverState.subCategoryHoverState]?.groups[hoverState.groupHoverState]?.subGroups?.length > 0 ? <>
                        <div className="col-md-3 col-6 py-4 px-0">
                            {hoverState.categoryHoverState > -1 && categories[hoverState.categoryHoverState]?.subCategories.map((subcategory, subcategoryIndex) => (<>
                                <div className="ps-4 pb-2 pt-2 subCategoryLinkWrapper" onClick={(event) => { navigate(`/productlist?subcategory=${subcategory._id}`) }}>
                                    <Link className="subCategoryLink newText" to={`/productlist?subcategory=${subcategory._id}`} onMouseEnter={(event) => setSubCategoryHoverState(event, hoverState.categoryHoverState, subcategoryIndex)} style={hoverState.subCategoryHoverState > -1 && hoverState.subCategoryHoverState === subcategoryIndex ? { fontWeight: "500" } : {}}>
                                        {subcategory.Sub_Category_name}
                                    </Link>
                                    {hoverState.subCategoryHoverState === subcategoryIndex && <>
                                        <span className="newHoverTriangle newText"></span>
                                    </>}
                                </div>
                            </>))}
                            {hoverState.categoryHoverState > -1 && categories[hoverState.categoryHoverState].subCategories.length === 0 && <>
                                <div className="w-100 d-flex align-items-center justify-content-center text-center subcategoryName newText" style={{ height: "100%" }} >
                                    Coming soon!
                                </div>
                            </>}
                        </div>
                        <div className="col-md-3 col-6 py-4 px-0 newGroupDivWrapper">
                            {categories[hoverState.categoryHoverState]?.subCategories.length > 0 && categories[hoverState.categoryHoverState].subCategories[hoverState.subCategoryHoverState]?.groups?.map((group, groupIndex) => (<>
                                <div className="ps-3 pb-2 pt-2 newLowerNavbarGroupLinkWrapper" onClick={(event) => { navigate(`/productlist?group=${group._id}`) }}>
                                    <Link to={`/productlist?group=${group._id}`} onMouseEnter={(event) => setGroupHoverState(event, hoverState.categoryHoverState, hoverState.subCategoryHoverState, groupIndex)} className="newLowerNavbarGroupLink newText" style={hoverState.groupHoverState > -1 && hoverState.groupHoverState === groupIndex ? { fontWeight: "500" } : {}}>
                                        {group.Group_name}
                                    </Link>
                                </div>
                            </>))}
                        </div>
                        <div className="col-md-3 col-6 py-4 px-0 newSubGroupDivWrapper">
                            {categories[hoverState.categoryHoverState]?.subCategories.length > 0 && categories[hoverState.categoryHoverState].subCategories[hoverState.subCategoryHoverState]?.groups[hoverState.groupHoverState]?.subGroups.map((subGroup, groupIndex) => (<>
                                <div className="ps-3 pb-2 pt-2 newLowerNavbarGroupLinkWrapper" onClick={(event) => { navigate(`/productlist?subgroup=${subGroup._id}`) }}>
                                    <Link to={`/productlist?subgroup=${subGroup._id}`} className="newLowerNavbarGroupLink newText">
                                        {subGroup.Subgroup_name}
                                    </Link>
                                </div>
                            </>))}
                        </div>
                        <div className={categories[hoverState.categoryHoverState].subCategories[hoverState.subCategoryHoverState]?.groups[hoverState.groupHoverState]?.subGroups.length > 0 ? "col-md-3 col-12 px-4 my-4 text-start newLowerNavbarBrandsWithSubgroups" : "col-md-3 col-12 px-4 my-4 text-start"}>
                            <h4 className="newText">POPULAR BRANDS</h4>
                            <div className="row">
                                {popularBrands.slice(0, 12).map(brand => (
                                    <>
                                        <div className="col-6">
                                            <div className="lowerNavbarPopularLinkWrapper">
                                                <Link className="lowerNavbarPopularLink newText" to={`/productlist?brand=${brand.brandId}`}>{<p onClick={onPopularBrandClick}>{brand.brandName}</p>}</Link>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </> : <>
                        <div className="col-md-3 col-6 py-4 px-0">
                            {hoverState.categoryHoverState > -1 && categories[hoverState.categoryHoverState]?.subCategories.map((subcategory, subcategoryIndex) => (<>
                                <div className="ps-4 pb-2 pt-2 subCategoryLinkWrapper" onClick={(event) => { navigate(`/productlist?subcategory=${subcategory._id}`) }}>
                                    <Link className="subCategoryLink newText" to={`/productlist?subcategory=${subcategory._id}`} onMouseEnter={(event) => setSubCategoryHoverState(event, hoverState.categoryHoverState, subcategoryIndex)} style={hoverState.subCategoryHoverState > -1 && hoverState.subCategoryHoverState === subcategoryIndex ? { fontWeight: "500" } : {}}>
                                        {subcategory.Sub_Category_name}
                                    </Link>
                                    {hoverState.subCategoryHoverState === subcategoryIndex && <>
                                        <span className="newHoverTriangle"></span>
                                    </>}
                                </div>
                            </>))}
                            {hoverState.categoryHoverState > -1 && categories[hoverState.categoryHoverState].subCategories.length === 0 && <>
                                <div className="w-100 d-flex align-items-center justify-content-center text-center subcategoryName newText" style={{ height: "100%" }} >
                                    Coming soon!
                                </div>
                            </>}
                        </div>
                        <div className="col-md-3 col-6 py-4 px-0 newGroupDivWrapper">
                            {categories[hoverState.categoryHoverState]?.subCategories.length > 0 && categories[hoverState.categoryHoverState].subCategories[hoverState.subCategoryHoverState]?.groups?.map((group, groupIndex) => (<>
                                <div className="ps-3 pb-2 pt-2 newLowerNavbarGroupLinkWrapper" onClick={(event) => { navigate(`/productlist?group=${group._id}`) }}>
                                    <Link to={`/productlist?group=${group._id}`} onMouseEnter={(event) => { setGroupHoverState(event, hoverState.categoryHoverState, hoverState.subCategoryHoverState, groupIndex) }} className="newLowerNavbarGroupLink newText" style={hoverState.groupHoverState > -1 && hoverState.groupHoverState === groupIndex ? { fontWeight: "500" } : {}}>
                                        {group.Group_name}
                                    </Link>
                                </div>
                            </>))}
                        </div>
                        <div className="col-md-6 col-12 p-4 text-start">
                            <h4>POPULAR BRANDS</h4>
                            <div className="row">
                                {popularBrands.map(brand => (
                                    <>
                                        <div className="col-4">
                                            <div className="lowerNavbarPopularLinkWrapper">
                                                <Link className="lowerNavbarPopularLink newText" to={`/productlist?brand=${brand.brandId}`}>{<p onClick={onPopularBrandClick}>{brand.brandName}</p>}</Link>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </>}
                </div>
            </div>}
        </div>
    </>)
}

export default NewLowerNavbar