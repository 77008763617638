import React from "react";
import error_back from "./error_back.png";
import "./ProductError.css";
function ProductError () {
  return (
    <>
      <div className="container">
        <div className="error_page_mainn" >
          <div className="error_page_img">
            <img src={error_back} alt="" />
            <div className="error_page_inner_contentt">
              <div className="error_page_textt1">Product Not Found</div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default ProductError;
