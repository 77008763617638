export const initialState = {
    accessToken: null,
    authenticated: false,
    user: null,
    product: null,
    cart: [],
    servicesCart: [],
    homeData: null,
    selectedAddress: null,
    appliedCoupon : null,
    selectedVehicle: null,
    selectedCity: null,
    categoryData: null,
    isLaborRequiredState: null,
    route: {
        categoryName: null,
        categoryId: null,
        subCategoryName: null,
        subCategoryId: null,
        groupName: null,
        groupId: null
    }
}
const EditData = (data, newData) => {
    data.name = newData.name;
    data.email = newData.email;
    data.mobile_number = newData.mobile_number
    data.dob = newData.dob;
    data.gender = newData.gender
    return data;
}

const updateAddressData = (data, index, newData) => {
    const updatedAddress = [...data];
    updatedAddress[index] = newData;
    return updatedAddress;
}

const Add2Cart = (data, newData) => {
    data.push(newData);
    return data;
}

const RemoveFromWishlist = (wishlist, item) => {
    const newWishlist = wishlist.filter((data) => (
        data !== item
    ));
    return newWishlist;
}
const DeleteData = (addresses, address) => {
    const newData = addresses.filter(function (item) {
        return item._id !== address._id;
    });
    return newData;
}

const AddReview = (rating_and_review, newReview) => {
    // console.log("before push",rating_and_review);
    rating_and_review?.push(newReview);
    // console.log("after push",rating_and_review);
    return rating_and_review;
}

const EditReview = (data, id, newReview) => {
    const newData = data.map(item => (item.user_id?._id === id ? newReview : item));
    // console.log(newData)
    return newData;
}

const DeleteReview = (review, oldReview) => {
    const newReview = review.filter(function (item) {
        return item._id !== oldReview._id;
    });
    return newReview;
}

const EditCart = (cart, product_id, quantity) => {
    const newCart = cart.map(item => (item.product_id?._id === product_id ? quantity : item));
    return newCart;
}

export const cityReducer=(action)=>{
    // console.log(action)
    switch(action.type){
        case "GET_CITY":
            return {
             
                city:action.payload,
            }
    }
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_AUTH":
            return {
                ...state,
                accessToken: action.payload.accessToken,
                authenticated: action.payload.auth,
            };
        case "SET_SELECTED_COUPON":
            return {
                ...state,
                appliedCoupon : action.payload.coupon,
            };
        case "SET_USER":
            return {
                ...state,
                user: action.payload.user,
            };
        case "UPDATE_USER":
            return {
                ...state,
                user: EditData(state.user, action.payload.newData)
            };
        case "UPDATE_WISHLIST":
            return {
                ...state,
                user: {
                    ...state.user,
                    wishlist: action.payload.wishlist
                },
            };
        case "UPDATE_CITY":
            return {
                ...state,
                selectedCity: action.payload.selectedCity
            };
            case "UPDATE_CITY_NAME":
                return {
                    ...state,
                    selectedCityName: action.payload.selectedCityName
                };            


        case "ADD_TO_CART":
            return {
                ...state,
                user: {
                    ...state.user,
                    wishlist: Add2Cart(state.user.wishlist, action.payload.newData)
                }
            };
        case "ADDCART":
            return {
                ...state,
                cart: AddReview(state.cart, action.payload.newData)
            };
        case "REMOVE_FROM_WISHLIST":
            return {
                ...state,
                user: {
                    ...state.user,
                    wishlist: RemoveFromWishlist(state.user.wishlist, action.payload.item)
                }
            };
        case "REMOVE_FROM_CART":
            return {
                ...state,
                cart: RemoveFromWishlist(state.cart, action.payload.item)
            }
        case "UPDATE_ADDRESS":
       

            return {
                ...state,
                user: {
                    ...state.user,
                    addresses: updateAddressData(state.user.addresses, action.payload.index, action.payload.address)
                }
            };
        case "DELETE_ADDRESS":
            return {
                ...state,
                user: {
                    ...state.user,
                    addresses: DeleteData(state.user.addresses, action.payload.address)
                }
            };
        case "LOGOUT":
            return {
                initialState,
                selectedCity: state?.selectedCity || null,
                selectedCityName: state?.selectedCityName || null
            };

        case"GET_ADDRESSES":{
            return {
                ...state,
                user: {
                    ...state.user,
                    addresses:action.payload.addresses
                }
            }
        }    
        case "ADD_ADDRESS": {
            let { address } = action.payload;
            address.mobile = `+91${address.mobile}`;
            return {
                ...state,
                user: {
                    ...state.user,
                    addresses: [...state.user.addresses, action.payload.address]
                }
            }
        }
        case "TEST": {
            alert("test");
            return state
        }
        case "SET_ROUTE": {
            return {
                ...state,
                route: action.payload.newData
            }
        }
        case "SET_CATEGORY_DATA": {
            return {
                ...state,
                categoryData: action.payload
            }
        }
        case "SET_PRODUCT": {
            return {
                ...state,
                product: action.payload.product
            }
        }
        case "ADD_PRODUCT_REVIEW": {
            return {
                ...state,
                product: {
                    ...state.product,
                    rating_and_review: AddReview(action.payload.ratingArray, action.payload.newReview)

                }
            }
        }
        case "EDIT_PRODUCT_REVIEW": {
            return {
                ...state,
                product: {
                    ...state.product,
                    rating_and_review: EditReview(state.product.rating_and_review, state.user._id, action.payload.newReview)
                }
            }
        }
        case "EDIT_PRODUCT_REVIEW_IN_PAGE": {
            return {
                ...state,
                product: {
                    ...state.product,
                    rating_and_review: EditReview(action.payload.ratingArray, state.user._id, action.payload.newReview)
                }
            }
        }
        case "DELETE_PRODUCT_REVIEW": {
            return {
                ...state,
                product: {
                    ...state.product,
                    rating_and_review: DeleteReview(state.product.rating_and_review, action.payload.oldReview)
                }
            }
        }
        case "SET_AVG_RATING": {
            return {
                ...state,
                product: {
                    ...state.product,
                    averageRating: action.payload.rating
                }
            }
        }
        case "SET_CART":
            return {
                ...state,
                cart: action.payload.cartItem,
            };

        case "SET_SERVICES_CART":
            return {
                ...state,
                servicesCart: action.payload.cartItem,
            };

        case "SET_HOME_DATA":
            return {
                ...state,
                homeData: action.payload.homeData
            }
        case "SET_CART_NULL":
            return {
                ...state,
                cart: []
            };
        case "SET_WISHLIST_NULL":
            return {
                ...state,
                user: {
                    ...state.user,
                    wishlist: []
                },
            };
        case "EDIT_CART_ITEM":
            return {
                ...state,
                cart: EditCart(state.cart, action.payload.product_id, action.payload.quantity)
            };
        case "SET_SELECTED_ADDRESS":
            return {
                ...state,
                selectedAddress: action.payload.selectedAddress
            };
        case "SET_SELECTED_VEHICLE":
            return {
                ...state,
                selectedVehicle: action.payload.selectedVehicle
            }
        case "SET_LABOR_REQUIRED":
            return {
                ...state,
                isLaborRequiredState: action.payload.isLaborRequiredState
            }
        default:
            return state;
    }
}