import { useEffect, useState } from "react"
import x from "../img/Filter/x.png"
import "./Discount.css"

function Discount({discountOnChange,discountVal,setDiscountVal}){
    const [discounts, setDiscounts] = useState([
        {
            discountPercent: 10,
            checked: false
        },
        {
            discountPercent: 20,
            checked: false
        },
        {
            discountPercent: 30,
            checked: false
        },
        {
            discountPercent: 40,
            checked: false
        },
        {
            discountPercent: 50,
            checked: false
        },
        {
            discountPercent: 60,
            checked: false
        },
        {
            discountPercent: 70,
            checked: false
        },
        {
            discountPercent: 80,
            checked: false
        },
    ])

    useEffect(() => {
      let arr = [];
      discounts.map((discount,i)=>{
        if(discount.checked){
            arr.push(discount.discountPercent);
        }else{
            let idx = arr.findIndex((x)=>x===discount.discountPercent);
            if(idx !== -1){
                arr.splice(arr[idx]);
            }
        }
      })
      setDiscountVal([...arr]);
    }, [discounts])
    
    const original = [
        {
            discountPercent: 10,
            checked: false
        },
        {
            discountPercent: 20,
            checked: false
        },
        {
            discountPercent: 30,
            checked: false
        },
        {
            discountPercent: 40,
            checked: false
        },
        {
            discountPercent: 50,
            checked: false
        },
        {
            discountPercent: 60,
            checked: false
        },
        {
            discountPercent: 70,
            checked: false
        },
        {
            discountPercent: 80,
            checked: false
        },
    ]
    return(<>
        <div>
            <table className="table my-0">
                <thead>
                    <tr>
                        <th scope="col" className="ps-0" style={{borderBottom:"none"}}>DISCOUNT</th>
                        <td className="clearFilterButton" style={{borderBottom:"none"}}>
                            <img src={x} alt="" onClick={(e) => {
                                e.preventDefault();
                                setDiscounts(original)
                            }} style={{cursor: "pointer"}} />
                        </td>
                    </tr>
                </thead>
            </table>
            <div className="">
                {discounts.map((discount, i) => (<>
                    <div className="row py-1 w-100 mx-0">
                        <div className="col-10 ps-0 discountPercentDescription">
                            {discount.discountPercent}% and above
                        </div>
                        <div className="col-2 p-0 d-flex align-items-end justify-content-end">
                            <label className="filterContainer">
                                <input type="checkbox" checked={discount.checked} onClick={(e) => {
                                    // e.preventDefault();
                                    const newArray = discounts;
                                    // newArray[i].checked = !discount.checked;
                                    if (discount.checked) {
                                        newArray[i].checked = false;
                                    } else {
                                        const findDiscount = newArray.findIndex(c => c.discountPercent === discount.discountPercent);
                                        if(i===findDiscount){
                                            newArray.map((x)=>x.checked=false);
                                            newArray[i].checked=!discounts[i].checked;
                                        }
                                    }
                                    setDiscounts([...newArray])
                                    discountOnChange(e, newArray);
                                    
                                }}></input>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </>))}
            </div>
        </div>
    </>)
}

export default Discount