import "./Component6.css";
import { SplideSlide, Splide } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

export default function Component6({ data }) {
    const [showData, setShowData] = useState([]);
    const navigate = useNavigate();
    const category = data?.category;
    const filters = data?.filters;
    const [aspectRatios, setAspectRatios] = useState([0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78, 0.78]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [filteredImages, setFilteredImages] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [selectedRedirectTo, setSelectedRedirectTo] = useState("")
    const isDesktop = useMediaQuery({
        query: "(min-width:1400px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width:992px)",
    });

    const isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    useEffect(() => {
        const categoryData = data?.category.find(
            (category) => category?.title === selectedCategory
        );

        if (categoryData) {
            const sortedData = [...categoryData.data];

            if (selectedFilter) {
                const filterData = data?.filters.find(
                    (filters) => filters?.title === selectedFilter
                );
                if (filterData?.title === "Sort By Asc") {
                    sortedData.sort((a, b) => {
                        const priceA = parseFloat(a.discounted_price);
                        const priceB = parseFloat(b.discounted_price);
                        return priceB - priceA;
                    });
                } else if (filterData?.title === "Sort By Dsc") {
                    sortedData.sort((a, b) => {
                        const priceA = parseFloat(a.discounted_price);
                        const priceB = parseFloat(b.discounted_price);
                        return priceA - priceB;
                    });
                }
            }

            const images = sortedData.map((product, index) => (
                <div key={index} onClick={() => navigate(product?.redirect)} className="w-100">
                    <Link to={product?.redirect}>
                        <img
                            onLoad={() => onloading()}
                            className="masoimg"
                            style={{ width: "100%", aspectRatio: 0.78, objectFit: "cover" }}
                            src={product.image}
                            alt={"cant load image"}
                        />
                    </Link>
                </div>
            ));


            setFilteredImages(images);
        } else {
            setFilteredImages([]);
        }
    }, [selectedCategory, selectedFilter, data?.category, data?.filters]);

    const handleCategoryClick = (categoryTitle, redirectTo) => {
        setSelectedCategory(categoryTitle);
        setSelectedRedirectTo(redirectTo)
    };

    const onloading = () => {
        setIsLoading(false);
        // }
    };
    return (
        <>
            <div style={{ display: isLoading ? "block" : "none" }}>
                <div style={{ display: "flex" }}>
                    <div className="comp4-loading-shimmer">
                        <div className="comp4-shimmer-image"></div>
                        <div className="comp4-shimmer-details">
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                        </div>
                    </div>
                    <div className="comp4-loading-shimmer">
                        <div className="comp4-shimmer-image"></div>
                        <div className="comp4-shimmer-details">
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                        </div>
                    </div>
                    <div className="comp4-loading-shimmer">
                        <div className="comp4-shimmer-image"></div>
                        <div className="comp4-shimmer-details">
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                            <div className="comp4-shimmer-line"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="compBottomMargin">
                <span className="newHeading">
                    Collection For You
                </span>
                <div className="collectionParent my-lg-5 my-sm-4 my-3">
                    <Splide
                        style={{
                            overflowX: "scroll",
                            scrollbarWidth: "none",
                            "-ms-overflow-style": "none",
                        }}
                        options={{
                            // type: "slide",
                            perPage: isMobile ? 2 : isTablet ? 3 : isDesktop ? 4 : 2.5,
                            perMove: 1,
                            pagination: false,
                            rewind: true,
                            arrows: true
                        }}
                    >
                        {category?.map((item, index) => (
                            <SplideSlide>
                                <div className="splideslide" key={index}>
                                    <button style={item?.title === selectedCategory ? { color: "black", border: "3px solid gray" } : { color: "black" }}
                                        className="px-3 py-1 newText"
                                        id="collectionBtn"
                                        onClick={() => handleCategoryClick(item?.title, item?.redirect)}
                                    >
                                        {item.title}
                                    </button>
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>
                <div className="masonry-container mb-2">
                    <Masonry
                        breakpointCols={{ default: 5, 992: 3 }}
                        className="d-flex flex-wrap justify-content-center w-sm-75 w-100"
                        columnClassName="my-masonry-grid_column"
                    >
                        {filteredImages.length > 0
                            ? filteredImages
                            : "No products in this category"}
                    </Masonry>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    {selectedRedirectTo && <button
                        style={{ borderRadius: "50px", background: "#1F1F1E", color: "white", border: "none" }}
                        type="button"
                        class="newText px-3 py-1 mt-lg-5 mt-sm-4"
                        onClick={() => navigate(selectedRedirectTo)}
                    >
                        View More
                    </button>}
                </div>
            </div>
        </>
    );
};