import { useEffect } from "react";
import Footer from "../../global_component/Footer/Footer";
import NavRoute from "../Nav_Route/NavRoute";
import { REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

const ContactUS = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: `/contact-us`,
    title : 'Contact Us'
  })

  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <>
        <NavRoute title="CONTACT US" policy navRouter link="contact-us" />
        <div className="TC container">
            <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            contact Us
            </span>
            <br />
            <span>Any query, suggestion, request, or complaint regarding the Service or
          the PlyPicker can be mailed at{" "}<a href="mailto:info@plypicker.com">
          info@plypicker.com
          </a></span>
          <br/>
            <span className="mt-3" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            Operational Address
            </span>
            <br />
            <span>Sr No. 251/4, Vrindavan Nagar, Pune, Maharashtra, India - 411021.</span>
            <br/>
            <span className="mt-3" style={{ fontWeight: "bold", textTransform: "uppercase" }}>
            Contact us by Phone
            </span>
            <br />
            <span>Call us on 18002088886</span>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/><br/><br/>
        <br/>
        <Footer/>
    </>
  )
}

export default ContactUS