import Rating from '@mui/material/Rating';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_URL,REACT_APP_GA_MEASUREMENT_ID } from '../../../../../constants';
import { addReview, editReviewInPage, setAvgRating } from '../../../../../store/reducers/userActions';
import { useStore } from '../../../../../store/store';
import useAxios from '../../../../../utils/useAxios';
import Footer from '../../../../global_component/Footer/Footer';
import LowerNavigation from '../../../../global_component/LowerNavigation/LowerNavigation';
import { ReactComponent as AddImage } from '../../NewProductDetail/img/AddImage.svg';
import "./rating.css";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function RatingReview() {
    const alert = useAlert();
    const location = useLocation();
    const {id} = useParams()
    const [state, dispatch] = useStore();
    const api = useAxios();
    const [apiData, setApiData] = useState({
        rating:"",
        reviewText:"",
        image:''
    });
    const navigate = useNavigate()
    const [newRating, setNewRating] = useState(0);
    const [reviewImage, setReviewImage] = useState("");
    const [reviewText, setReviewText] = useState("");
    const [imageStatus, setImageStatus] = useState("");
    const [fetchedData, setFetchedData] = useState();

    ReactGA.send({
        hitType: 'pageview',
        page: `rating-review/:${id}`,
        title : 'Services'
    })

    useEffect(() => {
        document.title = "Review Product - PLYPICKER"
        async function checkEditOrAdd(){
            try {
                const res = await axios.get(`${API_URL}/review/editOrAdd/${id}`, {
                    headers: {
                        authorization:`Bearer ${state.accessToken}`,
                    //   id: state.user._id,
                    //   name: "xyz",
                    },
                  });
                  if(res.data.addModal===false){
                    navigate(`/product/${id}`)
                  }
                  setApiData(res.data)
                  
                 if( res.data.editModal){
                    setNewRating(res.data.data.rating)
                    setReviewText(res.data.data.description)
                    setImageStatus(res.data.data?.reviewImage)
                 }
            } catch (error) {
                console.log(error)
            }
           
        }
        async function apiCall(){
            const res = await axios.get(`${API_URL}/products/${id}`, {
                headers: {
                  // name:'xyz'
                }});
                setFetchedData({
                    productId:res.data?._id,
                    productName:res.data?.product_name,
                    productImage:res.data?.attrs.imgs[0],
                    ratingReview:res.data?.rating_and_review,
                    brand:res.data?.brand.Brand_name
                })
                document.title = `Add Review for ${res.data?.product_name} - PLYPICKER`
        }
        apiCall();
        checkEditOrAdd();
    }, [id]);
    const updateReviewHander = async (r_id) => {
        const {url, fields} = await fetch(`${API_URL}/review/addImage`).then(response => response.json())
        const tempURL = `https://${fields.bucket}.s3.ap-south-1.amazonaws.com/${fields.key}`
        setReviewImage(tempURL)
       
        const data = {
            bucket: fields.bucket,
            ...fields,
            'Content-Type': reviewImage.type,
            //   'ACL':'public-read-write',
            file:reviewImage,
        };
        const formData  = new FormData();
        for (const name in data) {
            formData.append(name, data[name]);
        }
        try {
            if (newRating !== 0 & reviewText.length > 0) {
                const res = await api.patch(`/review/${r_id}`, {
                    rating: newRating,
                    review: reviewText,
                    reviewImage:reviewImage
                },{
                    headers:{
                        // name:'xyz'
                    }
                })
                if (res.status === 200) {
                    alert.success(res.data.message);
                    navigate(`/product/${id}`)
                } else {
                    alert.error(res.data.error)
                }
                dispatch(setAvgRating(res?.data?.averageRating))
                dispatch(editReviewInPage(fetchedData.ratingReview,res?.data?.review));
            } else {
                alert.error("Please Add New Rating / Review")
            }
            await fetch(`${url}`, {
                method: 'POST',
                body: formData,
              });
        } catch (err) {
            console.log(err);
        }
    }
    const addReviewHander = async (id) => {
        const {url, fields} = await fetch(`${API_URL}/review/addImage`).then(response => response.json())
        const tempURL = `https://${fields.bucket}.s3.ap-south-1.amazonaws.com/${fields.key}`
        setReviewImage(tempURL)
       
        // console.log(url,fields)
        const data = {
            bucket: fields.bucket,
            ...fields,
            'Content-Type': reviewImage.type,
            //   'ACL':'public-read-write',
            file:reviewImage,
        };
        const formData  = new FormData();
        for (const name in data) {
            formData.append(name, data[name]);
        }
        try {
            if (newRating !== 0 && reviewText.length > 0) {
                const res = await api.post("/review/", {
                    rating: newRating,
                    review: reviewText,
                    productId: id,
                    reviewImage:reviewImage?tempURL:""
                },{
                    headers:{
                        // name:'xyz'
                    }
                })
                dispatch(addReview(fetchedData.ratingReview,res?.data?.newData))
                dispatch(setAvgRating(res?.data?.averageRating))
                alert.success("Review Added Successfully")
                navigate(`/product/${id}`)
             
//   console.log(data)
        
            } else {
                alert.error("Please Add Rating and Review")
            }
        } catch (err) {
            console.log(err);
        }
        const response = await fetch(`${url}`, {
            method: 'POST',
            body: formData,
          });
    }
    const handleImageUpload = async(e) => {
        // console.log(e)
        let file = e.target
        // console.log(file.files,'fileeee')
        setReviewImage(file.files[0])
        setImageStatus(URL.createObjectURL(file.files[0]))
     }
    const handleImageEdit= async(e) => {
        // console.log(e)
        let file = e.target
        const {url, fields} = await fetch(`${API_URL}/review/addImage`).then(response => response.json())
        const tempURL = `https://${fields.bucket}.s3.ap-south-1.amazonaws.com/${fields.key}`
        setReviewImage(tempURL)
        setImageStatus(URL.createObjectURL(e.target.files[0]));
        // console.log(url,fields)
        const data = {
            bucket: fields.bucket,
            ...fields,
            'Content-Type': file.files[0].type,
            //   'ACL':'public-read-write',
            file:file.files[0],
        };
        const formData  = new FormData();
        for (const name in data) {
            formData.append(name, data[name]);
        }
        // console.log(file.files,'fileeee')
        
        const response = await fetch(`${url}`, {
            method: 'POST',
            body: formData,
          });
             }
     
     useEffect(() => {
        window.scroll(0,0);
      }, [])
  return (
    <>
   
        {apiData.editModal?(<> <div className='container'>
        <h1 className='ratingHeading'> Edit Rating & Review</h1>
        <div className='selectedProduct'>
            <div className='selectedProductImageContainer'>
                <img src={fetchedData?.productImage} height="150" width="100" alt="" onClick={()=>{navigate(`/product/${fetchedData.productId}`)}} />
            </div>
    <div className='selectedProductDescription'>
        <h1>{fetchedData?.productName}</h1>
        <h2>{fetchedData?.brand}</h2>
    </div>
        </div>
        <hr/>
        <div className='overallRating'>
            <h1 className='ratingHeading'>Overall Rating</h1>
            <Rating
                                            name="simple-controlled"
                                            value={newRating}
                                            size="large"
                                            onChange={(event, newValue) => {
                                                setNewRating(newValue);
                                            }}
                                        />
        </div>
        <hr/>
        <div className='uploadPhoto'>
                        <h1 >Add a Photo</h1>
                        <h2 >Upload a photo from your device
                        </h2>
                        {imageStatus>1?(<label htmlFor="reviewImage" style={{fontSize:'5px'}}>{imageStatus}
                        </label>):(<label htmlFor="reviewImage" style={{padding:'4px'}}>
                            {/* {imageStatus} */}
                            <span style={{ alignItems: "center", border: "1px dashed rgb(0, 0, 0)", display: "flex", justifyContent: "center", height: "100px", width: "100%", cursor: "pointer" }}><AddImage /></span>
                            <img src={!imageStatus ? reviewImage : imageStatus} alt="+" style={{height:'200px',width:'160px',padding:'4px'}}/>
                        </label>)}
                        
                        <input type="file" id='reviewImage' accept="image/*" onChange={handleImageEdit}/>
                        
        </div>
        <hr/>
        <div className='uploadPhoto'>
                        <h1 >Write a Review </h1>
                        <textarea column="20" rows="10" value={reviewText} onChange={(e)=>{setReviewText(e.currentTarget.value)}} placeholder='Did you like or dislike the product? How was your experience?'></textarea>
        </div>
        <div className='reviewSubmitButtonContainer'>
            <button type='submit' class="btn btn-lg px-4 me-md-2 text-light" style={{backgroundColor:'#FF7703'}} onClick={e=>{updateReviewHander(apiData.data._id)}}>Submit</button>
        </div>
        </div>
         <LowerNavigation/>
         <Footer/></>):(<>
        <div className='container'>
        <h1 className='ratingHeading'> Add Rating & Review</h1>
        <div className='selectedProduct'>
            <div className='selectedProductImageContainer'>
                <img src={fetchedData?.productImage} height="150" width="100" alt="" onClick={()=>{navigate(`/product/${fetchedData.productId}`)}} />
            </div>
    <div className='selectedProductDescription'>
        <h1>{fetchedData?.productName}</h1>
        <h2>{fetchedData?.brand}</h2>
    </div>
        </div>
        <hr/>
        <div className='overallRating'>
            <h1 className='ratingHeading'>Overall Rating</h1>
            <Rating
                                        name="simple-controlled"
                                        value={newRating}
                                        size="large"
                                        onChange={(event, newValue) => {
                                            setNewRating(newValue);
                                        }}
                                    />
        </div>
        <hr/>
        <div className='uploadPhoto'>
                        <h1 >Add a Photo</h1>
                        <h2 >Upload a photo from your device
                        </h2>
                        {!imageStatus?
                        (<label htmlFor="reviewImage" > + <br/><span style={{fontSize:'5px'}}>{imageStatus}</span>
                        </label>):
                        (<label htmlFor="reviewImage" style={{padding:'4px'}}>
                            {/* {imageStatus} */}
                            <span style={{ alignItems: "center", border: "1px dashed rgb(0, 0, 0)", display: "flex", justifyContent: "center", height: "100px", width: "100%", cursor: "pointer" }}><AddImage /></span>
                            <img src={!imageStatus ? reviewImage : imageStatus} alt="+" style={{height:'200px',width:'160px',padding:'4px'}}/>
                        </label>)}
                        
                        <input type="file" id='reviewImage' accept="image/*" onChange={handleImageUpload}/>
                        
        </div>
        <hr/>
        <div className='uploadPhoto'>
                        <h1 >Write a Review </h1>
                        <textarea column="20" rows="10" value={reviewText} onChange={(e)=>{setReviewText(e.currentTarget.value)}} placeholder='Did you like or dislike the product? How was your experience?'></textarea>
        </div>
        <div className='reviewSubmitButtonContainer'>
            <button type='submit' class="btn btn-lg px-4 me-md-2 text-light" style={{backgroundColor:'#FF7703'}} onClick={e=>{addReviewHander(id)}}>Submit</button>
        </div>
        </div>
         <LowerNavigation/>
         <Footer/></>)}
        
        </>
   
  )
}

export default RatingReview