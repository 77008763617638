import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  addToCart2,
  removeFromWishlistAction,
} from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import Footer from "../../../../global_component/Footer/Footer";
import LowerNavigation from "../../../../global_component/LowerNavigation/LowerNavigation";
import SideBar from "../../../../global_component/SideBar/SideBar";
import NavRoute from "../../../Nav_Route/NavRoute";
import { ReactComponent as Heart } from "../images/heart.svg";
import { BsArrowLeft } from "react-icons/bs";
import "./Wishlist.css";
import ReactGA from "react-ga4";
import {
  REACT_APP_GA_MEASUREMENT_ID
} from "../../../../../constants";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

function Wishlist() {
  const api = useAxios();
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isLoadingWishlist, setIsLoadingWishlist] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const alert = useAlert();
  const [state, dispatch] = useStore();

  ReactGA.send({
    hitType: 'pageview',
    page: '/wishlist',
    title: 'Wishlist'
  })

  let isMobile = useMediaQuery({
    query: "(max-width:576px)",
  });

  const removeFromWishlist = async (id) => {

    ReactGA.event({
      category: 'wishlist',
      action: 'removed',
      label: 'remove' + id,
      value: id
    })

    try {
      dispatch?.(removeFromWishlistAction(id))
      const res = await api?.delete(`/wishlist/${id}`);
      if (res?.data?.error) {
        alert?.error(res.data.error);
      } else {
        alert?.success("Removed from wishlist");
        setWishlist((prevWishlist) => prevWishlist?.filter((item) => item?._id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addToCart = async (id) => {

    ReactGA.event({
      category: 'shopping-cart',
      action: 'added',
      label: 'add ' + id + 'to shopping cart ',
      value: id
    })

    try {
      setIsButtonDisabled(true);
      dispatch?.(removeFromWishlistAction(id))
      const res = await api?.delete(`/wishlist/cart/${id}`);
      if (res?.data?.error) {
        alert?.error(res.data.error);
      } else {
        alert?.success("Added to cart.");
        dispatch?.(addToCart2(id))
        if (wishlist?.length === 1) {
          navigate?.("/cart");
        }
        setWishlist((prevWishlist) => prevWishlist?.filter((item) => item?._id !== id));
      }
      setIsButtonDisabled(false);
    } catch (err) {
      console.log(err);
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoadingWishlist(true);
      const res = await api?.get("/wishlist");
      setWishlist(res?.data);
      setIsLoadingWishlist(false);
      document.title = "Wishlist - PLYPICKER";
    })();
  }, []);

  function numberWithCommas(x) {
    return x?.toString()?.split('.')[0]?.length > 3
      ? x?.toString()?.substring(0, x.toString()?.split('.')[0]?.length - 3)?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString()?.substring(x.toString()?.split('.')[0]?.length - 3)
      : x?.toString();
  }

  return (
    <>
      {isMobile ? (
        <div className='MobileView'>
          <span className="headingView">
            <BsArrowLeft onClick={() => navigate?.(-1)} style={{ fontSize: "30px" }} /> Wishlist
          </span>
        </div>
      ) : (
        <NavRoute title="wishlist" />
      )}
      <div className="sidebarProfilePageContainer w-100 px-0" style={!isMobile ? { borderTop: "1px solid black" } : {}}>
        <div className="row m-0 p-0 w-100">
          <SideBar title="wishlist" />
          <div className="col-sm-9 col-12 d-flex flex-column px-lg-3 justify-content-between ps-3 pe-0">
            <div className="renderside_info">
              {isLoadingWishlist ? (
                <div className="wishlistLoadingDiv">
                  <h4 className="text-center">Loading... <CircularProgress style={{ "color": "#ff7703" }} /> </h4>
                </div>
              ) : null}
              {!isLoadingWishlist && wishlist?.length === 0 ? (
                <div className="wishlistLoadingDiv">
                  <h4 className="text-center">No products in wishlist!</h4>
                </div>
              ) : null}
              {wishlist?.map((item) => (
                <div key={item?._id} className="me-lg-4 mg-sm-2 me-0 wishlistpage_info">
                  <div className="wishlist_img">
                    <img src={item?.attrs?.imgs[0]} alt="" onClick={() => navigate?.(`/product/${item?._id}`)} style={{ cursor: "pointer" }} />
                  </div>
                  <div className="wishlist_text">
                    <div className="wishlist_product_text_container">
                      <div className="wishlist_product_heading" onClick={() => navigate?.(`/product/${item?._id}`)}>
                        {item?.product_name}
                      </div>
                      <div className="wishlist_product_company" onClick={() => navigate?.(`/productlist?brand=${item?.brand?._id}`)}>
                        {item?.brand?.Brand_name}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-end">
                          {item?.discounted_price ? (
                            <>
                              <span className="wishlistPrice">Rs. {numberWithCommas(item?.discounted_price)}</span>
                              <span className="reducedprice d-lg-block d-none">Rs. {numberWithCommas(item?.price)}</span>
                              <span className="discount d-lg-block d-none">
                                {/* {getDiscountPercent(state?.product?.price, state?.product?.discounted_price)} */}
                                -{Math.round(item?.discounted_percent)}%
                              </span>
                            </>
                          ) : (
                            <>
                              <p className="m-0 p-0 d-md-block d-none">Price not available at this moment</p>
                            </>
                          )}
                        </div>
                        {item?.discounted_price ? (
                          <button
                            className="wishlist_product_add mt-2"
                            onClick={() => {
                              if (item?.discounted_price) {
                                addToCart(item?._id)
                              } else {
                                alert?.error("This product doesn't have a price at this moment")
                              }
                            }}
                            disabled={isButtonDisabled}
                          >
                            Move to cart
                          </button>
                        ) : (
                          <button
                            className="wishlist_product_add mt-2"
                            onClick={() => {
                              navigate?.(`/product/${item?._id}`);
                            }}
                          >
                            Get Price
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="wishlist_hearticon">
                      <Heart
                        className="borderColor_orange"
                        style={{ height: "25px", width: "25px" }}
                        onClick={() => removeFromWishlist(item?._id)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <LowerNavigation />
      <Footer />
    </>
  );
}

export default Wishlist;
