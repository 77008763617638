import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

// import required modules
import { Autoplay } from 'swiper/modules';

export default function NewTopBrands({ data }) {
    const navigate = useNavigate();
    let isDesktop = useMediaQuery({
        query: "(min-width:1400px)",
    });

    let isTablet = useMediaQuery({
        query: "(max-width:992px)",
    });

    let isMobile = useMediaQuery({
        query: "(max-width:576px)",
    });

    let minScreen = useMediaQuery({
        maxWidth: 1300,
        minWidth: 993,
    });
    const propData = data?.data;
    return (
        <div className="compBottomMargin">
            <span className="newHeading">Top Brands</span>
            <Swiper
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false
                }}
                modules={[Autoplay]}
                className="mySwiper" slidesPerView={3} >
                {propData?.map((item, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="pe-2" style={{ cursor: "pointer" }} onClick={() => navigate(item?.redirect)}>
                                <img
                                    src={item.url}
                                    alt="Interior Design Image"
                                    className="w-100"
                                />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
};
