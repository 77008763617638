import Rating from '@mui/material/Rating';
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BsArrowDownCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { addReview, setAvgRating } from "../../../../../store/reducers/userActions";
import { useStore } from "../../../../../store/store";
import useAxios from "../../../../../utils/useAxios";
import "./YourOrders.css";

function OrderMoreThan2({ order }) {
  const navigate = useNavigate()
  const api = useAxios();
  const [state, dispatch] = useStore();
  const [orderMoreAccordion, setOrderMoreAccordion] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [newRating, setNewRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [product, setProduct] = useState({})
  const hideReviewModal = () => {
    setReviewModal(false);
    setNewRating(0);
    setReviewText("");
  };

  const checkReview = (allRating) => {
    // console.log("testdata",allRating)
    //   allRating?.rating_and_review?.some(function (x) {
    //     if (x.user_id === state.user?._id) {
    //         return true;
    //     } else {
    //       return false;
    //     }
    // })
    if (allRating?.rating_and_review.length > 0) {
      for (let item of allRating?.rating_and_review) {
        if (item.user_id === state.user?._id) {
          return true;
        }
      }
    }
    return false;
  }

  const showReviewModal = (e) => {
    setProduct(e)
    setReviewModal(true);
  }

  const addReviewHander = async (array, p_id) => {
    try {
      if (newRating !== 0 && reviewText.length > 0) {
        const res = await api.post("/review/", {
          rating: newRating,
          review: reviewText,
          productId: p_id
        })
        dispatch(addReview(array, res.data.newData))
        setReviewModal(false);
        dispatch(setAvgRating(res.data?.averageRating))
        alert.success("Review Added Successfully")
      } else {
        alert.error("Please Add Rating and Review")
      }
    } catch (err) {
      console.log(err);
    }
  }

  function numberWithCommas(x) {
    return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
  }

  return (
    <>
      {reviewModal && (
        <Modal
          show={reviewModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={hideReviewModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addRatingModalBody">
              <Rating
                name="simple-controlled"
                value={newRating}
                size="large"
                onChange={(event, newValue) => {
                  setNewRating(newValue);
                }}
              />
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Review</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
              </Form>
              <Button
                className="submitReview"
                onClick={() => addReviewHander(product.product?.rating_and_review, product.product._id)}
              >
                Add Review
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {orderMoreAccordion ? (
        <>
          {order.data.order_items.map((e) => (
            order.title === "orders"?
            <>
              <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
                <div className="p-1">
                  <img onClick={() => navigate(`/product/${e?.product?._id}`)} src={e?.image} className="orderComponentImg" />
                </div>
                <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                  <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                    <div className="mw-100">
                      <h5 className="orderComponentProductName" onClick={() => navigate(`/product/${e?.product?._id}`)} style={{ cursor: "pointer" }}>{e?.name}</h5>
                      <span onClick={() => navigate(`/productlist?brand=${e?.product?.brand?._id}`)} className='wishlist_product_company'>
                        {e?.product?.brand?.Brand_name}
                      </span>
                      {/* <div>
                        {e?.variation && Object.entries(e?.variation).map(([variationKey, variationValue]) => <>
                          <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                        </>)}
                      </div> */}
                    </div>
                    <div>
                      <div className="current_orders_orderID text-dark">
                        Quantity: {e?.quantity}
                      </div>
                    </div>
                  </div>
                  <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                    <p className="m-0 p-0 text-end">
                      Rs. {numberWithCommas(e?.price)}
                    </p>
                  </div>
                </div>
              </div>
              <span className="d-flex justify-content-sm-end justify-content-start">
                {order.data.delivery.status === "delivered" ? !checkReview(e?.product) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Edit Review</button>) : ""}
              </span>
              <hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />
            </>:
            <>
              <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
                <div className="p-1">
                  <img onClick={() => navigate(`/hire-artisans/service/${e?.service?._id}`)} src={e?.image} className="orderComponentImg" />
                </div>
                <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                  <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                    <div className="mw-100">
                      <h5 className="orderComponentProductName" onClick={() => navigate(`/hire-artisans/service/${e?.service?._id}`)} style={{ cursor: "pointer" }}>{e?.name}</h5>
                      <span onClick={() => navigate(`/hire-artisans/${e?.service?.serviceCategory?._id}/${e?.service?.serviceSubcategory?._id}`)} className='wishlist_product_company'>
                        {e?.service?.serviceSubcategory?.serviceSubcategory}
                      </span>
                      {/* <div>
                        {e?.variation && Object.entries(e?.variation).map(([variationKey, variationValue]) => <>
                          <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                        </>)}
                      </div> */}
                    </div>
                    <div>
                      <div className="current_orders_orderID text-dark">
                        Quantity: {e?.quantity}
                      </div>
                    </div>
                  </div>
                  <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                    <p className="m-0 p-0 text-end">
                      Rs. {numberWithCommas(e?.price)}
                    </p>
                  </div>
                </div>
              </div>
              {/* <span className="d-flex justify-content-sm-end justify-content-start">
                {order.data.payment.status !== "PENDING" ? !checkReview(e?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Edit Review</button>) : ""}
              </span> */}
              <hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />
            </>
          ))}
          <div
            className="d-flex justify-content-center align-items-center p-4 m-auto w-100 show-prop"
            style={{ color: "#878787" }}
            onClick={() => {
              setOrderMoreAccordion(false);
            }}
          >
            Show Less
          </div>
        </>
      ) : (
        order.title === "orders" ? 
        <>
          <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
            <div className="p-1">
              <img onClick={() => navigate(`/product/${order.data.order_items[0]?.product?._id}`)} src={order.data.order_items[0]?.image} className="orderComponentImg" />
            </div>
            <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
              <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                <div className="mw-100">
                  <h5 className="orderComponentProductName" onClick={() => navigate(`/product/${order.data.order_items[0]?.product?._id}`)} style={{ cursor: "pointer" }}>{order.data.order_items[0]?.name}</h5>
                  <span onClick={() => navigate(`/productlist?brand=${order.data.order_items[0]?.product?.brand?._id}`)} className='wishlist_product_company'>
                    {order.data.order_items[0]?.product?.brand?.Brand_name}
                  </span>
                  {/* <div>
                    {order.data.order_items[0]?.variation && Object.entries(order.data.order_items[0]?.variation).map(([variationKey, variationValue]) => <>
                      <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                    </>)}
                  </div> */}
                </div>
                <div>
                  <div className="current_orders_orderID text-dark">
                    Quantity: {order.data.order_items[0]?.quantity}
                  </div>
                </div>
              </div>
              <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                <p className="m-0 p-0 text-end">
                  Rs. {numberWithCommas(order.data.order_items[0]?.price)}
                </p>
              </div>
            </div>
          </div>
          <span className="d-flex justify-content-sm-end justify-content-start">
            {order.data.delivery.status === "delivered" ? !checkReview(order.data.order_items[0]?.product) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[0].product._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[0].product._id}`) }}>Edit Review</button>) : ""}
          </span>
          <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
            <div className="p-1">
              <img onClick={() => navigate(`/product/${order.data.order_items[1]?.product?._id}`)} src={order.data.order_items[1]?.image} className="orderComponentImg" />
            </div>
            <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
              <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                <div className="mw-100">
                  <h5 className="orderComponentProductName" onClick={() => navigate(`/product/${order.data.order_items[1]?.product?._id}`)} style={{ cursor: "pointer" }}>{order.data.order_items[1]?.name}</h5>
                  <span onClick={() => navigate(`/productlist?brand=${order.data.order_items[1]?.product?.brand?._id}`)} className='wishlist_product_company'>
                    {order.data.order_items[1]?.product?.brand?.Brand_name}
                  </span>
                  {/* <div>
                    {order.data.order_items[1]?.variation && Object.entries(order.data.order_items[1]?.variation).map(([variationKey, variationValue]) => <>
                      <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                    </>)}
                  </div> */}
                </div>
                <div>
                  <div className="current_orders_orderID text-dark">
                    Quantity: {order.data.order_items[1]?.quantity}
                  </div>
                </div>
              </div>
              <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                <p className="m-0 p-0 text-end">
                  Rs. {numberWithCommas(order.data.order_items[1]?.price)}
                </p>
              </div>
            </div>
          </div>
          <span className="d-flex justify-content-sm-end justify-content-start">
            {order.data.delivery.status === "delivered" ? !checkReview(order.data.order_items[1]?.product) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[1].product._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[1].product._id}`) }}>Edit Review</button>) : ""}
          </span>
          <hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />
          <div
            className="moreButton d-flex justify-content-center align-items-center p-4 m-auto show-prop w-100"
            style={{ color: "#878787" }}
            // onClick={() => {
            //   setOrderMoreAccordion(true);
            // }}
            onClick={()=>{
              navigate(`/order/${order.data?.orderid}`)
            }}
          >
            <BsArrowDownCircle className='downArrowBtn' />  +{order.data.order_items.length - 2} more items
          </div>
        </>:
        <>
        <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
            <div className="p-1">
              <img onClick={() => navigate(`/hire-artisans/service/${order.data.order_items[0]?.service?._id}`)} src={order.data.order_items[0]?.image} className="orderComponentImg" />
            </div>
            <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
              <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                <div className="mw-100">
                  <h5 className="orderComponentProductName" onClick={() => navigate(`/hire-artisans/service/${order.data.order_items[0]?.service?._id}`)} style={{ cursor: "pointer" }}>{order.data.order_items[0]?.name}</h5>
                  <span onClick={() => navigate(`/hire-artisans/${order.data.order_items[0]?.service?.serviceCategory?._id}/${order.data.order_items[0]?.service?.serviceSubcategory?._id}`)} className='wishlist_product_company'>
                    {order.data.order_items[0]?.service?.serviceSubcategory?.serviceSubcategory}
                  </span>
                  {/* <div>
                    {order.data.order_items[0]?.variation && Object.entries(order.data.order_items[0]?.variation).map(([variationKey, variationValue]) => <>
                      <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                    </>)}
                  </div> */}
                </div>
                <div>
                  <div className="current_orders_orderID text-dark">
                    Quantity: {order.data.order_items[0]?.quantity}
                  </div>
                </div>
              </div>
              <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                <p className="m-0 p-0 text-end">
                  Rs. {numberWithCommas(order.data.order_items[0]?.price)}
                </p>
              </div>
            </div>
          </div>
          {/* <span className="d-flex justify-content-sm-end justify-content-start">
            {order.data.payment.status !== "PENDING" ? !checkReview(order.data.order_items[0]?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[0].service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[0].service._id}`) }}>Edit Review</button>) : ""}
          </span> */}
          <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
            <div className="p-1">
              <img onClick={() => navigate(`/hire-artisans/service/${order.data.order_items[1]?.service?._id}`)} src={order.data.order_items[1]?.image} className="orderComponentImg" />
            </div>
            <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
              <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                <div className="mw-100">
                  <h5 className="orderComponentProductName" onClick={() => navigate(`/hire-artisans/service/${order.data.order_items[1]?.service?._id}`)} style={{ cursor: "pointer" }}>{order.data.order_items[1]?.name}</h5>
                  <span onClick={() => navigate(`/hire-artisans/${order.data.order_items[1]?.service?.serviceCategory?._id}/${order.data.order_items[1]?.service?.serviceSubcategory?._id}`)} className='wishlist_product_company'>
                    {order.data.order_items[1]?.service?.serviceSubcategory?.serviceSubcategory}
                  </span>
                  {/* <div>
                    {order.data.order_items[1]?.variation && Object.entries(order.data.order_items[1]?.variation).map(([variationKey, variationValue]) => <>
                      <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                    </>)}
                  </div> */}
                </div>
                <div>
                  <div className="current_orders_orderID text-dark">
                    Quantity: {order.data.order_items[1]?.quantity}
                  </div>
                </div>
              </div>
              <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                <p className="m-0 p-0 text-end">
                  Rs. {numberWithCommas(order.data.order_items[1]?.price)}
                </p>
              </div>
            </div>
          </div>
          {/* <span className="d-flex justify-content-sm-end justify-content-start">
            {order.data.payment.status !== "PENDING" ? !checkReview(order.data.order_items[1]?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[1].service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${order.data.order_items[1].service._id}`) }}>Edit Review</button>) : ""}
          </span> */}
          <hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />
          <div
            className="moreButton d-flex justify-content-center align-items-center p-4 m-auto show-prop w-100"
            style={{ color: "#878787" }}
            // onClick={() => {
            //   setOrderMoreAccordion(true);
            // }}
            onClick={()=>{
              navigate(`/order/${order.data?.orderid}`)
            }}
          >
            <BsArrowDownCircle className='downArrowBtn' />  +{order.data.order_items.length - 2} more items
          </div>
        </>
      )}
    </>
  );
}

function OrderComponent({ order }) {
  console.log("err",order)
  const navigate = useNavigate()
  const api = useAxios();
  const [state, dispatch] = useStore();
  const [orderMoreAccordion, setOrderMoreAccordion] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [newRating, setNewRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [product, setProduct] = useState({})
  const hideReviewModal = () => {
    setReviewModal(false);
    setNewRating(0);
    setReviewText("");
  };
  const checkReview = (allRating) => {
    // console.log("testdata",allRating)
    //   allRating?.rating_and_review?.some(function (x) {
    //     if (x.user_id === state.user?._id) {
    //         return true;
    //     } else {
    //       return false;
    //     }
    // })
    if (allRating?.rating_and_review?.length > 0) {
      for (let item of allRating?.rating_and_review) {
        if (item.user_id === state.user?._id) {
          return true;
        }
      }
    }
    return false;
  }

  const showReviewModal = (e) => {
    setProduct(e)
    setReviewModal(true);
  }
  // console.log("testing",checkReview(order.data.order_items[0].product))
  const addReviewHander = async (array, p_id) => {
    try {
      if (newRating !== 0 && reviewText.length > 0) {
        const res = await api.post("/review/", {
          rating: newRating,
          review: reviewText,
          productId: p_id
        })
        dispatch(addReview(array, res.data.newData))
        setReviewModal(false);
        dispatch(setAvgRating(res.data?.averageRating))
        alert.success("Review Added Successfully")
      } else {
        alert.error("Please Add Rating and Review")
      }
    } catch (err) {
      console.log(err);
    }
  }
  function numberWithCommas(x) {
    return x?.toString().split('.')[0].length > 3 ? x?.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x?.toString().substring(x.toString().split('.')[0].length - 3) : x?.toString();
  }
  return (
    <>
      {reviewModal && (
        <Modal
          show={reviewModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={hideReviewModal}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add Review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="addRatingModalBody">
              <Rating
                name="simple-controlled"
                value={newRating}
                size="large"
                onChange={(event, newValue) => {
                  setNewRating(newValue);
                }}
              />
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Review</Form.Label>
                  <Form.Control
                    as="textarea"
                    value={reviewText}
                    onChange={(e) => setReviewText(e.target.value)}
                    rows={3}
                  />
                </Form.Group>
              </Form>
              <Button
                className="submitReview"
                onClick={() => addReviewHander(product.product?.rating_and_review, product.product._id)}
              >
                Add Review
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {order.data.order_items.length > 2 ? (
        <OrderMoreThan2 order={order} />
      ) : (
        order.data.order_items.map((e) => (
          order.title === "orders" ?
          <>
          <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
              <div className="p-1">
                <img onClick={() => navigate(`/product/${e?.product?._id}`)} src={e?.image} className="orderComponentImg" />
              </div>
              <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                  <div className="mw-100">
                    <h5 className="orderComponentProductName" onClick={() => navigate(`/product/${e?.product?._id}`)} style={{ cursor: "pointer" }}>{e?.name}</h5>
                    <span onClick={() => navigate(`/productlist?brand=${e?.product?.brand?._id}`)} className='wishlist_product_company'>
                      {e?.product?.brand?.Brand_name}
                    </span>
                    {/* <div>
                      {e?.variation && Object.entries(e?.variation).map(([variationKey, variationValue]) => <>
                        <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                      </>)}
                    </div> */}
                  </div>
                  <div>
                    <div className="current_orders_orderID text-dark">
                      Quantity: {e?.quantity}
                    </div>
                  </div>
                </div>
                <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                  <p className="m-0 p-0 text-end">
                    Rs. {numberWithCommas(e?.price)}
                  </p>
                </div>
              </div>
            </div>
            <span className="d-flex justify-content-sm-end justify-content-start">
              {order.data.delivery.status === "delivered" ? !checkReview(e?.product) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.product._id}`) }}>Edit Review</button>) : ""}
            </span>

            {order.data.order_items?.length > 1?<hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />:""}
          
          </>:
          <>
          <div className="d-flex flex-row px-2 py-2" style={{ boder: "1px solid black" }}>
              <div className="p-1">
                <img onClick={() => navigate(`/hire-artisans/service/${e?.service?._id}`)} src={e?.image} className="orderComponentImg" />
              </div>
              <div className="d-flex flex-wrap flex-row orderComponentOrderDetailDiv ps-3">
                <div className="col-lg-8 col-12 d-flex flex-column justify-content-lg-between justify-content-start">
                  <div className="mw-100">
                    <h5 className="orderComponentProductName" onClick={() => navigate(`/hire-artisans/service/${e?.service?._id}`)} style={{ cursor: "pointer" }}>{e?.name}</h5>
                    <span onClick={() => navigate(`/hire-artisans/${e?.service?.serviceCategory?._id}/${e?.service?.serviceSubcategory?._id}`)} className='wishlist_product_company'>
                      {e?.service?.serviceSubcategory?.serviceSubcategory}
                    </span>
                    {/* <div>
                      {e?.variation && Object.entries(e?.variation).map(([variationKey, variationValue]) => <>
                        <div className="yourOrdersVariationBox mx-2 px-3 py-1 sm-my-2 ms-0">{variationKey}: {variationValue}</div>
                      </>)}
                    </div> */}
                  </div>
                  <div>
                    <div className="current_orders_orderID text-dark">
                      Quantity: {e?.quantity}
                    </div>
                  </div>
                </div>
                <div className="priceOrder col-lg-4 col-12 d-flex align-items-center justify-content-lg-end justify-content-start text-dark">
                  <p className="m-0 p-0 text-end">
                    Rs. {numberWithCommas(e?.price)}
                  </p>
                </div>
              </div>
            </div>
            {/* <span className="d-flex justify-content-sm-end justify-content-start">
              {order.data.payment.status !== "PENDING" ? !checkReview(e?.service) ? (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Review Product</button>) : (<button className="CloseaddUpdateButton" onClick={() => { navigate(`/rating-review/${e.service._id}`) }}>Edit Review</button>) : ""}
            </span> */}

            {order.data.order_items?.length > 1?<hr style={{ margin: "0px", width: "95%", marginLeft: "auto", marginRight: "auto" }} />:""}
          
          </>
        ))
      )}
    </>
  );
}

export default OrderComponent;