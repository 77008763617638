import { useState, createRef, useEffect } from "react";
import useAxios from "../../../utils/useAxios";
import { API_URL, REACT_APP_GA_MEASUREMENT_ID } from "../../../constants";
import { useStore } from "../../../store/store";
import { useParams } from "react-router-dom";
import LoginRegisterPopup from "../../global_component/LoginRegisterPopup/LoginRegisterPopup";
import { PDFExport } from "@progress/kendo-react-pdf";
import { BsAsterisk } from "react-icons/bs";
import "./Invoice.css";
import ReactGA from "react-ga4";

ReactGA.initialize(REACT_APP_GA_MEASUREMENT_ID);

export default function Invoice({ type }) {
  const api = useAxios();
  const [state, dispatch] = useStore();
  const { invoiceId } = useParams();
  const ref = createRef();
  const [invoiceHtml, setInvoiceHtml] = useState({ __html: "" });
  const [error, setError] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoginPopupVisible, setIsLoginPopupVisible] = useState(false);

  ReactGA.send({
    hitType: "pageview",
    page: `/invoices/${invoiceId}`,
    title: "Invoice",
  });

  async function getInvoice() {
    if (type !== "product" && type !== "service") {
      return;
    }
    if (!state.authenticated || !state.accessToken) {
      setIsLoginPopupVisible(true);
    } else {
      let res;
      if (type === "product") {
        res = await api.get(`/orders/invoices/${invoiceId}`);
      } else if (type === "service") {
        res = await api.get(`/servicesorder/serviceinvoices/${invoiceId}`);
      }
      if (res.data.err) {
        setIsError(true);
        setError(res.data.msg);
      } else {
        setInvoiceHtml({ __html: res.data.invoiceHtml });
      }
    }
  }

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    getInvoice();
  }, [isLoginPopupVisible]);

  return (
    <>
      <LoginRegisterPopup
        isVisible={isLoginPopupVisible}
        setIsVisible={setIsLoginPopupVisible}
      />
      <>
        {isError && (
          <>
            <p
              className="mx-auto mt-3 text-center"
              style={{ fontWeight: "bold" }}
            >
              {error}
            </p>
          </>
        )}
      </>
      {!isError && (
        <>
          <div className="mx-auto" style={{ maxWidth: "1000px" }}>
            <span className="download_warning">
              <BsAsterisk />
              <span style={{ fontWeight: "bold" }}>
                If you are unable to download invoice check permission of your
                browser or change your browser
              </span>
            </span>
            <button
              className="changeAddressButton mt-3"
              style={{ marginLeft: "10%" }}
              onClick={() => {
                if (ref.current) {
                  ref.current.save();
                }
              }}
            >
              Download Invoice as PDF
            </button>
            <PDFExport
              paperSize="A3"
              author="Plypicker Pvt Ltd"
              creator="Plypicker Pvt Ltd"
              fileName={`Invoice-${invoiceId}.pdf`}
              scale={0.8}
              repeatHeaders={true}
              ref={ref}
              date={new Date()}
              margin={20}
            >
              <div>
                <div
                  id="invoiceHtml"
                  dangerouslySetInnerHTML={invoiceHtml}
                ></div>
              </div>
            </PDFExport>
          </div>
        </>
      )}
    </>
  );
}
